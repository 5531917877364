import { createRef, useState, useEffect } from 'react';

/**
 * React component for a select search dropdown.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The id of the component.
 * @param {string} props.title - The title of the component.
 * @param {string} props.name - The name of the component.
 * @param {string} [props.value] - The value of the component.
 * @param {string} [props.className] - The class name of the component.
 * @param {boolean} [props.disabled] - Whether the component is disabled.
 * @param {Array<Object>} props.options - The options of the component.
 * @param {function} [props.onChange] - The function to be called when the value of the component changes.
 * @param {function} [props.onBlur] - The function to be called when the component loses focus.
 * @param {function} [props.onFocus] - The function to be called when the component gains focus.
 * @param {Array<string>} [props.permanentValues] - The permanent values of the component.
 */
export default function SelectSearch({
  id,
  title,
  name,
  value = '',
  className,
  disabled = false,
  options,
  onChange,
  onBlur,
  onFocus,
  permanentValues = [],
}) {
  const dropdownRef = createRef();
  const selectRef = createRef();
  const inputRef = createRef();
  // Get the default option values.
  const defaultOption = options.filter((option) => option.value === value);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [inputValue, setInputValue] = useState(
    defaultOption.length ? defaultOption[0].value : '',
  );
  const [inputLabel, setInputLabel] = useState(
    defaultOption.length ? defaultOption[0].name : '',
  );
  const [filterOptions, setFilterOptions] = useState(false);
  const [focus, setFocus] = useState(false);

  /**
   * Filter the dropdown by what the user is typing.
   *
   * @param {Object} event - The event object.
   * @param {string} event.target.value - The value of the input.
   */
  const filter = ({ target: { value } }) => {
    const filter = value.toUpperCase();
    // Get all of the permanent options
    // that should stay in the filter
    const filteredOptions = filter
      ? options.filter((option) => {
          return (
            option.name.toUpperCase().indexOf(filter) > -1 ||
            (permanentValues.length &&
              permanentValues.indexOf(option.value) > -1)
          );
        })
      : false;
    setFilterOptions(filteredOptions);
  };

  // When the user clicks on the button,
  // toggle between hiding and showing the dropdown content
  const openDropdown = () => {
    setFocus(true);
    // Trigger select focus.
    selectRef.current?.focus();
    // We want to reset the selected index
    // to make sure the selected value and input
    // value match up when the dropdown is open.
    const defaultOption = options
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.value === inputValue);
    setSelectedIndex(defaultOption.length ? defaultOption[0].index : 0);
  };

  /**
   * Set the select field value.
   *
   * @param {Object} event - The event object.
   * @param {string} event.name - The name of the selected option.
   * @param {string} event.value - The value of the selected option.
   * @param {number} index - The index of the selected option.
   */
  const select = ({ name, value }, index) => {
    setInputLabel(name);
    setInputValue(value);
    setSelectedIndex(index);
  };

  // Close the dropdown and reset the focus state.
  const closeDropdown = () => {
    // we want to trigger the select change function
    const event = new Event('change', { bubbles: true });
    selectRef.current?.dispatchEvent(event);
    // Hide the dropdown.
    setFilterOptions(false);
    setFocus(false);
    // refocus the select list
    // so we can trigger the
    // blur handler.
    selectRef.current?.focus();
  };

  /**
   * Scrolls the list option into view.
   *
   * @param {string} behavior - The behavior of the scroll effect
   *  Either 'instant', 'smooth' or 'auto'.
   */
  const scrollOptionToView = (behavior) => {
    const dropdownList = filterOptions ? filterOptions : options;
    // Get the selected li div.
    const listDiv = document.getElementById(dropdownList[selectedIndex]?.value);
    if (listDiv) {
      // We always want to keep the
      // li option in View.
      listDiv.scrollIntoView({
        behavior: behavior,
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (focus) {
      const handleKeyDown = (e) => {
        // scroll the list option into view.
        const dropdownList = filterOptions ? filterOptions : options;
        // Check to see what key was pressed.
        switch (e.key) {
          case 'ArrowUp':
            // prevent default behavior
            e.preventDefault();
            setSelectedIndex((prevIndex) => Math.max(0, prevIndex - 1));
            scrollOptionToView('smooth');
            break;
          case 'ArrowDown':
            // prevent default behavior
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
              Math.min(dropdownList.length - 1, prevIndex + 1),
            );
            scrollOptionToView('smooth');
            break;
          case 'Enter':
            // prevent default behavior
            e.preventDefault();
            // select the current selected index option.
            select(dropdownList[selectedIndex], selectedIndex);
            break;
          default:
            break;
        }
      };
      // Bind the event listener
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [selectedIndex, focus, filterOptions]);

  useEffect(() => {
    if (inputValue) {
      closeDropdown();
    }
  }, [inputValue]);

  useEffect(() => {
    if (focus) {
      // toggle focus if clicked outside of dropdown
      function handleClickOutside(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          closeDropdown();
        }
      }
      // Separate function to check if clicked outside of dropdown
      // that doesn't check if event target is inside dropdown.
      function handleClickOutsideNoCheck(event) {
        closeDropdown();
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', function (e) {
        if (e.key === 'Tab') {
          handleClickOutsideNoCheck(e);
        }
      });

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleClickOutsideNoCheck);
      };
    }
  }, [dropdownRef, focus]);

  useEffect(() => {
    if (focus) {
      // Trigger the input focus.
      inputRef.current?.focus();
      scrollOptionToView('instant');
    }
  }, [focus]);

  // Get the displayed dropdown options.
  const dropdownOptions = filterOptions ? filterOptions : options;

  // We want to keep the permanent options at the bottom of the dropdown,
  // if the permanentValues array is not empty.
  if (permanentValues.length) {
    dropdownOptions.sort(function (a, b) {
      const indexA = permanentValues.indexOf(a.value);
      const indexB = permanentValues.indexOf(b.value);
      if (indexA === -1 && indexB === -1) {
        return 0;
      }
      // index will be -1 (doesn't occur), 0 or 1
      return indexA - indexB;
    });
  }

  return (
    <div className="search-dropdown">
      <button
        type="button"
        onClick={openDropdown}
        className={`search-dropdown-btn ${className}`}
        title={title}
        id={`search-${id}`}
        disabled={disabled}
        onFocus={openDropdown}
        role="button"
        aria-haspopup="listbox"
        tabIndex="0"
        aria-expanded={focus}>
        {inputLabel ? <span>{inputLabel}</span> : <span>{title}</span>}
      </button>
      {focus && (
        <div className="search-dropdown-content" ref={dropdownRef}>
          <div className="bs-searchbox">
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              onKeyUp={filter}
              ref={inputRef}
              onChange={() => {
                // when typing something
                // we want to reset the selected index
                // and scroll into view.
                setSelectedIndex(0);
                scrollOptionToView('instant');
              }}
            />
          </div>
          <ul role="listbox" aria-labelledby={`search-${id}`}>
            {dropdownOptions &&
              dropdownOptions.map((item, index) => {
                let style = {};
                if (index === selectedIndex) {
                  style = { backgroundColor: '#b3d4fc' };
                }
                return (
                  <li
                    id={item.value}
                    onClick={() => select(item, index)}
                    key={item.value}
                    style={style}
                    tabIndex="-1"
                    role="option"
                    aria-selected={index === selectedIndex}>
                    {item.name}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      <select
        id={id}
        name={name}
        ref={selectRef}
        value={inputValue}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        tabIndex="-1"
        onBlur={(event) => {
          // We only send the blur event
          // if we are not focused.
          if (!focus && onBlur) onBlur(event);
        }}>
        <option value="">{title}</option>
        {options &&
          options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
      </select>
    </div>
  );
}
