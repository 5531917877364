import { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import { usStates } from '../../utils/helper';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Modal } from '../subcomponents/Modal';
import Timer from '../subcomponents/Timer';

export function AddProctorForm(props) {
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);
  const [course, setCourse] = useState(false);
  const modalRef = useRef();
  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();

  const { isLoading, data: courseHistory } = useQuery(['courseHistory'], () =>
    load('courseHistory'),
  );
  const { isLoading: proctorRegistering, mutate: proctorRegister } =
    useMutation((values) => save('proctor', values));

  useEffect(() => {
    if (isLoading === false && courseHistory) {
      courseHistory.map((item) => {
        if (
          item.brightspace_class_id === props.classId &&
          item.proctor_flag === 'Y' &&
          course === false
        ) {
          setCourse(item);
        }
        return false;
      });
    }
  }, [isLoading, courseHistory, props, course]);

  const formSubmit = async (values) => {
    // we want to add the term and class
    // for the api call. We can just use the
    // option.
    values.term = course.term;
    values.classNumber = course.class_number;
    // We want to register the course
    proctorRegister(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data.error === 'S') setSent(true);
        else setError('There was an error with the form submission.');
      },
    });
  };

  if (isLoading || proctorRegistering) {
    return <Timer />;
  }

  // If no coures is available that means the user does not have
  // access to this form.
  if (course === false && isLoading === false) {
    return <h2>Access Denied!</h2>;
  }

  // If the api call was successfull the show the thank you copy.
  if (sent) {
    return (
      <div className="container">
        <div className="intro-content-wrapper">
          {/* TODO: Maybe pull this text from the database so nothing is hardcoded. */}
          <h2>Thank you for your submission!</h2>
          <h2>
            Please go to <Link to="/dashboard">my portal</Link> to view the
            status of your proctor in the student progress section on the portal
            homepage.
          </h2>
          <p>
            An email has been sent to your potential proctor asking them if they
            meet the criteria and agree to fulfill the responsibilities
            described in the Proctor Acknowledge Form, which they need to accept
            or decline. Within the Student Progress section on your Portal home
            page you'll see one of the following statuses: pending, accept or
            declined. If the request is declined a link will be provided to
            request another proctor. If your status is pending you can request
            another proctor, and it will remove your initial request to the
            first proctor you contacted.
          </p>
          <p>
            <strong>NOTE:</strong> If you do not receive the confirmation
            message, please check your Spam, Junk or Bulk E-Mail folder just in
            case the confirmation email got delivered there instead of your
            inbox. If so, select the confirmation message and mark it Not Spam,
            which should allow future messages to get through.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <h2>Student Proctor Registration Form</h2>
      {error && <p className="red-text">{error}</p>}
      <Formik
        // Pull initial values from storage.
        initialValues={{
          licensestate: '',
          firstname: '',
          middlename: '',
          lastname: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          phoneext: '',
          email: '',
          confirmemail: '',
          aggreement: false,
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="proctorForm">
            <div className="field">
              <label htmlFor="licensestate">
                License State<span>*</span>
              </label>
              <select
                name="licensestate"
                id="licensestate"
                className={
                  touched.licensestate && errors.licensestate ? 'error' : null
                }
                value={values.licensestate}
                onChange={handleChange}
                onBlur={handleBlur}>
                {usStates.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {touched.licensestate && errors.licensestate && (
                <span className="red-text">{errors.licensestate}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="firstname">
                Proctor First Name<span>*</span>
              </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                className={
                  touched.firstname && errors.firstname ? 'error' : null
                }
                value={values.firstname}
                onChange={handleChange}
                placeholder="Proctor First Name*"
                onBlur={handleBlur}
              />
              {touched.firstname && errors.firstname && (
                <span className="red-text">{errors.firstname}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="firstname">Proctor Middle Name</label>
              <input
                type="text"
                id="middlename"
                name="middlename"
                value={values.middlename}
                onChange={handleChange}
                placeholder="Proctor Middle Name"
                onBlur={handleBlur}
              />
            </div>

            <div className="field">
              <label htmlFor="lastname">
                Proctor Last Name<span>*</span>
              </label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                className={touched.lastname && errors.lastname ? 'error' : null}
                value={values.lastname}
                onChange={handleChange}
                placeholder="Proctor Last Name*"
                onBlur={handleBlur}
              />
              {touched.lastname && errors.lastname && (
                <span className="red-text">{errors.lastname}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="address1">
                Proctor Street Address Line 1<span>*</span>
              </label>
              <input
                type="text"
                id="address1"
                name="address1"
                className={touched.address1 && errors.address1 ? 'error' : null}
                value={values.address1}
                onChange={handleChange}
                placeholder="Proctor Street Address Line 1*"
                onBlur={handleBlur}
              />
              {touched.address1 && errors.address1 && (
                <span className="red-text">{errors.address1}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="address2">Proctor Street Address Line 2</label>
              <input
                type="text"
                id="address2"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                placeholder="Proctor Street Address Line 2"
                onBlur={handleBlur}
              />
            </div>

            <div className="field">
              <label htmlFor="city">
                Proctor City<span>*</span>
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className={touched.city && errors.city ? 'error' : null}
                value={values.city}
                onChange={handleChange}
                placeholder="Proctor City*"
                onBlur={handleBlur}
              />
              {touched.city && errors.city && (
                <span className="red-text">{errors.city}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="state">
                Proctor State<span>*</span>
              </label>
              <select
                name="state"
                id="state"
                className={touched.state && errors.state ? 'error' : null}
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}>
                {usStates.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {touched.state && errors.state && (
                <span className="red-text">{errors.state}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="zip">
                Proctor Zip Code<span>*</span>
              </label>
              <input
                type="text"
                id="zip"
                name="zip"
                className={touched.zip && errors.zip ? 'error' : null}
                value={values.zip}
                onChange={handleChange}
                placeholder="Proctor Zip Code*"
                onBlur={handleBlur}
              />
              {touched.zip && errors.zip && (
                <span className="red-text">{errors.zip}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="phone">
                Proctor Phone<span>*</span>
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                className={touched.phone && errors.phone ? 'error' : null}
                value={values.phone}
                onChange={handleChange}
                placeholder="Proctor Phone*"
                onBlur={handleBlur}
              />
              {touched.phone && errors.phone && (
                <span className="red-text">{errors.phone}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="phoneext">Ext</label>
              <input
                type="text"
                id="phoneext"
                name="phoneext"
                className={touched.phoneext && errors.phoneext ? 'error' : null}
                value={values.phoneext}
                onChange={handleChange}
                placeholder="Ext"
                onBlur={handleBlur}
              />
              {touched.phoneext && errors.phoneext && (
                <span className="red-text">{errors.phoneext}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="email">
                Proctor Email Address<span>*</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                className={touched.email && errors.email ? 'error' : null}
                value={values.email}
                onChange={handleChange}
                placeholder="Proctor Email Address*"
                onBlur={handleBlur}
              />
              {touched.email && errors.email && (
                <span className="red-text">{errors.email}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="confirmemail">
                Confirm Proctor Email Address<span>*</span>
              </label>
              <input
                type="text"
                id="confirmemail"
                name="confirmemail"
                className={
                  touched.confirmemail && errors.confirmemail ? 'error' : null
                }
                value={values.confirmemail}
                onChange={handleChange}
                placeholder="Confirm Proctor Email Address*"
                onBlur={handleBlur}
              />
              {touched.confirmemail && errors.confirmemail && (
                <span className="red-text">{errors.confirmemail}</span>
              )}
            </div>

            <div className="field relative">
              <input
                type="checkbox"
                id="aggreement"
                name="aggreement"
                className={
                  touched.aggreement && errors.aggreement ? 'error' : null
                }
                checked={values.aggreement}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="aggreement">
                I have reviewed and accept{' '}
                <button
                  type="button"
                  onClick={() => modalRef.current.toggleOpen()}
                  className="t-accent strong">
                  The Proctored Examination Guidelines
                </button>
              </label>
              {touched.aggreement && errors.aggreement && (
                <span className="red-text">{errors.aggreement}</span>
              )}
            </div>

            <Modal
              title="The Proctored Examination Guidelines"
              linkClasses="t-accent strong"
              showLink={false}
              ref={modalRef}>
              <section>
                <div className="container container--sm">
                  <p className="title1 element-spacing">
                    The Proctored Examination Guidelines
                  </p>
                  <div className="w-full">
                    <div className="col">
                      <p>
                        The American College of Financial Services requires
                        every examination be proctored by a disinterested third
                        party who is not the moderator, not a relative, an
                        immediate supervisor nor employee of the producer and
                        one that has no financial or personal interest in the
                        outcome of the examination. Some states have additional
                        or alternate requirements please visit{' '}
                        <Link
                          to={{
                                pathname:`${process.env.REACT_APP_MARKETING_URL}learn/your-student-experience/taking-exams`
                            }}
                          target="_blank"
                          rel="noopener noreferrer">
                          The Exams and Proctor page
                        </Link>
                        . Students must follow all posted guidelines regardless
                        of the reason for taking the course.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Modal>
            <div className="flex w-full column-layout">
              <button type="submit" form="proctorForm" className="btn col">
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const ValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('Required Field*'),
  lastname: Yup.string().required('Required Field*'),
  address1: Yup.string().required('Required Field*'),
  licensestate: Yup.string().required('Required Field*'),
  city: Yup.string().required('Required Field*'),
  state: Yup.string().required('Required Field*'),
  zip: Yup.string()
    .required('Required Field*')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
  phoneext: Yup.number().typeError('Must be a number'),
  phone: Yup.string()
    .matches(phoneRegExp, '*Phone number is not valid')
    .required('*Phone number required'),
  email: Yup.string().email('Invalid email').required('Required'),
  confirmemail: Yup.string()
    .oneOf([Yup.ref('email'), null], "Emails don't match!")
    .required('Field is required'),
  aggreement: Yup.boolean().oneOf([true], 'You must aggree'),
});
