/*
 * ~TODO: Combine this file with the BasicCreateAccountForm compnents to be more DRY.
 *        Not combining now to limit regression testing that would be needed.
 */
import { useState, useRef } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import selectOptions from '../../data/selectOptions.json';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import { Formik } from 'formik';
import {
  FormikSelect,
  FormikText,
  FormikCheckbox,
} from '../subcomponents/InputField';
import * as Yup from 'yup';
import Modal from '../subcomponents/Modal';

export function CreateAccountForm({ setStep, companyId, nylData, sid }) {
  const formikRef = useRef();
  const [error, setError] = useState(null);
  const { save } = useTacfsService();
  const stateOptions = selectOptions.state;
  const phoneOptions = selectOptions.phoneType;
  const [duplicateAcctFound, setDuplicateAcctFound] = useState(false);

  const { isLoading: submitting, mutate: generateAccount } = useMutation(
    (values) => save('createNylStudent', values),
  );

  const { isLoading: ssnChecksubmitting, mutate: checkStudentSsnExists } =
    useMutation((values) => save('checkExistingStudent', values));

  const { isLoading: emailChecksubmitting, mutate: checkStudentEmailExists } =
    useMutation((values) => save('checkExistingStudent', values));

  const zipRegExp = /^\d{5}$/;
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  Yup.addMethod(Yup.string, 'checkStudentSsn', function (message) {
    return this.test('checkStudentSsn', message, function (value) {
      const { path } = this;
      return new Promise((resolve, reject) => {
        checkStudentSsnExists(
          {
            ssn: value,
          },
          {
            onError: (res) => {
              console.log(res);
              resolve(true);
            },
            onSuccess: (data) => {
              if (data.status === 'N') {
                resolve(true);
              } else {
                setDuplicateAcctFound(true);
                reject(this.createError({ path, message }));
              }
            },
          },
        );
      });
    });
  });

  Yup.addMethod(Yup.string, 'checkStudentEmail', function (message) {
    return this.test('checkStudentEmail', message, function (value) {
      const { path } = this;
      return new Promise((resolve, reject) => {
        checkStudentEmailExists(
          {
            email: value,
          },
          {
            onError: (res) => {
              console.log(res);
              resolve(true);
            },
            onSuccess: (data) => {
              if (data.status === 'N') {
                resolve(true);
              } else {
                setDuplicateAcctFound(true);
                reject(this.createError({ path, message }));
              }
            },
          },
        );
      });
    });
  });

  const customValidationSchema = Yup.object().shape({
    email: Yup.string().checkStudentEmail('This email has been taken.'),
    ssnOptOut: Yup.bool(),
    ssn: Yup.string().when(['ssnOptOut'], (ssnOptOut, schema) => {
      if (ssnOptOut && ssnOptOut[0] === false)
        return schema.checkStudentSsn('Social Security number already exists.');
    }),
  });

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Please enter a valid email'),
    confirmEmail: Yup.string().when(['email'], (email, schema) => {
      return schema
        .oneOf([email[0], null], "Passwords don't match!")
        .required('Field required');
    }),
    secondEmail: Yup.string()
      .email('Must be a valid email')
      .required('Please enter a valid backup email')
      .when(['email'], (email, schema) => {
        return schema
          .notOneOf(
            [email[0]],
            'Backup email cannot be the same as your primary email address.',
          )
          .required('Field required');
      }),
    confirmSecondEmail: Yup.string().when(
      ['secondEmail'],
      (secondEmail, schema) => {
        return schema
          .oneOf([secondEmail[0], null], "Backup email fields don't match!")
          .required('Field required');
      },
    ),
    firstName: Yup.string().required('Required Field*'),
    lastName: Yup.string().required('Required Field*'),
    ssnOptOut: Yup.bool(),
    ssn: Yup.string().when(['ssnOptOut'], (ssnOptOut, schema) => {
      if (ssnOptOut && ssnOptOut[0] === false)
        return schema
          .required('Required Field*')
          .matches(
            /^(?!666|000|111|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
            'Invalid SSN format.',
          );
    }),
    address: Yup.string().required('Required Field*'),
    city: Yup.string().required('Required Field*'),
    state: Yup.string().required('Required Field*'),
    zip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field*'),
    phone: Yup.string()
      .matches(phoneRegExp, '*Invalid phone format')
      .required('Required Field*'),
    phoneType: Yup.string().required('Required Field*'),
    busAddress: Yup.string().required('Required Field*'),
    busState: Yup.string().required('Required Field*'),
    busZip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field*'),
    busPhone: Yup.string()
      .matches(phoneRegExp, '*Invalid phone format')
      .required('Required Field*'),
    busPhoneType: Yup.string().required('Required Field*'),
    pass: Yup.string()
      .test(
        'does-not-contain-email',
        'You may not use parts of your username in your password',
        (value, context) => {
          if (!value) {
            return true;
          }
          const emailParts = context.parent.email.toLowerCase().split('@');
          if (value.toLowerCase().includes(emailParts[0])) {
            return false;
          }
          if (value.toLowerCase().includes(emailParts[1])) {
            return false;
          }
          return true;
        },
      )
      .when([], (field, schema) => {
        return schema
          .matches(
            /^(?=.*[a-z])/,
            'Must contain at least one lowercase character',
          )
          .matches(
            /^(?=.*[A-Z])/,
            'Must contain at least one uppercase character',
          )
          .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
          .min(8, 'Must use 8 or more characters')
          .required('Field required');
      }),
    confirmPass: Yup.string().when(['pass'], (pass, schema) => {
      return schema
        .oneOf([pass[0], null], "Passwords don't match!")
        .required('Field required');
    }),
    securityQuestion: Yup.string().when([], (field, schema) => {
      return schema.required('Field required');
    }),
    securityAnswer: Yup.string().when(
      ['securityQuestion'],
      (securityQuestion, schema, val) => {
        return schema
          .test('contains', 'Answer can not be apart of the question', () => {
            return !securityQuestion[0].includes(val.value);
          })
          .required('Field required');
      },
    ),
  });

  // There some validation we only want to run
  // when the submition hanlder is clicked as it
  // calls api endpoints.
  const validation = async (values) => {
    return customValidationSchema
      .validate(values, { abortEarly: false })
      .then(function () {
        return true;
      })
      .catch(function (err) {
        err.inner.forEach((error) => {
          // set the formik error.
          formikRef.current.setFieldError(error.path, error.errors);
        });
        return false;
      });
  };

  const formSubmit = async (values) => {
    // Check the custom form validation
    // if schema was passed in.
    const valid = await validation(values);
    // Make sure we passed the validation check.
    if (valid) {
      const data = {
        accountData: {
          last_name: values.lastName,
          first_name: values.firstName,
          middle_name: '',
          prefix: values.prefix,
          country: 'USA',
          ssn: values.ssn,
          enrollment_source: 'NY',
          home_information: {
            address1: values.address,
            address2: values.address2,
            address3: '',
            city: values.city,
            state: values.state,
            postal: values.zip,
            phone: values.phone,
            phone_type: values.phoneType,
            email: values.email,
            secondEmail: values.secondEmail,
          },
          business_information: {
            address1: values.busAddress,
            address2: values.busAddress2,
            address3: '',
            city: values.busCity,
            state: values.busState,
            postal: values.busZip,
            phone: values.busPhone,
            phone_type: values.busPhoneType,
            email: values.busEmail,
          },
          company_affiliation: {
            company_id: companyId,
            company_name: 'New York Life Insurance Co',
            affiliation_id: nylData.agent_id,
            secondary_affiliation_id: '',
            role: '',
            region: 0,
            lsp_cd: '',
            ntid: '',
          },
          nyl_password: values.pass,
          docebo_id: nylData.docebo_id,
          promo_status: nylData.promo_status,
          job_title: nylData.nyl_job_id,
          contract_type: nylData.nyl_contract_type,
        },
        credentials: {
          securityQuestion: values.securityQuestion,
          securityAnswer: values.securityAnswer,
        },
        sid: sid,
      };

      if (!submitting) {
        // We want to register the course
        generateAccount(data, {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            if (data.student_id) setStep('DONE');
            else setError(`There was an error with the form submission.`);
          },
        });
      }
    }
  };

  return (<>
  {duplicateAcctFound && (
    <Modal showLink={false} showNow={true} noClose={true} title="Account already exists" linkClasses="btn secondary">
        <div className="container--sm text-center flex-column gap-8">
          <p className="title2 w-full">
            You already have an account with The College!
          </p>
          <p className="w-full">
            Your email address and/or Social Security Number is already
            associated with a "My Learning Hub" account. Please log in before completing this application.
          </p>
          <Link to='/' className="btn mx-auto">
            Log in Now
          </Link>
          <p className="p4 w-full">
            If you believe you have received this message in error, contact
            Admissions at <a href="tel:888-263-7265">888-263-7265</a>.
          </p>
        </div>
    </Modal>)}
   {!duplicateAcctFound && (<Formik
      initialValues={{
        email: '',
        confirmEmail: '',
        secondEmail: '',
        confirmSecondEmail: '',
        firstName: nylData.first_name ?? '',
        lastName: nylData.last_name ?? '',
        prefix: '',
        ssn: '',
        ssnOptOut: false,
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        phoneType: '',
        busAddress: '',
        busAddress2: '',
        busCity: '',
        busState: '',
        busZip: '',
        busPhone: '',
        busPhoneType: '',
        pass: '',
        confirmPass: '',
        securityQuestion: '',
        securityAnswer: '',
      }}
      enableReinitialize={true}
      innerRef={formikRef}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        formSubmit(values);
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit} id="createAccount">
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="email"
                  id="email"
                  name="email"
                  placeholder="* Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikText
                  type="email"
                  id="confirmEmail"
                  name="confirmEmail"
                  placeholder="* Confirm Email Address"
                  value={values.confirmEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="email"
                  id="secondEmail"
                  name="secondEmail"
                  placeholder="* Backup Email Address"
                  value={values.secondEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikText
                  type="email"
                  id="confirmSecondEmail"
                  name="confirmSecondEmail"
                  placeholder="* Confirm Backup Email Address"
                  value={values.confirmSecondEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col--quarter">
              <div className="field">
                <FormikSelect
                  id="prefix"
                  name="prefix"
                  value={values.prefix}
                  onChange={handleChange}
                  className={touched.prefix && errors.prefix ? 'error' : null}>
                  <option value="">Prefix</option>
                  <option value="Mr">Mr.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Mrs">Mrs.</option>
                </FormikSelect>
              </div>
            </div>
            <div className="col--third">
              <div className="field">
                <FormikText
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="* First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col--third">
              <div className="field">
                <FormikText
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="* Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="ssn"
                  name="ssn"
                  placeholder="* SSN"
                  disabled={values.ssnOptOut}
                  value={values.ssn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <ul className="checkboxes">
                <li>
                  <FormikCheckbox
                    name="ssnOptOut"
                    id="ssnOptOut"
                    checked={values.ssnOptOut}
                    onChange={(e) => {
                      setFieldValue('ssn', '');
                      setFieldError('ssn', '');
                      handleChange(e);
                    }}>
                    I choose not to supply my Social Security Number. I
                    understand that by doing so, I will be ineligible to claim
                    tax credit for course tuition.
                  </FormikCheckbox>
                </li>
              </ul>
            </div>
          </div>

          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="address"
                  name="address"
                  placeholder="* Personal Street Address 1"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="address2"
                  name="address2"
                  placeholder="Personal Street Address 2"
                  value={values.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="column-layout">
            <div className="col--third">
              <div className="field">
                <FormikText
                  type="text"
                  id="city"
                  name="city"
                  placeholder="* City"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col--third">
              <div className="field">
                <FormikSelect
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}>
                  {stateOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className="col--quarter">
              <div className="field">
                <FormikText
                  type="text"
                  id="zip"
                  name="zip"
                  placeholder="* Zip Code"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="*Personal Phone Number"
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikSelect
                  id="phoneType"
                  name="phoneType"
                  value={values.phoneType}
                  onChange={handleChange}
                  required>
                  {phoneOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="busAddress"
                  name="busAddress"
                  placeholder="* Business Street Address 1"
                  value={values.busAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="busAddress2"
                  name="busAddress2"
                  placeholder="Business Street Address 2"
                  value={values.busAddress2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="column-layout">
            <div className="col--third">
              <div className="field">
                <FormikText
                  type="text"
                  id="busCity"
                  name="busCity"
                  placeholder="* City"
                  value={values.busCity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col--third">
              <div className="field">
                <FormikSelect
                  id="busState"
                  name="busState"
                  value={values.busState}
                  onChange={handleChange}>
                  {stateOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className="col--quarter">
              <div className="field">
                <FormikText
                  type="text"
                  id="busZip"
                  name="busZip"
                  placeholder="* Zip Code"
                  value={values.busZip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="email"
                  id="busEmail"
                  name="busEmail"
                  placeholder="* Email Address"
                  value={values.busEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="busPhone"
                  name="busPhone"
                  placeholder="*Business Phone Number"
                  value={values.busPhone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikSelect
                  id="busPhoneType"
                  name="busPhoneType"
                  value={values.busPhoneType}
                  onChange={handleChange}
                  required>
                  {phoneOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
          </div>
          <div>
            <p>
              <span className="section-header">PASSWORD: </span>Your password
              must have at least eight (8) characters, a lowercase letter, an
              uppercase letter, a number, and contain no parts of your username.
            </p>
            <div className="column-layout">
              <div className="col">
                <div className="field">
                  <FormikText
                    type="password"
                    id="pass"
                    name="pass"
                    value={values.pass}
                    className={touched.pass && errors.pass ? 'error' : null}
                    onChange={handleChange}
                    placeholder="Password"
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col">
                <div className="field">
                  <FormikText
                    type="password"
                    id="confirmPass"
                    name="confirmPass"
                    value={values.confirmPass}
                    className={
                      touched.confirmPass && errors.confirmPass ? 'error' : null
                    }
                    onChange={handleChange}
                    placeholder="Confirm Password"
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>
              <span className="section-header">
                PASSWORD RESET EMAIL (REQUIRED):{' '}
              </span>
              Please enter a security question and answer that we can use to
              send a link to your email if your forget your password.
            </p>
            <div className="column-layout">
              <div className="col">
                <div className="field">
                  <FormikSelect
                    name="securityQuestion"
                    id="securityQuestion"
                    value={values.securityQuestion}
                    className={
                      touched.securityQuestion && errors.securityQuestion
                        ? 'error'
                        : null
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="">*Select a security question.</option>
                    <option value="What is your mother's maiden name?">
                      What is your mother's maiden name?
                    </option>
                    <option value="What city were you born in?">
                      What city were you born in?
                    </option>
                    <option value="What is your paternal grandmother's first name?">
                      What is your paternal grandmother's first name?
                    </option>
                    <option value="What is your maternal grandmother's fist name?">
                      What is your maternal grandmother's fist name?
                    </option>
                    <option value="What is the name of your first pet?">
                      What is the name of your first pet?
                    </option>
                    <option
                      value="What is the first name of your childhood best
                              friend?">
                      What is the first name of your childhood best friend?
                    </option>
                  </FormikSelect>
                </div>
              </div>

              <div className="col">
                <div className="field">
                  <FormikText
                    type="text"
                    id="securityAnswer"
                    name="securityAnswer"
                    value={values.securityAnswer}
                    className={
                      touched.securityAnswer && errors.securityAnswer
                        ? 'error'
                        : null
                    }
                    onChange={handleChange}
                    placeholder="Answer"
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
          {submitting || ssnChecksubmitting || emailChecksubmitting ? (
            <button
              type="submit"
              form="createAccount"
              className="btn col disabled"
              disabled>
              Submitting...
            </button>
          ) : (
            <button type="submit" form="createAccount" className="btn col">
              Create Account
            </button>
          )}
          {error && <p className="red-text">{error}</p>}
        </form>
      )}
    </Formik>)}
  </>);
}
