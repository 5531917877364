import { useRef, useEffect } from 'react';
import { Formik, Field } from 'formik';

import * as Yup from 'yup';
import { Modal } from '../subcomponents/Modal';

import { getCurrentYears, months, days } from '../../utils/helper';

export function CourseModalForm(props) {
  const modalRef = useRef();
  const formikRef = useRef();

  const onClose = () => {
    props.close(false);
    // we want to reset
    // the from for
    // subsequent calls.
    formikRef.current.resetForm();
  };

  const formSubmit = (values) => {
    const currentValues = props.courses;
    if (props.index === false) {
      currentValues.push(values);
      props.setCourses(currentValues);
    } else {
      currentValues[props.index] = values;
    }
    onClose();
  };

  useEffect(() => {
    if (props.open != null) modalRef.current.toggleOpen(props.open);
  }, [modalRef, props]);

  return (
    <Modal
      title="Add School Information"
      linkClasses="t-accent strong"
      showLink={false}
      ref={modalRef}
      onClose={onClose}>
      <h4>
        COURSE INFORMATION AND WHAT HS COURSE YOU EXPECT TO RECEIVE CREDIT FOR
      </h4>
      <Formik
        initialValues={{
          title: props.index !== false ? props.courses[props.index].title : '',
          school:
            props.index !== false ? props.courses[props.index].school : '',
          completedMonth:
            props.index !== false
              ? props.courses[props.index].completedMonth
              : '',
          completedDay:
            props.index !== false
              ? props.courses[props.index].completedDay
              : '',
          completedYear:
            props.index !== false
              ? props.courses[props.index].completedYear
              : '',
          grade: props.index !== false ? props.courses[props.index].grade : '',
          hours: props.index !== false ? props.courses[props.index].hours : '',
          hsCourse:
            props.index !== false ? props.courses[props.index].hsCourse : '',
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        innerRef={formikRef}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="courseModalForm">
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="title">
                  Course Title and Number<span>*</span>
                </label>
                <Field
                  name="title"
                  type="text"
                  id="title"
                  placeholder="Course Title and Number*"
                  value={values.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.title && errors.title && (
                  <span className="red-text">{errors.title}</span>
                )}
              </div>
              <div className="field col">
                <label htmlFor="school">
                  Name of School<span>*</span>
                </label>
                <Field
                  name="school"
                  type="text"
                  id="school"
                  placeholder="Name of School*"
                  value={values.school}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.school && errors.school && (
                  <span className="red-text">{errors.school}</span>
                )}
              </div>
            </div>
            <label htmlFor="completedMonth">
              Date Course Completed<span>*</span>
            </label>
            <div className="form-layout">
              <div className="field col--third">
                <Field
                  name="completedMonth"
                  id="completedMonth"
                  as="select"
                  value={values.completedMonth}
                  onBlur={handleBlur}
                  onChange={handleChange}>
                  {months.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                {touched.completedMonth && errors.completedMonth && (
                  <span className="red-text">{errors.completedMonth}</span>
                )}
              </div>
              <div className="field col--third">
                <Field
                  name="completedDay"
                  id="completedDay"
                  as="select"
                  value={values.completedDay}
                  onBlur={handleBlur}
                  onChange={handleChange}>
                  {days.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                {touched.completedDay && errors.completedDay && (
                  <span className="red-text">{errors.completedDay}</span>
                )}
              </div>
              <div className="field col--third">
                <Field
                  name="completedYear"
                  id="completedYear"
                  as="select"
                  value={values.completedYear}
                  onChange={handleChange}
                  onBlur={handleBlur}>
                  {getCurrentYears(50).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                {touched.completedYear && errors.completedYear && (
                  <span className="red-text">{errors.completedYear}</span>
                )}
              </div>
            </div>
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="grade">
                  Grade Received or Pass/Fail<span>*</span>
                </label>
                <Field
                  name="grade"
                  type="text"
                  id="grade"
                  placeholder="Grade Received or Pass/Fail*"
                  value={values.grade}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.grade && errors.grade && (
                  <span className="red-text">{errors.grade}</span>
                )}
              </div>
              <div className="field col">
                <label htmlFor="hours">
                  Credit Hours<span>*</span>
                </label>
                <Field
                  name="hours"
                  type="text"
                  id="hours"
                  placeholder="Credit Hours*"
                  value={values.hours}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.hours && errors.hours && (
                  <span className="red-text">{errors.hours}</span>
                )}
              </div>
            </div>
            <div className="field">
              <label htmlFor="grade">
                HS Course You Expect to Receive Credit for<span>*</span>
              </label>
              <Field
                name="hsCourse"
                id="hsCourse"
                as="select"
                value={values.hsCourse}
                onBlur={handleBlur}
                onChange={handleChange}>
                {courseList.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              {touched.hsCourse && errors.hsCourse && (
                <span className="red-text">{errors.hsCourse}</span>
              )}
            </div>
            <button type="submit" form="courseModalForm" className="btn col">
              Submit
            </button>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

// TODO: Pull this from PS.
const courseList = [
  { label: '- Select Course -', value: '' },
  {
    label: 'HS 300 Financial Planning: Process and Environment',
    value: 'HS300',
  },
  { label: 'HS 311 Fundamentals of Insurance Planning', value: 'HS311' },
  { label: 'HS 321 Fundamentals of Income Taxation', value: 'HS321' },
  { label: 'HS 326 Planning for Retirement Needs', value: 'HS326' },
  { label: 'HS 328 Investments', value: 'HS328' },
  { label: 'HS 330 Fundamentals of Estate Planning', value: 'HS330' },
  {
    label: 'HS 333 Personal Financial Planning: Comprehensive Case Analysis',
    value: 'HS333',
  },
  {
    label: 'HS 347 Contemporary Applications in Financial Planning',
    value: 'HS347',
  },
  { label: 'HS 323 Individual Life Insurance', value: 'HS323' },
  { label: 'HS 324 Legal Aspects of Life Insurance', value: 'HS324' },
  {
    label: 'HS 331 Planning for the Business Owner and Professional',
    value: 'HS331',
  },
  {
    label: 'HS 375 Introduction to Disability and Lifetime Planning',
    value: 'HS375',
  },
  {
    label: 'HS 376 Legal and Financial Issues for Special Needs Families',
    value: 'HS376',
  },
  {
    label:
      'HS 377 Financial Planning for Families Caring for Those with Special Needs',
    value: 'HS377',
  },
  {
    label: 'HS 353 Retirement Income Process, Strategies and Solutions',
    value: 'HS353',
  },
  { label: 'HS 354 Sources of Retirement Income', value: 'HS354' },
  { label: 'HS 355 Managing the Retirement Income Plan', value: 'HS355' },
];

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  school: Yup.string().required('Required'),
  completedMonth: Yup.string().required('Required'),
  completedDay: Yup.string().required('Required'),
  completedYear: Yup.string().required('Required'),
  grade: Yup.string()
    .max(3, 'Must not exceed 3 characters')
    .required('Required'),
  hours: Yup.string().required('Required'),
  hsCourse: Yup.string().required('Required'),
});
