import useOktaRequest from './AxiosClient';

const OktaRequests = () => {
  const [request] = useOktaRequest();

  const verifyRecoveryToken = (recoveryToken) => {
    return request({
      url: `/authn/recovery/token`,
      method: 'POST',
      data: {
        recoveryToken: recoveryToken,
      },
    });
  };

  const verifyRecoveryAnswer = (values) => {
    return request({
      url: `/authn/recovery/answer`,
      method: 'POST',
      data: {
        stateToken: values.stateToken,
        answer: values.answer,
      },
    });
  };

  const resetPassword = (values) => {
    return request({
      url: `/authn/credentials/reset_password`,
      method: 'POST',
      data: {
        stateToken: values.stateToken,
        newPassword: values.pass,
      },
    });
  };

  const enrollFactors = (values) => {
    return request({
      url: `/authn/factors`,
      method: 'POST',
      data: {
        stateToken: values.stateToken,
        factorType: values.factorType,
        provider: 'OKTA',
        profile: values.profile,
      },
    });
  };

  const activateCodeFactor = (values) => {
    return request({
      url: `/authn/factors/${values.factorId}/lifecycle/activate`,
      method: 'POST',
      data: values.data,
    });
  };

  const verifyFactors = (values) => {
    return request({
      url: `/authn/factors/${values.factorId}/verify`,
      method: 'POST',
      data: values.data,
    });
  };

  const resendCodeFactor = (values) => {
    return request({
      url: `/authn/factors/${values.factorId}/verify/resend`,
      method: 'POST',
      data: values.data,
    });
  };

  const changePasswordFlow = (user, data) => {
    const url = `/users/${user}/credentials/change_password`;

    return request({
      url: url,
      method: 'POST',
      data: data,
    });
  };

  const changeSecurityQuestionFlow = (user, data) => {
    const url = `/users/${user}/credentials/change_recovery_question`;

    return request({
      url: url,
      method: 'POST',
      data: data,
    });
  };

  const changeBackupEmailFlow = (data) => {
    const url = '/users/me';

    return request({
      url: url,
      method: 'POST',
      data: data,
    });
  };
  
  const beginResetPasswordFlow = (data) => {
    const url = '/authn/recovery/password';
      
    return request({
      url: url,
      method: 'POST',
      data: data,
    });
  };
  
  const verifySMSRecoveryOTP = (data) => {
    const url = '/authn/recovery/factors/sms/verify';
      
    return request({
      url: url,
      method: 'POST',
      data: data,
    });
  };
  
  // Expects a stateToken & returns current transaction state
  const getCurrentState = (data) => {
    const url = '/authn';
      
    return request({
      url: url,
      method: 'POST',
      data: data,
    });
  };

  return {
    verifyRecoveryToken,
    verifyRecoveryAnswer,
    verifySMSRecoveryOTP,
    resetPassword,
    changePasswordFlow,
    changeSecurityQuestionFlow,
    changeBackupEmailFlow,
    enrollFactors,
    verifyFactors,
    resendCodeFactor,
    activateCodeFactor,
    beginResetPasswordFlow,
    getCurrentState,
  };
};

export default OktaRequests;
