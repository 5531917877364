import { /*useEffect,*/ useContext } from 'react';
import { PortalMessageContext } from '../global/Context';
import { useState } from 'react';
import { Layout } from '../Layout';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { AuthDetails } from '../login/AuthDetails';
import parse from 'html-react-parser';
import * as dayjs from 'dayjs';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

/**
 * MessageCenter component
 *
 * @param {Object} userInfo - user information
 * @param {Array} messages - array of messages
 *
 * @returns {JSX.Element} - JSX element containing IntroContent and MessageDisplayBox components
 */
export function MessageCenter() {
  const msgContext = useContext(PortalMessageContext);
  const messages = msgContext
    ? Array.isArray(msgContext.messageContext.messages)
      ? msgContext.messageContext.messages
      : []
    : [];

  const { save: postRequest } = useTacfsService();
  const { mutate: ackMsg } = useMutation((params) =>
    postRequest('acknowledgePortalMessage', params),
  );

  function AcknowledgeMessage(msgId, type = "dismissed") {
//    console.log(`Acknowledge type ${type} for msg ${msgId}`);
    // Make the API Call to acknowledge this message.
    const params = { message_id: msgId, ackType: type }; //Student ID should be automatically added by useTacfsService::save()
    ackMsg(params, {
      onError: (res) =>
        console.log('We encountered an error acknowledging message'),
      onSuccess: (data) => {
        if (data.code === 201 || data.code === 200) {
          if(type === "dismissed"){  
            const msgList = msgContext.messageContext.messages.filter(
              (msg) => {
                  return (msg.id !== msgId);
              },
            );
            // Update context with the updated list
            msgContext.setMessageContext({"messages":msgList, "lastUpdate":Date.now()});
          } else if(type === "read"){  
            const msgList = msgContext.messageContext.messages.filter(
              (msg) => {
                    if(msg.id === msgId){
                        msg.status = "read";
                    }
                    return true;
              },
            );
            // Update context with the updated list
            msgContext.setMessageContext({"messages":msgList, "lastUpdate":Date.now()});
          }//TODO: this may need to be adjusted to update the status of a 'read' message
        }
      },
    });
  }

  return (
    <Layout>
      <Breadcrumbs />
      <div className="message-center-container">
        <div className="container">
          <div className="intro-content-wrapper">
            <AuthDetails page="message center" />
            <p className="page-intro-text">
              Receive the messages you need to identify next steps and take
              action.
            </p>
          </div>
        </div>

        <div className="messages">
          <div className="container">
            {messages.map((x, i) => {
              return (
                <MessageDisplayBox
                  key={i}
                  message={x}
                  ackMsg={AcknowledgeMessage}
                />
              );
            })}
            {messages.length === 0? "No New Messages!":""}
          </div>
        </div>
      </div>
    </Layout>
  );
}

/**
 * MessageDisplayBox
 *
 * @param {Object} message - The message object containing title, body, date, ctaLink, unread, and cta
 *
 * This component renders a message box that displays the title, date, and body of the message. If the message is unread, a BELL icon will be displayed. If the cta prop is true, a CTA button will be rendered.
 */
function MessageDisplayBox({ message, ackMsg }) {
  const {
    id,
    label,
    description,
    created,
    field_message_link,
    field_close_modal,
  } = message;
  const unread = message.status.toLowerCase() === "unread"??false;
  const [expanded, setExpanded] = useState(false);
  const body = description[0].value;
  const expandActions = (e, isUnread) => {
    if(isUnread && !expanded){
        //If the message is unread and the message is opened
        ackMsg(id, 'read'); 
    }
    setExpanded((e) => !e);
  };
    let ctaLinkTarget = "_blank";
    let ctaUrl = "";
  if(field_message_link.length > 0){
    ctaLinkTarget = "_blank";
    ctaUrl = field_message_link[0]?.uri;
    if(field_message_link[0]?.uri?.indexOf("internal:") !==  -1){
        ctaLinkTarget = "_self";
        ctaUrl = field_message_link[0].uri.replace("internal:", "");
    }
}

//  useEffect(() => {
//    if (unread) {
//      setExpanded(true);
//    } else {
//      setExpanded(false);
//    }
//  }, [unread]);

  return (
    <div
      className={
        expanded ? 'message-display-box active' : 'message-display-box'
      }
    >
      <div className="title flex gap-4" onClick={(e) => expandActions(e, unread)}>
        <p className={unread ? 'title7 col unreadMsg':'title7 col'}>
          {label}{' '}
          { field_close_modal == "0" && expanded && (
            <img
              onClick={(e) => ackMsg(id)}
              src="/icons/trash-can-solid.svg"
              alt="Click icon to dismiss message. You won't see it again."
              title="Click icon to dismiss message. You won't see it again."
            />
          )}
        </p>
        <p className="date col desktop">
          {dayjs.unix(created).format('MM/DD/YYYY')}
        </p>
        <div className="expand-toggle"></div>
      </div>
      <p className="date mobile">{dayjs.unix(created).format('MM/DD/YYYY')}</p>
      <div className="content">
        <div className="col">
          <div className="message-display-body">{parse(body)}</div>
          {field_message_link.length > 0 && (
            <Link className="btn" to={{pathname:ctaUrl}} target={ctaLinkTarget}>
              {field_message_link[0].title}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
