import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation, Link, useParams } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/login/LoginForm';

import { GraduateForm } from '../components/graduateApplication/GraduateForm';
import { OktaSSOLink } from '../_okta/config/OktaSSO';

export function GraduateApplication() {
  const { authState } = useOktaAuth();
  const [step, setStep] = useState('LOGIN');
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const location = useLocation();
  const { designation } = useParams();

  useEffect(() => {
    // If user already logged in then we just want
    // to skip to the form step.
    if (step !== 'SUCCESS') {
      if (authState != null && authState.isAuthenticated) {
        setStep('FORM');
        setLoggedIn(true);
      } else if (step !== 'LOGIN') {
        setStep('LOGIN');
      }
    }
  }, [authState, step]);

  // We currerntly only allow for these designations.
  const allowedDesignations = ['msfp'];
  if (allowedDesignations.indexOf(designation) === -1) {
    return (
      <Layout>
        <div className="container graduate_application-page">
          <h3>Access Denied!</h3>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container graduate_application-page">
        {
          {
            LOGIN: (
              <div className="column-layout new-returning-landing">
                <div className="col">
                  <LoginForm
                    alt={true}
                    loginDestination={location.pathname}
                    setToken={setToken}
                  />
                </div>
                {token === false && (
                  <div className="col">
                    <div className="card-layout drop-box login-box">
                      <div className="card">
                        <h4>New Student</h4>
                        <p>
                          New to our Community? Just click the button below to
                          get started.
                        </p>
                        <Link
                          to={`/account/register?designation=${designation.toLowerCase()}`}
                          className="btn">
                          Start Enrollment
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ),
            FORM: (
              <>
                <h2>Application For Admission</h2>
                <p>
                  To apply for admission to The American College of Financial
                  Services graduate programs, please fill out the requested
                  information in the fields below.
                </p>
                <p>
                  After you have submitted the application, please call
                  888-263-7265 to pay the admissions fee. The ability to pay
                  online is coming soon!
                </p>
                <GraduateForm
                  setStep={(step) => setStep(step)}
                  designation={designation.toLowerCase()}
                  authState={authState}
                />
              </>
            ),
            SUCCESS: (
              <>
                <h2>Success!</h2>
                <p>Application Submitted Successfully.</p>
                {loggedIn && (
                  <OktaSSOLink
                    classes="btn"
                    grd="1"
                    program={designation.toLowerCase()}>
                    Proceed to Payment
                  </OktaSSOLink>
                )}
                {!loggedIn && (
                  <Link to="/dashboard" className="btn">
                    Go to Dashboard
                  </Link>
                )}
              </>
            ),
          }[step]
        }
      </div>
    </Layout>
  );
}
