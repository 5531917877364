import { useState, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';

import OktaRequests from '../_okta/utils/utils';

import PasswordReset from '../components/login/PasswordReset';
import { ReflexBlueLogoImage } from '../components/subcomponents/Icon';
import Timer from '../components/subcomponents/Timer';

export function AccountReset() {
  const [error, setError] = useState('');
  const [foundExisting, setFoundExisting] = useState('');
  const { verifyRecoveryToken, getCurrentState } = OktaRequests();
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const stateToken = query.get('stoken');

  // Verify the okta recovery token
  const { isLoading: tokenVerficationLoading, mutate: verifyToken } =
    useMutation((values) => verifyRecoveryToken(values));
  // Get the current transaction state
  const { isLoading: gettingState, mutate: getState } =
    useMutation((values) => getCurrentState(values));
    
    
  useEffect(() => {
    if (token) {
      verifyToken(token, {
        onError: (res) => setError(res.data.summary),
        onSuccess: (data) => {
          if (data) {
            // Save the existing record
            // to be used in subsequent
            // okta calls.
            setFoundExisting(data);
          } else {
            // there is an issue no data was brought back from okta.
            setError('There was an issue verifiying the token.');
          }
        },
      });
    } else if (stateToken) {
       getState({stateToken:stateToken}, {
        onError: (res) => setError(res.data.summary),
        onSuccess: (data) => {
          console.log(data);
          if (data) {
              if(data.status === "RECOVERY"){
                // Save the existing record
                // to be used in subsequent
                // okta calls.
                console.log("Correct transaction state");
                setFoundExisting(data);
              } else {
                console.log("incorrect transaction state");  
                setError('There was an issue verifiying the token: Invalid recovery state.');  
              }
          } else {
             console.log("data issue");
            // there is an issue no data was brought back from okta.
            setError('There was an issue verifiying the token.');
          }
        },
      }); 
    } else {
        console.log("I'm a teapot");
    }
  }, [verifyToken, token, getState, stateToken]);

  // If the application is still loading display a message.
  if (tokenVerficationLoading || gettingState)
    return (
      <Layout>
        <Timer />
      </Layout>
    );

  return (
    <Layout>
      <div className="container password-reset-page">
        <div className="logo">
          <ReflexBlueLogoImage className="h-24" />
        </div>
        <div className="card-layout drop-box login-box">
          {(!token && !stateToken) || error ? (
            <div>
              <h1>Access Denied!</h1>
              {error && <p>{error}</p>}
              <Link to="/" className="btn">
                Login
              </Link>
            </div>
          ) : (
            foundExisting && <PasswordReset account={foundExisting} />
          )}
        </div>
        <p className="disclaimer pt-8">
          If you are having issues, please contact the American College of
          Financial Services at <a href="tel:8882637265">888-263-7265</a>.
        </p>
      </div>
    </Layout>
  );
}
