import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { ProctorAcknowledgeForm } from '../components/proctorRegistration/ProctorAcknowledgeForm';
import Timer from '../components/subcomponents/Timer';

import useTacfsService from '../utils/tacfs/useTacfsService';

export function ConfirmProctor() {
  const { term, classNumber } = useParams();
  const { search } = useLocation();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const query = queryString.parse(search);
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  // Find the student in the system.
  const { isLoading, data } = useQuery(
    ['validateRetrieveProctor'],
    () =>
      load('validateRetrieveProctor', {
        studentID: query.EMPLID,
        term: term,
        classNumber: classNumber,
        registrationDate: query.REGISTRATION_DT,
        registrationNum: query.REGISTRATION_NUM,
      }),
    {
      retry: 0, // we dont want to retry this call.
    },
  );

  useEffect(() => {
    if (isLoading === false) {
      if (data?.student && data?.proctor) {
        setValid(true);
      } else {
        setError("You don't have access to this form.");
      }
    }
  }, [isLoading, data]);

  if (error && isLoading === false) {
    return (
      <Layout>
        <Breadcrumbs />
        <h1>Access Denied!</h1>
      </Layout>
    );
  }

  if (valid) {
    return (
      <Layout>
        <Breadcrumbs />
        <div className="container">
          <div className="intro-content-wrapper">
            <ProctorAcknowledgeForm
              term={term}
              classNumber={classNumber}
              student={data.student}
              proctor={data.proctor}
              registrationDate={query.REGISTRATION_DT}
              registrationNum={query.REGISTRATION_NUM}
            />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />
      <Timer />
    </Layout>
  );
}
