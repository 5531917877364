import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import queryString from 'query-string';

import { useQuery } from 'react-query';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { SupervisorApprovalForm } from '../components/DesignationApplication/SupervisorApprovalForm';
import Timer from '../components/subcomponents/Timer';

import classOptions from '../data/classOptions.json';
import useTacfsService from '../utils/tacfs/useTacfsService';

export function DesignationAppSupervisor() {
  const { designation } = useParams();
  const { search } = useLocation();
  const [valid, setValid] = useState(false);
  const [name, setName] = useState(false);
  const designations = classOptions.designations;
  const query = queryString.parse(search);
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();

  const { isLoading, data } = useQuery(['supervisorAppStatus'], () =>
    load('designationAppStatusSupervisor', {
      studentID: query.EMPLID,
      designation: designation,
      applid: query.APPLID,
    }),
  );

  useEffect(() => {
    if (isLoading === false && data) {
      if (data.valid) {
        setName(data.fullName);
        setValid(true);
      }
    }
  }, [isLoading, data, query, designation]);

  if (isLoading)
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );

  if (valid === false)
    return (
      <Layout>
        <Breadcrumbs />
        <h1>Access Denied!</h1>
      </Layout>
    );

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container">
        <div className="intro-content-wrapper">
          <div>
            {valid && (
              <>
                <h1>
                  {designations[designation.toLowerCase()] ? (
                    <>
                      {parse(designations[designation.toLowerCase()].shortName)}
                    </>
                  ) : (
                    <>{designation}</>
                  )}
                  Designation Application
                </h1>
                <SupervisorApprovalForm
                  fullName={name}
                  designation={designation}
                  studentId={query.EMPLID}
                  applId={query.APPLID}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
