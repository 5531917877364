import { Timeout } from './subcomponents/Timeout';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
export function Layout({ children }) {
  const { oktaAuth } = useOktaAuth();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    checkAuthState();
  }, [oktaAuth]);

  const checkAuthState = async () => {
    await oktaAuth.isAuthenticated('none').then((status) => {
      setLoggedIn(status);
    });
  };

  return (
    <main id="mainContent" role="main">
      {children}
      {loggedIn && <Timeout />}
    </main>
  );
}
