import { useState, useEffect } from 'react';

import { Layout } from '../components/Layout';
import { useParams, useLocation } from 'react-router-dom';
import Timer from '../components/subcomponents/Timer';
import queryString from 'query-string';

import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useMutation } from 'react-query';
import useTacfsService from '../utils/tacfs/useTacfsService';

import { LoginForm } from '../components/login/LoginForm';
import { EncryptStorage } from 'encrypt-storage';

import { CreateAccountForm } from '../components/createAccount/CreateAccountForm';

export function NylTransferOfCredit() {
  const { authState } = useOktaAuth();
  const { search, pathname } = useLocation();
  const { company } = useParams();
  const { load, save } = useTacfsService();
  const [error, setError] = useState(false);
  const [token, setToken] = useState(false);
  const [nylData, setNylData] = useState(null);
  const [linked, setLinked] = useState(null);
  const [step, setStep] = useState(null);
  const query = queryString.parse(search);
  const sid = query.aicc_sid;
  // ~ This is a failsafe as AICC SID will always be
  //   greator than 10 characters in Prod.
  let aiccSid = query.aicc_sid;
  if (query.aicc_sid.length < 10) {
    aiccSid += '1234567890';
  }

  const encryptStorage = new EncryptStorage(aiccSid, {
    storageType: 'sessionStorage',
  });

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const { data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null && authState.isAuthenticated,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );

  // Validate the proctor to make sure they have access to the form.
  const { isLoading: validating, mutate: validate } = useMutation((values) =>
    save('nylStudent', values),
  );

  const { isLoading: loading, mutate: linkStudent } = useMutation((values) =>
    save('nylStudentLink', values),
  );

  useEffect(() => {
    // ~ TODO: Turn this into a get to prevent double call.
    if (validating === false && error === false && nylData === null) {
      validate(
        {
          sid: sid,
          tempId: '',
          course: '',
        },
        {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            setNylData(data);
          },
        },
      );
    }
  }, [validate, validating, error, nylData, sid]);

  useEffect(() => {
    if (nylData) {
      // if the NYL student ID is set meaing account is already linked or
      // if the user is already logged in then we want to run the link
      // student call to link account and send out an email.
      if (
        (nylData.student_id ||
          (authState != null && authState.isAuthenticated && userData)) &&
        loading === false &&
        linked === null &&
        error === false
      ) {
        const linkData = {
          sid: sid,
          agent_id: nylData.agent_id,
          studentID: nylData.student_id,
          email: userData?.profile?.login ?? nylData.username,
          firstName: nylData.first_name,
          lastName: nylData.last_name,
          docebo_id: nylData.docebo_id,
          promo_status: nylData.promo_status,
          job_title: nylData.nyl_job_id,
          contract_type: nylData.nyl_contract_type,
        };
        // We want to link the account if we have the available data.
        if (!nylData.student_id && encryptStorage.getItem('pSecret')) {
          linkData.password = encryptStorage.getItem('pSecret');
        }
        // We want to link the users account to the NYL flow
        linkStudent(linkData, {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            // send email to registrar
            setLinked(data);
          },
        });
      } else if (step === null) {
        // new student not link then show login screen
        setStep('LOGIN');
      }
    }
    // eslint-disable-next-line
  }, [nylData, userData, authState, linked, loading, error]);

  if (linked || step === 'DONE') {
    return (
      <Layout>
        <div className="column-layout container">
          <div className="col">
            <p>
              The registrar office will review your submission to make sure all
              necessary data has been collected and we will be in contact
              shortly. Thank you and we appreciate your partnership!
            </p>
          </div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container">
          <h2>Sorry there was an error!</h2>
          <h4>Error: {error}</h4>
        </div>
      </Layout>
    );
  }

  if (step === 'LOGIN' && loading === false) {
    return (
      <Layout>
        <div className="column-layout container new-returning-landing">
          <div className="col">
            <LoginForm
              alt={true}
              loginDestination={pathname + search}
              setToken={setToken}
              encryptStorage={encryptStorage}
            />
          </div>
          {token === false && (
            <div className="col">
              <div className="card-layout drop-box login-box">
                <div className="card">
                  <h4>New Student</h4>
                  <p>
                    New to our Community? Just click the button below to get
                    started.
                  </p>
                  <button onClick={() => setStep('FORM')} className="btn">
                    Create Account
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    );
  }

  if (step === 'FORM' && loading === false) {
    return (
      <Layout>
        <div className="container">
          <h2>Account Creation</h2>
          <h4>
            Welcome to The American College of Financial Services. Please follow
            the steps to setup your account.
          </h4>
          <div className="column-layout form-layout">
            <CreateAccountForm
              setStep={() => setStep('DONE')}
              companyId={company}
              nylData={nylData}
              sid={sid}
            />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container">
        <Timer />
      </div>
    </Layout>
  );
}
