import { useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { InfoIcon } from './Icon';

const ToolTip = ({
  placement = 'top',
  icon = <InfoIcon />,
  useDiv = false,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className="tooltip-wrapper">
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement={placement}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={children}
        >
          {useDiv ? (
            <div
              role="button"
              tabIndex="0"
              className="tooltip-toggle"
              onClick={handleTooltipOpen}
            >
              {icon}
            </div>
          ) : (
            <button
              type="button"
              className="tooltip-toggle"
              onClick={handleTooltipOpen}
            >
              {icon}
            </button>
          )}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ToolTip;
