import { useState, useContext } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { TacfsContext } from '../global/Context';
import Divider from '@mui/material/Divider';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import calculateProfileCompletion from '../../utils/calculateProfileCompletion';

import { Formik, Field } from 'formik';
import FileUpload from '../subcomponents/FileUpload';
import Timer from '../subcomponents/Timer';
import * as Yup from 'yup';

import InputField from '../subcomponents/InputField';
import CompanyAffiliationField from '../subcomponents/companyFields/CompanyAffiliationField';
import {
  CompanyCustomFields,
  getCustomizedCompanyFields as getCustomFields,
} from '../subcomponents/companyFields/CompanyCustomFields';
import selectOptions from '../../data/selectOptions.json';
import mapping from '../../data/selectValuesMapping.json';
import checkboxOptions from '../../data/checkboxOptions.json';
import parse from 'html-react-parser';
import {
  CustomizedCompanies as cc,
  DefaultAdvisorObject,
} from '../../data/Constants';

export function CompanyInfoForm({
  userData,
  combinedProfile,
  setProfile,
  setPercentComplete,
}) {
  const { /* load, */ save } = useTacfsService();
  const [error, setError] = useState('');

  const { isLoading /*, mutate */ } = useMutation((values) =>
    save('studentCompany', values),
  );

  const [edit, setEdit] = useState(false);
  const [employeeIDLabel, roleLabel] = getCustomFields({
    companyId: combinedProfile.company.company_id,
  });
  const [companyName, setCompanyName] = useState(
    combinedProfile.company.company_name,
  );
  const [companyId, setCompanyId] = useState(
    combinedProfile.company.company_id,
  );
  const [region, setRegion] = useState(combinedProfile.company.region?.trim());
  const [ntid, setNtid] = useState(combinedProfile.company.nt_id?.trim());
  const [isIndependent, setIsIndependent] = useState('N');
  const [role, setRole] = useState(
    [cc._ALLSTATE, cc._STATEFARM].includes(
      companyId ? companyId : combinedProfile.company.company_id,
    )
      ? mapping.jobTitleReverse[companyId][combinedProfile.company.agent_role]
      : '',
  );

  const [employeeID, setEmployeeID] = useInput(
    combinedProfile.company.agent_id,
  );
  const [crd, setCrd] = useInput(combinedProfile.company.agent_license);
  const [npn, setNpn] = useInput(combinedProfile.company.license_number);
  const { isLoading: companyLoading, mutateAsync: companyMutate } = useMutation(
    (values) => save('studentContactall', values),
  );
  const context = useContext(TacfsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      studentID: userData.profile.studentID,
      student_id: userData.profile.studentID,
      company_affiliation: {
        company_id: companyId,
        company_name: companyName.substring(0,30),
        independent: isIndependent,
        affiliation_id: employeeID,
        role: role,
        agency_name: '',
        region: region,
        nt_id: ntid.substring(0,15),
        lsp_cd: '',
        market: '',
        territory: '',
        agent_license: crd,
        license_number: npn,
      },
    };

    setError('');
    companyMutate(data, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data) {
          if (data.code === 'E') {
            setError('Error saving data.');
          } else {
            combinedProfile.company.company_id = companyId;
            combinedProfile.company.company_name = companyName;
            combinedProfile.company.agent_id = employeeID;
            combinedProfile.company.agent_role = role;
            combinedProfile.company.agent_license = crd;
            combinedProfile.company.license_number = npn;
            combinedProfile.company.region = region;
            combinedProfile.company.nt_id = ntid;
            setProfile(combinedProfile);

            setPercentComplete(calculateProfileCompletion(combinedProfile));
            setEdit(false);

            // #519 - when Company name updates, also update the context for company name: context.tacfsContext.studentInfoInitial.company_name
            let currentContext = context.tacfsContext;
            currentContext.studentInfoInitial.company_name =
              combinedProfile.company.company_name;
            currentContext.studentInfoInitial.company_id =
              combinedProfile.company.company_id;
            context.setContext(currentContext);
          }
        } else {
          setError('Error saving data.');
        }
      },
    });
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Company Information</h4>
          {edit ? (
            companyLoading ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit">
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}>
          <div className="row">
            <div className="cell">
              Company
              {!companyName && (
                <>
                  <br />
                  <span className="missing-field">Please select company</span>
                </>
              )}
            </div>
            <div className="cell">{companyName || 'Not Provided'}</div>
          </div>
          {/* Display State Farm and AllState -specific fields */}
          {[cc._ALLSTATE, cc._STATEFARM].includes(companyId) && (
            <div className="row">
              <div className="cell">{roleLabel}</div>
              <div className="cell">
                {mapping.jobTitle[companyId][role] || 'Not Provided'}
              </div>
            </div>
          )}
          {/* Display AllState-specific fields */}
          {[cc._ALLSTATE].includes(companyId) && (
            <>
              <div className="row">
                <div className="cell">Region</div>
                <div className="cell">{region}</div>
              </div>
              <div className="row">
                <div className="cell">NTID</div>
                <div className="cell">{ntid}</div>
              </div>
            </>
          )}
          <div className="row">
            <div className="cell">{employeeIDLabel}</div>
            <div className="cell">{employeeID || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">CRD</div>
            <div className="cell">{crd || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">NPN</div>
            <div className="cell">{npn || 'Not Provided'}</div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {error && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">Error saving data.</div>
            </div>
          )}
          <CompanyAffiliationField
            combinedProfile={combinedProfile}
            setCompanyName={setCompanyName}
            setCompanyId={setCompanyId}
            setIsIndependent={setIsIndependent}
          />
          {/* Controls following fields: Employee ID, Role */}
          <CompanyCustomFields
            companyId={companyId}
            employeeID={employeeID}
            setEmployeeID={setEmployeeID}
            combinedProfile={combinedProfile}
            setRole={setRole}
            setRegion={setRegion}
            setNtid={setNtid}
          />
          <div className="row">
            <div className="cell">CRD</div>
            <div className="cell">
              <InputField
                label="CRD"
                id="crd-field"
                name="crd"
                type="text"
                value={crd}
                onChange={setCrd}
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">NPN</div>
            <div className="cell">
              <InputField
                label="NPN"
                id="npn-field"
                name="npn"
                type="text"
                value={npn}
                onChange={setNpn}
                srOnly={true}
              />
            </div>
          </div>
        </div>
        {edit ? (
          isLoading ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit">
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function CareerEducationInfoForm({
  userData,
  combinedProfile,
  setProfile,
  setPercentComplete,
}) {
  const { save } = useTacfsService();
  const [error, setError] = useState('');

  const { isLoading, mutate } = useMutation((values) =>
    save('studentEducation', values),
  );

  const [edit, setEdit] = useState(false);
  const [years, setYears] = useInput(combinedProfile.education.number_of_years);
  /*const [jobFocus, setJobFocus] = useInput(
    combinedProfile.education.tac_job_focus,
  );*/
  const [income, setIncome] = useInput(combinedProfile.education.income_level);
  const [onlineEd, setOnlineEd] = useInput(
    combinedProfile.education.prev_online_education,
  );
  const [onlineSuccess, setOnlineSuccess] = useInput(
    combinedProfile.education.success,
  );

  const yearsOptions = selectOptions.years;
  const incomeOptions = selectOptions.income;
  const educationOptions = checkboxOptions.education;
  const onlineEdOptions = selectOptions.onlineEd;
  const onlineSuccessOptions = selectOptions.onlineSuccess;
  const learningStyleOptions = checkboxOptions.learningStyle;

  const educationArray = [
    combinedProfile.education.hs_diploma === 'Y',
    combinedProfile.education.some_college === 'Y',
    combinedProfile.education.associate_degree === 'Y',
    combinedProfile.education.bachelor_degree === 'Y',
    combinedProfile.education.master_degree === 'Y',
    combinedProfile.education.prof_degree === 'Y',
    combinedProfile.education.doctorat_degree === 'Y',
    combinedProfile.education.other_education?.trim(),
  ];

  const [education, setEducation] = useCheckboxes(educationArray);
  const [educationOther, setEducationOther] = useInput(
    combinedProfile.education.other_education,
  );

  const learningStyleArray = [
    combinedProfile.education.self_directed === 'Y',
    combinedProfile.education.high_structure === 'Y',
    combinedProfile.education.support_service === 'Y',
    combinedProfile.education.trad_education_prog === 'Y',
    combinedProfile.education.learning_style?.trim(),
  ];

  const [learningStyle, setLearningStyle] = useCheckboxes(learningStyleArray);
  const [learningStyleOther, setLearningStyleOther] = useInput(
    combinedProfile.education.learning_style,
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const educationEls = e.target.getElementsByClassName('education-checkbox');
    let educationChecked = false;
    for (let i = 0; i < educationEls.length; i++) {
      if (educationEls[i].checked) {
        educationChecked = true;
        break;
      }
    }

    let educationError = document.getElementById('education-error').classList;
    if (!educationChecked) {
      educationError.remove('hidden');
    } else if (!educationError.contains('hidden')) {
      educationError.add('hidden');
    }

    const learning = e.target.getElementsByClassName('learning-checkbox');
    let learningChecked = false;
    for (let i = 0; i < learning.length; i++) {
      if (learning[i].checked) {
        learningChecked = true;
        break;
      }
    }

    let learningError = document.getElementById('learning-error').classList;
    if (!learningChecked) {
      learningError.remove('hidden');
    } else if (!learningError.contains('hidden')) {
      learningError.add('hidden');
    }

    if (!educationChecked || !learningChecked) {
      return;
    }

    const hsDiploma = education[0] ? 'Y' : 'N';
    const someCollege = education[1] ? 'Y' : 'N';
    const assocDegree = education[2] ? 'Y' : 'N';
    const bachDegree = education[3] ? 'Y' : 'N';
    const mastersDegree = education[4] ? 'Y' : 'N';
    const profDegree = education[5] ? 'Y' : 'N';
    const doctorate = education[6] ? 'Y' : 'N';

    const selfDirected = learningStyle[0] ? 'Y' : 'N';
    const highStruct = learningStyle[1] ? 'Y' : 'N';
    const supportService = learningStyle[2] ? 'Y' : 'N';
    const tradEd = learningStyle[3] ? 'Y' : 'N';

    const data = {
      studentID: userData.profile.studentID,
      number_of_years: years,
      hs_diploma: hsDiploma,
      some_college: someCollege,
      associate_degree: assocDegree,
      bachelor_degree: bachDegree,
      master_degree: mastersDegree,
      prof_degree: profDegree,
      doctorate_degree: doctorate,
      other_education: education[education.length - 1] ? educationOther : '',
      prev_online_education: onlineEd,
      success: onlineSuccess,
      self_directed: selfDirected,
      high_structure: highStruct,
      support_service: supportService,
      trad_education_prog: tradEd,
      learning_style: learningStyle[learningStyle.length - 1]
        ? learningStyleOther
        : '',
      income_level: income,
    };

    setError('');
    mutate(data, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data) {
          if (data.code === 'E') {
            setError('Error saving data.');
          } else {
            combinedProfile.education.number_of_years = years;
            combinedProfile.education.income_level = income;
            combinedProfile.education.prev_online_education = onlineEd;
            combinedProfile.education.success = onlineSuccess;
            combinedProfile.education.hs_diploma = hsDiploma;
            combinedProfile.education.some_college = someCollege;
            combinedProfile.education.associate_degree = assocDegree;
            combinedProfile.education.bachelor_degree = bachDegree;
            combinedProfile.education.master_degree = mastersDegree;
            combinedProfile.education.prof_degree = profDegree;
            combinedProfile.education.doctorat_degree = doctorate;
            combinedProfile.education.other_education = educationOther;
            combinedProfile.education.self_directed = selfDirected;
            combinedProfile.education.high_structure = highStruct;
            combinedProfile.education.support_service = supportService;
            combinedProfile.education.trad_education_prog = tradEd;
            combinedProfile.education.learning_style = learningStyleOther;
            setProfile(combinedProfile);

            setPercentComplete(calculateProfileCompletion(combinedProfile));
            setEdit(false);
          }
        } else {
          setError('Error saving data.');
        }
      },
    });
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Career & Education Information</h4>
          {edit ? (
            isLoading ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit">
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}>
          <div className="row">
            <div className="cell">
              Years in Financial Services Position
              {!years && (
                <>
                  <br />
                  <span className="missing-field">
                    Please select number of years
                  </span>
                </>
              )}
            </div>
            <div className="cell">{mapping.years[years] || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">
              Income Level
              {!income && (
                <>
                  <br />
                  <span className="missing-field">Please select income</span>
                </>
              )}
            </div>
            <div className="cell">
              {mapping.income[income] || 'Not Provided'}
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Education Background
              {checkCheckboxes(education, educationOptions, educationOther)
                .length === 0 && (
                <>
                  <br />
                  <span className="missing-field">Please select education</span>
                </>
              )}
            </div>
            <div className="cell">
              <DisplayCheckboxData
                checkboxes={education}
                data={educationOptions}
                other={educationOther}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Previous Online Education
              {!onlineEd && (
                <>
                  <br />
                  <span className="missing-field">
                    Please select previous online education
                  </span>
                </>
              )}
            </div>
            <div className="cell">
              {mapping.onlineEd[onlineEd] || 'Not Provided'}
            </div>
          </div>
          {onlineEd === 'YES' && (
            <div className="row">
              <div className="cell">Success with Online Education</div>
              <div className="cell">
                {mapping.onlineSuccess[onlineSuccess] || 'Not Provided'}
              </div>
            </div>
          )}
          <div className="row">
            <div className="cell">
              Learning Style
              {checkCheckboxes(
                learningStyle,
                learningStyleOptions,
                learningStyleOther,
              ).length === 0 && (
                <>
                  <br />
                  <span className="missing-field">
                    Please select learning style
                  </span>
                </>
              )}
            </div>
            <div className="cell">
              <DisplayCheckboxData
                checkboxes={learningStyle}
                data={learningStyleOptions}
                other={learningStyleOther}
              />
            </div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {error && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">Error saving data.</div>
            </div>
          )}
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="years-field">
                Years in Financial Services Position{' '}
                <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="years-field"
                name="years"
                value={years}
                onChange={setYears}
                required>
                {yearsOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="income-field">
                Income Level <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="income-field"
                name="income"
                value={income}
                onChange={setIncome}
                required>
                {incomeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Education Background <span className="req-star">*</span>
            </div>
            <div className="cell">
              <ul className="checkboxes">
                {educationOptions.map((option, index) => (
                  <li key={option.value}>
                    <input
                      type="checkbox"
                      id={`education-${index}`}
                      className="education-checkbox"
                      name={option.value}
                      value={option.value}
                      checked={education[index]}
                      onChange={() => setEducation(index)}
                    />
                    <label htmlFor={`education-${index}`}>{option.label}</label>
                  </li>
                ))}
                <li id="education-error" className="field-error hidden">
                  Please choose an Education Background.
                </li>
              </ul>
            </div>
          </div>
          {education[education.length - 1] && (
            <div className="row">
              <div className="cell"></div>
              <div className="cell">
                <InputField
                  label="Other Education"
                  id="educationOther-field"
                  name="educationOther"
                  type="text"
                  value={educationOther}
                  onChange={setEducationOther}
                  srOnly={true}
                  required={true}
                />
              </div>
            </div>
          )}
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="onlineEd-field">
                Previous Online Education <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="onlineEd-field"
                name="onlineEd"
                value={onlineEd}
                onChange={setOnlineEd}
                required>
                {onlineEdOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {onlineEd === 'YES' && (
            <div className="row inline-field">
              <div className="cell">
                <label htmlFor="onlineSuccess-field">
                  Success with Online Education
                </label>
              </div>
              <div className="cell">
                <select
                  id="onlineSuccess-field"
                  name="onlineSuccess"
                  value={onlineSuccess}
                  onChange={setOnlineSuccess}>
                  {onlineSuccessOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="row">
            <div className="cell">
              Learning Style <span className="req-star">*</span>
            </div>
            <div className="cell">
              <ul className="checkboxes">
                {learningStyleOptions.map((option, index) => (
                  <li key={option.value}>
                    <input
                      type="checkbox"
                      id={`learningStyle-${index}`}
                      className="learning-checkbox"
                      name={option.value}
                      value={option.value}
                      checked={learningStyle[index]}
                      onChange={() => setLearningStyle(index)}
                    />
                    <label htmlFor={`learningStyle-${index}`}>
                      {option.label}
                    </label>
                  </li>
                ))}
                <li id="learning-error" className="field-error hidden">
                  Please choose a Learning Style.
                </li>
              </ul>
            </div>
          </div>
          {learningStyle[learningStyle.length - 1] && (
            <div className="row">
              <div className="cell"></div>
              <div className="cell">
                <InputField
                  label="Other Learning Style"
                  id="learningStyleOther-field"
                  name="learningStyleOther"
                  type="text"
                  value={learningStyleOther}
                  onChange={setLearningStyleOther}
                  srOnly={true}
                  required={true}
                />
              </div>
            </div>
          )}
        </div>
        {edit ? (
          isLoading ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit">
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function AdvisorGuideInfoForm({ combinedProfile }) {
  const { load, save } = useTacfsService();
  const { isLoading: advisorLoading, data: advisorData } = useQuery(
    ['advisorData'],
    () => load('advisorData', {}),
    {
      staleTime: 30 * (60 * 1000), // 30 mins
    },
  );
  const { mutate: saveAdvisor, isLoading } = useMutation((params) =>
    save('advisorData', params),
  );
  const [edit, setEdit] = useState(false);
  const ValidationSchema = Yup.object().shape({
    //        phone: Yup.string()
    //          .matches(phoneRegExp, '*Invalid phone format')
    //          .required('*Phone number required'),
    //        email: Yup.string().email('Invalid email').required('*Required'),
    //        whenCompleted: Yup.string().required('The field is required.'),
    //        clu: Yup.array().min(1, 'Atleast One Required'),
    //        chfc: Yup.array().min(1, 'Atleast One Required'),
    //        certificationLetter: Yup.array().min(
    //          1,
    //          'Required - Please be sure to upload a CFP® Verification Letter.',
    //        ),
  });

  const paidOptions = selectOptions.paid;
  const statesOptions = selectOptions.state;
  const minAssetsOptions = selectOptions.minAssets;
  const languagesOptions = checkboxOptions.languages;
  const formatAdvisorLanguagesObj = (langArray) => {
    let formattedLangs = [];
    if (Array.isArray(langArray)) {
      langArray.forEach((langObj) => {
        formattedLangs.push(langObj.language);
      });
    }
    return formattedLangs;
  };
  const formatAdvisorLanguagesForAPI = (valuesArray) => {
    let formattedLangs = [];
    valuesArray.forEach((langValue) => {
      formattedLangs.push({
        language: langValue,
        language_long: getLabelFromValueFilter(langValue, languagesOptions),
      });
    });
    return formattedLangs;
  };
  const formSubmit = async (values) => {
    // Update original data with new data from form
    let advisor = advisorData.results[0];

    // WRP-931 - custom logic for *new* YAG users who have no advisorData.
    // We are setting values for properties not managed in this form.
    // For existing YAG users, we don't need to update these values since
    // they're not managed by this form. If that changes, we'll need to update
    // this logic.
    if (
      advisor === undefined ||
      advisor === null ||
      (typeof advisor === 'object' && Object.keys(advisor).length === 0)
    ) {
      advisor = DefaultAdvisorObject;
      // For a new YAG user, set the values in the advisor profile which
      // are NOT set as part of the YAG profile form.
      advisor.first_name = combinedProfile.initial.first_name;
      advisor.last_name = combinedProfile.initial.last_name;
      advisor.title = combinedProfile.basic.title;
      advisor.phone = combinedProfile.contact.phone;
      advisor.email_address = combinedProfile.contact.email;
      advisor.company_id = combinedProfile.company.company_id;
      advisor.company_name = combinedProfile.company.company_name;
      advisor.broker_id = combinedProfile.company.company_id;
      advisor.studentID = combinedProfile.initial.empl_id;
    }
    // Only update advisor values for fields in the form.
    advisor.opt_in = values.opt_in;
    advisor.asset_range = values.asset_range;
    advisor.opt_in = values.opt_in;
    advisor.profile_photo = values.profile_photo;
    advisor.state = values.state;
    advisor.address1 = values.address1;
    advisor.address2 = values.address2 ? values.address2 : '';
    advisor.city = values.city;
    advisor.postal = values.postal;
    advisor.retired = values.retired;
    advisor.linkedin = values.linkedin;
    advisor.facebook = values.facebook;
    advisor.website = values.website;
    advisor.advisor_language = formatAdvisorLanguagesForAPI(
      values.advisor_language,
    );
    advisor.files = values.files;

    // TODO: At some future point we may choose to implement these fields:
    // advisor.address_type = values.address_type;
    // advisor.payment_method = values.payment_method;
    // advisor.fiduciary_advisor = values.fiduciary_advisor;
    // advisor.advisor_expertise = values.advisor_expertise;

    saveAdvisor(advisor, {
      onError: (res) => console.log('We encountered an error saving form data'),
      onSuccess: (data) => {
        if (data.status === 'Profile updated successfully!') {
          //TODO: Give success message
          setEdit(false);
        } else {
          //TODO: Give error message
        }
      },
    });
  };
  const businessAddress = (advisorData) => {
    if (
      !advisorData ||
      advisorData === undefined ||
      advisorData === null ||
      (typeof advisorData === 'object' && Object.keys(advisorData).length === 0)
    ) {
      return false;
    }
    let addressString = '';
    addressString = addressString.concat(advisorData?.address1);
    addressString = addressString.concat('<br/>');
    if (advisorData.address2) {
      addressString = addressString.concat(advisorData?.address2);
      addressString = addressString.concat('<br/>');
    }
    addressString = addressString.concat(
      advisorData?.city,
      ', ',
      advisorData?.state,
      ' ',
      advisorData?.postal,
    );
    return parse(addressString);
  };

  if (advisorLoading) return <Timer />;
  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <Formik
        initialValues={{
          student_id: advisorData.results.length
            ? advisorData.results[0].studentID
            : '',
          first_name: advisorData.results.length
            ? advisorData.results[0].first_name
            : '',
          last_name: advisorData.results.length
            ? advisorData.results[0].last_name
            : '',
          title: advisorData.results.length ? advisorData.results[0].title : '',
          state: advisorData.results.length ? advisorData.results[0].state : '',
          asset_range: advisorData.results.length
            ? advisorData.results[0].range
            : '',
          payment_method: advisorData.results.length
            ? advisorData.results[0].payment_method
            : '',
          opt_in: advisorData.results.length
            ? advisorData.results[0].opt_in
            : '',
          profile_photo: advisorData.results.length
            ? advisorData.results[0].profile_photo
            : '',
          address_type: advisorData.results.length
            ? advisorData.results[0].address_type
            : '',
          address1: advisorData.results.length
            ? advisorData.results[0].address1
            : '',
          address2: advisorData.results.length
            ? advisorData.results[0].hasOwnProperty('address2')
              ? advisorData.results[0]?.address2
              : ''
            : '',
          city: advisorData.results.length ? advisorData.results[0].city : '',
          postal: advisorData.results.length
            ? advisorData.results[0].postal
            : '',
          phone: advisorData.results.length ? advisorData.results[0].phone : '',
          email_address: advisorData.results.length
            ? advisorData.results[0].email_address
            : '',
          retired: advisorData.results.length
            ? advisorData.results[0].retired
            : '',
          company_id: advisorData.results.length
            ? advisorData.results[0].company_id
            : '',
          company_name: advisorData.results.length
            ? advisorData.results[0].company_name
            : '',
          broker_id: advisorData.results.length
            ? advisorData.results[0].broker_id
            : '',
          linkedin: advisorData.results.length
            ? advisorData.results[0].linkedin
            : '',
          facebook: advisorData.results.length
            ? advisorData.results[0].facebook
            : '',
          website: advisorData.results.length
            ? advisorData.results[0].website
            : '',
          fiduciary_advisor: advisorData.results.length
            ? advisorData.results[0].fiduciary_advisor
            : '',
          advisor_expertise: advisorData.results.length
            ? advisorData.results[0].advisor_expertise
            : [],
          advisor_language: advisorData.results.length
            ? formatAdvisorLanguagesObj(advisorData.results[0].advisor_language)
            : [],
          //          asset_range: advisorData.results.length
          //            ? advisorData.results[0].asset_range
          //            : '',
          files: [],
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex card-header">
              <h4>Your Advisor Guide Information</h4>
              {edit ? (
                isLoading ? (
                  <input
                    type="submit"
                    value="Saving..."
                    className="btn desktop-save disabled"
                    disabled
                  />
                ) : (
                  <input
                    type="submit"
                    value="Save"
                    className="btn desktop-save"
                  />
                )
              ) : (
                <button
                  onClick={() => setEdit(true)}
                  className="btn secondary large desktop-edit">
                  Edit
                </button>
              )}
            </div>
            <div className={edit ? '' : 'hidden'}>
              <em>
                Please note that it may take up to 24 hours for changes to your
                information to take effect. Your changes may not immediately
                reflect in the data displayed below.
              </em>
              <br />
              <br />
            </div>
            <Divider />

            <div className={!edit ? 'card-intro' : 'card-intro hidden'}>
              <p className="p3">
                Any client-facing advisor with a designation from The American
                College of Financial Services can create a public profile for
                themselves on{' '}
                <Link
                  to={{ pathname: 'https://www.youradvisorguide.com/' }}
                  target="_blank">
                  YourAdvisorGuide.com
                </Link>
                . Showcase your latest accomplishments, help prospective clients
                searching for an advisor in your area get in touch with you, and
                keep us informed so we may better serve you.
              </p>
              <p className="p3">
                Please note that updating these fields will update information
                contained in your{' '}
                <Link
                  to={{ pathname: 'https://www.youradvisorguide.com/' }}
                  target="_blank">
                  YourAdvisorGuide.com
                </Link>{' '}
                profile as well as your student records with The American
                College of Financial Services.
              </p>
              <p className="p3">
                <span className="font-semibold">Contact information:</span>{' '}
                Potential clients will be able to use{' '}
                <Link
                  to={{ pathname: 'https://www.youradvisorguide.com/' }}
                  target="_blank">
                  YourAdvisorGuide.com
                </Link>{' '}
                to search for advisors like you by location and contact you
                directly through the method you've chosen.
              </p>
            </div>

            <div
              className={
                edit ? 'table display-data hidden' : 'table display-data'
              }>
              <div className="row">
                <div className="cell">
                  Show My Profile on Your Advisor Guide
                </div>
                <div className="cell">
                  {getLabelFromValue(values.opt_in, checkboxOptions.yesNo)}
                </div>
              </div>
              <div className="row">
                <div className="cell">Profile Photo</div>
                <div className="cell profile_photo">
                  {values.profile_photo && (
                    <img alt="My Advisor Guide" src={values.profile_photo} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="cell">Business Address</div>
                <div className="cell">
                  {businessAddress(values) || 'Not Provided'}
                </div>
              </div>
              <div className="row">
                <div className="cell">Are you retired?</div>
                <div className="cell">
                  {getLabelFromValue(values.retired, checkboxOptions.yesNo)}
                </div>
              </div>
              <div className="row">
                <div className="cell">Personal LinkedIn Profile</div>
                <div className="cell">{values.linkedin || 'Not Provided'}</div>
              </div>
              <div className="row">
                <div className="cell">Company Facebook Profile</div>
                <div className="cell">{values.facebook || 'Not Provided'}</div>
              </div>
              <div className="row">
                <div className="cell">Company Website</div>
                <div className="cell">{values.website || 'Not Provided'}</div>
              </div>
              <div className="row">
                <div className="cell">Minimum Assets Required</div>
                <div className="cell">
                  {getLabelFromValue(values.asset_range, minAssetsOptions) ||
                    'Not Provided'}
                </div>
              </div>
              <div className="row">
                <div className="cell">How am I paid?</div>
                <div className="cell">
                  {getLabelFromValue(values.payment_method, paidOptions) ||
                    'Not Provided'}
                </div>
              </div>
              <div className="row">
                <div className="cell">Languages Spoken</div>
                <div className="cell">
                  <ul>
                    {values.advisor_language &&
                    Array.isArray(values.advisor_language) &&
                    values.advisor_language.length > 0
                      ? values.advisor_language.map((item, index) => (
                          <li key={index}>
                            {getLabelFromValue(item, languagesOptions)}
                          </li>
                        ))
                      : 'Not provided'}
                  </ul>
                </div>
              </div>
            </div>

            <div
              className={edit ? 'table info-form' : 'table info-form hidden'}>
              <div className="row">
                <div className="row">
                  <div className="cell">
                    Show My Profile on Your Advisor Guide
                  </div>
                  <div className="cell">
                    <div className="inline-field inputField radio">
                      <label>
                        <Field type="radio" name="opt_in" value="Y" />
                        Yes
                      </label>
                      <label>
                        <Field type="radio" name="opt_in" value="N" />
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="inline-field row">
                  <div className="cell">
                    <label htmlFor="profilePhoto-field">Profile Photo</label>
                  </div>
                  <div className="cell">
                    {/*<input 
                        id="profilePhoto-field" 
                        name="profile_photo" 
                        type="file" 
                        onChange={(e) => setFieldValue("profile_photo" , e.target.files[0])} 
                    />*/}
                    <FileUpload
                      selector="files"
                      values={values.files}
                      setFieldValue={setFieldValue}
                      setErrors={setErrors}
                      errors={errors.files}
                      uploadCount={1}
                      allowedTypes=".jpg, .png, .jpeg"
                      maxFileSize="6291456"
                    />
                    {values.files.length > 0 && (
                      <img
                        alt="Uploaded"
                        src={window.atob(values.files[0].file_data)}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Business Address</div>
                  <div className="cell">&nbsp;</div>
                </div>
                <div className="row">
                  <div className="cell">Address 1</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="address1"
                        name="address1"
                        value={values.address1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Address 2</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="address2"
                        name="address2"
                        value={values.address2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">City</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">State</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        name="state"
                        as="select"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        {statesOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Zipcode</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="postal"
                        name="postal"
                        value={values.postal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Are you retired?</div>
                  <div className="cell">
                    <div className="inline-field inputField radio">
                      <label>
                        <Field type="radio" name="retired" value="Y" />
                        Yes
                      </label>
                      <label>
                        <Field type="radio" name="retired" value="N" />
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Personal LinkedIn Profile</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        value={values.linkedin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Company Facebook Profile</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="facebook"
                        name="facebook"
                        value={values.facebook}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Company Website</div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        type="text"
                        id="website"
                        name="website"
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="row inline-field">
                  <div className="cell">
                    <label htmlFor="minAssets-field">
                      Minimum Assets Required
                    </label>
                  </div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        name="asset_range"
                        as="select"
                        value={values.asset_range}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        {minAssetsOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row inline-field">
                  <div className="cell">
                    <label htmlFor="paid-field">How am I paid?</label>
                  </div>
                  <div className="cell">
                    <div className="inline-field inputField">
                      <Field
                        name="payment_method"
                        as="select"
                        value={values.payment_method}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        {paidOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">Languages Spoken</div>
                  <div className="cell">
                    <div
                      className="inputField check"
                      role="group"
                      aria-labelledby="my-radio-group">
                      {languagesOptions.map((option, index) => (
                        <div key={index}>
                          <label>
                            <Field
                              onChange={handleChange}
                              checked={values.advisor_language.includes(
                                option.value,
                              )}
                              onBlur={handleBlur}
                              type="checkbox"
                              name="advisor_language"
                              value={option.value}
                            />
                            {option.label}
                          </label>
                          <br />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {edit ? (
              <input type="submit" value="Save" className="btn mobile-save" />
            ) : (
              <button
                onClick={() => setEdit(true)}
                className="btn secondary large mobile-edit">
                Edit
              </button>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
}
function getLabelFromValue(value, list) {
  if (value === undefined) {
    return false;
  }
  const labelArray = list.map((option) => {
    if (option.value === value) {
      return option.label;
    } else {
      return false;
    }
  });
  return labelArray;
}
function getLabelFromValueFilter(value, list) {
  if (value === undefined) {
    return false;
  }
  const result = list.filter((option) => option.value === value);
  if (result.length > 0) {
    return result[0].label;
  } else {
    return false;
  }
}
function useInput(initialValue) {
  if (!initialValue) initialValue = '';

  const [value, setValue] = useState(initialValue.trim());

  function handleChange(e) {
    setValue(e.target.value.trim());
  }

  return [value, handleChange];
}

function useCheckboxes(initialValue) {
  const [values, setValues] = useState(initialValue);

  function handleChange(position) {
    const newValues = values.map((item, index) =>
      index === position ? !item : item,
    );

    setValues(newValues);
  }

  return [values, handleChange];
}

function checkCheckboxes(checkboxes, data, other = false) {
  let checkedItems = [],
    number;

  if (other) {
    number = checkboxes.length - 1;
  } else {
    number = checkboxes.length;
  }

  for (let i = 0; i < number; i++) {
    if (checkboxes[i] && i < data.length) {
      checkedItems.push(data[i].label);
    }
  }

  if (other && checkboxes[checkboxes.length - 1]) {
    checkedItems.push(other);
  }

  return checkedItems;
}

function DisplayCheckboxData({ checkboxes, data, other = false }) {
  const checkedItems = checkCheckboxes(checkboxes, data, other);

  if (checkedItems.length > 0) {
    return <div>{checkedItems.join(', ')}</div>;
  } else {
    return 'Not Provided';
  }
}
