import { useRef, useEffect, useState } from 'react';

/**
 * @function ProgressDots component
 * 
 * @description
 * The function starts by using the useRef hook to create a ref 
 * for each dot element in the progress bar. It then uses the useState 
 * hook to manage the isAnimated state, which indicates whether
 * the animation should be triggered.
 * 
 * Two useEffect hooks are used for handling the 
 * scrolling and animation effects.
 * 
 * The first useEffect hook adds a scroll event listener 
 * to the window and triggers a handleScroll function when 
 * the component mounts. The handleScroll function 
 * checks if the dots are in the viewport 
 * and sets the isAnimated state to true if they are.
 * 
 * The second useEffect hook monitors changes in the
 * isAnimated and completedCourses states.
 * When isAnimated is true, it loops through 
 * each dot element using the ref and animates 
 * the dot and line colors based on the 
 * index and the number of completed courses.
 * 
 * The function then creates an array of dots dynamically 
 * based on the total number of courses provided.
 * It uses a for loop to push each dot element 
 * with appropriate styling and a ref to the dotRefs array.
 * 
 * The component takes in the following props:
 * @param totalCourses The total number of courses in the learning journey
 * @param completedCourses: The number of courses that have been completed
 * @param title: The title of the progress dots
 * 
 * @returns jsx
 */
export const ProgressDots = ({ totalCourses, completedCourses, title }) => {
  const dotRefs = useRef([]);
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the DOM rect of the first dot element using a ref
      const rect = dotRefs.current[0].getBoundingClientRect();
      
      // Get the view height of the document, taking into account browser differences
      const viewHeight = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
  
      // Check if the animation is not already triggered and if the top of the dot element is at least 75% inside the view height
      if (!isAnimated && rect.top <= viewHeight * 0.75) {
        // Set the isAnimated state to true, indicating that the animation should be triggered
        setIsAnimated(true);
      }
    };
  
    // Add a scroll event listener to the window that triggers the handleScroll function
    window.addEventListener('scroll', handleScroll);
  
    // Trigger the handleScroll function on mount to check if dots are already in the viewport
    handleScroll();
  
    // Clean up the event listener when the component is unmounted or when the isAnimated state changes
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimated]);

  useEffect(() => {
    // Check if the `isAnimated` state is `true`
    if (isAnimated) {
      // Loop through each dot element using a ref
      dotRefs.current.forEach((ref, index) => {
        // Get the dot element and line element using querySelector
        const dotElement = ref.querySelector('div:nth-child(1)');
        const lineElement = ref.querySelector('div:nth-child(2)');
  
        // If both dot element and line element exist
        if (dotElement) {
          // Calculate a delay for sequential animation based on the index
          const delay = index * 300;
  
          // Set a timeout to animate the dot and line color
          setTimeout(() => {
            // If the index is less than the number of completed courses
            if (index < completedCourses) {

              // Set the background color of the dot element to blue
              dotElement.style.backgroundColor = '#4200ed';

              if (lineElement) {
                // Set the background color of the line element to blue for line color animation
                lineElement.style.backgroundColor = '#4200ed';
              }
            } else {
              // Set the background color of the dot element to white
              dotElement.style.backgroundColor = 'white';

              if (lineElement) {
                // Set the background color of the line element to black for line color animation
                lineElement.style.backgroundColor = 'black';
              }
            }
          }, delay);
        }
      });
    }
  }, [isAnimated, completedCourses]);

  const dots = [];

  // Loop through each course and create a dot with appropriate styling
  for (let i = 0; i < totalCourses; i++) {
    dots.push(
      <div
        className='flex justify-center items-center flex-row'
        key={i}
        ref={ref => (dotRefs.current[i] = ref)}
      >
        <div className='border border-black rounded-full w-3 h-3 md:w-6 md:h-6 inline-block relative overflow-hidden transition duration-500 ease bg-white'></div>
        {/* Create a connecting line for all dots except the last one */}
        {i !== totalCourses - 1 && (
          <div className='bg-black w-5 md:w-10 h-0.5 inline-block relative transition duration-500 ease'></div>
        )}
      </div>
    );
  }

  return (
    <div className='progress-bar-wrapper flex-column !items-center'>
      <div className="dots-progress-title">{title}</div>
      <div className='flex justify-center items-center flex-row'>{dots}</div>
    </div>
  );
};


