import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReadStorage from '../../components/storage/ReadStorage';
import { TacfsContext } from '../../components/global/Context';
import useTacfsService from '../../utils/tacfs/useTacfsService';

import { StandardResourceTab } from './StandardResourceTab';
import { SavedResources } from './SavedResources';

export default function ResourcesTabs() {
  const [value, setValue] = useState(0);
  const { load } = useTacfsService();
  const oktaProfile = ReadStorage('oktaProfile');
  const context = useContext(TacfsContext);
  const tacfsProfile = context.tacfsContext;

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  //Get SavedResource IDs
  const { isLoading: loadingSavedResources, data: savedResourcesIDs } =
    useQuery(['savedResourcesIDs'], () =>
      load('savedResources', { oktaID: oktaProfile.id, offset: 0, limit: -1 }),
    );

  //Get Student Flags & determine if user is alumni
  const { isLoading: loadingFlags, data: studentFlags } = useQuery(
    ['studentFlags'],
    () => load('studentFlags'),
  );

  // Once we have our saved resouces, pull out the NIDs so that we can mark resources in the other sections as saved.
  let savedNIDs = [];
  if (savedResourcesIDs && Array.isArray(savedResourcesIDs)) {
    savedNIDs = savedResourcesIDs.map((resource) => {
      return resource.nid;
    });
  }

  return (
    <div>
      <div className="resources-tabs tabs-layout">
        <Tabs
          value={value}
          onChange={handleChange}
          className="resources-tabs-inner"
          aria-label="resources tabs"
          classes={{ indicator: 'tabs-indicator' }}
        >
          <Tab disableRipple label="All Resources" {...tabProps(0)} />
          <Tab disableRipple label="Orientation Resources" {...tabProps(1)} />
          <Tab
            disableRipple
            label="Alumni Resources"
            {...tabProps(2)}
            disabled={studentFlags?.imodules_access == 'Y' ? false : true}
            textColor={
              studentFlags?.imodules_access == 'Y' ? 'inherit' : '#e5dbe7'
            }
          />
          <Tab disableRipple label="Saved Resources" {...tabProps(3)} />
        </Tabs>
      </div>

      {/* All Resources */}
      <ResourcesTabPanel value={value} index={0}>
        <StandardResourceTab
          queryName="allResources"
          saved={savedNIDs}
          studentFlags={studentFlags}
        />
      </ResourcesTabPanel>

      {/* Orientation Resources */}
      <ResourcesTabPanel value={value} index={1}>
        <StandardResourceTab
          queryName="orientationResources"
          resourceClassArr={['Orientation']}
          saved={savedNIDs}
        />
      </ResourcesTabPanel>

      {/* Alumni Resources */}

      <ResourcesTabPanel value={value} index={2}>
        <StandardResourceTab
          queryName="alumniResources"
          resourceClassArr={['Alumni']}
          saved={savedNIDs}
          studentFlags={studentFlags}
        />
      </ResourcesTabPanel>

      {/* Saved Resources */}
      <ResourcesTabPanel value={value} index={3}>
        <SavedResources saved={true} />
      </ResourcesTabPanel>
    </div>
  );
}

function ResourcesTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="resources-panel"
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
