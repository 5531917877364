import { useEffect, useState, useRef } from 'react';
import Divider from '@mui/material/Divider';
import { DownloadArrow } from '../subcomponents/Icon';
import Timer from '../subcomponents/Timer';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ReadStorage from '../storage/ReadStorage';
import {
  getRecertStatusRevised,
  compliantStatuses,
} from '../../pages/DesignationsCertifications';
export function KnowledgeHubPlus({studentCE}) {
  const ref = useRef();
  const [error, setError] = useState(null);
  const [hash, setHash] = useState(null);
  const [openLink, setOpenLink] = useState(null);
  const [khPaidVersion, setKhPaidVersion] = useState(false);
  /* membershipStatus is true is studentFlags.khp_access === "Y". */
  const [membershipStatus/*, setMembershipStatus*/] = useState("Active");
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  // Pull in the user data from local storage.
  const tacfsProfile = ReadStorage('oktaProfile');
  const { save, load } = useTacfsService();

  const { isLoading: khPlusHashLoading, mutate: khPlusHash } = useMutation(() =>
    save('khPlusHash'),
  );
  
  const { isLoading: isLoadingFlags, data: studentFlags } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );
  const status = getRecertStatusRevised(studentCE);
  const prpValid = compliantStatuses.includes(status);
  
  const onLifterClick = (e) => {
    e.preventDefault();
    if (khPlusHashLoading === false && hash === null) {
      khPlusHash([], {
        onError: (res) => setError('Sorry, something went wrong.'),
        onSuccess: (data) => {
          if (data && data.value) {
            // Save the report data.
            setHash(data.value);
          } else {
            setError(
              'We encountered an error retrieving the students hash value.',
            );
          }
        },
      });
    }
    setOpenLink(true);
  };

  useEffect(() => {
    if (khPlusHashLoading === false && hash && openLink) {
      ref.current.click();
      setOpenLink(false);
    }
  }, [khPlusHashLoading, hash, openLink]);
  
  useEffect(()=>{
      if(!isLoadingFlags && studentFlags){
        if(studentFlags.khp_subscription_id){
            setKhPaidVersion(true);
            setSubscriptionStatus(studentFlags.subscription_status);
        }
      }
  }, [isLoadingFlags, studentFlags, setKhPaidVersion]);

  return (
    <div className="card drop-box padding--sm w-full no-enrollment knowledge-hub-module">
      <div className="column-layout">
        <div className="title3">
           {khPaidVersion && (<Link to="/subscriptions" className="">My Subscription</Link>)}
           {!khPaidVersion && !prpValid && (<>My Subscription</>)}
           {!khPaidVersion && prpValid && (<button onClick={onLifterClick}>Knowledge Hub</button>)}
        </div>
        {khPaidVersion && (<Link to="/subscriptions" className="desktop btn">Manage Subscription</Link>)}
      </div>
      <Divider className="top-divider" />
      <div className="content-wrapper khplus">
        <div className="no-enrollment-img">
          <div className="no-img khplus-box">
            <h3>
              <em>Knowledge</em>
              <br />
              Hub<span className="plus">+</span>
            </h3>
          </div>
        </div>
        <div>
        { (khPaidVersion || !prpValid) && (
           <p className="p2">Knowledge Hub+ Subscription</p>
        )}
          <div className="small-headline gray">
            { khPaidVersion?"Manage & explore":"Explore"} your CE platform for the latest insight and expertise
          </div>
          { khPaidVersion && (
            <ul className="bullets">
                <li>Membership Status: {membershipStatus}</li>
                {subscriptionStatus?.toUpperCase() === "ACTIVE" && (
                    <li>{subscriptionStatus?.toUpperCase() === "ACTIVE"?"Subscription Auto-renewal":"Access Expires on Date"}: {dayjs(studentFlags?.khp_subscription_date).add(1, "year").format('M/D/YYYY')}</li>
                )}
                {subscriptionStatus?.toUpperCase() === "PAUSED" && membershipStatus === "Active" && (
                    <li>Subscription paused, access expires on {dayjs(studentFlags?.khp_subscription_date).add(1, "year").format('M/D/YYYY')}</li>
                )}
                {subscriptionStatus?.toUpperCase() === "SKIPPED" && membershipStatus === "Active" && (
                    <li>Subscription skipped, access expires on {dayjs(studentFlags?.khp_subscription_date).add(1, "year").format('M/D/YYYY')}</li>
                )}
                {subscriptionStatus?.toUpperCase() === "CANCELLED" && membershipStatus === "Active" && (
                    <li>Subscription cancelled, access expires on {dayjs(studentFlags?.khp_subscription_date).add(1, "year").format('M/D/YYYY')}</li> 
                )}
            </ul>       
           )}
           {!prpValid && !khPaidVersion && (
            <ul className="bullets">
                <li>Membership Status: Active</li>
            </ul>       
           )}
          {khPlusHashLoading === false && hash && (
            <form
              style={{ display: 'none' }}
              target="_blank"
              action={process.env.REACT_APP_KNOWLEDGE_HUB_PLUS_URL}
              rel="noreferrer"
              method="post"
              id={`knowledgeOfferingsPlusLoginForm`}
              acceptCharset="UTF-8">
              <div>
                <>
                  <div className="see-all" style={{ display: 'flex' }}>
                    <div>
                      <input
                        type="submit"
                        ref={ref}
                        name="access_course"
                        value={`Access Knowledge Hub+ `}
                      />
                    </div>
                    <div>
                      <DownloadArrow />
                    </div>
                  </div>
                  <input
                    type="hidden"
                    name="student_id"
                    value={tacfsProfile.profile.studentID}
                  />
                  <input type="hidden" name="hash" value={hash} />
                </>
              </div>
            </form>
          )}
          {khPlusHashLoading && <Timer />}
          <button className="see-all" onClick={onLifterClick}>
            Access Knowledge Hub+ <DownloadArrow />
          </button>
          <p className="red-text">{error}</p>
          {khPaidVersion && (<div className="mobile"><hr/><br/><Link to="/subscriptions" className="btn">Manage Subscription</Link></div>)}
        </div>
      </div>
    </div>
  );
}
