import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
  return (
    <Layout>
      <Breadcrumbs />
      <div className="container">
        <h2>
          Uh oh! This page cannot be found, but let's advance you to another
          page!
        </h2>
      </div>

      <div className="container resources">
        <div className="resources-callout column-layout halves padding--sm">
          <div className="col title2 padding--lg">
            Looking for more resources?
          </div>
          <div className="col padding--lg">
            <p>
              As the preeminent institution for applied financial knowledge and
              education, The College offers an extensive library of free
              resources through its Knowledge Hub.
            </p>
            <div className="flex">
              <Link
                to={{ pathname: `${process.env.REACT_APP_MARKETING_URL}knowledge-hub` }}
                target="_blank"
                className="btn tertiary"
              >
                View the Knowledge Hub
              </Link>
              <Link
                to={{ pathname: 'https://acfs.tdnetdiscover.com/' }}
                target="_blank"
                className="underline-link peach"
              >
                College Library
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
