import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export function ReportingForm(props) {
  const {/*isStateFarmUser,*/ showClear, clear} = props;
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        emplId: '',
//        agentId: '',
//        arid: '',
        time: {
          startDate: null,
          endDate: null,
        },
      }}
      enableReinitialize={true}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        props.formSubmit(values);
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit} id="designationReport">
          <h4>By Date</h4>
          <div className="form-layout">
            <div className="field col--quarter">
              <label htmlFor="startDate">From Date</label>
              <DatePicker
                showIcon
                selected={values.time.startDate}
                onChange={(date) => setFieldValue('time.startDate', date)}
                placeholderText="MM/DD/YYYY"
                selectsStart
                startDate={values.time.startDate}
                endDate={values.time.endDate}
              />
              {touched.time && errors.time && (
                <span className="red-text">{errors.time.startDate}</span>
              )}
            </div>
            <div className="field col--quarter">
              <label htmlFor="endDate">To Date</label>
              <DatePicker
                showIcon
                selected={values.time.endDate}
                onChange={(date) => setFieldValue('time.endDate', date)}
                placeholderText="MM/DD/YYYY"
                selectsEnd
                startDate={values.time.startDate}
                endDate={values.time.endDate}
                minDate={values.time.startDate}
              />
              {touched.time && errors.time && (
                <span className="red-text">{errors.time.endDate}</span>
              )}
            </div>
          </div>
          <h4>By Student Information</h4>
          <div className="form-layout">
            <div className="field col--third">
              <label htmlFor="firstname">First Name</label>
              <Field
                type="text"
                id="firstName"
                name="firstName"
                value={values.firstName}
                className={
                  touched.firstName && errors.firstName ? 'error' : null
                }
                onChange={handleChange}
                placeholder="First Name"
                onBlur={handleBlur}
              />
              {touched.firstName && errors.firstName && (
                <span className="red-text">{errors.firstName}</span>
              )}
            </div>
            <div className="field col--third">
              <label htmlFor="emplId">Last Name</label>
              <Field
                type="text"
                id="lastName"
                name="lastName"
                value={values.lastName}
                className={touched.lastName && errors.lastName ? 'error' : null}
                onChange={handleChange}
                placeholder="Last Name"
                onBlur={handleBlur}
              />
              {touched.lastName && errors.lastName && (
                <span className="red-text">{errors.lastName}</span>
              )}
            </div>
            <div className="field col--third">
              <label htmlFor="emplId">Student ID</label>
              <Field
                type="text"
                id="emplId"
                name="emplId"
                value={values.emplId}
                className={touched.emplId && errors.emplId ? 'error' : null}
                onChange={handleChange}
                placeholder="7 Digits"
                onBlur={handleBlur}
              />
              {touched.emplId && errors.emplId && (
                <span className="red-text">{errors.emplId}</span>
              )}
            </div>
{/*            <div className="field">
              <label htmlFor="agentId">{isStateFarmUser?'Alias':'Employee ID'}</label>
              <Field
                type="text"
                id="agentId"
                name="agentId"
                value={values.agentId}
                className={touched.agentId && errors.agentId ? 'error' : null}
                onChange={handleChange}
                placeholder="Alias"
                onBlur={handleBlur}
              />
              {touched.agentId && errors.agentId && (
                <span className="red-text">{errors.agentId}</span>
              )}
          </div>
          { isStateFarmUser && (
            <div className="field">
              <label htmlFor="arid">AR ID</label>
              <Field
                type="text"
                id="arid"
                name="arid"
                value={values.arid}
                className={touched.arid && errors.arid ? 'error' : null}
                onChange={handleChange}
                placeholder="AR ID"
                onBlur={handleBlur}
              />
              {touched.arid && errors.arid && (
                <span className="red-text">{errors.arid}</span>
              )}
            </div>
            )}*/}
          </div>

          <div className="form-layout center">
            <button
              type="submit"
              form="designationReport"
              className="btn col--quarter">
              Run Report
            </button>
            {showClear && (
              <button
                type="button"
                className="btn col--quarter clear"
                onClick={() => {
                  clear();
                  resetForm();
                }}>
                Clear
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

export const ValidationSchema = Yup.object().shape(
  {
    firstName: Yup.string().when('lastName', (lastName, schema) => {
      if (lastName && lastName[0] == null)
        return schema.when('emplId', (emplId, schema) => {
          if (emplId && emplId[0] == null)
//            return schema.when('agentId', (agentId, schema) => {
//              if (agentId && agentId[0] == null)
//                return schema.when('arid', (arid, schema) => {
//                  if (arid && arid[0] == null)
            return schema.when('time', (time, schema) => {
              if (
                time[0] &&
                time[0].startDate === null &&
                time[0].endDate === null
              )
                return schema.required(
                  'Please provide at least one piece of information',
                );
            });
        });
//            });
//        });
    }),
    lastName: Yup.string().when('firstName', (firstName, schema) => {
      if (firstName && firstName[0] == null)
        return schema.when('emplId', (emplId, schema) => {
          if (emplId && emplId[0] == null)
//            return schema.when('agentId', (agentId, schema) => {
//              if (agentId && agentId[0] == null)
//                return schema.when('arid', (arid, schema) => {
//                  if (arid && arid[0] == null)
            return schema.when('time', (time, schema) => {
              if (
                time[0] &&
                time[0].startDate === null &&
                time[0].endDate === null
              )
                return schema.required(
                  'Please provide at least one piece of information',
                );
            });
        });
//            });
//        });
    }),
    emplId: Yup.string()
      .matches(
        /^1\d{6}$/,
        'Student ID must be 7-digit numeric and start with 1',
      )
      .when('firstName', (firstName, schema) => {
        if (firstName && firstName[0] == null)
          return schema.when('lastName', (lastName, schema) => {
            if (lastName && lastName[0] == null)
//              return schema.when('agentId', (agentId, schema) => {
//              if (agentId && agentId[0] == null)
//                return schema.when('arid', (arid, schema) => {
//                  if (arid && arid[0] == null)
              return schema.when('time', (time, schema) => {
                if (
                  time[0] &&
                  time[0].startDate === null &&
                  time[0].endDate === null
                )
                  return schema.required(
                    'Please provide at least one piece of information',
                  );
              });
          });
//            });
//          });
      }),
    time: Yup.object().shape(
      {
        startDate: Yup.string()
          .nullable()
          .when('endDate', (endDate, schema) => {
            if (endDate && endDate[0] != null)
              return schema.required('Please provide both date fields');
          }),
        endDate: Yup.string()
          .nullable()
          .when('startDate', (startDate, schema) => {
            if (startDate && startDate[0] != null)
              return schema.required('Please provide both date fields');
          }),
      },
      ['startDate', 'endDate'],
    ),
//    agentId: Yup.string().when('firstName', (firstName, schema) => {
//        if (firstName && firstName[0] == null)
//            return schema.when('lastName', (lastName, schema) => {
//              if (lastName && lastName[0] == null)
//                return schema.when('emplId', (emplId, schema) => {
//                  if (emplId && emplId[0] == null)
//                    return schema.when('arid', (arid, schema) => {
//                      if (arid && arid[0] == null)
//                        return schema.when('time', (time, schema) => {
//                          if (
//                            time[0] &&
//                            time[0].startDate === null &&
//                            time[0].endDate === null
//                          )
//                            return schema.required(
//                              'Please provide at least one piece of information',
//                            )
//                    });
//                });
//            });
//        });
//    }),
//    arid: Yup.string().when('firstName', (firstName, schema) => {
//        if (firstName && firstName[0] == null)
//          return schema.when('lastName', (lastName, schema) => {
//            if (lastName && lastName[0] == null)
//              return schema.when('emplId', (emplId, schema) => {
//                if (emplId && emplId[0] == null)
//                  return schema.when('agentId', (agentId, schema) => {
//                    if (agentId && agentId[0] == null)
//                      return schema.when('time', (time, schema) => {
//                          if (
//                            time[0] &&
//                            time[0].startDate === null &&
//                            time[0].endDate === null
//                          )
//                            return schema.required(
//                              'Please provide at least one piece of information',
//                            )
//                    });
//                  });
//              });
//          });
//        }),
  },
  [['firstName', 'lastName', 'emplId', 'time']],
);
