import { useQuery } from 'react-query';

import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import LearningJourneyTabs from '../components/learningJourney/LearningJourneyTabs';
import { AuthDetails } from '../components/login/AuthDetails';
import Timer from '../components/subcomponents/Timer';

export function LearningJourney() {
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();

  const { isLoading, data: studentFlags } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );

  const { isLoading: courseHistoryLoading, data: courseHistory } = useQuery(
    ['courseHistory'],
    () => load('courseHistory'),
  );

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container learning-journey">
        <div className="intro-content-wrapper">
          <AuthDetails page="learning journey" />
          <p className="page-intro-text lg:w-1/2">
            Your learning journey represents professional aspirations and a
            commitment to client service. This dashboard view collates your
            current courses, transcripts, and recommended next steps.
          </p>
        </div>
        <div>
          {!courseHistoryLoading && !isLoading ? (
            <LearningJourneyTabs
              courseHistory={courseHistory}
              flags={studentFlags}
            />
          ) : (
            <Timer />
          )}
        </div>
      </div>
    </Layout>
  );
}
