import { Dashboard } from '../pages/Dashboard';
import { LearningJourney } from '../pages/LearningJourney';
import { MyProfile } from '../pages/MyProfile';
import { Taxes } from '../pages/Taxes';
import { MessageCenter } from '../components/messageCenter/MessageCenter';
import { LoginPage } from '../pages/LoginPage';
import { LoginCallback } from '../pages/auth/LoginCallback';
import { SSOLoginCallback } from '../pages/auth/SSOLoginCallback';
import { SSONwmLoginCallback } from '../pages/auth/SSONwmLoginCallback';
import { SSOAdminLoginCallback } from '../pages/auth/SSOAdminLoginCallback';
import { Resources } from '../pages/Resources';
import { TransferOfCredit } from '../pages/TransferOfCredit';
import { CfpTransferOfCredit } from '../pages/CfpTransferOfCredit';
import { PurchaseHistory } from '../pages/PurchaseHistory';
import PageNotFound from '../pages/PageNotFound';
import { AlumniToolkits } from '../pages/AlumniToolkits';

import { AddProctor } from '../pages/AddProctor';
import { ConfirmProctor } from '../pages/ConfirmProctor';

import { DesignationsCertifications } from '../pages/DesignationsCertifications';
import { Recertification } from '../pages/Recertification';
import { EthicsQuestionnaire } from '../pages/EthicsQuestionnaire';
import { CEReporting } from '../pages/CEReporting';
import { DesignationAppSupervisor } from '../pages/DesignationAppSupervisor';
import { DesignationApplication } from '../pages/DesignationApplication';
import { GraduateApplication } from '../pages/GraduateApplication';
import { NylTransferOfCredit } from '../pages/NylTransferOfCredit';

import { AccountValidate } from '../pages/AccountValidate';
import { AccountReset } from '../pages/AccountReset';
import { MfaLogin } from '../pages/MfaLogin';
import { CreateAccount } from '../pages/CreateAccount';
import { CreateSSOAccount } from '../pages/CreateSSOAccount';
import { CompanyPortal } from '../pages/CompanyPortal';
import { CookiePage } from '../pages/CookiePage';
import { SubsLandingPage } from '../pages/SubsLandingPage';

const routesList = [
  {
    path: '/',
    component: LoginPage,
    breadcrumb: 'Dashboard',
    private: false,
  },
  {
    path: '/auth/mfa',
    component: MfaLogin,
    breadcrumb: 'Sign In',
    private: false,
  },
  {
    path: '/auth/login',
    component: LoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/auth/sso/login',
    component: SSOLoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/auth/ssonwm/login',
    component: SSONwmLoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/account/nwm-setup',
    component: CreateSSOAccount,
    breadcrumb: '',
    private: true,
  },
  {
    path: '/auth/sso/admin-login/:uid',
    component: SSOAdminLoginCallback,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/account/setup/:webToken',
    component: AccountValidate,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/account/password-reset',
    component: AccountReset,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    breadcrumb: '',
    private: true,
  },
  {
    path: '/dashboard/messages',
    component: MessageCenter,
    breadcrumb: 'Message Center',
    private: true,
  },
  {
    path: '/designations-certifications',
    component: DesignationsCertifications,
    breadcrumb: 'My Designations & Certifications',
    private: true,
  },
  {
    path: '/designations-certifications/recertification',
    component: Recertification,
    breadcrumb: 'Recertification',
    private: true,
  },
  {
    path: '/designations-certifications/recertification/ce-reporting',
    component: CEReporting,
    breadcrumb: 'CE Reporting',
    private: true,
  },
  {
    path: '/designations-certifications/recertification/ethics-questionnaire',
    component: EthicsQuestionnaire,
    breadcrumb: 'Ethics Questionnaire',
    private: true,
  },
  {
    path: '/learning-journey',
    component: LearningJourney,
    breadcrumb: 'My Education',
    private: true,
  },
  {
    path: '/profile',
    component: MyProfile,
    breadcrumb: 'My Profile',
    private: true,
  },
  {
    path: '/my-tax-forms',
    component: Taxes,
    breadcrumb: 'My Tax Forms',
    private: true,
  },
  {
    path: '/purchase-history',
    component: PurchaseHistory,
    breadcrumb: 'My Purchase History',
    private: true,
  },
  {
    path: '/resources',
    component: Resources,
    breadcrumb: 'Resource Library',
    private: true,
  },
  {
    path: '/transfer-of-credit-application',
    component: TransferOfCredit,
    breadcrumb: 'Transfer of Credit Application',
    private: true,
  },
  {
    path: '/cfp-transfer-of-credit-application',
    component: CfpTransferOfCredit,
    breadcrumb: 'CFP Transfer of Credit Application',
    private: true,
  },
  {
    path: '/proctor-registration/:classId/add-proctor',
    component: AddProctor,
    breadcrumb: 'Add Proctor',
    private: true,
  },
  {
    path: '/proctor-registration/proctor/:term/:classNumber/agreement-confirmation',
    component: ConfirmProctor,
    breadcrumb: 'Confirm Proctor',
    private: false,
  },
  {
    path: '/company-portal',
    component: CompanyPortal,
    breadcrumb: 'Company Reports',
    private: true,
  },
  {
    path: '/designation-application-form/:designation',
    component: DesignationApplication,
    breadcrumb: 'Designation Application',
    private: true,
  },
  {
    path: '/designation-application-form/:designation/supervisor-approval',
    component: DesignationAppSupervisor,
    breadcrumb: 'Supervisor Approval',
    private: false,
  },
  {
    path: '/application-for-admission/:designation/apply',
    component: GraduateApplication,
    breadcrumb: 'Application for Admission',
    private: false,
  },
  {
    path: '/register/company/:company/program/:designation/course/TOC',
    component: NylTransferOfCredit,
    breadcrumb: 'Create Account',
    private: false,
  },
  {
    path: '/account/register',
    component: CreateAccount,
    breadcrumb: 'Create Account',
    private: false,
  },
  {
    path: '/cookie-declaration',
    component: CookiePage,
    breadcrumb: '',
    private: false,
  },
  {
    path: '/subscriptions',
    component: SubsLandingPage,
    breadcrumb: '',
    private: true,
  },
  {
    path: '/designations-certifications/toolkits/:toolkit',
    component: AlumniToolkits,
    breadcrumb: 'Toolkit',
    private: true,
  },
  {
    path: '*',
    component: PageNotFound,
    breadcrumb: '',
    private: false,
  },
];

export default routesList;
