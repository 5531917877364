import { getBase64FromFile, formatBytes } from '../../utils/helper';

const FileUpload = ({
  selector,
  values,
  setFieldValue,
  setErrors,
  errors,
  uploadCount,
  allowedTypes = '.doc, .docx, .jpg, .pdf',
  maxFileSize = 52428800,
}) => {
  // Upload user certification file.
  const uploadFile = () => {
    // Gather the files from the page.
    const files = Array.from(document.getElementById(selector).files);
    // We only want to upload if the files are set.
    if (files.length) {
      files.forEach((file) => {
        // Update the files to base64 and encode it for URL.
        getBase64FromFile(file, (result) => {
          // Format the file to what API is expecting.
          // Using the window object bc Node btoa
          // functions have been depreciated.
          values.push({
            file_type: file.type,
            file_name: encodeURIComponent(file.name),
            file_data: window.btoa(result),
          });
          // Set the files to the form.
          setFieldValue(selector, values);
        });
      });
      // Reset the form input file field.
      document.getElementById(selector).value = '';
    }
  };

  const removeFile = (index) => {
    // Get the current files that loaded in the form.
    const newfiles = values.filter((item, arrIndex) => arrIndex !== index);
    // Set the updated certifcation form.
    setFieldValue(selector, newfiles);
  };

  return (
    <>
      {values.length < uploadCount && (
        <>
          <div>
            <input
              id={selector}
              name={selector}
              type="file"
              accept={allowedTypes}
              onChange={(event) => {
                // Check if the file size is bigger than the maxFileSize.
                if (event.currentTarget.files[0].size > maxFileSize) {
                  setErrors({ [selector]: 'File is too big' });
                  event.currentTarget.value = '';
                }
              }}
            />
          </div>
          <sup>
            Files must be less than {formatBytes(maxFileSize)}. Allowed file
            types: {allowedTypes.split('.').join('')}.
          </sup>
          <button className="btn col" type="button" onClick={uploadFile}>
            Upload
          </button>
        </>
      )}
      {errors && <span className="red-text">{errors}</span>}
      {values &&
        values.map((file, index) => (
          <div key={index}>
            <p className="fileName">{file.file_name}</p>
            <button
              type="button"
              className="removeFile"
              onClick={() => removeFile(index)}
              title="Remove selected file">
              X
            </button>
          </div>
        ))}
    </>
  );
};
export default FileUpload;
