import { useState } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { AuthDetails } from '../components/login/AuthDetails';
import { PurchaseHistoryCard } from '../components/purchaseHistory/PurchaseHistoryCard';
import Timer from '../components/subcomponents/Timer';

import {
  FormikText,
  FormikSelect,
} from '../components/subcomponents/InputField';

import selectOptions from '../data/selectOptions.json';

/**
 *
 * This page will display the user’s history across all
 * purchasing activity.
 *
 * The Select Options dropdown will allow users to filter
 * their purchases by Product Type (i.e. Textbook, Course, or Exam).
 *
 *
 * The “Sort by” options would allow the user
 * to sort their purchases by the options listed.
 * Each order pane will show the title of
 * the item purchased, the purchase amount, the order number,
 * the product type, and the status.
 *
 * The options are:
 *  Course History Status
 *    COMPLETED
 *    IN PROGRESS
 *    RESCHEDULE INTO ES SECTION
 *    RETAKE INTO ET SECTION
 *    RE-ENROLL INTO RE SECTION
 *    RE-ENROLL INTO GS SECTION
 *    RETAKE
 *    RESCHEDULE
 *     PURCHASED
 *  Book Product Status – TAC only displays orders within the last 1 year
 *    Processing
 *    Shipped
 *    Submitted – paid, ready for processing
 *    Open – not paid as of yet
 *    Refunded
 *    Order Released – sent to shipper
 *
 * The user will have the ability to download
 * a PDF of the receipt.
 * @Question: Will the user will be able to
 * download a receipt for a cancelled order?
 *
 * The user will have the ability to track their
 * shipment where applicable (using a button or a text link)
 */

export function PurchaseHistory() {
  const { load } = useTacfsService();

  const [sort, setSort] = useState('title');
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');

  const { isLoading: purchaseHistoryLoading, data: purchaseHistory } = useQuery(
    ['purchaseHistory'],
    () => load('purchaseHistory'),
  );

  const sortBy = (a, b) => {
    switch (sort) {
      case 'title':
        return a.product_title.localeCompare(b.product_title);
      case 'titleReverse':
        return b.product_title.localeCompare(a.product_title);
      case 'date':
        if (!dayjs(a.date_of_purchase).isValid()) return 1;
        if (!dayjs(b.date_of_purchase).isValid()) return -1;
        return dayjs(a.date_of_purchase).isBefore(dayjs(b.date_of_purchase))
          ? -1
          : 1;
      case 'dateReverse':
        if (!dayjs(a.date_of_purchase).isValid()) return 1;
        if (!dayjs(b.date_of_purchase).isValid()) return -1;
        return dayjs(a.date_of_purchase).isBefore(dayjs(b.date_of_purchase))
          ? 1
          : -1;
      case 'amount':
        return parseFloat(a.purchase_amount) - parseFloat(b.purchase_amount);
      case 'amountReverse':
        return parseFloat(b.purchase_amount) - parseFloat(a.purchase_amount);
      default:
        return 0;
    }
  };

  const filterPurchases = (item) => {
    const title = item.product_title?.toLowerCase();
    const searchStr = search?.toLowerCase();

    const productType = item.product_type?.toLowerCase()?.trim();
    const selectedType = type?.toLowerCase();
    return (
      title.includes(searchStr) &&
      (!selectedType || productType === selectedType)
    );
  };

  const searchSubmit = (values) => {
    setSearch(values.search);
    setType(values.type);
  };

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container purchase-history">
        <div className="intro-content-wrapper">
          <AuthDetails page="purchase history" />
          <p className="page-intro-text">
            View your purchase history with The College, including receipts for
            your and your company’s records.
          </p>
        </div>

        <Search searchSubmit={searchSubmit} />
        <Sort sort={sort} setSort={setSort} />

        <div className="card-layout">
          {!purchaseHistoryLoading ? (
            <>
              {purchaseHistory &&
                purchaseHistory
                  .filter(filterPurchases)
                  .sort(sortBy)
                  .map((purchase, key) => {
                    return (
                      <PurchaseHistoryCard
                        key={`${purchase.product_title}-${key}`}
                        purchase={purchase}
                      />
                    );
                  })}
            </>
          ) : (
            <Timer />
          )}
        </div>
      </div>
    </Layout>
  );
}

const Search = ({ searchSubmit }) => {
  const typeOptions = selectOptions.productType;

  return (
    <Formik
      initialValues={{
        search: '',
        type: '',
      }}
      onSubmit={(values) => {
        searchSubmit(values);
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <form id="searchForm" onSubmit={handleSubmit}>
          <div className="search-wrapper">
            <div className="field">
              <FormikText
                label="Search Purchases"
                type="text"
                name="search"
                id="search"
                value={values.search}
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <FormikSelect
                label="Select Product Type"
                name="type"
                id="type"
                value={values.type}
                onChange={handleChange}
              >
                {typeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormikSelect>
            </div>
            <div className="button-wrapper">
              <button type="submit" form="searchForm" className="btn">
                Search
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const Sort = ({ sort, setSort }) => {
  return (
    <div className="sort-wrapper">
      <div className="font-semibold sort-label">Sort By:</div>
      <ul className="radios">
        <li>
          <input
            type="radio"
            name="sortRadios"
            id="sortTitle"
            onClick={() => setSort('title')}
            defaultChecked={sort === 'title'}
          />
          <label htmlFor="sortTitle">Title (A - Z)</label>
        </li>
        <li>
          <input
            type="radio"
            name="sortRadios"
            id="sortTitleReverse"
            onClick={() => setSort('titleReverse')}
            defaultChecked={sort === 'titleReverse'}
          />
          <label htmlFor="sortTitleReverse">Title (Z - A)</label>
        </li>
        <li>
          <input
            type="radio"
            name="sortRadios"
            id="sortDateReverse"
            onClick={() => setSort('dateReverse')}
            defaultChecked={sort === 'dateReverse'}
          />
          <label htmlFor="sortDateReverse">Date (New - Old)</label>
        </li>
        <li>
          <input
            type="radio"
            name="sortRadios"
            id="sortDate"
            onClick={() => setSort('date')}
            defaultChecked={sort === 'date'}
          />
          <label htmlFor="sortDate">Date (Old - New)</label>
        </li>
        <li>
          <input
            type="radio"
            name="sortRadios"
            id="sortAmount"
            onClick={() => setSort('amount')}
            defaultChecked={sort === 'amount'}
          />
          <label htmlFor="sortAmount">Amount (Low - High)</label>
        </li>
        <li>
          <input
            type="radio"
            name="sortRadios"
            id="sortAmountReverse"
            onClick={() => setSort('amountReverse')}
            defaultChecked={sort === 'amountReverse'}
          />
          <label htmlFor="sortAmountReverse">Amount (High - Low)</label>
        </li>
      </ul>
    </div>
  );
};
