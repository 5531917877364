import { useEffect } from 'react';
import Timer from '../../components/subcomponents/Timer';
import SetStorage from '../../components/storage/SetStorage';
import { useOktaAuth } from '@okta/okta-react';

export function SSOLoginCallback() {
  const { oktaAuth } = useOktaAuth();

  const queryParams = new URLSearchParams(window.location.search);
  const session = queryParams.get('session');
  const destination = queryParams.get('destination');
  // Set the settion token to the session
  SetStorage('session', session);
  // if the designation param is set we want to save that to the browser.
  if (destination) SetStorage('loginDestination', destination);

  useEffect(() => {
    oktaAuth.token.getWithRedirect({
      sessionToken: session,
      responseType: ['code', 'email', 'profile'],
      state: 'null',
    });
  }, [oktaAuth, session]);

  return <Timer />;
}
