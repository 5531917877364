import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import ExcelExport from '../../utils/ExcelExport';
import Table from '../subcomponents/Table';
import Timer from '../subcomponents/Timer';

import { ReportingForm } from './components/ReportingForm';

import dayjs from 'dayjs';

export function PasslistReport() {
  const [error, setError] = useState('');
  const [reportData, setReportData] = useState('');
  const [isStateFarmUser, setIsStateFarmUser] = useState(false);
  const exportToExcel = ExcelExport();

  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();
  const { isLoading, mutate } = useMutation((values) =>
    save('passListReport', values),
  );
  const { isLoading: reportingFlagsLoading, data: companyFlags } = useQuery(
    ['companyReportingFlags'],
    () => load('companyReportingFlags'),
  );

  useEffect(() => {
    if (reportingFlagsLoading === false && companyFlags) {
      setIsStateFarmUser(companyFlags.company_report_id === '1000376'? true: false);
      console.log("Is State Farm User? "+ (companyFlags.company_report_id === '1000376'?'Yes':'No'));
    }
  }, [companyFlags, reportingFlagsLoading, setIsStateFarmUser]);


  // Export the data to an excel spreadsheet
  const download = () => exportToExcel(reportData, 'Passlist Report');
  // we want to clear the form and data.
  const clear = () => {
    // Reset the data
    // and clear the form.
    setReportData('');
    setError('');
  };

  const columns = [
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Student ID',
      accessor: 'emp_id',
    },
    {
      Header: 'Address Line 1',
      accessor: 'address1',
    },
    {
      Header: 'Address Line 2',
      accessor: 'address2',
    },
    {
      Header: 'Address Line 3',
      accessor: 'address3',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'State',
      accessor: 'state',
    },
    {
      Header: 'Zip',
      accessor: 'postal',
    },
    {
      Header: 'Primary Email',
      accessor: 'email_primary',
    },
    {
      Header: 'Date Added',
      accessor: 'date_added',
    },
    {
      Header: 'Acadamic Plan',
      accessor: 'acad_plan',
    },
    {
      Header: 'Payer Name',
      accessor: 'payer_name',
    },
  ];
  // If State Farm User, insert AR ID column at column 5. 
  if(isStateFarmUser){
      columns.splice(3,0,{
      Header: 'Alias',
      accessor: 'agent_id',
    },)
      columns.splice(4,0,{
      Header: 'AR ID',
      accessor: 'ar_id',
    },);
  }
  console.log(columns);

  const formSubmit = async (values) => {
    // reset the form data
    setReportData('');
    setError('');
    mutate(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data && Array.isArray(data)) {
            if(data.length === 0){
                setError('No results found',);
            } else {
                // Format the data before
                // setting especially for
                //  the date fields.
                data.map((item, key) => {
                  data[key].date_added = dayjs(item.date_added).format('MM/DD/YY');
                  return data[key];
                });
                // Save the report data.
                setReportData(data);
            }
        } else {
          setError(
            'We encountered an error processing this request. Please refine your search criteria and try again.',
          );
        }
      },
    });
  };

  if (reportingFlagsLoading) return <Timer />;
  // If no student is available that means the user does not have
  // access to this form.
  if (
    reportingFlagsLoading === false &&
    (companyFlags === false || companyFlags.pass_list_report !== 'Y')
  )
    return <h2>Access Denied!</h2>;

  return (
    <>
      <h3>Passlist Report</h3>
      <p>
        View a list of courses passed by a specific student by searching their
        name, student ID and date range. To view a list of designations earned
        by more than one student then just enter the date range and hit “run
        report”.”
      </p>
      <ReportingForm
        formSubmit={formSubmit}
        clear={clear}
        showClear={reportData}
      />
      {error && <p className="red-text">{error}</p>}
      {isLoading && <Timer />}
      {reportData && (
        <>
          <div className="container download-wrapper">
            <button type="button" className="btn download" onClick={download}>
              Download Report
            </button>
          </div>
          <Table reportColumns={columns} reportData={reportData} />
        </>
      )}
    </>
  );
}
