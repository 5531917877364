import { useState, useEffect } from 'react';
import { FormikText, FormikSearchSelect } from '../InputField';
import { OtherCompanyIds as ocId } from '../../../data/Constants';

export function CompanyAffiliationFormikField({
  values,
  handleChange,
  handleBlur,
  companies,
  companiesLoading,
  disabled,
  showLabels = false
}) {
  const [field1HasChanged, setField1HasChanged] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState(null);
  const [independentCompanies, setIndependentCompanies] = useState(null);

  useEffect(() => {
    if (
      !companiesLoading &&
      companies &&
      loadedCompanies === null &&
      independentCompanies === null
    ) {
      // company data for non-logged in users, returning load in LoginSuccess.jsx
      // grab companies
      let _companies = [];
      _companies = companies.companies;

      // Sort companies alphabetically by name
      _companies.sort((a, b) => a.name.localeCompare(b.name));

      saveCompanyArray(_companies);
    }
  }, [companiesLoading, companies, loadedCompanies, independentCompanies]);

  const saveCompanyArray = (_companies) => {
    // We want to remove the broker dealer
    // not listed from the base list.
    setLoadedCompanies([
      ..._companies.filter((company) => {
        return company.org_id !== '1599250';
      }),
      // add other
      { org_id: 'other', name: 'Other' },
    ]);
    setIndependentCompanies([
      ..._companies.filter((company) => {
        return company.independent_flag === 'Y';
      }),
      { org_id: '1000927', name: 'I do not have a broker/dealer' },
    ]);
  };

  /**
   * If company1 changes to a "regular" company, clear the additional field values.
   */
  function clearCompanyValues(values) {
    // If company1 changes to something other than these values, clear additional company fields
    if (
      ![
        ocId._INDEPENDENT,
        ocId._OTHER,
        ocId._NONPROFIT,
        ocId._BROKER_NOT_LISTED,
        ocId._ATTORNEY,
        ocId._CPA,
      ].includes(values.company1)
    ) {
      values.company2 = '';
      values.userCompanyName = '';
      values.companyName = '';
    }
    // Clear company2 value if company1 changes to NONPROFIT
    if ([ocId._NONPROFIT].includes(values.company1)) {
      values.company2 = '';
    }
  }

  return (
    <>
      <div className="column-layout">
        <div className="col">
          <div className="field">
            {loadedCompanies && (<>
              {showLabels  && (<label htmlFor="company1">Your Company Name <span className="req-star">*</span></label>)}      
              <FormikSearchSelect
                id="company1"
                name="company1"
                title="Choose a Company"
                value={values.company1}
                disabled={disabled}
                required={true}
                onChange={(e) => {
                  handleChange(e);
                  setField1HasChanged(true);
                  clearCompanyValues(values);
                }}
                onBlur={handleBlur}
                options={loadedCompanies.map((item) => {
                  return {
                    name: item.name,
                    value: item.org_id,
                  };
                })}
                permanentValues={['other']}
              />
            </>)}
          </div>
        </div>
      </div>
      {field1HasChanged &&
        [ocId._INDEPENDENT, ocId._OTHER, ocId._OTHER_FIN].includes(
          values.company1,
        ) && (
          <div className="column-layout">
            <div className="col">
              <div className="field">
              {showLabels && (<label htmlFor="company2">Broker/Dealer <span className="req-star">*</span></label>)}
                <FormikSearchSelect
                  id="company2"
                  name="company2"
                  title="Choose a Broker Dealer"
                  value={values.company2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  options={independentCompanies.map((item) => {
                    return {
                      name: item.name,
                      value: item.org_id,
                    };
                  })}
                  permanentValues={['1599250', '1000927']}
                />
              </div>
            </div>
          </div>
        )}
      {[
        ocId._INDEPENDENT,
        ocId._OTHER,
        ocId._OTHER_FIN,
        ocId._NONPROFIT,
        ocId._BROKER_NOT_LISTED,
        ocId._ATTORNEY,
        ocId._CPA,
      ].includes(values.company1) && (
        <div className="column-layout">
          <div className="col--third">
            <div className="field">
            {showLabels && (<label htmlFor="userCompanyName">Your Company Name <span className="req-star">*</span></label>)}
              <FormikText
                type="text"
                id="userCompanyName"
                name="userCompanyName"
                placeholder="Your Company Name*"
                value={values.userCompanyName}
                onChange={handleChange}
                required={true}
                maxLength="30"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CompanyAffiliationFormikField;
