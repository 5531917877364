import {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

// SLIGHTLY MODIFIED FROM THE ENROLLMENT VERSION

export const Modal = forwardRef(
  (
    {
      title,
      children,
      linkClasses = 'btn center',
      noClose = false,
      showLink = true,
      useAnchor = false,
      onClose,
      scrollable = false,
      showNow = false,
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false);

    const close = () => {
      setVisible(false);
      // if onClose callback set
      // then we want to run that
      // callback.
      if (onClose) onClose();
    };

    useEffect(() => {
      return () => {
        if (typeof document !== 'undefined') {
          document.querySelector('body').classList.add('overflow-auto');
          document.querySelector('body').classList.remove('overflow-hidden');
        }
      };
    }, []);

    useEffect(() => {
      if (visible) {
        document.querySelector('body').classList.remove('overflow-auto');
        document.querySelector('body').classList.add('overflow-hidden');
      } else {
        document.querySelector('body').classList.remove('overflow-hidden');
        document.querySelector('body').classList.add('overflow-auto');
      }
    }, [visible]);

    useEffect(() => {
      if (showNow) {
        setVisible(true);
      }
    }, [showNow]);

    // We want to expose the open modal
    // to be called in parent component.
    useImperativeHandle(
      ref,
      () => {
        return {
          toggleOpen(show) {
            setVisible(show ?? true);
          },
        };
      },
      [],
    );

    return (
      <Fragment>
        {showLink &&
          (useAnchor ? (
            <a
              role="button"
              onClick={() => setVisible(true)}
              className={linkClasses}
            >
              {title}
            </a>
          ) : (
            <button
              type="button"
              onClick={() => setVisible(true)}
              className={linkClasses}
            >
              {title}
            </button>
          ))}
        <div className={visible ? 'modal active' : 'modal'}>
          <div
            className={scrollable ? 'modalContent scrollable' : 'modalContent'}
          >
            {!noClose && (
              <div className="close" onClick={close}>
                <span role="img" aria-label="close modal">
                  &times;
                </span>
              </div>
            )}

            {children}
          </div>
        </div>
      </Fragment>
    );
  },
);

export default Modal;
