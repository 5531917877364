import { useState } from 'react';
import { ResourceGrid } from './ResourceGrid';
import { ResourceFilters } from './ResourceFilters';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { useInfiniteQuery } from 'react-query';
import { yesNoBool } from '../../utils/helper.js';

export function StandardResourceTab({queryName, saved = [], studentFlags={}, resourceClassArr=[]}) {
    const isAlumni = yesNoBool(studentFlags?.imodules_access);
    const limit = 16;
    const resourceKeysBase = {classes:resourceClassArr, offset:0, limit:limit, isAlumni:isAlumni};
    const [resourceKeys, setResourceKeys] = useState(resourceKeysBase);
    const [searchFields, setSearchFields] = useState({textSearch:"",format:""});
    const { load } = useTacfsService();
      
    // For future state, we can add a "premium" resource to data and provide 
    // it with special CSS for CSS Grid such that it automatically appears in 
    // a specific position in the grid.
    const { data, hasNextPage, fetchNextPage, refetch, remove, isFetching} = useInfiniteQuery(
        [queryName], 
        ({pageParam=0}) => {
            const updatedResourceKeys = resourceKeys;
            updatedResourceKeys.offset = pageParam;
            setResourceKeys(updatedResourceKeys);
            return load('allResources', resourceKeys);
        },
        {
            getNextPageParam: (lastPage, pages) => {
                const offset = pages.length*limit;
                //TODO: The following logic only causes the load more button to 
                //disable after an empty result set.
                if(lastPage.length>0) return offset;
            }
        }
    );
    /**
     * Accepts an object and adds it to the resourceKeys object.
     * 
     * @param object filter
     * @void
     */
    function addFilterParams(filter = {}){
        const updatedResourceKeys = resourceKeys;
        updatedResourceKeys.offset = 0;
        for (const [key, value] of Object.entries(filter)) {
            updatedResourceKeys[key] = value;
        }
        remove(); //remove current query
        setResourceKeys(updatedResourceKeys);
        refetch();
    }
    /**
     * Adds textSearch and format to the filter params object.
     * @void
     */
    function searchByFields(){
       addFilterParams(searchFields);
    }
    /**
     * Resets the state values of filters & search terms
     * @void
     */
    function resetSearch(){
        remove(); //clear the current Query
        setSearchFields({
            textSearch:"",
            format:"",
        });
        const updatedResourceKeys = resourceKeys;
        //Reset all values to the default
        for (const [key, value] of Object.entries(resourceKeysBase)) {
            updatedResourceKeys[key] = value;
        }
        //Unset specific elements 
        updatedResourceKeys.filterBy = "";
        updatedResourceKeys.textSearch = "";
        updatedResourceKeys.format = "";
        setResourceKeys(updatedResourceKeys); 
        refetch(); //refetch the query
    }
    /**
     * Returns a title for the tab based on the value of the queryName param.
     * @returns {String}
     */
    function getTitle(){
        switch(queryName){
            case "allResources":
                return "All Resources";
            case "orientationResources":
                return "Orientation Resources";
            case "alumniResources":
                return "Alumni Resources";
            default:
                return "Resources";
        }
    }
    return (
        <div className="all-resources">
            <ResourceFilters 
                reset={resetSearch} 
                addFilterParam={addFilterParams} 
                searchFields={searchFields} 
                setSearchFields={setSearchFields} 
                searchFunction={searchByFields}
                title=<h3>{getTitle()}</h3>
            />
            { isFetching  && ("Loading your Resources...")}
            { !isFetching  && !data && ('Resources are currently unavailable. Please check back later.')}
            { !isFetching  && data && (<>
                <ResourceGrid resources={data} saved={saved}/>
                <button className={`loadMore btn ${(!hasNextPage?" disabled":"")}`} disabled={!hasNextPage} onClick={fetchNextPage}>Load More</button>
            </>)}
        </div>
    );
}
