import { useQuery } from 'react-query';

import ReadStorage from '../../components/storage/ReadStorage';
import SetStorage from '../../components/storage/SetStorage';
import useTacfsService from './useTacfsService';

const useProfileQuery = (userData) => {
  const { load } = useTacfsService();
  let profileData, profileLoading;

  const { isLoading: initialLoading, data: initial } = useQuery(
    ['studentInitial'],
    () => load('studentInitial', { studentID: userData.profile.studentID }),
    {
      enabled: userData != null,
    },
  );

  const { isLoading: basicLoading, data: basic } = useQuery(
    ['studentBasic'],
    () => load('studentBasic', { studentID: userData.profile.studentID }),
    {
      enabled: userData != null,
    },
  );

  const { isLoading: contactLoading, data: contact } = useQuery(
    ['studentContact'],
    () => load('studentContact', { studentID: userData.profile.studentID }),
    {
      enabled: userData != null,
    },
  );

  const { isLoading: companyLoading, data: company } = useQuery(
    ['studentCompany'],
    () => load('studentCompany', { studentID: userData.profile.studentID }),
    {
      enabled: userData != null,
    },
  );

  const { isLoading: educationLoading, data: education } = useQuery(
    ['studentEducation'],
    () => load('studentEducation', { studentID: userData.profile.studentID }),
    {
      enabled: userData != null,
    },
  );

  const { isLoading: flagsLoading, data: flags } = useQuery(
    ['studentFlags'],
    () => load('studentFlags', { studentID: userData.profile.studentID }),
    {
      enabled: userData != null,
    },
  );

  profileLoading =
    initialLoading ||
    basicLoading ||
    contactLoading ||
    companyLoading ||
    educationLoading ||
    flagsLoading;
  profileData = {
    initial: initial?.studentInfoInitial,
    basic: basic,
    contact: contact,
    company: company,
    education: education,
    flags: flags,
  };

  return { profileLoading, profileData };
};

export default useProfileQuery;
