import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import ExcelExport from '../../utils/ExcelExport';
import Table from '../subcomponents/Table';
import { TranscriptDetailModal } from './components/TranscriptDetailModal';
import Timer from '../subcomponents/Timer';
import {TranscriptReportCompanies as tc }  from '../../data/Constants';

export function TranscriptReport() {
  const [error, setError] = useState('');
  const [rowIndex, setRowIndex] = useState(false);
  const [reportData, setReportData] = useState('');
  const [transcriptCol, setTranscriptCol] = useState(4);
  const [columnNames, setColumnNames] = useState([
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Alias',
      accessor: 'agent_id',
    },
    {
      Header: 'AR ID',
      accessor: 'ar_id',
    },
    {
      Header: 'Transcript',
      accessor: 'transcript',
    },
  ]);
  const exportToExcel = ExcelExport();

  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();
  const { isLoading, mutate } = useMutation((values) =>
    save('statefarmReport', values),
  );
  const { isLoading: reportingFlagsLoading, data: companyFlags } = useQuery(
    ['companyReportingFlags'],
    () => load('companyReportingFlags'),
  );

  const rowCallback = (value) => setRowIndex(value);

  // Export the data to an excel spreadsheet
  const download = () => exportToExcel(reportData, 'Transcript Report');
  // we want to clear the form and data.
  const clear = () => {
    // Reset the data
    // and clear the form.
    setReportData('');
    setError('');
  };
  
//  let columns = [
//    {
//      Header: 'First Name',
//      accessor: 'first_name',
//    },
//    {
//      Header: 'Last Name',
//      accessor: 'last_name',
//    },
//    {
//      Header: 'Alias',
//      accessor: 'agent_id',
//    },
//    {
//      Header: 'AR ID',
//      accessor: 'ar_id',
//    },
//    {
//      Header: 'Transcript',
//      accessor: 'transcript',
//    },
//  ];
  
  useEffect(() => {
    if (reportingFlagsLoading === false && companyFlags) {
      if([tc._NWMUTUAL, tc._PRUDENTIAL].includes(companyFlags.company_report_id)){
        setTranscriptCol(3);
          setColumnNames([
            {
              Header: 'First Name',
              accessor: 'first_name',
            },
            {
              Header: 'Last Name',
              accessor: 'last_name',
            },
            {
              Header: 'Employee ID',
              accessor: 'agent_id',
            },
            {
              Header: 'Transcript',
              accessor: 'transcript',
            },
          ]);
      }
    }
  }, [companyFlags, setColumnNames, setTranscriptCol, reportingFlagsLoading]);
  
  const formSubmit = async (values) => {
    // reset the form data
    setReportData('');
    setError('');
    mutate(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
         if (data && Array.isArray(data)) {
            if(data.length === 0){
                setError('No results found',);
            } else {
                // If NW Mutual or Prudential, remove the ar_id column from the data set
                if([tc._NWMUTUAL, tc._PRUDENTIAL].includes(companyFlags?.company_report_id)){
                    data.map((item, key) => {
                        delete data[key].ar_id;
                        return data[key];
                    });
                }
                // Save the report data.
                setReportData(data);
            }
        } else {
          setError(
            'We encountered an error processing this request. Please refine your search criteria and try again.',
          );
        }
      },
    });
  };

  if (reportingFlagsLoading) return <Timer />;
  // If no student is available that means the user does not have
  // access to this form.
  if (
    reportingFlagsLoading === false &&
    (companyFlags === false || companyFlags.pass_list_report !== 'Y')
  )
    return <h2>Access Denied!</h2>;

  return (
    <>
      <h3>Transcript Report</h3>
      <p>
        Search for a specific student by looking up their name, student ID,
        { [tc._STATEFARM].includes(companyFlags?.company_report_id)?" AR-ID/Alias or AR ID.":" or Employee ID."}
      </p>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          emplId: '',
          agentId: '',
          arid: '',
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} id="designationReport">
            <h4>By Student Information</h4>
            <div className="form-layout">
              <div className="field col--third">
                <label htmlFor="firstname">First Name</label>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  className={
                    touched.firstName && errors.firstName ? 'error' : null
                  }
                  onChange={handleChange}
                  placeholder="First Name"
                  onBlur={handleBlur}
                />
                {touched.firstName && errors.firstName && (
                  <span className="red-text">{errors.firstName}</span>
                )}
              </div>
              <div className="field col--third">
                <label htmlFor="emplId">Last Name</label>
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  className={touched.lastName && errors.lastName ? 'error' : null}
                  onChange={handleChange}
                  placeholder="Last Name"
                  onBlur={handleBlur}
                />
                {touched.lastName && errors.lastName && (
                  <span className="red-text">{errors.lastName}</span>
                )}
              </div>
              <div className="field col--third">
                <label htmlFor="emplId">Student ID</label>
                <Field
                  type="text"
                  id="emplId"
                  name="emplId"
                  value={values.emplId}
                  className={touched.emplId && errors.emplId ? 'error' : null}
                  onChange={handleChange}
                  placeholder="7 Digits"
                  onBlur={handleBlur}
                />
                {touched.emplId && errors.emplId && (
                  <span className="red-text">{errors.emplId}</span>
                )}
              </div>
            </div>
            <div className="form-layout">
              <div className="field col--third">
                <label htmlFor="agentId">{[tc._NWMUTUAL, tc._PRUDENTIAL].includes(companyFlags?.company_report_id)?"Employee ID":"Alias"}</label>
                <Field
                  type="text"
                  id="agentId"
                  name="agentId"
                  value={values.agentId}
                  className={touched.agentId && errors.agentId ? 'error' : null}
                  onChange={handleChange}
                  placeholder="Alias"
                  onBlur={handleBlur}
                />
                {touched.agentId && errors.agentId && (
                  <span className="red-text">{errors.agentId}</span>
                )}
              </div>
              {[tc._STATEFARM].includes(companyFlags?.company_report_id) && (
                <div className="field col--third">
                  <label htmlFor="arid">AR ID</label>
                  <Field
                    type="text"
                    id="arid"
                    name="arid"
                    value={values.arid}
                    className={touched.arid && errors.arid ? 'error' : null}
                    onChange={handleChange}
                    placeholder="AR ID"
                    onBlur={handleBlur}
                  />
                  {touched.arid && errors.arid && (
                    <span className="red-text">{errors.arid}</span>
                  )}
                </div>
              )}
              <div className="field col--third"></div>
            </div>
            <div className="flex w-full column-layout">
              <button
                type="submit"
                form="designationReport"
                className="btn col">
                Run Report
              </button>
              {reportData && (
                <button
                  type="button"
                  className="btn col clear"
                  onClick={() => {
                    clear();
                    resetForm();
                  }}>
                  Clear
                </button>
              )}
            </div>
          </form>
        )}
      </Formik>
      {error && <p className="red-text">{error}</p>}
      {isLoading && <Timer />}
      {reportData && (
        <>
          <div className="container download-wrapper">
            <button type="button" className="btn col" onClick={download}>
              Download Report
            </button>
          </div>
          <Table
            reportColumns={columnNames}
            reportData={reportData}
            callback={rowCallback}
            callbackCellIndex={transcriptCol}
            callbackCellText="View Transcript"
          />
          <TranscriptDetailModal
            rowIndex={rowIndex}
            close={() => setRowIndex(false)}
          />
        </>
      )}
    </>
  );
}

export const ValidationSchema = Yup.object().shape(
  {
    firstName: Yup.string().when('lastName', (lastName, schema) => {
      if (lastName && lastName[0] == null)
        return schema.when('emplId', (emplId, schema) => {
          if (emplId && emplId[0] == null)
            return schema.when('agentId', (agentId, schema) => {
              if (agentId && agentId[0] == null)
                return schema.when('arid', (arid, schema) => {
                  if (arid && arid[0] == null)
                    return schema.required(
                      'Please provide at least one piece of information',
                    );
                });
            });
        });
    }),

    lastName: Yup.string().when('firstName', (firstName, schema) => {
      if (firstName && firstName[0] == null)
        return schema.when('emplId', (emplId, schema) => {
          if (emplId && emplId[0] == null)
            return schema.when('agentId', (agentId, schema) => {
              if (agentId && agentId[0] == null)
                return schema.when('arid', (arid, schema) => {
                  if (arid && arid[0] == null)
                    return schema.required(
                      'Please provide at least one piece of information',
                    );
                });
            });
        });
    }),
    emplId: Yup.string()
      .matches(
        /^1\d{6}$/,
        'Student ID must be 7-digit numeric and start with 1',
      )
      .when('firstName', (firstName, schema) => {
        if (firstName && firstName[0] == null)
          return schema.when('lastName', (lastName, schema) => {
            if (lastName && lastName[0] == null)
              return schema.when('agentId', (agentId, schema) => {
                if (agentId && agentId[0] == null)
                  return schema.when('arid', (arid, schema) => {
                    if (arid && arid[0] == null)
                      return schema.required(
                        'Please provide at least one piece of information',
                      );
                  });
              });
          });
      }),
    agentId: Yup.string().when('firstName', (firstName, schema) => {
      if (firstName && firstName[0] == null)
        return schema.when('lastName', (lastName, schema) => {
          if (lastName && lastName[0] == null)
            return schema.when('emplId', (emplId, schema) => {
              if (emplId && emplId[0] == null)
                return schema.when('arid', (arid, schema) => {
                  if (arid && arid[0] == null)
                    return schema.required(
                      'Please provide at least one piece of information',
                    );
                });
            });
        });
    }),
    arid: Yup.string().when('firstName', (firstName, schema) => {
      if (firstName && firstName[0] == null)
        return schema.when('lastName', (lastName, schema) => {
          if (lastName && lastName[0] == null)
            return schema.when('emplId', (emplId, schema) => {
              if (emplId && emplId[0] == null)
                return schema.when('agentId', (agentId, schema) => {
                  if (agentId && agentId[0] == null)
                    return schema.required(
                      'Please provide at least one piece of information',
                    );
                });
            });
        });
    }),
  },
  [['firstName', 'lastName', 'emplId', 'agentId', 'arid']],
);
