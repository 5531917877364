import { useEffect, useState, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';

/**
 * @function CircularProgressBar component.
 *
 * @description
 * The CircularProgressBar component is a reusable React
 * component that renders a circular progress bar.
 * It takes a single prop percentage which represents
 * the percentage of progress to be displayed
 * on the progress bar.
 *
 * The progress bar is animated using the
 * react-spring library to smoothly transition the
 * stroke dash offset based on the visibility
 * of the progress bar element using an intersection observer.
 *
 * The progress bar is rendered as an SVG element
 * with two circles: one white circle to show
 * the background, and one animated circle with
 * a stroke dash array and stroke dash offset to
 * display the progress.
 *
 * The percentage prop is used to calculate the
 * stroke dash offset and update the animation accordingly.
 * The progress bar also includes a text element
 * in the center to display the percentage value.
 *
 * The CircularProgressBar component also uses
 * useState and useRef hooks to track
 * the visibility of the progress bar
 * element and obtain a reference to
 * the DOM element for observing
 * visibility changes.
 *
 * It also uses the useEffect hook
 * to set up the intersection observer
 * and clean it up on component unmount.
 *
 * @param {number} percentage: The percentage of progress (0-100) to be displayed on the progress bar.
 * @returns {React.Element}: The rendered circular progress bar component.
 */
export const CircularProgressBar = ({ percentage, title = '' }) => {
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - percentage) / 100) * circumference; // Offset for the stroke dash array to show progress
  const [isVisible, setIsVisible] = useState(false); // Track visibility of progress bar
  const progressBarRef = useRef(null); // Ref to progress bar element

  useEffect(() => {
    const currentProgressBarRef = progressBarRef.current; // Get the current DOM element of the progress bar
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update isVisible state when visibility changes
        setIsVisible(entry.isIntersecting); // Update isVisible state with the visibility status
      },
      {
        threshold: 1, // Set threshold for visibility to 0.5, meaning 50% visibility required
      },
    );
    // Observe progress bar element
    if (currentProgressBarRef) {
      observer.observe(currentProgressBarRef); // Start observing the progress bar element for visibility changes
    }
    return () => {
      // Cleanup observer on unmount
      if (currentProgressBarRef) {
        observer.unobserve(currentProgressBarRef); // Stop observing the progress bar element on component unmount
      }
    };
  }, []);

  // Define the animated stroke dash offset value using react-spring
  const progressAnimation = useSpring({
    from: { strokeDashoffset: isVisible ? circumference : offset }, // Start animation from initial offset or circumference based on visibility
    to: { strokeDashoffset: isVisible ? offset : circumference }, // End animation at initial offset or circumference based on visibility
  });

  return (
    <div
      ref={progressBarRef} // Assign ref to progress bar element
      style={{
        background: 'linear-gradient(90deg, #cfcfff 0%, #ddf3ff 100%)',
      }}
      className="circular-progress-bar relative w-full h-full p-8"
    >
      {/* White circle to show background */}
      <svg
        className="absolute overflow-visible"
        height="100%"
        width="100%"
        viewBox="0 0 50 50" // reduced viewBox to make the circle smaller
      >
        <circle
          className="stroke-white fill-transparent"
          style={{
            strokeWidth: '4',
          }}
          r={radius}
          cx={radius}
          cy={radius}
          transform={`rotate(-90 ${radius} ${radius})`}
        />
      </svg>

      {/* Progress circle */}
      <svg
        className="absolute overflow-visible"
        height="100%"
        width="100%"
        viewBox="0 0 50 50" // reduced viewBox to make the circle smaller
      >
        <animated.circle
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeWidth: '4',
            fill: 'transparent',
            stroke: '#007bff', // color of the circular progress bar
            strokeDashoffset: progressAnimation.strokeDashoffset, // set the animated stroke dash offset
          }}
          r={radius}
          cx={radius}
          cy={radius}
          transform={`rotate(-90 ${radius} ${radius})`}
        />
      </svg>

      {title ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 circular-progress-title">{title}</div>
      ) : (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl font-bold">
          {percentage}%
        </div>
      )}
    </div>
  );
};
