import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useMutation } from 'react-query';
import OktaRequests from '../../_okta/utils/utils';

import { encode } from 'html-entities';
import { FormikText, FormikSelect } from '../subcomponents/InputField';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import selectOptions from '../../data/selectOptions.json';
import Timer from '../subcomponents/Timer';
import SetStorage from '../storage/SetStorage';

// TODO: Make this a constant.
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

/**
 * MfaEnroll component for handling MFA enrollment.
 *
 * @param {Object} account - The account object containing user details.
 * @returns {JSX.Element} - The JSX element representing the MFA enrollment component.
 */
export default function MfaEnroll({ account }) {
  const { oktaAuth } = useOktaAuth();
  const [oktaAccount, setOktaAccount] = useState(account);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (oktaAccount && oktaAccount.status === 'SUCCESS') {
      const sessionToken = oktaAccount.sessionToken;
      setToken(sessionToken);
      // @TODO: we would need to take into account the NYL variables.
      SetStorage('session', sessionToken);
      oktaAuth.signInWithRedirect({ sessionToken });
    }
    // eslint-disable-next-line
  }, [oktaAccount]);

  if (token) return <Timer />;

  return (
    <div>
      {{
        MFA_ENROLL: (
          <MfaEnrollment account={oktaAccount} setAccount={setOktaAccount} />
        ),
        MFA_REQUIRED: (
          <MfaVerify account={oktaAccount} setAccount={setOktaAccount} />
        ),
        MFA_ENROLL_ACTIVATE: (
          <MfaVerify account={oktaAccount} setAccount={setOktaAccount} />
        ),
        MFA_CHALLENGE: (
          <MfaVerify account={oktaAccount} setAccount={setOktaAccount} />
        ),
      }[oktaAccount?.status] || (
        <>
          <h2>There was an issue!</h2>
          <p>
            Please contact the American College of Financial Services at{' '}
            <a href="tel:8882637265">888-263-7265</a>.
          </p>
        </>
      )}
    </div>
  );
}
/**
 * @description Component for enabling Multi-factor Authentication.
 * @param {Object} account - The account object containing the user's information.
 * @param {Function} setAccount - The function to update the account state.
 * @returns {JSX.Element} - The JSX element representing the MFA enrollment component.
 */
function MfaEnrollment({ account, setAccount }) {
  const [error, setError] = useState('');
  const { enrollFactors } = OktaRequests();

  const { isLoading: factorLoading, mutate: enrollFactor } = useMutation(
    (values) => enrollFactors(values),
  );

  const securityQuestionOptions = selectOptions.mfaSecurityQuestion;

  /**
   * Function to handle the form submission for enabling MFA.
   *
   * @param {Object} values - The form values containing the selected factor and answer.
   * @returns {void} - No return value.
   */
  const formSubmit = async (values) => {
    if (!factorLoading) {
      try {
        // The state token is needed
        // for this process flow.
        const data = {
          stateToken: account.data.stateToken,
          factorType: values.factorEnrollment,
        };
        // depending on the factor enrollment some
        // things are different here.
        switch (values.factorEnrollment) {
          case 'sms':
            data.profile = {
              phoneNumber: values.sms,
            };
            break;

          case 'question':
            data.profile = {
              question: encode(values.question),
              answer: encode(values.answer),
            };
            break;

          default:
            break;
        }
        // validate the recovery answer inputted.
        enrollFactor(data, {
          onError: (res) => {
            const message = res.data.summary || res.data.errorSummary;
            setError(message);
          },
          onSuccess: (data) => {
            if (data) {
              setAccount(data);
            } else {
              setError('There was an issue verifying the token.');
            }
          },
        });
      } catch (error) {
        setError(error);
      }
    }
  };

  /**
   * Validation schema for the form fields in the MFA enrollment component.
   * @type {Object} - The Yup validation schema object.
   */
  const validationSchema = Yup.object().shape({
    factorEnrollment: Yup.string().required('Field is required'),
    sms: Yup.string().when('factorEnrollment', (factorEnrollment, schema) => {
      if (factorEnrollment && factorEnrollment[0] === 'sms')
        return schema
          .matches(phoneRegExp, '*Invalid phone format')
          .required('Field is required');
    }),
    question: Yup.string().when(
      'factorEnrollment',
      (factorEnrollment, schema) => {
        if (factorEnrollment && factorEnrollment[0] === 'question')
          return schema.required('Field is required');
      },
    ),
    answer: Yup.string().when(
      ['factorEnrollment', 'question'],
      (fields, schema, val) => {
        if (fields && fields[0] === 'question')
          return schema
            .test('contains', 'Answer can not be apart of the question', () => {
              return !fields[1]
                ?.toLowerCase()
                .includes(val.value?.toLowerCase());
            })
            .required('Field required');
      },
    ),
  });

  return (
    <>
      <h2>Enable Multifactor Authentication</h2>
      <h5>
        We require multifactor authentication to add an additional layer of
        security when signing in to your Learning Hub account!
      </h5>
      {error && <span className="red-text">{error}</span>}
      <Formik
        initialValues={{
          factorEnrollment: '',
          question: '',
          answer: '',
          sms: '',
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="validateAnswer">
            <div className="radio-question">
              <ul
                className="radios"
                role="group"
                aria-labelledby="factor-enrollment">
                <li>
                  <Field
                    type="radio"
                    name="factorEnrollment"
                    value="sms"
                    id="sms-factor"
                    className={
                      touched.factorEnrollment && errors.factorEnrollment
                        ? 'error'
                        : null
                    }
                  />
                  <label htmlFor="sms-factor">
                    <p>
                      <strong>SMS Authentication</strong>
                      <br />
                      Enter a single-use code sent to your mobile phone.
                    </p>
                    {values.factorEnrollment === 'sms' && (
                      <div>
                        <div className="field">
                          <FormikText
                            type="text"
                            id="sms"
                            name="sms"
                            value={values.sms}
                            className={
                              touched.sms && errors.sms ? 'error' : null
                            }
                            onChange={handleChange}
                            placeholder="* Phone Number"
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    )}
                  </label>
                </li>
                <li>
                  <Field
                    type="radio"
                    name="factorEnrollment"
                    value="question"
                    id="question-factor"
                    className={
                      touched.factorEnrollment && errors.factorEnrollment
                        ? 'error'
                        : null
                    }
                  />
                  <label htmlFor="question-factor">
                    <p>
                      <strong>Security Question</strong>
                      <br />
                      Use the answer to a security question to authenticate.
                    </p>
                    {values.factorEnrollment === 'question' && (
                      <div>
                        <div className="field">
                          <FormikSelect
                            id="question"
                            name="question"
                            value={values.question}
                            onChange={handleChange}>
                            {securityQuestionOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </FormikSelect>
                        </div>
                        <div className="field">
                          <FormikText
                            type="text"
                            id="answer"
                            name="answer"
                            value={values.answer}
                            className={
                              touched.answer && errors.answer ? 'error' : null
                            }
                            onChange={handleChange}
                            placeholder="* Answer"
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    )}
                  </label>
                </li>
              </ul>
              {touched.factorEnrollment && errors.factorEnrollment && (
                <span className="red-text">{errors.factorEnrollment}</span>
              )}
            </div>
            <div className="flex">
              <button type="submit" form="validateAnswer" className="btn">
                Continue
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

/**
 * @description This component handles the MFA (Multi-Factor Authentication) process.
 * @param {Object} account - The user account object.
 * @param {Function} setAccount - A function to update the user account object.
 * @returns {JSX.Element} - A JSX element representing the MFA process.
 */
function MfaVerify({ account, setAccount }) {
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [stateToken, setStateToken] = useState(null);
  const { verifyFactors, activateCodeFactor, resendCodeFactor } =
    OktaRequests();
  const [verifyFactor, setVerifyFactor] = useState(null);

  const { isLoading: sendCodeLoading, mutate: sendFactorCode } = useMutation(
    (values) => verifyFactors(values),
  );

  const { isLoading: resendCodeLoading, mutate: resendFactorCode } =
    useMutation((values) => resendCodeFactor(values));

  const { isLoading: verifyCodeLoading, mutate: verifyFactorCode } =
    useMutation((values) => verifyFactors(values));

  const { isLoading: activateCodeLoading, mutate: activateFactorCode } =
    useMutation((values) => activateCodeFactor(values));

  useEffect(() => {
    if (account) {
      switch (account.status) {
        case 'MFA_REQUIRED':
          setStateToken(account.data.stateToken);
          setVerifyFactor(account.factors[0]);
          break;
        case 'MFA_CHALLENGE':
          setStateToken(account.stateToken);
          setVerifyFactor(account._embedded.factor);
          break;
        case 'MFA_ENROLL_ACTIVATE':
          setCodeSent(true);
          setStateToken(account.stateToken);
          setVerifyFactor(account._embedded.factor);
          break;

        default:
          break;
      }
    }
  }, [account]);

  /**
   * @description This function sends a code to the user's phone number.
   * @param {Object} values - An object containing the necessary data for the request.
   * @returns {Promise<void>} - A promise that resolves when the code has been sent.
   */
  const sendCode = async () => {
    if (!sendCodeLoading && !resendCodeLoading && verifyFactor) {
      try {
        // The state token is needed
        // for this process flow.
        const data = {
          data: { stateToken: stateToken },
          factorId: verifyFactor.id,
        };
        // validate the recovery answer inputted.
        sendFactorCode(data, {
          onError: (res) => {
            const message = res.data.summary || res.data.errorSummary;
            setError(message);
          },
          onSuccess: (data) => {
            if (data) {
              setCodeSent(true);
              setAccount(data);
            } else {
              setError('There was an sending the code.');
            }
          },
        });
      } catch (error) {
        setError(error);
      }
    }
  };

  /**
   * @description This function resends a code to the user's phone number.
   * @param {Object} values - An object containing the necessary data for the request.
   * @returns {Promise<void>} - A promise that resolves when the code has been resent.
   */
  const resendCode = async () => {
    if (!resendCodeLoading && verifyFactor) {
      try {
        // The state token is needed
        // for this process flow.
        const data = {
          data: { stateToken: stateToken },
          factorId: verifyFactor.id,
        };
        // validate the recovery answer inputted.
        resendFactorCode(data, {
          onError: (res) => {
            const message = res.data.summary || res.data.errorSummary;
            setError(message);
          },
          onSuccess: (data) => {
            if (data) {
              setCodeSent(true);
              setAccount(data);
            } else {
              setError('There was an sending the code.');
            }
          },
        });
      } catch (error) {
        setError(error);
      }
    }
  };

  /**
   * @description This function submits the form with the user's input.
   * @param {Object} values - An object containing the user's input.
   * @returns {Promise<void>} - A promise that resolves when the form has been submitted.
   */
  const formSubmit = async (values) => {
    if (!verifyCodeLoading && !activateCodeLoading && verifyFactor) {
      try {
        // The state token is needed
        // for this process flow.
        const data = {
          factorId: verifyFactor.id,
        };
        // depending on the factor enrollment some
        // things are different here.
        switch (verifyFactor.factorType) {
          case 'sms':
            data.data = {
              passCode: values.code,
              stateToken: stateToken,
            };
            break;

          case 'question':
            data.data = {
              answer: values.answer,
              stateToken: stateToken,
            };
            break;

          default:
            break;
        }
        // Theres a different endpoint for this status.
        if (account.status === 'MFA_ENROLL_ACTIVATE') {
          // validate the recovery answer inputted.
          activateFactorCode(data, {
            onError: (res) => {
              const message = res.data.summary || res.data.errorSummary;
              setError(message);
            },
            onSuccess: (data) => {
              if (data) {
                setAccount(data);
              } else {
                setError('There was an sending the code.');
              }
            },
          });
        } else {
          // validate the recovery answer inputted.
          verifyFactorCode(data, {
            onError: (res) => {
              const message = res.data.summary || res.data.errorSummary;
              setError(message);
            },
            onSuccess: (data) => {
              if (data) {
                setAccount(data);
              } else {
                setError('There was an sending the code.');
              }
            },
          });
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  /**
   * Validation schema for the form fields in the MFA enrollment component.
   * @type {Object} - The Yup validation schema object.
   */
  const validationSchema = Yup.object().shape({
    code: Yup.string().when([], (vars, schema) => {
      if (verifyFactor && verifyFactor.factorType === 'sms')
        return schema.required('Field is required');
    }),
    answer: Yup.string().when([], (vars, schema) => {
      if (verifyFactor && verifyFactor.factorType === 'question')
        return schema.required('Field required');
    }),
  });

  if (verifyFactor === null || sendCodeLoading || verifyCodeLoading)
    return <Timer />;

  return (
    <>
      <Formik
        initialValues={{
          answer: '',
          code: '',
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="verifyAnswer">
            {verifyFactor.factorType === 'sms' && (
              <div>
                <h2>SMS Authentication</h2>
                <h5>{verifyFactor.profile.phoneNumber}</h5>
                {error && <span className="red-text">{error}</span>}
                <div className="field">
                  <FormikText
                    type="text"
                    id="code"
                    name="code"
                    value={values.code}
                    className={
                      touched.code && errors.code
                        ? 'factor-code error'
                        : 'factor-code'
                    }
                    onChange={handleChange}
                    placeholder="* Enter Code"
                    onBlur={handleBlur}
                  />
                  {codeSent === false ? (
                    <button className="btn" onClick={sendCode}>
                      Send Code
                    </button>
                  ) : (
                    <button className="btn" onClick={resendCode}>
                      Resend Code
                    </button>
                  )}
                </div>
              </div>
            )}
            {verifyFactor.factorType === 'question' && (
              <div>
                <h2>Security Question</h2>
                <h5>{verifyFactor.profile.questionText}</h5>
                {error && <span className="red-text">{error}</span>}
                <div className="field">
                  <FormikText
                    type="text"
                    id="answer"
                    name="answer"
                    value={values.answer}
                    className={touched.answer && errors.answer ? 'error' : null}
                    onChange={handleChange}
                    placeholder="* Enter Answer"
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            )}
            <div className="flex">
              <button type="submit" form="verifyAnswer" className="btn">
                Continue
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
