import { useEffect, useState } from 'react';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { openBase64NewTab } from '../../utils/helper';

import Timer from '../subcomponents/Timer';

import { useQuery } from 'react-query';

// will need to modify the useStates (like completed courses) for userData - needed dummy to make and style table
export function Taxforms() {
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  // Retrieves the tax form years for the user.
  const { isLoading: statmentYearsLoading, data: statmentYears } = useQuery(
    ['taxYears'],
    () => load('taxYears'),
  );
  const [taxYear, setTaxYear] = useState(false);
  const [action, setAction] = useState(false);
  // Retrieves the tax forms for the user.
  const { isLoading: isTaxFormLoading, data: taxForm } = useQuery(
    [`${taxYear}taxForm`],
    () => load('taxForm', { year: taxYear }),
    {
      enabled: action !== false && taxYear >= 2020,
    },
  );
  const { isLoading: isOldTaxFormLoading, data: oldTaxForm } = useQuery(
    [`${taxYear}oldTaxForm`],
    () => load('oldTaxForm', { year: taxYear }),
    {
      enabled: action !== false && taxYear < 2020,
    },
  );

  useEffect(() => {
    if (isTaxFormLoading === false && taxForm && taxForm?.pdf_data) {
      if (action === 'view') {
        openBase64NewTab(
          decodeURIComponent(taxForm.pdf_data),
          `${taxYear}_1098-T.pdf`,
        );
      } else if (action === 'download') {
        // Setting various property values to
        // download the pdf file.
        const downloadLink = document.createElement('a');
        const fileName = `${taxYear}_1098-T.pdf`;
        // Add the attributes to the a
        // link to download the file.
        downloadLink.href = `data:application/pdf;base64,${taxForm.pdf_data}`;
        downloadLink.target = '_blank';
        downloadLink.download = fileName;
        downloadLink.click();
      }
      // reset the action.
      setAction(false);
    }
  }, [taxForm, action, isTaxFormLoading, taxYear]);

  useEffect(() => {
    if (isOldTaxFormLoading === false && oldTaxForm && oldTaxForm.pdf_data) {
      if (action === 'view') {
        openBase64NewTab(
          decodeURIComponent(oldTaxForm.pdf_data),
          `${taxYear}_1098-T.pdf`,
        );
      } else if (action === 'download') {
        // Setting various property values to
        // download the pdf file.
        const downloadLink = document.createElement('a');
        const fileName = `${taxYear}_1098-T.pdf`;
        // Add the attributes to the a
        // link to download the file.
        downloadLink.href = `data:application/pdf;base64,${oldTaxForm.pdf_data}`;
        downloadLink.target = '_blank';
        downloadLink.download = fileName;
        downloadLink.click();
      }
      // reset the action.
      setAction(false);
    }
  }, [oldTaxForm, action, isOldTaxFormLoading, taxYear]);

  // Load the pdf in a seperate window.
  const viewForm = (year) => {
    setTaxYear(year);
    setAction('view');
  };

  // Downloads the IRS form.
  const downloadForm = (year) => {
    setTaxYear(year);
    setAction('download');
  };

  return (
    <div className="container">
      {statmentYearsLoading === false ? (
        statmentYears.length > 0 && (
          <>
            {statmentYears
              .sort(
                (a, b) => parseInt(b.year_display) - parseInt(a.year_display),
              )
              .map((item, index) => (
                <div key={index} className="card-layout halves sm-gap">
                  <div className="card drop-box padding--sm">
                    <div className="flex card-holder">
                      <p className="title4 card-header">
                        {item.year_display} 1098-T IRS Form
                      </p>
                      <div className="buttons">
                        <button
                          className="btn w-full"
                          onClick={() => viewForm(item.year_display)}>
                          View Form
                        </button>
                        <button
                          className="btn secondary w-full"
                          onClick={() => downloadForm(item.year_display)}>
                          Download Form
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )
      ) : (
        <Timer />
      )}
    </div>
  );
}
