import { useState } from 'react';
import { ResourceGrid } from './ResourceGrid';
import { ResourceFilters } from './ResourceFilters';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { useInfiniteQuery } from 'react-query';
import ReadStorage from '../../components/storage/ReadStorage';

export function SavedResources({saved}) {
    const limit = 16;
    const oktaProfile = ReadStorage('oktaProfile');
    const resourceKeysBase = {oktaID:oktaProfile.id, offset:0, limit: limit};
    const [resourceKeys, setResourceKeys] = useState(resourceKeysBase);
    const [searchFields, setSearchFields] = useState({textSearch:"",format:""});
    const { load } = useTacfsService();
  
    const { data, hasNextPage, fetchNextPage, refetch, remove, isFetching} = useInfiniteQuery(
        ['savedResources'], 
        ({pageParam=0}) => {
            const updatedResourceKeys = resourceKeys;
            updatedResourceKeys.offset = pageParam;
            setResourceKeys(updatedResourceKeys);
            return load('savedResources', resourceKeys);
        },
        {
            getNextPageParam: (lastPage, pages) => {
                const offset = pages.length*limit;
                //TODO: The following logic only causes the load more button to 
                //disable after an empty result set.
                if(lastPage.length>0) return offset;
            }
        }
    );
    /**
     * Accepts an object and adds it to the resourceKeys object.
     * 
     * @param object filter
     * @void
     */
    function addFilterParams(filter = {}){
        const updatedResourceKeys = resourceKeys;
        updatedResourceKeys.offset = 0;
        for (const [key, value] of Object.entries(filter)) {
            updatedResourceKeys[key] = value;
        }
        remove(); //remove current query
        setResourceKeys(updatedResourceKeys);
        refetch();
    }
    /**
     * Adds textSearch and format to the filter params object.
     * @void
     */
    function searchByFields(){
       addFilterParams(searchFields);
    }
    /**
     * Resets the state values of filters & search terms
     * @void
     */
    function resetSearch(){
        remove(); //clear the current Query
        setSearchFields({
            textSearch:"",
            format:"",
        });
        const updatedResourceKeys = resourceKeys;
        //Reset all values to the default
        for (const [key, value] of Object.entries(resourceKeysBase)) {
            updatedResourceKeys[key] = value;
        }
        //Unset specific elements 
        updatedResourceKeys.filterBy = "";
        updatedResourceKeys.textSearch = "";
        updatedResourceKeys.format = "";
        setResourceKeys(updatedResourceKeys); 
        refetch(); //refetch the query
    }
   return (
        <div className="saved-resources">
            {/* TODO: Disable trending & popular filters for saved-resources */}
            <ResourceFilters 
                reset={resetSearch} 
                addFilterParam={addFilterParams} 
                searchFields={searchFields} 
                setSearchFields={setSearchFields} 
                searchFunction={searchByFields} 
                isSavedResources={true}
                title=<h3>Saved Resources</h3>
            />
            { isFetching  && ("Loading your Saved Resources...")}
            { !isFetching  && !data && ('Resources are currently unavailable. Please check back later.')}
            { !isFetching  && data && (<>
                <ResourceGrid resources={data} saved={saved}/>
                <button className={`loadMore btn ${(!hasNextPage?" disabled":"")}`} disabled={!hasNextPage} onClick={fetchNextPage}>Load More</button>
            </>)}
            
        </div>
    );
}
