import { useState } from 'react';
import { Layout } from '../components/Layout';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import useTacfsService from '../utils/tacfs/useTacfsService';
import { ReflexBlueLogoImage } from '../components/subcomponents/Icon';
import Timer from '../components/subcomponents/Timer';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

export function AccountValidate() {
  const { webToken } = useParams();
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();
  // // Find the student in the system.
  const { isLoading: isStudentLoading, data: student } = useQuery(
    ['unifyLookup'],
    () => load('unifyLookup', { webToken }),
  );

  // Create an Okta account
  const { isLoading: isProvisioning, mutate: createAccount } = useMutation(
    (values) => save('provisionOktaAccount', values),
  );

  const formSubmit = async (values) => {
    setError('');
    try {
      const data = {
        email: student.email,
        mobilePhone: values.mobilePhone,
        pass: values.pass,
        securityQuestion: values.securityQuestion,
        securityAnswer: values.securityAnswer,
        webToken: webToken,
      };

      createAccount(data, {
        onError: (error) => {
          setError(error);
        },
        onSuccess: (data) => {
          // confirm to the user that the process completed successfully.
          if (data) setSent(true);
          else setError('Sorry there was an issue with the account setup.');
        },
      });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Layout>
      <div className="container password-reset-page">
        {/*  If the application is still loading display a message. */}
        {isStudentLoading ? (
          <Timer />
        ) : (
          <>
            <div className="logo">
              <ReflexBlueLogoImage className="h-24" />
            </div>
            {/* We only allow access if a valid student ID was returned.
            We also do a server check to make sure the user has access. */}
            {!isStudentLoading &&
            (!student || (student && !/^\d+$/.test(student.student_id))) ? (
              <h1>Access Denied!</h1>
            ) : sent ? (
              <>
                <p className="title1 element-spacing">
                  Your Account Has Been created!
                </p>
                <p>Please login with the link below.</p>
                <a href="/" className="btn">
                  Login
                </a>
              </>
            ) : (
              <div>
                <h1>We found you in our system, {student.name}!</h1>
                <p className="p4">
                  Create your new account by entering your new username and
                  password credentials.
                </p>
                {error && <p className="red-text">{error}</p>}
                <Formik
                  initialValues={{
                    email: student.email,
                    pass: '',
                    confirmPass: '',
                    securityQuestion: '',
                    securityAnswer: '',
                    mobilePhone: '',
                  }}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    formSubmit(values);
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} id="locateUser">
                      <p>
                        <span className="section-header">USERNAME: </span>You
                        will use this username to access all of your resources.
                      </p>
                      <div className="field">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          value={values.email}
                          disabled={true}
                          className={
                            touched.email && errors.email ? 'error' : null
                          }
                          onChange={handleChange}
                          placeholder="Email Address"
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email && (
                          <span className="red-text">{errors.email}</span>
                        )}
                      </div>
                      <div>
                        <p>
                          <span className="section-header">PASSWORD: </span>Your
                          password must have at least eight (8) characters, a
                          lowercase letter, an uppercase letter, a number, and
                          contain no parts of your username.
                        </p>
                        <div className="field">
                          <Field
                            type="password"
                            id="pass"
                            name="pass"
                            value={values.pass}
                            className={
                              touched.pass && errors.pass ? 'error' : null
                            }
                            onChange={handleChange}
                            placeholder="Password"
                            onBlur={handleBlur}
                          />
                          {touched.pass && errors.pass && (
                            <span className="red-text">{errors.pass}</span>
                          )}
                        </div>

                        <div className="field">
                          <Field
                            type="password"
                            id="confirmPass"
                            name="confirmPass"
                            value={values.confirmPass}
                            className={
                              touched.confirmPass && errors.confirmPass
                                ? 'error'
                                : null
                            }
                            onChange={handleChange}
                            placeholder="Confirm Password"
                            onBlur={handleBlur}
                          />
                          {touched.confirmPass && errors.confirmPass && (
                            <span className="red-text">
                              {errors.confirmPass}
                            </span>
                          )}
                        </div>
                      </div>

                      <div>
                        <p>
                          <span className="section-header">
                            PASSWORD RESET EMAIL (REQUIRED):{' '}
                          </span>
                          Please enter a security question and answer that we
                          can use to send a link to your email if your forget
                          your password.
                        </p>
                        <div className="field">
                          <Field
                            as="select"
                            name="securityQuestion"
                            id="securityQuestion"
                            value={values.securityQuestion}
                            className={
                              touched.securityQuestion &&
                              errors.securityQuestion
                                ? 'error'
                                : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}>
                            <option value="">
                              *Select a security question.
                            </option>
                            <option value="What is your mother's maiden name?">
                              What is your mother's maiden name?
                            </option>
                            <option value="What city were you born in?">
                              What city were you born in?
                            </option>
                            <option value="What is your paternal grandmother's first name?">
                              What is your paternal grandmother's first name?
                            </option>
                            <option value="What is your maternal grandmother's fist name?">
                              What is your maternal grandmother's fist name?
                            </option>
                            <option value="What is the name of your first pet?">
                              What is the name of your first pet?
                            </option>
                            <option
                              value="What is the first name of your childhood best
                              friend?">
                              What is the first name of your childhood best
                              friend?
                            </option>
                          </Field>
                          {touched.securityQuestion &&
                            errors.securityQuestion && (
                              <span className="red-text">
                                {errors.securityQuestion}
                              </span>
                            )}
                        </div>

                        <div className="field">
                          <Field
                            type="text"
                            id="securityAnswer"
                            name="securityAnswer"
                            value={values.securityAnswer}
                            className={
                              touched.securityAnswer && errors.securityAnswer
                                ? 'error'
                                : null
                            }
                            onChange={handleChange}
                            placeholder="Answer"
                            onBlur={handleBlur}
                          />
                          {touched.securityAnswer && errors.securityAnswer && (
                            <span className="red-text">
                              {errors.securityAnswer}
                            </span>
                          )}
                        </div>
                      </div>

                      <p>
                        <span className="section-header">
                          PASSWORD RESET PHONE (OPTIONAL):{' '}
                        </span>
                        You can also enter your mobile phone number to reset
                        your password through text.
                      </p>
                      <div className="field">
                        <Field
                          type="text"
                          id="mobilePhone"
                          name="mobilePhone"
                          value={values.mobilePhone}
                          className={
                            touched.mobilePhone && errors.mobilePhone
                              ? 'error'
                              : null
                          }
                          onChange={handleChange}
                          placeholder="Mobile Phone Number (Optional)"
                          onBlur={handleBlur}
                        />
                        {touched.mobilePhone && errors.mobilePhone && (
                          <span className="red-text">{errors.mobilePhone}</span>
                        )}
                        <p>
                          You will receive a passcode to enter on the activation
                          page and your mobile phone number will be used for
                          reset only. Standard message and data rates may apply.
                        </p>
                      </div>

                      <div className="flex">
                        {isProvisioning ? (
                          <button
                            type="submit"
                            form="locateUser"
                            className="btn disabled"
                            disabled>
                            Submitting...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            form="locateUser"
                            className="btn">
                            Create My Account
                          </button>
                        )}
                      </div>
                      <p className="disclaimer pt-8">
                        If you are having issues, please contact the American
                        College of Financial Services at{' '}
                        <a href="tel:8882637265">888-263-7265</a>.
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = Yup.object().shape({
  email: Yup.string(),
  pass: Yup.string()
    .test("does-not-contain-email", "You may not use parts of your username in your password", 
          (value, context)=>{
            if(!value){return true;}
            const emailParts = context.parent.email.toLowerCase().split('@');
            if(value.toLowerCase().includes(emailParts[0])){
                return false;
            }
            if(value.toLowerCase().includes(emailParts[1])){
                return false;
            }
            return true;
        }
    )
    .required('Field is required')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
    .min(8, 'Must use 8 or more characters'),
  confirmPass: Yup.string()
    .oneOf([Yup.ref('pass'), null], "Passwords don't match!")
    .required('Field is required'),
  securityQuestion: Yup.string().required('Field is required'),
  securityAnswer: Yup.string().required('Field is required'),
  mobilePhone: Yup.string().matches(phoneRegExp, '*Phone number is not valid'),
});
