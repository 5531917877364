import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useMutation } from 'react-query';
import dayjs from 'dayjs';

import useTacfsService from '../utils/tacfs/useTacfsService';
import ReadStorage from '../components/storage/ReadStorage';
import useProfileQuery from '../utils/tacfs/useProfileQuery';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import ToolTip from '../components/subcomponents/ToolTip';
import InputField from '../components/subcomponents/InputField';
import Modal from '../components/subcomponents/Modal';
import Timer from '../components/subcomponents/Timer';

import {
  BasicInfoForm,
  DemographicInfoForm,
  ContactInfoForm,
} from '../components/profile/PersonalInfoForms';

import { CompanyInfoForm } from '../components/profile/ProfessionalInfoForms';

import selectOptions from '../data/selectOptions.json';
import mapping from '../data/selectValuesMapping.json';

export function EthicsQuestionnaire() {
  const { authState } = useOktaAuth();
  const { load, save } = useTacfsService();
  const navigate = useHistory();

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [infoVerified, setInfoVerified] = useState(false);
  const [learnMore, setLearnMore] = useRadios([true, false]);
  const [contactMethod, setContactMethod] = useInput('');
  const [otherContactMethod, setOtherContactMethod] = useInput('');

  const [cfModal, setCfModal] = useState(false);
  const [cfModalVisible, setCfModalVisible] = useState(false);

  const [clientFacing, setClientFacing] = useInput('');
  const [radioQuestions, setRadioQuestions] = useRadioSet(
    new Array(questions.length).fill(''),
  );
  const [descriptions, setDescriptions] = useInputSet(
    new Array(questions.length).fill(''),
  );
  //  const [affiliation, setAffiliation] = useInput('');
  const [promo, setPromo] = useInput('');
  const [promoError, setPromoError] = useState('');
  const [promoVerified, setPromoVerified] = useState(false);

  const [attestation1, setAttestation1] = useState(false);
  const [attestation2, setAttestation2] = useState(false);
  const [attestation3, setAttestation3] = useState(false);

  const clientFacingOptions = selectOptions.clientFacing;
  const contactMethodOptions = selectOptions.contactMethod;

  const [/*percentComplete, */ setPercentComplete] = useState(
    parseInt(ReadStorage('percentComplete')),
  );

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const { isLoading: userDataLoading, data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );

  const { profileLoading, profileData } = useProfileQuery(userData);
  const [profile, setProfile] = useState(profileData);

  //  @TODO: This needs to be refactored as the
  //         the prfileData should be in the
  //         dependency array but cant add it
  //         bc the profileData gets updated in
  //         the useEffect hook.
  useEffect(() => {
    if (
      profileData.basic &&
      profileData.contact &&
      profileData.education &&
      profileData.company &&
      profileData.flags &&
      userData
    ) {
      setProfile(profileData);
    }
  }, [profileLoading, userData, profileData]);

  const { isLoading: studentCELoading, data: studentCE } = useQuery(
    ['studentCE'],
    () => load('studentCE'),
  );

  useEffect(() => {
    if (cfModalVisible) {
      document.querySelector('body').classList.remove('overflow-auto');
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
      document.querySelector('body').classList.add('overflow-auto');
    }
  }, [cfModalVisible]);

  useEffect(() => {
    // if the user doesnt have access to the form we redirect back to
    // the recertification homepage.
    if (studentCE && studentCE?.tac_ques_ethics_bt?.toUpperCase() === 'N') {
      navigate.push('/designations-certifications/recertification');
    }
  }, [studentCE, navigate]);

  const { isLoading: companiesLoading /*, data: companies */ } = useQuery(
    ['companies'],
    () => load('companies'),
  );

  const { /* isLoading: learnMoreLoading, */ mutate: learnMoreMutate } =
    useMutation((values) => save('learnMore', values));

  const { /* isLoading: promoLoading, */ mutate: promoCodeVerify } =
    useMutation((values) => save('promoCodeVerify', values));

  const { /* isLoading: qLoading, */ mutate: ethicsQuestionnaireMutate } =
    useMutation((values) => save('ethicsQuestionnaire', values));

  const handleContinue = (e) => {
    e.preventDefault();
    setError('');

    if (learnMore[0]) {
      const values = {
        studentID: profile.initial.empl_id,
        contactMethod: contactMethod,
        other: otherContactMethod,
      };

      learnMoreMutate(values, {
        onError: (res) => setError(res.toString()),
        onSuccess: (data) => {
          setInfoVerified(true);
          window.scrollTo({ top: 0 });
        },
      });
    } else {
      setInfoVerified(true);
      window.scrollTo({ top: 0 });
    }
  };

  const handlePromo = () => {
    setPromoError('');

    const values = {
      studentID: profile.initial.empl_id,
      promo_code: promo.trim(),
      company_id: profileData.company.company_id,
    };

    promoCodeVerify(values, {
      onError: (res) => setPromoError(res.toString()),
      onSuccess: (data) => {
        if (!data || data.promo_code !== values.promo_code) {
          setPromoError('The promo code could not be verified.');
        } else {
          setPromoVerified(true);
        }
      },
    });
  };

  const formIsValid = () => {
    if (!attestation1 || !attestation2 || !attestation3 || !clientFacing)
      return false;

    for (let i = 0; i < questions.length; i++) {
      if (radioQuestions[i] === '') return false;
      if (radioQuestions[i] && !descriptions[i]) return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    // Only send description if answer was Yes, otherwise the user might type
    // something, switch it back to No, and the description will still be sent
    const values = {
      begin_date: dayjs(studentCE?.begin_date).format('YYYY-MM-DD'),
      year_display: studentCE?.year_display,
      datetime_added: dayjs().format('YYYY-MM-DD H:mm:ss.SSS'),
      approval_date: dayjs().format('YYYY-MM-DD'),
      promo_code: promoVerified ? promo : '',
      company_id: profileData.company.company_id,
      q1_yn: clientFacing,
      q2_yn: radioQuestions[0] ? 'Y' : 'N',
      q2_comments: radioQuestions[0] ? descriptions[0] : '',
      q3_yn: radioQuestions[1] ? 'Y' : 'N',
      q3_comments: radioQuestions[1] ? descriptions[1] : '',
      q4_yn: radioQuestions[2] ? 'Y' : 'N',
      q4_comments: radioQuestions[2] ? descriptions[2] : '',
      q5_yn: radioQuestions[3] ? 'Y' : 'N',
      q5_comments: radioQuestions[3] ? descriptions[3] : '',
      q6_yn: radioQuestions[4] ? 'Y' : 'N',
      q6_comments: radioQuestions[4] ? descriptions[4] : '',
      q7_yn: radioQuestions[5] ? 'Y' : 'N',
      q7_comments: radioQuestions[5] ? descriptions[5] : '',
      q8_yn: radioQuestions[6] ? 'Y' : 'N',
      q8_comments: radioQuestions[6] ? descriptions[6] : '',
    };

    ethicsQuestionnaireMutate(values, {
      onError: (res) => {
        setError(res.toString());
        setIsSubmitting(false);
      },
      onSuccess: (data) => {
        if (data.errors?.trim()) {
          setError(data.errors.toString());
          setIsSubmitting(false);
        } else {
          const sessionToken = ReadStorage('session');
          setCfModal(true);
          setCfModalVisible(false);
          setIsSubmitting(false);
          if (promoVerified) {
            window.location.href = `${process.env.REACT_APP_ECOMM_URL}utilAuth/loginSSO?session=${sessionToken}123&params=rcrt=1,promocode=${promo}`;
          } else {
            window.location.href = `${process.env.REACT_APP_ECOMM_URL}utilAuth/loginSSO?session=${sessionToken}123&params=rcrt=1`;
          }
        }
      },
    });
  };

  if (
    userDataLoading ||
    profileLoading ||
    studentCELoading ||
    companiesLoading
  ) {
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container ethics-questionnaire">
        <div className="intro-content-wrapper">
          <h2>Ethics Questionnaire</h2>
          <p className="lg:w-1/2">
            Please fill out the Ethics Questionnaire to determine your status as
            a client facing or non-client facing professional.
          </p>
        </div>

        {!infoVerified ? (
          <div className="my-profile">
            <h3 className="info-header">Information Verification</h3>

            {userData &&
              profile.basic &&
              profile.contact &&
              profile.company &&
              profile.education && (
                <div className="card-layout halves sm-gap">
                  <BasicInfoForm
                    userData={userData}
                    combinedProfile={profile}
                    setProfile={setProfile}
                    setPercentComplete={setPercentComplete}
                  />
                  <DemographicInfoForm
                    userData={userData}
                    combinedProfile={profile}
                    setProfile={setProfile}
                    setPercentComplete={setPercentComplete}
                  />
                  <ContactInfoForm
                    userData={userData}
                    combinedProfile={profile}
                    setProfile={setProfile}
                    setPercentComplete={setPercentComplete}
                  />
                  <CompanyInfoForm
                    userData={userData}
                    combinedProfile={profile}
                    setProfile={setProfile}
                    setPercentComplete={setPercentComplete}
                  />
                </div>
              )}

            <h3>We want to hear from you!</h3>
            <p>
              Here at The American College, there is nothing we value more than
              the voice of our students and alumni. Over the next year we will
              be conducting anonymized surveys, confidential interviews, and
              creating topical industry councils to understand personal
              experiences, educational impact, and the long-term direction of
              financial services.
            </p>
            <p>
              We would love to hear your personal views, stories, and
              perspectives in order to help us better serve you, your needs, and
              interests.
            </p>
            <h4>Would you be interested in learning more or participating?</h4>

            <form onSubmit={handleContinue}>
              <ul className="radios learn-more">
                <li>
                  <input
                    type="radio"
                    id="learn-more-yes"
                    name="learn-more-radios"
                    value="YES"
                    checked={learnMore[0]}
                    onChange={() => setLearnMore(0)}
                  />
                  <label htmlFor="learn-more-yes">
                    Yes, I'd like to learn more
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="learn-more-no"
                    name="learn-more-radios"
                    value="NO"
                    checked={learnMore[1]}
                    onChange={() => setLearnMore(1)}
                  />
                  <label htmlFor="learn-more-no">No, I'm not interested</label>
                </li>
              </ul>

              {learnMore[0] && (
                <div className="flex-column">
                  <div className="field">
                    <label htmlFor="contact-method">
                      Contact Method <span className="req-star">*</span>
                    </label>
                    <select
                      id="contact-method"
                      name="contact-method"
                      value={contactMethod}
                      onChange={setContactMethod}
                      required>
                      {contactMethodOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {contactMethod === 'NS' && (
                    <div className="field">
                      <InputField
                        label="Other Contact Method"
                        id="other-contact-method"
                        type="text"
                        value={otherContactMethod}
                        defaultValue={otherContactMethod}
                        onChange={setOtherContactMethod}
                        required={true}
                        srOnly={true}
                      />
                    </div>
                  )}
                </div>
              )}

              {error && <div className="red-text">{error}</div>}

              <input
                type="submit"
                className="btn center"
                value="Continue to Questionnaire"
              />
            </form>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="questionnaire-form">
            <h3 className="question-header">
              Recertification Status Questionnaire
            </h3>
            <p className="q-intro">
              Complete the recertification questionnaire to determine your
              client facing status and recommit to your professional pledge of
              ethical conduct.
            </p>

            <div className="field flex-column">
              <div className="flex gap-4">
                <label htmlFor="client-facing">
                  1. Are you client-facing or non-client facing?
                </label>
                <ToolTip>
                  <p>
                    Generally, client-facing professionals hold certain licenses
                    and/or certifications and work directly with clients and/or
                    manage or supervise individuals who work with clients. Learn
                    more in the{' '}
                    <Link
                      to="/designations-certifications/recertification"
                      target="_blank">
                      FAQ section
                    </Link>
                    .
                  </p>
                </ToolTip>
              </div>
              <select
                id="client-facing"
                name="client-facing"
                value={clientFacing}
                onChange={setClientFacing}
                required>
                {clientFacingOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="yes-instructions">
              <p className="red-text">
                IF YOU ANSWER YES TO ANY QUESTIONS 2-8, PLEASE INCLUDE THE
                FOLLOWING IN YOUR EXPLANATION:
              </p>
              <ul className="bullets red-text">
                <li>The circumstances of each incident</li>
                <li>
                  The resolution of each charge and final judgment (if
                  applicable)
                </li>
                <li>
                  The status of each charge, if a final judgment is still
                  pending
                </li>
              </ul>
            </div>

            {questions.map((question, index) => {
              return (
                <div key={question.id} className="field">
                  <div className="radio-question">
                    {index + 2}. {question.text}
                  </div>
                  <ul className="radios">
                    <li>
                      <input
                        type="radio"
                        id={`${question.id}-yes`}
                        name={`${question.id}-radios`}
                        value="YES"
                        checked={radioQuestions[index] === true}
                        onChange={() => setRadioQuestions(index, true)}
                      />
                      <label htmlFor={`${question.id}-yes`}>Yes</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id={`${question.id}-no`}
                        name={`${question.id}-radios`}
                        value="NO"
                        checked={radioQuestions[index] === false}
                        onChange={() => setRadioQuestions(index, false)}
                      />
                      <label htmlFor={`${question.id}-no`}>No</label>
                    </li>
                  </ul>

                  {radioQuestions[index] === true && (
                    <>
                      <label
                        htmlFor={`${question.id}-desc`}
                        className="yes-description">
                        Please elaborate as specified in the red text above{' '}
                        <span className="req-star">*</span>
                      </label>
                      <textarea
                        id={`${question.id}-desc`}
                        name={`${question.id}-desc`}
                        type="text"
                        value={descriptions[index]}
                        onChange={(e) => setDescriptions(e, index)}
                        required
                      />
                    </>
                  )}
                </div>
              );
            })}

            {/* <div className="field flex-column">
              <label htmlFor="company_affiliation">
                9. What company are you affiliated with?
              </label>
              <select
                id="company_affiliation"
                name="company_affiliation"
                value={affiliation}
                onChange={setAffiliation}
                required
              >
                <option value="">Choose a Company</option>
                // Sort so that "not listed" is at the top and is otherwise alphabetical
                {companies &&
                  companies.companies
                    .sort((a, b) =>
                      a.org_id === '1599250'
                        ? -1
                        : b.org_id === '1599250'
                        ? 1
                        : a.name > b.name
                        ? 1
                        : -1,
                    )
                    .map((item) => (
                      <option value={item.org_id} key={item.org_id}>
                        {item.name}
                      </option>
                    ))}
              </select>
            </div> */}
            {profileData.company.company_id === '1000376' && (
              <div className="promo-section">
                <div className="title4">
                  In order for State Farm to cover the cost of your professional
                  recertification program fee, you must enter a promotional
                  code.
                </div>

                <p className="p3">
                  The recertification promo code has changed. If you are a State
                  Farm agent, please visit State Farm ABS/Designations to obtain
                  the promo code. If you are a State Farm employee, please visit
                  State Farm University/Designations/Certifications to obtain
                  the promo code. If you are experiencing any difficulty
                  obtaining the promo code, please contact State Farm HR&amp;D
                  Talent Operations.
                </p>

                <div className="promo-submit">
                  <InputField
                    label="Enter Promo Code"
                    id="promocode"
                    type="text"
                    value={promo}
                    defaultValue={promo}
                    onChange={setPromo}
                    required={false}
                  />
                  {promoVerified ? (
                    <button type="button" className="btn disabled" disabled>
                      Verified
                    </button>
                  ) : (
                    <button type="button" className="btn" onClick={handlePromo}>
                      Verify
                    </button>
                  )}
                </div>
                {promoError && (
                  <div className="red-text promo-error">{promoError}</div>
                )}
              </div>
            )}
            <div className="attestation">
              <h4>Applicant's Certification and Attestation</h4>
              <p>I hereby certify and attest to the following:</p>
              <ol>
                <li>
                  I hereby certify that all of the information on this form
                  provided by me is true and complete. I am aware that
                  submitting false information is, in and of itself, a
                  sufficient ground for a revocation of any designation issued
                  to me by The American College of Financial Services.
                </li>
                <li>
                  I further certify that I grant permission to The American
                  College of Financial Services to verify all information
                  provided by me in the Recertification Status Questionnaire,
                  with my current or former employers or any financial services
                  company.
                </li>
                <li>
                  I authorize entities such as state insurance departments,
                  federal, state or local agencies, or any other relevant
                  organizations, to provide all information necessary to verify
                  my answers to the Recertification Status Questionnaire to The
                  American College of Financial Services.
                </li>
                <li>
                  I release and discharge The American College of Financial
                  Services and anyone working on behalf of The College from any
                  and all liability of whatever nature, which may arise out of
                  The College’s seeking and employing any and all information
                  used to verify my answers on the questionnaire.
                </li>
                <li>
                  I agree that I will fully cooperate with The American College
                  of Financial Services during the recertification and review
                  process including providing any documents or information that
                  The College requests as part of its review.
                </li>
              </ol>

              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="attestation1"
                    name="attestation1"
                    value="attestation1"
                    checked={attestation1}
                    onChange={() => setAttestation1(!attestation1)}
                  />
                  <label htmlFor="attestation1">
                    I attest to the above statement and certify that all answers
                    in this questionnaire are true.
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="attestation2"
                    name="attestation2"
                    value="attestation2"
                    checked={attestation2}
                    onChange={() => setAttestation2(!attestation2)}
                  />
                  <div aria-hidden="true">
                    I have reviewed{' '}
                    <Modal
                      title="The American College of Financial Services Code of Ethics"
                      linkClasses="underline-link reflexblue"
                      useAnchor={true}
                      scrollable={true}>
                      <div className="container">
                        <p className="title3">Code of Ethics</p>
                        <p>
                          To underscore the importance of maintaining ethical
                          standards in financial services, the Board of Trustees
                          of The American College of Financial Services adopted
                          a Code of Ethics in 1984. Embodied in the Code are the
                          designees’ Professional Pledge and eight Canons.
                        </p>

                        <p className="title4">The Canons</p>

                        <ol className="upper-roman">
                          <li>
                            Conduct yourself at all times with honor and
                            dignity.
                          </li>
                          <li>
                            Avoid practices that would bring dishonor upon your
                            profession or The American College.
                          </li>
                          <li>
                            Publicize your achievements in ways that enhance the
                            integrity of your profession.
                          </li>
                          <li>
                            Continue your studies throughout your working life
                            so as to maintain a high level of professional
                            competence.
                          </li>
                          <li>
                            Do your utmost to attain a distinguished record of
                            professional service.
                          </li>
                          <li>
                            Support the established institutions and
                            organizations concerned with the integrity of your
                            profession.
                          </li>
                          <li>
                            Participate in building your profession by
                            encouraging and providing appropriate assistance to
                            qualified persons pursuing professional studies.
                          </li>
                          <li>
                            Comply with all laws and regulations, particularly
                            as they relate to professional and business
                            activities.
                          </li>
                        </ol>

                        <p className="title4">The Professional Pledge</p>

                        <p className="italic">
                          "In all my professional relationships, I pledge myself
                          to the following rule of ethical conduct: I shall, in
                          light of all conditions surrounding those I serve,
                          which I shall make every conscientious effort to
                          ascertain and understand, render that service which,
                          in the same circumstances, I would apply to myself."
                        </p>
                      </div>
                    </Modal>{' '}
                    and confirm that I have agreed to be bound by it.
                  </div>
                  <label className="sr-only" htmlFor="attestation2">
                    I have reviewed{' '}
                    <Modal
                      title="The American College of Financial Services Code of Ethics"
                      linkClasses="underline-link reflexblue"
                      useAnchor={true}
                      scrollable={true}>
                      <div className="container">
                        <p className="title3">Code of Ethics</p>
                        <p>
                          To underscore the importance of maintaining ethical
                          standards in financial services, the Board of Trustees
                          of The American College of Financial Services adopted
                          a Code of Ethics in 1984. Embodied in the Code are the
                          designees’ Professional Pledge and eight Canons.
                        </p>

                        <p className="title4">The Canons</p>

                        <ol className="upper-roman">
                          <li>
                            Conduct yourself at all times with honor and
                            dignity.
                          </li>
                          <li>
                            Avoid practices that would bring dishonor upon your
                            profession or The American College.
                          </li>
                          <li>
                            Publicize your achievements in ways that enhance the
                            integrity of your profession.
                          </li>
                          <li>
                            Continue your studies throughout your working life
                            so as to maintain a high level of professional
                            competence.
                          </li>
                          <li>
                            Do your utmost to attain a distinguished record of
                            professional service.
                          </li>
                          <li>
                            Support the established institutions and
                            organizations concerned with the integrity of your
                            profession.
                          </li>
                          <li>
                            Participate in building your profession by
                            encouraging and providing appropriate assistance to
                            qualified persons pursuing professional studies.
                          </li>
                          <li>
                            Comply with all laws and regulations, particularly
                            as they relate to professional and business
                            activities.
                          </li>
                        </ol>

                        <p className="title4">The Professional Pledge</p>

                        <p className="italic">
                          "In all my professional relationships, I pledge myself
                          to the following rule of ethical conduct: I shall, in
                          light of all conditions surrounding those I serve,
                          which I shall make every conscientious effort to
                          ascertain and understand, render that service which,
                          in the same circumstances, I would apply to myself."
                        </p>
                      </div>
                    </Modal>{' '}
                    and confirm that I have agreed to be bound by it.
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="attestation3"
                    name="attestation3"
                    value="attestation3"
                    checked={attestation3}
                    onChange={() => setAttestation3(!attestation3)}
                  />
                  <div aria-hidden="true">
                    I have reviewed the{' '}
                    <Link
                      to={{
                        pathname: `${process.env.REACT_APP_MARKETING_URL}sites/default/files/2023-09/recertification-program-guidelines-2024.pdf`,
                      }}
                      target="_blank"
                      className="underline-link reflexblue">
                      Professional Recertification Guidelines
                    </Link>{' '}
                    and agree to comply with the program’s requirements. I
                    understand that The American College of Financial Services
                    at any time may amend, replace or eliminate the program, its
                    continuing education requirements, and any related fees.
                  </div>
                </li>
              </ul>
            </div>
            {isSubmitting ? (
              <button type="button" className="btn center disabled" disabled>
                Submitting...
              </button>
            ) : formIsValid() ? (
              cfModal ? (
                <>
                  <input type="submit" value="Submit" className="btn center" />
                  {error && (
                    <div className="red-text submit-error">{error}</div>
                  )}
                </>
              ) : (
                <CfModal
                  title="Submit"
                  visible={cfModalVisible}
                  setVisible={setCfModalVisible}>
                  <div className="container--sm cf-modal">
                    <p className="title3">
                      On the questionnaire for the Professional Recertification
                      Program, you chose the following as your status:
                    </p>
                    <p className="title4">
                      {mapping.clientFacing[clientFacing]}
                    </p>
                    <p>
                      If you wish to adjust your status at this time, you can
                      return to the questionnaire, but if everything looks good
                      you can move forward to the next step. Once you move
                      forward your status can no longer be updated.
                    </p>
                    <div className="buttons">
                      <button
                        type="button"
                        className="btn secondary large"
                        onClick={() => {
                          setCfModal(true);
                          setCfModalVisible(false);
                        }}>
                        Return to Questionnaire
                      </button>
                      <input
                        type="submit"
                        value="Continue to Next Step"
                        className="btn"
                      />
                    </div>
                    {error && (
                      <div className="red-text submit-error">{error}</div>
                    )}
                  </div>
                </CfModal>
              )
            ) : (
              <input
                type="submit"
                value="Submit"
                className="btn center disabled"
                disabled
              />
            )}
          </form>
        )}
      </div>
    </Layout>
  );
}

function CfModal({ title, children, visible, setVisible }) {
  return (
    <>
      <button
        type="button"
        onClick={() => setVisible(true)}
        className="btn center">
        {title}
      </button>

      {visible && (
        <div className={visible ? 'modal active' : 'modal'}>
          <div className="modalContent scrollable">{children}</div>
        </div>
      )}
    </>
  );
}

function useRadios(initialValue) {
  const [values, setValues] = useState(initialValue);

  function handleChange(position) {
    const newValues = values.map((item, index) =>
      index === position ? true : false,
    );

    setValues(newValues);
  }

  return [values, handleChange];
}

// For a set of yes/no radio button questions
// The state is an array of true/false where each question maps to one index
function useRadioSet(initialValue) {
  const [values, setValues] = useState(initialValue);

  function handleChange(position, value) {
    const newValues = values.map((item, index) =>
      index === position ? value : item,
    );

    setValues(newValues);
  }

  return [values, handleChange];
}

function useInput(initialValue) {
  if (!initialValue) initialValue = '';

  const [value, setValue] = useState(initialValue.trim());

  function handleChange(e) {
    setValue(e.target.value.trim());
  }

  return [value, handleChange];
}

// For a set of textareas where we don't know how many there will be, one for each radio question
// The state is an array of strings, each string will be associated with the radio question of the
// same index
function useInputSet(initialValue) {
  const [values, setValues] = useState(initialValue);

  function handleChange(e, position) {
    const newValues = values.map((item, index) =>
      index === position ? e.target.value : item,
    );

    setValues(newValues);
  }

  return [values, handleChange];
}

const questions = [
  {
    text: 'In the past 12 months, have you been found to be in violation of, pled no contest to, stipulated to any form of violation of, or settled any proceeding involving an insurance law, regulation or rule?',
    id: '2',
  },
  {
    text: 'In the past 12 months, have you been found to be in violation of, pled no contest to, stipulated to any form of violation of, settled any proceeding involving a securities law, regulation or rule?',
    id: '3',
  },
  {
    text: 'In the past 12 months, have you had a professional, occupational and/or vocational license refused, suspended, or revoked?',
    id: '4',
  },
  {
    text: 'In the past 12 months, have you been named as a defendant in a civil lawsuit or administrative hearing brought by any public or governmental licensing agency or regulatory authority (e.g. NASD, FINRA, SEC, etc.) for violation of, or to prevent the violation of, any securities or insurance law, rule or regulation?',
    id: '5',
  },
  {
    text: 'Have any of the following happened to you in the past 12 months: terminated, resigned after being told you would be terminated, left by mutual agreement following charges or allegations of misconduct?',
    id: '6',
  },
  {
    text: 'In the past 12 months have you received a written warning, been officially reprimanded, suspended, or disciplined for misconduct in the workplace, such as a violation of conduct or ethics policy?',
    id: '7',
  },
  {
    text: 'In the past 12 months, to your knowledge are you currently charged with any activity, proceeding, or possible judgements or are you engaged in any activity whatsoever which is in violation of your commitment to The American College of Financial Services Code of Ethics?',
    id: '8',
  },
];
