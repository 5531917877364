import { useState, useEffect} from 'react';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import ReadStorage from '../../components/storage/ReadStorage';
import { useMutation } from 'react-query';
import * as dayjs from 'dayjs';

export function ResourceItem({resource, saved}) {
    const [isSavedResource, setIsSavedResource] = useState(0);
    const oktaProfile = ReadStorage('oktaProfile');
    const { save: postRequest } = useTacfsService();
    const { mutate } = useMutation((params) =>
        postRequest('savedResources', params),
      );
    const iconAltText = ["Click icon to save this Resource", "Click icon to remove this Saved Resource"] 
    // Default to the external link if not null, otherwise URL of resource on mktg site
    const itemUrl = resource.field_resource_external_link??resource.url;
    
    // TODO: At a future point we will need to add ability to remove favorite.
    useEffect(()=>{
        setIsSavedResource(typeof(saved)==='boolean'?saved:saved.includes(resource.nid));
    }, [resource,saved]);
    
    const saveResource = (nid) => {
        const params =  {
            oktaID:oktaProfile.id,//data.oktaID,
            nid:nid
        };
        mutate(params, {
          onError: (res) =>
            console.log('We encountered an error saving resource'),
          onSuccess: (data) => {
            console.log(data);
            if (data.code === 201) { 
              //Resource has been created or published.
                setIsSavedResource(true);
                console.log(data.status);
              //TODO: Call for saved resources to be updated
            }
            if (data.code === 200) {
              //saved resource has been unpublished. 
              console.log(data.status);
                setIsSavedResource(false);
            }
          },  
        });
    };
    const imgUrl = resource.field_resource_teaser_image === null? "/images/rt-knowledgehub-general.png": resource.field_resource_teaser_image.url;
  
    return (
        <div className="resourceCell">
            <div className="resourceCover">
                <a href={itemUrl} target="_blank" rel="noreferrer">
                        <img src={imgUrl} alt={"Teaser Image for " + resource.title} className="resourceImg" />
                </a>
                 <img 
                  className={`saveResourceIcon${isSavedResource?" savedResource":""}`}
                  onClick={() => saveResource(resource.nid)}
                  src={`/icons/heart-icon${isSavedResource?"-clicked":""}.svg`}
                  alt={isSavedResource?iconAltText[1]:iconAltText[0]}
                  title={isSavedResource?iconAltText[1]:iconAltText[0]}
                /> 
            </div>
            <div className="infoRow resource-all-caps">
                <span>{dayjs(resource.field_resource_published_date).format('MMM DD, YYYY')}</span> 
                {resource.field_resource_content_tag[0] &&
                    <>
                     &nbsp;| <span className="tag">{ resource.field_resource_content_tag[0] }</span>
                    </>
                }
            </div>
            <a href={itemUrl} target="_blank" rel="noreferrer">
                <h1 className="headline">{resource.title}</h1>
            </a>
            { resource.field_resource_authors.length > 0
                ? <h2 className="byline">By {resource.field_resource_authors.join(", ")}</h2>
                : ""}
        </div>   
    );
}