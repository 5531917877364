import ReactGA from "react-ga4";

// Recommended Events
const login = () => ReactGA.event("Login", {method:"default"});

// Custom Events
const gradAppSubmission = (designation) => ReactGA.event({
    action: "GradApp Submission",
    category: "Custom",
    label: designation,
});

const testEvent = (anyString) => ReactGA.event({
    action: "test_event",
    category: "testing",
    label: "I am a Test: "+anyString,
});

// Include all of the above in this export obj.
const gaEvents = {
    eventLogin: login,
    eventGradAppSubmission: gradAppSubmission,
    testEvent:testEvent
};
export { gaEvents };