import axios from 'axios';

// The base request used to get query data.
const useOktaRequest = () => {
  const OktaAxiosClient = (() => {
    return axios.create({
      baseURL: process.env.REACT_APP_OKTA_API_ENDPOINT,
      timeout: 60000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
  })();

  // Success hanlder for the request
  const onSuccess = function (response) {
    if (response.status === 200) {
      const { data } = response;
      return data;
    }

    return Promise.reject(response);
  };
  // Catch the error response.
  const onError = function (error) {
    // if an error was returned but not response was set then we must manually set it.
    if (typeof error.response == 'undefined')
      error.response = {
        data: { message: 'There was an error contacting the server!' },
      };
    // TODO: we re-verify the session if we get a 403 access denied error.
    else if (error.response.status === 403) {
      error.response = {
        data: {
          summary: error.response.data.errorSummary,
          cause: error.response.data.errorCauses[0]?.errorSummary,
        },
      };
    } else if (error.response.status === 404)
      error.response = { data: { message: 'Process Not Found!' } };
    // return promise.
    return Promise.reject(error.response);
  };
  const request = async function (options) {
    // Adding the axios client.
    return OktaAxiosClient(options).then(onSuccess).catch(onError);
  };
  return [request];
};

export default useOktaRequest;
