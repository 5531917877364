import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import parse from 'html-react-parser';
import ReadStorage from '../storage/ReadStorage';
import SetStorage from '../storage/SetStorage';

/**
 * SiteNotificationBanner - Displays notifications set on Marketing site.
 * Messages should be dismissable and set in local storage or a cookie so they
 * don't appear again while on same device/browser/session.
 * Should work the same as on marketing site.
 */
export function SiteNotificationBanner() {
  const { load } = useTacfsService();
  const [notifications, setNotifications] = useState([]);
  const { isLoading, data: notificationData } = useQuery(
    ['notificationFeed'],
    () => load('notificationFeed'),
  );

  useEffect(() => {
       if (!isLoading && notificationData) {
//           console.log("We have data!",notificationData);
           setNotifications(notificationData);
       }
  }, [isLoading,notificationData]);

  return (
    <>
      {notifications.length > 0 &&
        notifications.map((notification, i) => {
          return <Notification notification={notification} key={i} />;
        })}
    </>
  );
}

function Notification({ notification }) {
  const {
    id,
    field_notif_banner_body: body,
    field_notif_banner_label: bannerLabel,
    field_notif_banner_mobile_body: mobileBody,
    field_notif_banner_link: link,
  } = notification;
  
  const mobileDisplayMsg = mobileBody.length >0?mobileBody:body;
  
  const [visible, setVisible] = useState(false);
  const wasDismissed = JSON.parse(
    ReadStorage(`dismissedNotification_${id}`) || '0',
  );
  //TODO: Put this in its own component to manage an array of IDs rather than creating a new key/value per notification.
  useEffect(() => {
    !wasDismissed ? setVisible(true) : setVisible(false);
  }, [wasDismissed]);

  return (
    <>
      {visible && (
        <div className="notification">
          <div
            className="close"
            onClick={() => {
              setVisible(false);
              SetStorage(`dismissedNotification_${id}`, 1);
            }}>
            &times;
          </div>
          <div className="container--lg notif-wrapper">
            <p className="eyebrow">{bannerLabel}</p>
            <p className="desktop">{parse(body)}</p>{' '}
            <p className="mobile">{parse(mobileDisplayMsg)}</p>{' '}
            {link != null && (
              <a
                className="desktop notification-bar-link"
                href={link.url}
                target='_blank'>
                {link.title}
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
}
