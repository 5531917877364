import { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { decode, encode } from 'html-entities';
import { Formik/*, Form*/ } from 'formik';
import * as Yup from 'yup';
import { OktaSSOLink } from '../../_okta/config/OktaSSO';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import { WarningIcon } from '../subcomponents/Icon';
import {
  FormikCheckbox,
  FormikText,
  FormikSelect,
  FormikDatePicker,
} from '../subcomponents/InputField';

import mapping from '../../data/selectValuesMapping.json';
import selectOptions from '../../data/selectOptions.json';

export function CEChart({ ceCourses, studentCE }) {
  const { save } = useTacfsService();
  const ceStartYear = dayjs(studentCE.tac_ce_begin_dt).format('YYYY');
  const ceEndYear = dayjs(studentCE.tac_ce_end_dt).format('YYYY');
//  const recertDate = dayjs(studentCE.tac_ce_end_dt).format('MMMM D, YYYY');

  const [error, setError] = useState('');
  const categoryOptions = selectOptions.category;

  // Use to keep track of whether the edit and delete modals for each employment
  // record is visible
  // First set will set visibility of one modal by index,
  // second set will set entire array of modal visibilities
  const [editModals, setOneEditModals/*, setEditModals*/] = useModals(
    Array(ceCourses.length).fill(false),
  );
  const [deleteModals, setOneDeleteModals/*, setDeleteModals*/] = useModals(
    Array(ceCourses.length).fill(false),
  );

  useEffect(() => {
    if (editModals.includes(true) || deleteModals.includes(true)) {
      document.querySelector('body').classList.remove('overflow-auto');
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
      document.querySelector('body').classList.add('overflow-auto');
    }
  }, [editModals, deleteModals]);

  const { isLoading: exCELoading, mutate: externalCEMutate } = useMutation(
    (values) => save('externalCE', values),
  );

  const editCESubmit = async (values) => {
    setError('');

    let params = { ...values };

    // initial_entry = 1 if modify or delete
    params.initial_entry = '1';
    params.period_begin_date = dayjs(values.period_begin_date).format(
      'YYYY-MM-DD',
    );
    params.year_display = dayjs(values.date_earned).format('YYYY');
    params.institution = encode(values.institution);
    params.catalog_number = '';
    params.delete_flag = 'N';
    params.ce_crd_earned = params.ce_crd_earned.toString();
    params.eth_crd_earned = params.eth_crd_earned.toString();
    delete params.index;
    delete params.report_ce_check;

    externalCEMutate(params, {
      onError: (res) => setError(res.toString()),
      onSuccess: (data) => {
        setOneEditModals(values.index, false);
        window.location.reload();
      },
    });
  };

  function handleDelete(course) {
    setError('');

    const values = {
      // initial_entry = 1 if modify or delete
      initial_entry: '1',
      period_begin_date: dayjs(course.period_begin_date).format('YYYY-MM-DD'),
      year_display: dayjs(course.date_earned).format('YYYY'),
      date_earned: dayjs(course.date_earned).format('YYYY-MM-DD'),
      institution: course.provider,
      catalog_number: '',
      seq_number: course.seq_number,
      course_title: course.course,
      ce_crd_earned: course.credits,
      eth_crd_earned: course.ethics_credits,
      category_number: course.category_number,
      delete_flag: 'Y',
    };

    externalCEMutate(values, {
      onError: (res) => setError(res.toString()),
      onSuccess: (data) => {
        window.location.reload();
      },
    });
  }

  return (
    <div className="ce-chart container">
      <h4>
        {ceStartYear}-{ceEndYear} Reporting Cycle
      </h4>

      <div>
        <span className="font-semibold">Total CE Reported:</span>{' '}
        {studentCE.tac_ce_crd_earned}
      </div>
      <div>
        <span className="font-semibold">Ethics CE Reported:</span>{' '}
        {studentCE.tac_eth_crd_earned}
      </div>
      {studentCE.tac_ce_msg && (
        <p className="p4 recert-notif">
          <span>
            <WarningIcon />
          </span>
          <span>{studentCE.tac_ce_msg}</span>
        </p>
      )}

      <div className="chart-table">
        <table className="table-auto">
          <thead>
            <tr>
              <th scope="col">Course</th>
              <th scope="col">Provider</th>
              <th scope="col">Category</th>
              <th scope="col">Credits</th>
              <th scope="col">Ethics Credits</th>
              <th scope="col">Date Earned</th>
            </tr>
          </thead>
          <tbody>
            {ceCourses.sort(sortCourses).map((course, i) => {
              const nonTAC = course.ce_type === 'E';

              return (
                <tr key={i} className={nonTAC ? 'has-edit' : 'no-edit'}>
                  <td>
                    {course.course}
                    {nonTAC && (
                      <div className="edit-chart modal-wrapper">
                        <EditModal
                          title="Edit"
                          visible={editModals[i]}
                          setVisible={setOneEditModals}
                          index={i}>
                          <div className="padding--sm container">
                            <h2>Edit CE for {course.course}</h2>

                            <Formik
                              initialValues={{
                                date_earned: dayjs(course.date_earned).format(
                                  'YYYY-MM-DD',
                                ),
                                institution: decode(course.provider),
                                course_title: course.course,
                                ce_crd_earned: course.credits,
                                eth_crd_earned: course.ethics_credits,
                                category_number: course.category_number,
                                report_ce_check: false,
                                index: i,
                                seq_number: course.seq_number,
                                period_begin_date: course.period_begin_date,
                              }}
                              validationSchema={EditValidationSchema}
                              onSubmit={(values) => {
                                editCESubmit(values);
                              }}>
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                              }) => (
                                <form
                                  onSubmit={handleSubmit}
                                  id={`editCEForm${i}`}>
                                  <p>
                                    Please note that the total of your reported
                                    CE may exceed your requirements for the
                                    current reporting period. If that is the
                                    case, excess CE will NOT be carried over
                                    into the next period and cannot be reported
                                    again.
                                  </p>
                                  <h3>Course Information</h3>

                                  <div className="column-layout halves">
                                    <div className="field col">
                                      <FormikText
                                        label="Course/Program Name"
                                        type="text"
                                        id="course_title"
                                        name="course_title"
                                        value={values.course_title}
                                        onChange={handleChange}
                                        formikRequired
                                      />
                                    </div>
                                    <div className="field col">
                                      <FormikText
                                        label="Provider"
                                        type="text"
                                        id="institution"
                                        name="institution"
                                        value={values.institution}
                                        onChange={handleChange}
                                        formikRequired
                                      />
                                    </div>
                                  </div>

                                  <div className="column-layout halves">
                                    <div className="field col">
                                      <FormikText
                                        label="Credits"
                                        type="text"
                                        id="ce_crd_earned"
                                        name="ce_crd_earned"
                                        value={values.ce_crd_earned}
                                        onChange={handleChange}
                                        formikRequired
                                      />
                                    </div>
                                    <div className="field col">
                                      <FormikText
                                        label="Ethic Credits"
                                        type="text"
                                        id="eth_crd_earned"
                                        name="eth_crd_earned"
                                        value={values.eth_crd_earned}
                                        onChange={handleChange}
                                        formikRequired
                                      />
                                    </div>
                                  </div>

                                  <div className="column-layout halves">
                                    <div className="field col">
                                      <FormikDatePicker
                                        label="Date Earned"
                                        id="date_earned"
                                        name="date_earned"
                                        value={values.date_earned}
                                        onChange={handleChange}
                                        formikRequired
                                      />
                                    </div>
                                  </div>

                                  <h3>3 Categories of Acceptable CE</h3>

                                  <div className="category">
                                    <div className="small-headline black">
                                      Category 1
                                    </div>
                                    <p>
                                      Programs pre-approved for CE credits by
                                      the CFP Board of Standards, State
                                      Insurance Commission, State Bar or State
                                      Board of Accountancy are accepted by the
                                      Professional Recertification Program.
                                    </p>
                                  </div>

                                  <div className="category">
                                    <div className="small-headline black">
                                      Category 2
                                    </div>
                                    <p className="mb-0">
                                      Pass an examination in the recertification
                                      period for:
                                    </p>
                                    <ol>
                                      <li>
                                        A course in The American College Huebner
                                        School or Graduate School program or
                                        another recognized professional
                                        designation program (30 hours per
                                        course); or
                                      </li>
                                      <li>A FINRA license (30 hours); or</li>
                                      <li>
                                        The CFP® certification (30 hours); or
                                      </li>
                                      <li>
                                        A college or university course from
                                        which credit can be earned toward a
                                        degree (15 hours for each semester
                                        credit hour or its equivalent).
                                      </li>
                                    </ol>
                                  </div>

                                  <div className="category">
                                    <div className="small-headline black">
                                      Category 3
                                    </div>
                                    <p>
                                      Live/Virtual: Attend an educational
                                      program or meeting that has at least one
                                      (1) credit hour (50 minutes of attendance
                                      equals one credit hour).
                                    </p>
                                  </div>

                                  <div className="field flex-column category-field">
                                    <FormikSelect
                                      label="Category"
                                      id="category_number"
                                      name="category_number"
                                      value={values.category_number}
                                      onChange={handleChange}
                                      formikRequired>
                                      {categoryOptions.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </FormikSelect>
                                  </div>

                                  <ul className="checkboxes">
                                    <li>
                                      <FormikCheckbox
                                        name="report_ce_check"
                                        id="report_ce_check">
                                        I attest to the above statements and
                                        certify that all information is true.
                                      </FormikCheckbox>
                                    </li>
                                  </ul>

                                  <div className="modal-buttons">
                                    <button
                                      type="button"
                                      className="btn secondary"
                                      onClick={() =>
                                        setOneEditModals(i, false)
                                      }>
                                      Cancel
                                    </button>
                                    {exCELoading ? (
                                      <button className="btn disabled" disabled>
                                        Submitting
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn"
                                        form={`editCEForm${i}`}>
                                        Submit
                                      </button>
                                    )}
                                  </div>
                                  {error && (
                                    <div className="red-text error">
                                      {error}
                                    </div>
                                  )}
                                </form>
                              )}
                            </Formik>
                          </div>
                        </EditModal>

                        <DeleteModal
                          title="Delete"
                          handleDelete={() => handleDelete(course, i)}
                          visible={deleteModals[i]}
                          setVisible={setOneDeleteModals}
                          index={i}>
                          <div className="container padding--sm">
                            <h3 className="text-center">
                              Are you sure you want to delete {course.course}?
                            </h3>
                            {error && (
                              <p className="red-text text-center">{error}</p>
                            )}
                          </div>
                        </DeleteModal>
                      </div>
                    )}
                  </td>
                  <td>{decode(course.provider)}</td>
                  <td>{mapping.category[course.category_number]}</td>
                  <td>{course.credits}</td>
                  <td>{course.ethics_credits}</td>
                  <td>{dayjs(course.date_earned).format('MM/DD/YYYY')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="chart-subtext">
        <p className="p3">
          If you have not fulfilled your Ethics Requirement, due December 31, 2024, please enroll in 
          <OktaSSOLink program="ce" course="0003331">
            CE 128
          </OktaSSOLink> or {' '} 
          <OktaSSOLink program="ce" course="0003331">
            CE 126
          </OktaSSOLink>.
        </p>
        <p className="p3">
          This course is FREE to all designees who recertify their
          designation(s) to satisfy the 1.00 hour ethics continuing education
          requirement.
        </p>
      </div>
    </div>
  );
}

const DeleteModal = ({
  title,
  children,
  linkClasses = 'underline-link',
  handleDelete,
  visible,
  setVisible,
  index,
}) => {
  return (
    <>
      <button onClick={() => setVisible(index, true)} className={linkClasses}>
        {title}
      </button>
      <div className={visible ? 'modal active' : 'modal'}>
        <div className="modalContent scrollable">
          {children}
          <div className="modal-buttons">
            <button
              className="btn secondary"
              onClick={() => setVisible(index, false)}>
              Cancel
            </button>
            <button
              className="btn"
              onClick={() => {
                handleDelete();
                setVisible(index, false);
              }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const EditModal = ({
  title,
  children,
  linkClasses = 'underline-link',
  visible,
  setVisible,
  index,
}) => {
  return (
    <>
      <button onClick={() => setVisible(index, true)} className={linkClasses}>
        {title}
      </button>
      <div className={visible ? 'modal active' : 'modal'}>
        <div className="modalContent scrollable">
          <div className="close" onClick={() => setVisible(index, false)}>
            <span role="img" aria-label="close modal">
              &times;
            </span>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

function useModals(initialValue) {
  const [values, setValues] = useState(initialValue);

  function handleOne(position, value) {
    const newValues = values.map((item, index) =>
      index === position ? value : item,
    );

    setValues(newValues);
  }

  return [values, handleOne, setValues];
}

const sortCourses = (a, b) => {
  return dayjs(a.date_earned).isBefore(dayjs(b.date_earned)) ? -1 : 1;
};

const today = dayjs();
const thisYear = dayjs().startOf('year');
const lastYear = dayjs().endOf('year').subtract(1, 'year');

const EditValidationSchema = Yup.object().shape({
  institution: Yup.string().required('Provider is required.'),
  course_title: Yup.string().required('Course name is required.'),
  ce_crd_earned: Yup.number()
    .typeError('Credits earned must be a number.')
    .min(0, 'Credits earned must be at least 0.')
    .max(9, 'Credits earned must be at most 9.')
    .required('Credits earned is required.'),
  eth_crd_earned: Yup.number()
    .typeError('Ethics credits earned must be a number.')
    .min(0, 'Ethics credits earned must be at least 0.')
    .max(9, 'Ethics credits earned must be at most 9.')
    .required('Ethics credits earned is required.'),
  category_number: Yup.string().required('Category is required.'),
  date_earned: Yup.date()
    .when('category_number', {
      is: (category_number) => category_number === '7',
      then: () =>
        Yup.date()
          .max(lastYear, 'Carryover credits must be from a previous year.')
          .required('Date earned is required.'),
    })
    .when('category_number', {
      is: (category_number) => category_number !== '7',
      then: () =>
        Yup.date()
          .min(thisYear, 'Credits must be from this year.')
          .max(today, 'Date earned must not be later than today.')
          .required('Date earned is required.'),
    }),
  report_ce_check: Yup.boolean().oneOf(
    [true],
    'You must agree to the attestation.',
  ),
});
