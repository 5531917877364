import { useEffect } from 'react';
import { Layout } from '../components/Layout';
import postscribe from 'postscribe';

//

export function CookiePage() {
    
    useEffect(() => {
        const elem = document.querySelector("#cookie-declaration");
        if(elem.childNodes.length === 0){
            postscribe('#cookie-declaration', '<script id="CookieDeclaration" src="https://consent.cookiebot.com/a7f92c81-4da1-49bd-88d0-fce4ae820190/cd.js" type="text/javascript" async></script>');
        }
    }, []);
    
    
    
    return (
    <Layout>
      <div className="container dashboard">
        <div id="cookie-declaration">
        </div>
      </div>
    </Layout>
  );
}

