import { useRef, useEffect } from 'react';
import { Formik, Field } from 'formik';

import * as Yup from 'yup';
import { Modal } from '../subcomponents/Modal';

import { getCurrentYears, months, days } from '../../utils/helper';

export function DegreeModalForm(props) {
  const modalRef = useRef();
  const formikRef = useRef();

  const onClose = () => {
    props.close(false);
    // we want to reset
    // the from for
    // subsequent calls.
    formikRef.current.resetForm();
  };

  const formSubmit = (values) => {
    const currentValues = props.degrees;
    if (props.index === false) {
      currentValues.push(values);
      props.setDegrees(currentValues);
    } else {
      currentValues[props.index] = values;
    }
    onClose();
  };

  useEffect(() => {
    if (props.open != null) modalRef.current.toggleOpen(props.open);
  }, [modalRef, props]);

  return (
    <Modal
      title="Add School Information"
      linkClasses="t-accent strong"
      showLink={false}
      ref={modalRef}
      onClose={onClose}>
      <h4>COLLEGE OR UNIVERSITY INFORMATION</h4>
      <Formik
        initialValues={{
          name: props.index !== false ? props.degrees[props.index].name : '',
          yearFrom:
            props.index !== false ? props.degrees[props.index].yearFrom : '',
          yearTo:
            props.index !== false ? props.degrees[props.index].yearTo : '',
          certificate:
            props.index !== false ? props.degrees[props.index].certificate : '',
          awardMonth:
            props.index !== false ? props.degrees[props.index].awardMonth : '',
          awardDay:
            props.index !== false ? props.degrees[props.index].awardDay : '',
          awardYear:
            props.index !== false ? props.degrees[props.index].awardYear : '',
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        innerRef={formikRef}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="schoolModalForm">
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="name">
                  Name of school<span>*</span>
                </label>
                <Field
                  name="name"
                  type="text"
                  id="name"
                  placeholder="Name of school*"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <span className="red-text">{errors.name}</span>
                )}
              </div>
            </div>
            <div>
              <label>
                Years attended<span>*</span>
              </label>
              <div className="form-layout">
                <div className="field col">
                  <label htmlFor="yearFrom">
                    From<span>*</span>
                  </label>
                  <Field
                    name="yearFrom"
                    id="yearFrom"
                    as="select"
                    value={values.yearFrom}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {getCurrentYears(50).map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {touched.yearFrom && errors.yearFrom && (
                    <span className="red-text">{errors.yearFrom}</span>
                  )}
                </div>
                <div className="field col">
                  <label htmlFor="yearTo">
                    To<span>*</span>
                  </label>
                  <Field
                    name="yearTo"
                    id="yearTo"
                    as="select"
                    value={values.yearTo}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {getCurrentYears(50).map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {touched.yearTo && errors.yearTo && (
                    <span className="red-text">{errors.yearTo}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="name">
                  Degree or certificate awarded<span>*</span>
                </label>
                <Field
                  name="certificate"
                  type="text"
                  id="certificate"
                  placeholder="Degree or certificate awarded*"
                  value={values.certificate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.certificate && errors.certificate && (
                  <span className="red-text">{errors.certificate}</span>
                )}
              </div>
            </div>

            <label htmlFor="yearFrom">
              Date certificate awarded<span>*</span>
            </label>
            <div className="form-layout">
              <div className="field col--third">
                <Field
                  name="awardMonth"
                  id="awardMonth"
                  as="select"
                  value={values.awardMonth}
                  onBlur={handleBlur}
                  onChange={handleChange}>
                  {months.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                {touched.awardMonth && errors.awardMonth && (
                  <span className="red-text">{errors.awardMonth}</span>
                )}
              </div>
              <div className="field col--third">
                <Field
                  name="awardDay"
                  id="awardDay"
                  as="select"
                  value={values.awardDay}
                  onBlur={handleBlur}
                  onChange={handleChange}>
                  {days.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                {touched.awardDay && errors.awardDay && (
                  <span className="red-text">{errors.awardDay}</span>
                )}
              </div>
              <div className="field col--third">
                <Field
                  name="awardYear"
                  id="awardYear"
                  as="select"
                  value={values.awardYear}
                  onChange={handleChange}
                  onBlur={handleBlur}>
                  {getCurrentYears(50).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                {touched.awardYear && errors.awardYear && (
                  <span className="red-text">{errors.awardYear}</span>
                )}
              </div>
            </div>

            <button type="submit" form="schoolModalForm" className="btn col">
              Submit
            </button>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  yearFrom: Yup.string().required('Required'),
  yearTo: Yup.string().required('Required'),
  certificate: Yup.string().required('Required'),
  awardMonth: Yup.string().required('Required'),
  awardDay: Yup.string().required('Required'),
  awardYear: Yup.string().required('Required'),
});
