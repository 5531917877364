import { Link } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import ResourcesTabs from '../components/resources/ResourcesTabs';

export function Resources() {
  return (
    <Layout>
      <Breadcrumbs />
      <div className="container resources">
        <div className="intro-content-wrapper">
          <h2 className="lg:w-1/2">Resource Library</h2>
          <p className="lg:w-1/2">
            This dashboard view collates all of your student orientation,
            alumni, and saved resources in one place for easy viewing.
          </p>
        </div>

        <ResourcesTabs />

        <div className="resources-callout column-layout halves">
          <div className="col title2">
            Looking for more resources?
          </div>
          <div className="col">
            <p>
              As the preeminent institution for applied financial knowledge and
              education, The College offers an extensive library of free
              resources through its Knowledge Hub.
            </p>
            <div className="buttons">
              <Link
                to= {{ pathname: `${process.env.REACT_APP_MARKETING_URL}knowledge-hub` }}
                target="_blank"
                className="btn tertiary"
              >
                View the Knowledge Hub
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
