import selectOptions from '../../../data/selectOptions.json';
import { validateAgainstOptions } from '../../global/AnnualInfoLock';
import Accordion from '../../subcomponents/Accordion';
import { useState, useEffect  } from 'react';

export default function DemographicInfoForm({values, errors, touched, handleChange, handleBlur, presetVals}) {
    const [errorInSection,setErrorInSection] = useState(false);
    const citizenshipOptions = selectOptions.citizenship;
    const raceOptions = selectOptions.race;
    const ethnicityOptions = selectOptions.ethnicity;
    const militaryOptions = selectOptions.military;
    
    // Check here to make sure the values sent down from PS are considered valid by the data. 
    const validCitizenship = validateAgainstOptions(values.citizenship, citizenshipOptions);
    const validRace = validateAgainstOptions(values.race, raceOptions);
    const validMilitary = validateAgainstOptions(values.military, militaryOptions);
    const validEthnicity = validateAgainstOptions(values.ethnicity, ethnicityOptions);
    
    // If the option is invalid, reset it to force the user to select a valid option
    if(!validCitizenship ) { values.citizenship = ""; presetVals.citizenship = false;}
    if(!validRace ) { values.race = ""; presetVals.race = false;}
    if(!validMilitary ) { values.military = ""; presetVals.military = false;}
    if(!validEthnicity ) { values.ethnicity = ""; presetVals.ethnicity = false;}
    
    // If everything is A-OK, hide the section.
    const hideSection = presetVals.citizenship && 
                        presetVals.race && 
                        presetVals.military &&
                        presetVals.ethnicity &&
                        validCitizenship  && validRace && validMilitary &&  validEthnicity; 
    
    
    /* highlight section title if any fields in this section have thrown an error via yup! */
    useEffect(()=>{
        const fieldArray = ["citizenship", "race", "ethnicity", "military"];
        const matchingErrors = Object.keys(errors).filter(element => fieldArray.includes(element));
//        console.log(matchingErrors);
        if(matchingErrors.length > 0){
            setErrorInSection(true);
        } else {
            setErrorInSection(false);
        }
        
    },[errors]);
    
    if(hideSection){return <></>}
    
    return (
     <Accordion id="test3"  title=<h4 className={errorInSection?'error red-text':''}>Demographic Information</h4> closeTitle=<h4 className={errorInSection?'error red-text':''}>Demographic Information</h4> iconElementClose='title3' 
        iconElementOpen='title4' >
      {(!presetVals.citizenship || !validCitizenship) && (<> 
      <div className="column-layout">
        <div className="col">
         <div className="field">
            <label htmlFor="citizenship-field">Citizenship <span className="req-star">*</span></label>
            <select
                id="citizenship-field"
                name="citizenship"
                value={values.citizenship}
                onChange={handleChange}
                required>
                {citizenshipOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              { errors.citizenship && (
                  <span className="red-text">{errors.citizenship}</span>
                )}
        </div></div></div>
        </>)
       }
       {(!presetVals.race || !validRace) && (<>   
       <div className="column-layout">
        <div className="col">
         <div className="field">
            <label htmlFor="race-field">Race <span className="req-star">*</span></label>
            <select
                id="race-field"
                name="race"
                value={values.race}
                onChange={handleChange}
                required>
                {raceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.race && (
                  <span className="red-text">{errors.race}</span>
                )}
          </div></div></div>
        </>)
       }
       {(!presetVals.ethnicity || !validEthnicity) && (<>  
       <div className="column-layout">
        <div className="col">
         <div className="field">
            <label htmlFor="ethnicity-field">Ethnicity <span className="req-star">*</span></label>
            <select
                id="ethnicity-field"
                name="ethnicity"
                value={values.ethnicity}
                onChange={handleChange}
                required>
                {ethnicityOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.ethnicity && (
                  <span className="red-text">{errors.ethnicity}</span>
                )}
          </div></div></div>
        </>)
       }
       {(!presetVals.military || !validMilitary) && (<>   
       <div className="column-layout">
        <div className="col">
         <div className="field">
            <label htmlFor="military-field">Military Status <span className="req-star">*</span></label>
            <select
                id="military-field"
                name="military"
                value={values.military}
                onChange={handleChange}
                required>
                {militaryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.military && (
                  <span className="red-text">{errors.military}</span>
                )}
          </div></div></div>
        </>)
       }
    </Accordion>
    );
}
