import { useRef, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Modal } from '../../subcomponents/Modal';
import Timer from '../../subcomponents/Timer';

import useTacfsService from '../../../utils/tacfs/useTacfsService';

import dayjs from 'dayjs';

export function TranscriptDetailModal(props) {
  const modalRef = useRef();
  const [data, setData] = useState(false);

  // Load the tacfs helper Service Worker.
  const { save } = useTacfsService();
  const { isLoading, mutate } = useMutation((values) =>
    save('statefarmDetailReport', values),
  );

  // we want to clear the form and data.
  const clear = () => {
    // Reset the data
    setData(false);
    props.close();
  };

  useEffect(() => {
    if (props.rowIndex) {
      modalRef.current.toggleOpen(true);
      if (isLoading === false && data === false) {
        mutate(
          { emplId: props.rowIndex.emp_id },
          {
            onError: (res) => console.log(res),
            onSuccess: (data) => {
              if (data) {
                // Save the report data.
                setData(data);
              } 
            },
          },
        );
      }
    } else {
      modalRef.current.toggleOpen(false);
    }
  }, [modalRef, props, isLoading, mutate, data]);

  return (
    <Modal
      title="Add School Information"
      linkClasses="t-accent strong"
      showLink={false}
      ref={modalRef}
      onClose={clear}
    >
      {isLoading && <Timer />}
      {data && (
        <div id="companyReportModalContent" className="modal-body">
          <h2>Student Data</h2>
          <div>
            <div>
              <p>The data used for these reports is updated nightly.</p>
              <p>
                <span className="report-header">Student Name:</span> {data.name}
              </p>
              <p>
                <span className="report-header">Student ID:</span> {data.emplid}
              </p>

              <h4 className="section-header">Primary Contact Information</h4>
              <div className="report-section address-section">
                <div className="flex-section-1">
                  <p>
                    <span className="report-header">Address:</span>
                  </p>
                  <p>{data.address1}</p>
                  <p>{data.address2}</p>
                  <p>{data.address3}</p>
                  <p>
                    {data.city}, {data.state} {data.postal}
                  </p>
                </div>
                <div className="flex-section-2">
                  <p>
                    <span className="report-header">Phone:</span> {data.phone_primary}
                  </p>
                </div>
                <div className="flex-section-3">
                  <p>
                    <span className="report-header">Email:</span> {data.email_primary}
                  </p>
                </div>
              </div>

              <h4 className="section-header">Designations</h4>
              <div className="report-section">
                <div className="flex-section-50">
                  <p>
                    <span className="report-header">Completed:</span>
                  </p>
                  <div>
                    {data.completed_plans &&
                      data.completed_plans.map((acad, key) => (
                        <p key={key}>
                          <strong>{acad.acad_plan}</strong> - enrolled on{' '}
                          {dayjs(acad.date).format('MM/DD/YY')}
                        </p>
                      ))}
                  </div>
                </div>

                <div className="flex-section-50">
                  <p>
                    <span className="report-header">Active:</span>
                  </p>
                  <div>
                    {data.active_plans &&
                      data.active_plans.map((acad, key) => (
                        <p key={key}>
                          <strong>{acad.acad_plan}</strong> - enrolled on{' '}
                          {dayjs(acad.date).format('MM/DD/YY')}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
              {data.transfer_of_credit && (
                <>
                  <h4 className="section-header">Tranfer Of Credit</h4>
                  <table className="table">
                    <thead className="hidden-xs">
                      <tr>
                        <th className="report-header" scope="col">
                          Course:
                        </th>
                        <th className="report-header" scope="col">
                          Course Name:
                        </th>
                        <th className="report-header" scope="col">
                          Class:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.transfer_of_credit.map((course, key) => (
                        <tr key={key}>
                          <td>
                            <p>{course.course}</p>
                          </td>
                          <td>
                            <p>{course.class_description}</p>
                          </td>
                          <td>
                            <p>{course.course_description}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {data.grades && (
                <>
                  <h4 className="section-header">Grades</h4>
                  <table className="table">
                    <thead className="hidden-xs">
                      <tr>
                        <th className="report-header" scope="col">
                          Course:
                        </th>
                        <th className="report-header" scope="col">
                          Registration Date:
                        </th>
                        <th className="report-header" scope="col">
                          Instruction Mode:
                        </th>
                        <th className="report-header" scope="col">
                          Grade:
                        </th>
                        <th className="report-header" scope="col">
                          Date:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.grades.map((grade, key) => (
                        <tr key={key}>
                          <td>
                            <p>{grade.course}</p>
                          </td>
                          <td>
                            <p>{dayjs(grade.enroll_date).format('MM/DD/YY')}</p>
                          </td>
                          <td>
                            <p>{grade.instruction_mode}</p>
                          </td>
                          <td>
                            <p>{grade.grade}</p>
                          </td>
                          <td>
                            <p>{dayjs(grade.grade_date).format('MM/DD/YY')}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
