import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';

import ToolTip from '../components/subcomponents/ToolTip';

import { DesignationReport } from '../components/companyPortal/DesignationReport';
import { EnrollmentReport } from '../components/companyPortal/EnrollmentReport';
import { PasslistReport } from '../components/companyPortal/PasslistReport';
import { TranscriptReport } from '../components/companyPortal/TranscriptReport';
import { ExpiredReport } from '../components/companyPortal/ExpiredReport';
import { FailedReport } from '../components/companyPortal/FailedReport';
import { MatriculationReport } from '../components/companyPortal/MatriculationReport';
import Timer from '../components/subcomponents/Timer';

export function CompanyPortal() {
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  const [activeReport, setActiveReport] = useState('');
  const [reports, setReports] = useState([]);
  const { isLoading, data } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );
  const { isLoading: reportingFlagsLoading, data: companyFlags } = useQuery(
    ['companyReportingFlags'],
    () => load('companyReportingFlags'),
  );

  useEffect(() => {
    if (reportingFlagsLoading === false && companyFlags) {
      const reportArray = [];
      if (companyFlags.designation_report === 'Y')
        reportArray.push('designation');
      if (companyFlags.enrollment_report === 'Y')
        reportArray.push('enrollment');
      if (companyFlags.pass_list_report === 'Y') reportArray.push('passlist');
      if (companyFlags.transcript_report === 'Y')
        reportArray.push('transcript');
      if (companyFlags.expired_exam_report === 'Y') reportArray.push('expired');
      if (companyFlags.fail_list_report === 'Y') reportArray.push('failed');
      if (companyFlags.matriculation_report === 'Y')
        reportArray.push('matriculation');
      setActiveReport(reportArray[0]);
      setReports(reportArray);
    }
  }, [companyFlags, reportingFlagsLoading]);

  const switchReport = (event) => setActiveReport(event.target.value);

  if (isLoading || reportingFlagsLoading)
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );
  // If no student is available that means the user does not have
  // access to this form.
  if (isLoading === false && (data === false || data.reporting_user !== 'Y'))
    return (
      <Layout>
        <Breadcrumbs />
        <h1>Access Denied!</h1>
      </Layout>
    );

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container">
        <div className="intro-content-wrapper">
          <h1>Company Reports</h1>
          <p>
            You can access the following reports to see the designation/degree
            status of students from your organization.
          </p>
          {reports.length > 0 && (
            <div className="field select-report">
              <label htmlFor="reports">Select a Report</label>
              <ToolTip>
                <p>
                  You can view reports showing designees, student course
                  enrollments, successful student course completions, and
                  individual student transcripts.
                </p>
              </ToolTip>
              <div className="form-layout form-select">
                <div className="col--quarter">
                  <select
                    name="reports"
                    id="reports"
                    onChange={switchReport}
                    value={activeReport}
                  >
                    {reports.map((report) => (
                      <option key={report} value={report}>
                        {report.charAt(0).toUpperCase() + report.slice(1)}{' '}
                        Report
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="report-display">
                {
                  {
                    designation: <DesignationReport />,
                    enrollment: <EnrollmentReport />,
                    passlist: <PasslistReport />,
                    transcript: <TranscriptReport />,
                    expired: <ExpiredReport />,
                    failed: <FailedReport />,
                    matriculation: <MatriculationReport />,
                  }[activeReport]
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
