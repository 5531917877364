import { Layout } from '../components/Layout';

import MfaEnroll from '../components/login/MfaEnroll';
import { ReflexBlueLogoImage } from '../components/subcomponents/Icon';

import ReadStorage from '../components/storage/ReadStorage';

export function MfaLogin() {
  const mfaEnroll = ReadStorage('mfa');

  return (
    <Layout>
      <div className="container password-reset-page">
        <div className="logo">
          <ReflexBlueLogoImage className="h-24" />
        </div>
        <div className="card-layout drop-box login-box">
          <MfaEnroll account={mfaEnroll} />
        </div>
        <p className="disclaimer pt-8">
          If you are having issues, please contact the American College of
          Financial Services at <a href="tel:8882637265">888-263-7265</a>.
        </p>
      </div>
    </Layout>
  );
}
