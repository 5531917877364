import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

export function LoginCallback() {
  const { oktaAuth } = useOktaAuth();
  const navigate = useHistory();

  if (oktaAuth.isLoginRedirect()) {
    // Parse token from redirect url
    oktaAuth.token.parseFromUrl().then((data) => {
      const { accessToken, idToken } = data.tokens;
      // Store parsed token in Token Manager
      oktaAuth.tokenManager.add('idToken', idToken);
      oktaAuth.tokenManager.add('accessToken', accessToken);
      // Navigate to the destination.
      navigate.push('/dashboard');
    });
  }
}
