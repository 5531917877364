import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { AuthDetails } from '../components/login/AuthDetails';
import Timer from '../components/subcomponents/Timer';
import LocalTimer from '../components/subcomponents/LocalTimer';
import classOptions from '../data/classOptions.json';
import { DesignationStatus } from '../components/designationsCertifications/DesignationStatus';

import { openBase64NewTab } from '../utils/helper';
import { BlueCheck, RedX } from '../components/subcomponents/Icon';

export function DesignationsCertifications() {
  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();
  const designationInfo = classOptions.designations;
  const [statusDisplay, setStatusDisplay] = useState('');
  const [recertify, setRecertify] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [showCouplrOffer, setShowCouplrOffer] = useState(true);
  const [couplrConfirmation, setCouplrConfirmation] = useState(false);
  const [ackCalled, setAckCalled] = useState(false);

  const { isLoading: designationsLoading, data: designationAppStatus } =
    useQuery(['designationAppStatus'], () => load('designationAppStatus'));

  const { isLoading: lettersLoading, data: letters } = useQuery(
    ['confirmationLetters'],
    () => load('confirmationLetters'),
  );
  const { isLoading: flagsLoading, data: studentFlags } = useQuery(
    ['studentFlags'],
    () => load('studentFlags'),
  );
  const { isLoading: studentCELoading, data: studentCE } = useQuery(
    ['studentCE'],
    () => load('studentCE'),
  );
  const { mutate: ackCouplr } = useMutation((params) =>
    save('couplrOptIn', params),
  );
  const openPdf = (name, pdfData) => {
    openBase64NewTab(decodeURIComponent(pdfData), name);
  };

  useEffect(() => {
    if (studentCE && Object.keys(studentCE).length > 0) {
      setRecertify(studentCE?.tac_dc_time_to_rec?.toUpperCase() === 'Y');
      setStatusDisplay(getRecertDisplayRevised(studentCE));
    }
  }, [studentCELoading, studentCE]);

  const {
    isLoading: completedDesignationsLoading,
    data: completedDesignations,
  } = useQuery(['completerKitInfo'], () => load('completerKitInfo'));

  const acknowledgeCouplrOffer = (accepted) => {
    if (ackCalled) {
      return;
    } //If the acknowledgement is in progress, don't do anything else.
    setAckCalled(true);
    setCouplrConfirmation(true);
    setShowCouplrOffer(false);
    //Now post to somewhere with the response (Y or N)
    const params = { couplr: accepted };
    ackCouplr(params, {
      onError: (res) => {
        console.log('We encountered an error acknowledging couplr');
        setAckCalled(false); //we had a failure, allow for the possibility of a resubmission
      },
      onSuccess: (data) => {
        load('studentFlags'); //pull down updated flag data
      },
    });
  };
  const closeCouplrOffer = () => {
    setCouplrConfirmation(false);
  };
  // Remove any designations from designationAppStatus if they are already in completerKitInfo
  useEffect(() => {
    if (
      !designationsLoading &&
      !completedDesignationsLoading &&
      Array.isArray(designationAppStatus) &&
      Array.isArray(completedDesignations)
    ) {
      const noDups = designationAppStatus.filter((program) => {
        for (let i = 0; i < completedDesignations.length; i++) {
          if (program.acad_plan === completedDesignations[i].acad_plan) {
            return false;
          }
        }
        return true;
      });

      setDesignations(noDups);
    } else {
      setDesignations(designationAppStatus);
    }
  }, [
    designationAppStatus,
    completedDesignations,
    designationsLoading,
    completedDesignationsLoading,
  ]);

  if (designationsLoading || studentCELoading || completedDesignationsLoading) {
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />
      <div className="designations-certifications">
        <div className="container">
          <div className="intro-content-wrapper">
            <AuthDetails page="designations" />
            <p className="page-intro-text lg:w-1/2">
              {studentCE && Object.keys(studentCE).length > 0 ? (
                <>
                  Your commitment to lifelong learning is demonstrated through
                  well-respected, well-recognized designations and
                  certifications earned at The College. Manage those
                  designations and certifications with the prompts below.
                </>
              ) : (
                <>
                  Your lifelong learning journey with The College is just
                  beginning. If you’re in the middle of a program, keep going!
                  If you want to start a new path, discover what learning at The
                  College can mean for you. We’re here to help!
                </>
              )}
            </p>
            {studentCE && Object.keys(studentCE).length > 0 && (
              <>
                {recertify && (
                  <div className="recertify page-intro-text">
                    <div className="recert-headline">
                      <img src="/icons/danger-icon.svg" alt="Danger Icon" />
                      <span className="title2">It's time to recertify!</span>
                    </div>
                    <Link
                      to="designations-certifications/recertification"
                      className="underline-link">
                      Complete recertification process here.
                    </Link>
                  </div>
                )}
                <div className="reinstate">
                  <p className="global-status recert-status-wrapper">
                    <span className="font-semibold">Status:</span>{' '}
                    {statusDisplay}
                  </p>

                  <p>
                    {studentCE?.tac_dc_renewal && (
                      <>{studentCE?.tac_dc_renewal}</>
                    )}
                  </p>

                  {studentCE?.tac_dc_reinst_bt?.trim().toUpperCase() ===
                    'Y' && (
                    <>
                      <h3>Reinstate My Designations</h3>
                      <p>
                        Because your professional recertification is more than
                        three months past due, you have relinquished your right
                        to use The American College designation marks and/or
                        advertise your credentials.
                      </p>
                      <p>
                        Please complete your recertification requirements today
                        to restore your designations to active status by: Pay
                        Annual Recertification Fee, Late Fee and Reinstatement
                        Fee.
                      </p>
                      <Link
                        to="designations-certifications/recertification"
                        className="btn">
                        Reinstate
                      </Link>
                    </>
                  )}
                </div>
              </>
            )}
            {!flagsLoading &&
              studentFlags?.couplr_access === 'Y' &&
              showCouplrOffer && (
                <div className="ce-callout couplr">
                  <div className="title2">
                    Announcing Couplr.ai Partnership!
                  </div>
                  <div className="title3">A New Lead Gen Tool for You</div>
                  <div className="">
                    <p>
                      We’re proud to announce a partnership with Couplr.ai – a
                      ground-breaking AI-driven advisor matching tool that
                      connects consumers with professionals that align with
                      their needs, preferences, and values. If you want to take
                      advantage of a free advisor profile, click the "Yes,
                      please share my information." button below. Your name and
                      email address will be shared with Couplr to unlock your
                      free public profile in the matching tool. Information is
                      batched and shared once per month, so expect an email from
                      Couplr the first Monday of every month.
                    </p>
                    <p>
                      If you have any inquiries about access to Couplr.ai or
                      don't receive the initial profile set-up email within 10
                      days of opting in, please email{' '}
                      <a
                        href="mailto:support@couplr.ai"
                        style={{ color: '#fff' }}>
                        support@couplr.ai
                      </a>
                      .
                    </p>
                    <a
                      href="https://www.theamericancollege.edu/knowledge-hub/press/partnership-with-couplrai"
                      target="_blank"
                      rel="noreferrer"
                      styles="color:#fff"
                      className="underline-link peach whitespace-nowrap">
                      Learn more about the partnership here.
                    </a>
                    <br />
                    <br />
                    <div className="buttons">
                      <button
                        onClick={() => acknowledgeCouplrOffer('Y')}
                        className="btn tertiary">
                        Yes, Please Share My Information
                      </button>
                      <button
                        onClick={() => acknowledgeCouplrOffer('N')}
                        className="btn large peach secondary whitespace-nowrap">
                        No, Please Do Not Share My Information.
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {couplrConfirmation && (
              <div className="ce-callout couplr">
                <div className="title2">Thank you for your submission</div>
                <div className="">
                  <p>
                    If you shared your information, expect an email from Couplr
                    on the first Monday of each month. If you decided against
                    sharing your information, no further action is needed.
                  </p>
                  <div className="buttons">
                    <button onClick={closeCouplrOffer} className="btn tertiary">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {(designations?.length > 0 || completedDesignations?.length > 0) && (
          <div className="designations-list">
            <div className="container">
              {designations &&
                designations.status !== 'No Status' &&
                designations
                  .sort((a, b) => (a.confer_date > b.confer_date ? 1 : -1))
                  .map((designation, key) => {
                    if (
                      designationInfo[designation.acad_plan.toLowerCase()] ===
                      undefined
                    ) {
                      return <></>;
                    } else {
                      return (
                        <div key={key} className="designation">
                          <DesignationStatus
                            designation={designation}
                            studentCE={studentCE}
                          />
                        </div>
                      );
                    }
                  })}
              {completedDesignations &&
                completedDesignations.map((designation, key) => {
                  if (
                    designationInfo[designation.acad_plan.toLowerCase()] ===
                    undefined
                  ) {
                    return <></>;
                  } else {
                    return (
                      <div key={key} className="designation">
                        <DesignationStatus
                          designation={designation}
                          studentCE={studentCE}
                          completed={true}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}

        {studentCE?.tac_dc_rep_ce_ally?.toUpperCase() === 'Y' && (
          <div className="container">
            <div className="ce-callout column-layout halves">
              <div className="col title2">
                Report Recertification CE All Year Long
              </div>
              <div className="col">
                <p>
                  You can report CE earned all year long. The Professional
                  Recertification Program accepts all programs pre-approved for
                  credit by the CFP Board of Standards, State Insurance
                  Commission, State Bar or State Board of Accountancy.
                </p>
                <div className="buttons">
                  <Link
                    to="designations-certifications/recertification/ce-reporting"
                    className="btn tertiary">
                    Report PRP CE
                  </Link>
                  <Link
                    to={{ pathname: 'https://acfs.tdnetdiscover.com' }}
                    target="_blank"
                    className="underline-link peach whitespace-nowrap">
                    College Library
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          {/* TODO: get these from PeopleSoft */}
          <div className="confirmation-letters">
            <div className="title3">Confirmation Letters</div>
            <p className="">
              Download your designation and certification confirmation letters
              by clicking on the link(s) below.
            </p>
            {lettersLoading && <LocalTimer />}
            {!lettersLoading && letters && (
              <>
                {!letters.documents && 'No confirmation letters found.'}
                {letters.documents &&
                  letters.documents.map((letter, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        openPdf(
                          `${letter.year}_confirmation_letter.pdf`,
                          letter.pdf_data,
                        );
                      }}
                      className="underline-link certLink">
                      {letter.year ? letter.year : 'Unknown Year'}
                    </button>
                  ))}
              </>
            )}
            {!lettersLoading && !letters && (
              <span className="red-text">
                Unable to load your confirmation letters at this time.
              </span>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const getRecertStatus = (studentCE) => {
  const compliant = studentCE?.tac_compliant_stat?.trim();
  const fee = studentCE?.tac_recer_fee_stat?.trim();

  if (studentCE?.tac_revoked_flag.toUpperCase() === 'Y') return 'revoked';

  if (
    compliant.toUpperCase() === 'N' &&
    (fee.toUpperCase() === 'L' || fee.toUpperCase() === 'R')
  )
    return 'expired';

  if (
    (compliant.toUpperCase() === 'R' ||
      compliant.toUpperCase() === 'N' ||
      compliant.toUpperCase() === 'O') &&
    fee.toUpperCase() === 'L'
  )
    return 'late';

  if (
    (compliant.toUpperCase() === 'R' ||
      compliant.toUpperCase() === 'N' ||
      compliant.toUpperCase() === 'O') &&
    (fee.toUpperCase() === 'R' || fee.toUpperCase() === 'L')
  )
    return 'renew now';

  return 'in good standing';
};

/**
tac_prog_catg": "A" - 'Participation Not Required At This Time.'
tac_prog_catg": "B" - 'Participation Not Required At This Time.'
tac_prog_catg": "N" - 'Participation Not Required At This Time.'
tac_prog_catg": "E" - 'The first Recertification cycle begins in' || January 1 tac_first_ce_yr || 'The annual fee is paid in advance of the recertification year. Payment of your first recertification fee is due no later than' || 12/31/ tac_first_fee_yr.
If tac_prog_catg": "D"  Then
	if compliant = 'C'  'Recertified'
	if compliant = 'R'  Then if fee = 'R'  then 'Immediate Action Required'
			            fee = 'L'  then 'Immediate Action Required - PAST DUE!'
                                    fee = 'P'   --- no such case since it would be in a compliant status
	if compliant = 'N'  Then if fee = 'R' or fee = 'L' then 'Immediate Action Required - PAST DUE!'
                                    fee = 'P' then 'Inactive. CE not current'
	if compliant = 'O'  Then if fee = 'R' or fee = 'L' then 'Immediate Action Required - PAST DUE!'                 
                                    fee = 'P' 'Inactive CE Not current'
*/

export const getRecertStatusRevised = (studentCE) => {
  let returnCode;
  //  console.log(`prog cat is ${studentCE?.tac_prog_catg}`);
  switch (studentCE?.tac_prog_catg?.toUpperCase()) {
    case 'A':
    case 'B':
    case 'N':
      returnCode = 'noparticipation';
      break;
    case 'E':
      returnCode = 'firstyear';
      break;
    case 'D':
      //      console.log(`Cat D...`);
      returnCode = checkCatD(studentCE);
      break;
    default:
      break;
  }
  //  console.log("returnCode: "+returnCode);
  return returnCode;
};
const checkCatD = (studentCE) => {
  let status = 'unknown';
  const compliant = studentCE?.tac_compliant_stat?.trim();
  const fee = studentCE?.tac_recer_fee_stat?.trim();

  switch (compliant.toUpperCase()) {
    case 'C':
      status = 'recertified';
      break;
    case 'R':
      if (fee.toUpperCase() === 'R') status = 'immediateaction';
      else if (fee.toUpperCase() === 'L') status = 'pastdue';
      else if (fee.toUpperCase() === 'P') status = 'paidcedue';
      else {
        status = `unknown-${fee}`;
      }
      break;
    case 'N':
    case 'O':
      if (fee.toUpperCase() === 'R' || fee.toUpperCase() === 'L')
        status = 'pastdue';
      else if (fee.toUpperCase() === 'P') status = 'inactive';
      break;
    default:
      status = 'unknown';
      break;
  }
  return status;
};
export const getRecertDisplayRevised = (studentCE) => {
  if (studentCE?.tac_db_status) {
    return (
      <span className="recert-status">
        {studentCE?.tac_db_status}
        {studentCE?.tac_db_message ? <RedX /> : <BlueCheck />}
      </span>
    );
  } else {
    return (
      <span className="recert-status">
        Status is unknown. Please contact Student Experience.
        <RedX />
      </span>
    );
  }
};

export const getRecertDisplay = (status) => {
  switch (status) {
    case 'revoked':
      return (
        <span className="recert-status">
          Revoked
          <RedX />
        </span>
      );
    case 'expired':
      return (
        <span className="recert-status">
          Expired
          <RedX />
        </span>
      );
    case 'late':
      return <span className="recert-status">Late</span>;
    case 'renew now':
      return <span className="recert-status">Renew Now</span>;
    default:
      return (
        <span className="recert-status">
          In Good Standing
          <BlueCheck />
        </span>
      );
  }
};
export const compliantStatuses = [
  'firstyear',
  'noparticipation',
  'recertified',
  'paidcedue',
];
