import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { PortalMessageContext } from '../global/Context';
import {
  compliantStatuses, getRecertStatusRevised,
} from '../../pages/DesignationsCertifications';

export function ProcessMessages({courseHistory, transcriptHistory, studentCE}){
    dayjs.extend(require('dayjs/plugin/isBetween')); // Load the IsBetween dayjs plugin
    const {setMessageContext} = useContext(PortalMessageContext);
    const { load } = useTacfsService();
    const { isLoading: portalMessagesLoading, data: portalMessages, dataUpdatedAt } =
    useQuery(['getPortalMessages'], () => load('getPortalMessages'), {
      staleTime: 15 * (60 * 1000), // 15 mins
    });
    const { isLoading: flagsLoading, data: studentFlags } = useQuery(['studentFlags'], () =>
        load('studentFlags'),
      );
    useEffect(() => {
        if (portalMessagesLoading === false && flagsLoading ===false && portalMessages) {
           //Loop through portalMessages and evaluate rules
           const portalMessageQueue = [];
           portalMessages.forEach((message)=>{
               if(evalMessage(message, courseHistory, transcriptHistory, studentCE, studentFlags)){
                    portalMessageQueue.push(message);
                }
           });

          setMessageContext({"messages":portalMessageQueue, "lastUpdate":dataUpdatedAt});
        }
    }, [portalMessagesLoading, portalMessages, setMessageContext, dataUpdatedAt, courseHistory, transcriptHistory, studentCE,studentFlags, flagsLoading]);
  return (<></>);
    
}
const evalMessage = (message, courseHistory, transcriptHistory, studentCE, studentFlags) =>{
    let includeMessage = true; // Assume message should  be included
    if(message.hasOwnProperty('rules') && Object.keys(message.rules).length > 0){
//        console.group("Checking Rules");
        for(const [ruleName, ruleCondition] of Object.entries(message.rules)){
            if(!evalMessageRules(ruleName, ruleCondition, courseHistory, transcriptHistory, studentCE, studentFlags)){
//                console.log(`${ruleName} failed. Message will not be displayed.`);
                includeMessage = false;
//                console.log(`Do not evaluate further rules`);
                break;
            } //else {console.log(`${ruleName} passed. Evaluate additional rules.`);}
        }
        console.groupEnd();
    }
    return includeMessage;
};
const evalMessageRules = (ruleName, ruleCondition, courseHistory, transcriptHistory, studentCE, studentFlags) => {
//    console.group(ruleName);
    let rulePasses = false; // Assume rule will fail
    switch(ruleName){
        /* currently_enrolled: If a course has a status of "IN-PROGRESS". */
        case "currently_enrolled":
            // ruleCondition is not used
            if(!Array.isArray(courseHistory)){break;}
            for(let i=0; i<courseHistory.length; i++){
                  const course = courseHistory[i];
                  if(course.hasOwnProperty('status') && 
                      course.status.toLowerCase() === "in-progress"){
//                      console.log(`${ruleName} Passed!`);
                      rulePasses = true; 
                      break;
                  }
            }
//          rulePasses = true; console.log(`${ruleName} hard-coded pass for testing purposes`);//ONLY FOR TESTING!
        break;
       /* designation_program: If the ruleCondition matches the academic_plan of any course  */   
       case "designation_program":
           // ruleCondition is array of strings (designation/program machine names)
            if(!Array.isArray(courseHistory)){break;}
            for(let i=0; i<courseHistory.length; i++){ // loop through courses in history
                  const course = courseHistory[i];
//                  console.log(`Course "${course.course_title}" is part of designation/program: ${course.academic_plan.trim().toLowerCase()}`);
                  if(course.hasOwnProperty('academic_plan') && 
                      ruleCondition.includes(course.academic_plan.trim().toLowerCase())){
                      rulePasses = true;
//                      console.log(`${course.academic_plan} found in rule conditions: ${ruleCondition}!`);
                      break;
                  }
            }
          break;
       /* enrolled_in_course: If the ruleCondition matches the tac_course_id of any course */
       case "enrolled_in_course":
           // ruleCondition is array of strings (tac_course_ids)
            if(!Array.isArray(courseHistory)){break;}
            for(let i=0; i<courseHistory.length; i++){ // loop through courses in history
                  const course = courseHistory[i];
                  // If the course has no status prop, then it's a course 
                  // available to the student but not yet taken. The rule 
                  // should not be checked against this course. 
                  if(!course.hasOwnProperty('status')){
//                    console.log(`${course.course} has not been taken yet. Do not eval rule.`);
                    continue;
                  }
                  
                  if(course.hasOwnProperty('tac_course_id') && 
                      ruleCondition.includes(course.tac_course_id.trim().toLowerCase())){
                      rulePasses = true;
//                      console.log(`${course.tac_course_id} found in rule conditions: ${ruleCondition}!`);
                      break;
                  }
            }
          break;
       /* enrollment_period: If the student was enrolled in any course during the ruleCondition daterange */   
       case "enrollment_period":
           // ruleCondition is associative array representing a date range (keys: value, end_value) 
           if(!Array.isArray(transcriptHistory)){break;}
           const {value: startDate_ep, end_value: endDate_ep} = ruleCondition[0];
           for(let i = 0; i<transcriptHistory.length;i++){
               const course = transcriptHistory[i];
//               console.log(`Check to see if ${course.enroll_add_date} is between ${startDate_ep} and ${endDate_ep}`);
                if(dayjs(course.enroll_add_date).isBetween(startDate_ep, endDate_ep, 'day', '[]')){
                    rulePasses = true; 
//                    console.log(`Student was enrolled between ${startDate_ep} and ${endDate_ep}`);
                    break;
                }
           }
          break;
       /* enrollment_period: If the student's first enrollment was during the ruleCondition daterange */
       case "first_enrollment":
            // ruleCondition is associative array representing a date range (keys: value, end_value) 
        if(!Array.isArray(transcriptHistory)){break;}
        const {value: startDate, end_value: endDate} = ruleCondition[0];
//        console.table(transcriptHistory);    
          const firstEnrollment = transcriptHistory.reduce(
            (prev, curr) =>  prev.enroll_add_date < curr.enroll_add_date ? prev : curr);
//          console.log("First enrollment:", firstEnrollment.enroll_add_date);
//          console.log(ruleCondition);
//          console.log(`Looking for the first enrollment date between ${startDate} and ${endDate}`);
          // If firstEnrollment is between (inclusive) start and end date
          if(dayjs(firstEnrollment.enroll_add_date).isBetween(startDate, endDate, 'day', '[]')){
//              console.log(`First enrollment (${firstEnrollment.enroll_add_date}) is between ${startDate} and ${endDate}`);
              rulePasses = true; 
          } 
          break;
       /* in_good_standing: If the student flags evaluate to "in good standing" combinations */   
       case "in_good_standing":
           // ruleCondition is not used
          const recertStatus = getRecertStatusRevised(studentCE);
          if(compliantStatuses.includes(recertStatus)){
              rulePasses = true; 
          }
          break;
       /* subject_to_recert: If the student's tac_prog_catg is E or D */   
       case "subject_to_recert":
           // ruleCondition is not used
           if(['E', 'D'].includes(studentCE.tac_prog_catg)){
              rulePasses = true; 
           }
           break;
       case "field_couplr_offer_enabled":
           if(studentFlags.hasOwnProperty("couplr_access") && studentFlags.couplr_access === "Y"){
                rulePasses = true; 
           }
        break;
       default:  
          break;
    }
    console.groupEnd();
    return rulePasses;
};