import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { openBase64NewTab } from '../../utils/helper';
import useTacfsService from '../../utils/tacfs/useTacfsService';

export const PurchaseHistoryCard = ({ purchase }) => {
  const { load } = useTacfsService();
  const trackingNum = purchase.tac_tracking_number?.trim();
  const trackingLink = `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNum}`;
  const [receiptDownload, setReceiptDownload] = useState(false);

  const { isLoading, data: receipt } = useQuery(
    [`${purchase.date_of_purchase}receipt`],
    () => load('receipt', { postedDate: purchase.date_of_purchase }),
    { enabled: receiptDownload },
  );

  useEffect(() => {
    const purchasePrice = parseInt(purchase.purchase_amount);
    if (
      isLoading === false &&
      receipt?.pdf_data &&
      receiptDownload &&
      purchasePrice > 0
    ) {
      setReceiptDownload(false);
      openBase64NewTab(
        decodeURIComponent(receipt.pdf_data),
        `receipt_${purchase.date_of_purchase}.pdf`,
      );
    }
  }, [receipt, receiptDownload, isLoading, purchase]);

  return (
    <div className="card drop-box padding--sm w-full purhcase-history-card">
      <div className="title3">{parse(purchase.product_title)}</div>
      <Divider className="top-divider" />

      <div className="content-wrapper">
        <div className="table display-data">
          <div className="row">
            {dayjs(purchase.date_of_purchase).isValid() && (
              <>
                <div className="cell">Date of Purchase</div>
                <div className="cell">
                  {dayjs(purchase.date_of_purchase).format('MM/DD/YY')}
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="cell">Purchase Amount</div>
            <div className="cell">${purchase.purchase_amount}</div>
          </div>
          <div className="row">
            <div className="cell">Order Number</div>
            <div className="cell">
              {purchase.tac_order_number?.trim()
                ? purchase.tac_order_number
                : 'N/A'}
            </div>
          </div>

          <div className="row">
            <div className="cell">Product Type</div>
            <div className="cell">{purchase.product_type}</div>
          </div>

          <div className="row">
            <div className="cell">Status</div>
            <div className="cell">
              {purchase.status_field === 'NA' ? 'N/A' : purchase.status_field}
            </div>
          </div>
        </div>
        <div className="buttons">
          {parseInt(purchase.purchase_amount) > 0 &&
            (isLoading ? (
              <button className="btn disabled" disabled>
                Downloading...
              </button>
            ) : (
              <button className="btn" onClick={() => setReceiptDownload(true)}>
                Download Receipt
              </button>
            ))}
          {purchase.product_type === 'Textbook' &&
            purchase.status_field === 'Shipped' &&
            trackingNum && (
              <Link
                to={{ pathname: trackingLink }}
                target="_blank"
                className="btn">
                Track Shipment:
                <div>{trackingNum}</div>
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};
