import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';

// The base request used to get query data.
const useRequest = () => {
  // We want to add the bearer token to every
  // subsequent call once authenticated.
  const { authState } = useOktaAuth();
  // Compsoe the headers and add the
  // bearer access token if set.
  const headers = {
    'Content-Type': 'application/json',
  }
  if (authState?.accessToken) {
    headers.Authorization = `Bearer ${authState?.accessToken?.accessToken}`
  }
  // Make the axios client.
  const AxiosClient = axios.create({
    baseURL: '/tacapi/ws/rest',
    timeout: 60000,
    headers: headers,
    withCredentials: true,
  });

  // Success hanlder for the request
  const onSuccess = function (response) {
    const { data } = response;
    // Return the data.
    // TODO: Account for updated data structure returned from API.
    //       This will change once we uniform the enpdoint return data.
    return typeof data.data !== 'undefined' ? data.data : data;
  };
  // Catch the error response.
  const onError = function (error) {
    // if an error was returned but not response was set then we must manually set it.
    if (typeof error.response == 'undefined')
      error.response = {
        data: { message: 'There was an error contacting the server!' },
      };
    // TODO: we re-verify the session if we get a 403 access denied error.
    else if (error.response.status === 403)
      error.response = { data: { message: 'Access Denied!' } };
    else if (error.response.status === 404)
      error.response = { data: { message: 'Process Not Found!' } };
    // return promise.
    return Promise.reject(
      error.response.data.message ??
        'There was an error but no message was returned',
    );
  };
  const request = async function (options) {
    // Adding the axios client.
    if (options.baseUrl !== undefined) {
      AxiosClient.defaults.baseURL = options.baseUrl;
    }
    return AxiosClient(options).then(onSuccess).catch(onError);
  };
  return [request];
};

export default useRequest;
