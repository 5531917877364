import { useState } from "react";
import { OktaSSOLink } from '../../_okta/config/OktaSSO';

export function RecommendedCourseDetails({title, subhead, description, program, course}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="card drop-box padding--sm topbar--Continuing-Education">
        <div className="card-holder column-layout">
          <div className="card-header">
            <h4>{title}</h4>
            <p>
             {subhead}
            </p>
            <p className="view" onClick={() => setIsOpen(!isOpen)}>View Description +</p>
          </div>
        </div>
        <div className="col--third">
          <OktaSSOLink classes="btn" program={program} enroll={course}>
            Enroll Now
          </OktaSSOLink>
        </div>
      </div>
      <div className={isOpen ? 'modal active' : 'modal'}>
        <div className="programContent">
          <div className="overlay">
            <div className="card drop-box topbar--lilac open topbar--Continuing-Education">
              <div className="close" onClick={() => setIsOpen(!isOpen)}>
                &times;
              </div>
              <div>
                <p className="collection-title">
                  {title}
                </p>
                <p className="p4">
                  {subhead}
                </p>
              </div>
              <p className="desc">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}