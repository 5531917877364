import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { Taxforms } from '../components/taxForms/Taxforms';
import { AuthDetails } from '../components/login/AuthDetails';

export function Taxes() {
  return (
    <Layout>
      <Breadcrumbs />
      <div className="container tax-forms">
        <div className="intro-content-wrapper">
          <AuthDetails page="tax forms" />
          <p className="page-intro-text">
            View and download your applicable tax forms for your and your
            company’s records.
          </p>
        </div>
        <div>
          <Taxforms />
        </div>
      </div>
    </Layout>
  );
}
