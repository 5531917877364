import { useState, useEffect } from 'react';
import InputField from '../../subcomponents/InputField';
import SelectSearch from '../../subcomponents/SelectSearch';
import { useQuery } from 'react-query';
import useTacfsService from '../../../utils/tacfs/useTacfsService';
// Import constants defining the various 'other' types from our company list.
import { OtherCompanyIds as ocId } from '../../../data/Constants';
export function CompanyAffiliationField({
  combinedProfile,
  setCompanyName,
  setCompanyId,
  setIsIndependent,
}) {
  const { load } = useTacfsService();
  const { companiesLoading, data: companies } = useQuery(['companies'], () =>
    load('companies'),
  );

  const [loadedCompanies, setLoadedCompanies] = useState(null);
  const [independentCompanies, setIndependentCompanies] = useState(null);

  const [companyName, updateCompanyName] = useState(
    combinedProfile.company.company_name,
  );
  const [field1HasChanged, setField1HasChanged] = useState(false);
  const [companyField1, setCompanyField1] = useCompanyField1(
    combinedProfile.company.company_id === '1599250' ||
      combinedProfile.company.company_id === '1000927'
      ? 'other'
      : combinedProfile.company.company_id,
  );
  const [companyField2, setCompanyField2] = useCompanyField2(
    combinedProfile.company.company_id,
  );
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value.trim());
  };
  function useCompanyField1(initialValue = '') {
    const [value, setValue] = useState(initialValue.trim());

    function handleChange(e) {
      if (e === undefined) {
        //If e is undefined, set value and company name to null
        setValue();
        updateCompanyName();
      } else {
        const company_id = e.target.value.trim();
        const company_name = [
          ocId._INDEPENDENT,
          ocId._OTHER,
          ocId._NONPROFIT,
          ocId._BROKER_NOT_LISTED,
          ocId._ATTORNEY,
          ocId._CPA,
        ].includes(company_id)
          ? combinedProfile.company.company_name
          : e.target[e.target.selectedIndex].innerHTML;

        setCompanyField2(); //Reset companyField2's value
        setValue(company_id); // Set value to the option value
        updateCompanyName(company_name); //Maintain state within this component.

        checkIsIndependent(company_id); // Lift independent flag up to parent component
        setCompanyId(company_id); // Lift companyId up to parent component
        setCompanyName(company_name); // Lift company name up to parent component
      }
    }

    return [value, handleChange];
  }
  function useCompanyField2(initialValue = '') {
    const [value, setValue] = useState(initialValue.trim());

    function handleChange(e) {
      if (e === undefined) {
        //If e is undefined, set value and company name to null
        setValue();
        updateCompanyName();
      } else {
        const company_id = e.target.value.trim();

        setValue(company_id); // Set value to the option value
        setCompanyId(company_id); // Lift companyId up to parent component
      }
    }

    return [value, handleChange];
  }
  function checkIsIndependent(companyId) {
    if ([ocId._INDEPENDENT, ocId._OTHER].includes(companyId)) {
      setIsIndependent('Y');
    } else {
      setIsIndependent('N');
    }
  }

  useEffect(() => {
    if (
      !companiesLoading &&
      companies &&
      loadedCompanies === null &&
      independentCompanies === null
    ) {
      // company data for non-logged in users, returning load in LoginSuccess.jsx
      // grab companies
      let _companies = [];
      _companies = companies.companies;

      // Sort companies alphabetically by name
      _companies.sort((a, b) => a.name.localeCompare(b.name));

      saveCompanyArray(_companies);
    }
  }, [companiesLoading, companies, loadedCompanies, independentCompanies]);

  const saveCompanyArray = (_companies) => {
    // We want to remove the broker dealer
    // not listed from the base list.
    setLoadedCompanies([
      ..._companies.filter((company) => {
        return company.org_id !== '1599250';
      }),
      // add other
      { org_id: 'other', name: 'Other' },
    ]);
    setIndependentCompanies([
      ..._companies.filter((company) => {
        return company.independent_flag === 'Y';
      }),
      { org_id: '1000927', name: 'I do not have a broker/dealer' },
    ]);
  };

  return (
    <>
      <div className="row inline-field">
        <div className="cell">
          <label htmlFor="company-field">
            Company <span className="req-star">*</span>
          </label>
        </div>
        <div className="cell">
          {loadedCompanies && (
            <SelectSearch
              id="company-field"
              name="company"
              title="Choose a Company"
              value={companyField1}
              onChange={(e) => {
                setCompanyField1(e);
                setField1HasChanged(true);
              }}
              onBlur={(e) => {
                //TODO: Refactor to use Formik to handle error messages clear
              }}
              options={loadedCompanies.map((item) => {
                return {
                  name: item.name,
                  value: item.org_id,
                };
              })}
              permanentValues={['other']}
            />
          )}
        </div>
      </div>
      <div
        className={
          field1HasChanged &&
          [ocId._INDEPENDENT, ocId._OTHER, ocId._OTHER_FIN].includes(
            companyField1,
          )
            ? 'row  inline-field'
            : 'row hidden'
        }>
        <div className="cell">
          <label htmlFor="company-field">
            Broker Dealer <span className="req-star">*</span>
          </label>
        </div>
        <div className="cell">
          {independentCompanies && (
            <SelectSearch
              id="company2-field"
              name="company2"
              title="Choose a Broker Dealer"
              value={companyField2}
              onChange={setCompanyField2}
              onBlur={(e) => {
                //TODO: Refactor to use Formik to handle error messages clear
              }}
              options={independentCompanies.map((item) => {
                return {
                  name: item.name,
                  value: item.org_id,
                };
              })}
              permanentValues={['1599250', '1000927']}
            />
          )}
        </div>
      </div>
      {/* TODO: This needs to appear based on state, i.e. company is other, independent, non-profit & broker dealer is other/Not Listed  */}
      <div
        className={
          [
            ocId._INDEPENDENT,
            ocId._OTHER,
            ocId._OTHER_FIN,
            ocId._NONPROFIT,
            ocId._BROKER_NOT_LISTED,
            ocId._ATTORNEY,
            ocId._CPA,
          ].includes(companyField1)
            ? 'row  inline-field'
            : 'row hidden'
        }>
        <div className="cell">
          Your Company Name <span className="req-star">*</span>
        </div>
        <div className="cell">
          <InputField
            label="Your Company Name"
            id="companyName-field"
            name="companyName"
            type="text"
            value={
              [
                ocId._INDEPENDENT,
                ocId._OTHER,
                ocId._NONPROFIT,
                ocId._BROKER_NOT_LISTED,
                ocId._ATTORNEY,
                ocId._CPA,
              ].includes(companyField1)
                ? companyName
                : ''
            }
            onChange={handleCompanyName}
            srOnly={true}
            maximumLength="30"
            required={
              [
                ocId._INDEPENDENT,
                ocId._OTHER,
                ocId._NONPROFIT,
                ocId._BROKER_NOT_LISTED,
                ocId._ATTORNEY,
                ocId._CPA,
              ].includes(companyField1)
                ? true
                : false
            }
          />
        </div>
      </div>
    </>
  );
}
export default CompanyAffiliationField;
