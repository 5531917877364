import {React, useState, useEffect} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { OktaConfig } from './_okta/config/OktaConfig';

import { Routes } from './routes/Routes.jsx';
import ScrollToTop from './components/subcomponents/ScrollToTop';

import { Maintenance } from './components/global/Maintenance';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000,
    },
    mutations: {
      retry: 0,
    },
  },
});

export default function App() {
  const [inMaintMode, setInMaintMode] = useState(false);      
  const history = useHistory();
  
  useEffect(()=>{
      fetch(`${process.env.REACT_APP_MARKETING_URL}api/v1/mmfeed`)
        .then(response => response.json())
        .then((data)=>{
            if(data?.lh === 1){
                setInMaintMode(true);
            } else {
                setInMaintMode(false); 
            }
        })
        .catch(error => console.error(error));
  },[]);

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'TRUE' || inMaintMode ) {
    return <Maintenance />;
  } else {
    const oktaAuth = new OktaAuth({
      clientId: OktaConfig.clientId,
      issuer: OktaConfig.issuer,
      redirectUri: OktaConfig.redirectUri,
      scopes: OktaConfig.scopes,
      pkce: OktaConfig.pkce,
    });

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
      <QueryClientProvider client={queryClient}>
        <div className="portal">
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Router>
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
            </Router>
          </Security>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  }
}
