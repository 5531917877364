import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
//import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import useProfileQuery from '../../utils/tacfs/useProfileQuery';
//import ReadStorage from '../storage/ReadStorage';
//import SetStorage from '../storage/SetStorage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BasicInfoForm from '../subcomponents/annualInfoLock/BasicInfoForm';
import ContactInfoForm from '../subcomponents/annualInfoLock/ContactInfoForm';
import DemographicInfoForm from '../subcomponents/annualInfoLock/DemoInfoForm';
import CompanyInfoForm from '../subcomponents/annualInfoLock/CompanyInfoForm';
import { CustomizedCompanies as cc, OtherCompanyIds as ocId  } from '../../data/Constants';
import mapping from '../../data/selectValuesMapping.json';
//import { TacfsContext } from '../global/Context';
import Timer from '../../components/subcomponents/Timer';

export function AnnualInfoLock() {
  const { load } = useTacfsService();
  const { authState } = useOktaAuth();
  const [showAnnualReviewModal, setShowAnnualReviewModal] = useState(false);
  const { /*isLoading: userDataLoading,*/ data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );
  const { isLoading: loadingLastReviewedDate, data: lastReviewedDate } = useQuery(['studentInfoReview'], () =>
    load('studentInfoReview'),
  );
  
  const { profileLoading, profileData } = useProfileQuery(userData);
  const [profile, setProfile] = useState(profileData);
  
  /* We're essentially copying profileData into an editable state object while preserving the original obj.  
   * (which i don't think we really need to do) */
  useEffect(()=>{
     if(!profileLoading && profileData){
        setProfile(profileData); 
     }
  },[profileLoading,setProfile]);
  
  useEffect(() => {
    if (!loadingLastReviewedDate && lastReviewedDate) {
      console.groupCollapsed('Show Modal Debugging!');
        // TODO: If lastReviewedDate is empty, make initial date TODAy minus 1 year. this would force an immediate update upon login
      if(lastReviewedDate.length === 0){ 
          console.log("We got an empty array for the reviewed dates. Is this expected?");
          lastReviewedDate.push({student_id: '1617150', status_date: dayjs().subtract(1,'year').format('YYYY-MM-DD')});
      }
      console.log("The first element in the following array is the 'enrollment' date; the last element is the last time the info was reviewed.");
      console.table(lastReviewedDate);
      // first element of array is first enrollment date, which is our initial trigger.
      const initialDate = dayjs(lastReviewedDate[0].status_date)
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0);
      //Debug date: 
//      const initialDate = dayjs('7/18/2021').hour(0).minute(0).second(0).millisecond(0);
      
      //last element is the most recent reviewed date.
      const lastReview = dayjs(lastReviewedDate.slice(-1)[0].status_date)
              .hour(0)
              .minute(0)
              .second(0)
              .millisecond(0);
      //debug date: 
//      const lastReview = dayjs('07/2/2023').hour(0).minute(0).second(0).millisecond(0);
      console.log(`Initial date: ${initialDate}`);
      console.log(`Last reviewed: ${lastReview}`);
      
      const today = dayjs();
      const currentYearAnniversary = dayjs()
                .month(initialDate.subtract(1, 'month').format('M')) //Format returns the month #, not the index. But setting the month requires the index. 
                .date(initialDate.format('D'))
                .year(today.format('YYYY'))
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0);
      console.log(`Today: ${today}`);
      console.log(`Current anniversary of initial date: ${currentYearAnniversary} `)
      
      /*
       * Is the lastReview more than 365 days older than currentAnniversaryDate? Trigger review
       * Is today newer than currentAnniversaryDate? Is the last review older than the currentAnniversaryDate? Trigger review
       * Is the lastReview newer than the currentAnniversaryDate? Prevent trigger review
       */
      let timeToReview = false;
      
      // last review is more than 365 days older than upcoming anniversary
      if(currentYearAnniversary.diff(lastReview, 'day') > 365){
          console.log("Last review older than 365 days from this year's anniversary");
          timeToReview = true; //This superceeds all other cases
      } else {console.log("Last review less than 1 year ago")}
      
      //if today is the anniversary or later AND the lastReview is greater than the anniversary
      if(today >= currentYearAnniversary && lastReview < currentYearAnniversary){
        console.log("Today is past the anniversary AND the last review is older than the anniversary");  
        timeToReview = true;
      } else {
          console.log("Today is not the anniversary or greater AND/or the last review is newer than the anniversary")
      }
      
      if(lastReview > currentYearAnniversary){
          console.log("The last review happend since the current year's anniversary. Prevent the modal!");
          timeToReview = false;
      }
      console.log("Time to review?", timeToReview);
      //Debugging~~~!
//      console.log('**forcing render of modal*');
//      timeToReview = true;
      setShowAnnualReviewModal(timeToReview);
      console.groupEnd();
    }
  }, [lastReviewedDate, loadingLastReviewedDate, setShowAnnualReviewModal]);
  
  if(!showAnnualReviewModal){
      <></>
  } else {
    return (
      <>
          {userData &&
              profile.basic &&
              profile.contact &&
              profile.company &&
              profile.education &&
              profile.flags && (
          <AnnualInfoModal 
              userData={userData}
              combinedProfile={profile}
          />
          )}
      </>
    );
  }
}

function AnnualInfoModal({ userData, combinedProfile}) {
  const { authState } = useOktaAuth();
  const { save } = useTacfsService();
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
//  const context = useContext(TacfsContext);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.querySelector('body').classList.remove('overflow-auto');
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }, []);

  useEffect(() => {
    return () => {
      if (typeof document !== 'undefined') {
        document.querySelector('body').classList.add('overflow-auto');
        document.querySelector('body').classList.remove('overflow-hidden');
      }
    };
  }, []);
  
  //Set up all the various mutations to save data!
  
  // SSN OptOpt update
  const { mutateAsync: optOutMutate } = useMutation((values) =>
    save('ssnOptOut', values),
  );
  // Demographic Info
  const { /*isLoading,*/ mutateAsync: demographicMutate  } = useMutation((values) =>
    save('studentBasic', values),
  );
  const { /*isLoading: isUpdatingPrimaryEmail,*/ mutateAsync: updatePrimaryEmail } =
    useMutation((values) => save('updatePrimaryEmail', values));
 // Update all account information
   const { /*isLoading: accountInfoLoading,*/ mutateAsync: accountInfoMutate } = useMutation(
    (values) => save('studentContactall', values),
  );
  // Mark student as having reviewed their info
  const { /*isLoading: isUpdatingReviewedDate,*/ mutateAsync: reviewMutate } = useMutation(
    (values) => save('studentInfoReview', values),
  );
  
  const formSubmit = async (values) => {
    setIsFormSubmitting(true);
    console.groupCollapsed("Form Submitted");
    // Generate objects to update the student account.
    const basicInfoObj = getBasicInfoObj(values); 
    const contactInfoObj = getContactInfoObj(values);
    const companyInfoObj = getCompanyInfoObj(values);
    // Construct the payload.
    const accountInfoPayload = {...basicInfoObj, ...contactInfoObj, ...companyInfoObj};
    
    // Update the accountInformation.
    // TODO: Implement the SSN validation before calling either of these
    const status_AccountInformation = await updateAccountInformation(accountInfoPayload);
    const status_DemographicInformation = await updateDemographicInfo(values);
    
    // Set up conditional updates. 
    try {
      await Promise.all([status_AccountInformation, status_DemographicInformation]);
      if ( false) {
        setError('Error saving data.');
      } else {
         // Call additional updates 
         const status_OptOut = await updateOptOutStatus(values);
         const status_UpdateEmail = await updatePrimaryEmailAddress(values);
         try {
            await Promise.all([status_OptOut,status_UpdateEmail]);
            if ( false) {
              setError('Error saving data.');
            } else {
               //Only if everything works, call
//                setProfile(updateCombinedProfile(values, combinedProfile)); // Update the local profile context
                markDataAsReviewed();
//               console.log("Disabled markDataAsReviewed for debugging");
                //TODO: Provide success message before reload
//                setIsFormSubmitting(false);
            }
          } catch {
            setError('Error saving data.');
            setIsFormSubmitting(false);
          }
      }
    } catch {
      setError('Error saving data.');
      setIsFormSubmitting(false);
    }
    console.groupEnd();
  };
  const updateAccountInformation = async (data) => {
      console.log("Updating Account Information");
      return accountInfoMutate(data, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        console.log("Account info updated");
      },
    });
  };
  const markDataAsReviewed = async () => {
     console.log("Marking Data as reviewed");
     reviewMutate({studentID: userData.profile.studentID}, {
      onError: (res) => {
          setError(res);
          console.log("We had an error setting the most recent review date.");
      },
      onSuccess: (data) => {
        console.log("Successfully set latest review date.");
        console.log("Begin attempt to invalidate queries to force data reload from API & to hide modal.");
       
        const invalidateBasic = queryClient.invalidateQueries({ queryKey: ['studentBasic']});
        const invalidateContact = queryClient.invalidateQueries({ queryKey: ['studentContact']});
        const invalidateCompany = queryClient.invalidateQueries({ queryKey: ['studentCompany']});
        const invalidateStudentFlags = queryClient.invalidateQueries({ queryKey: ['studentFlags']});
        
        Promise.all([invalidateBasic, invalidateContact, invalidateCompany, invalidateStudentFlags]).then(
            (invalidationSuccess)=>{
                console.log('We successfully invalidated all of our queries', invalidationSuccess);
                // invalidating this specific query will cause the modal to disappear if we successfully set the infoReview date
                queryClient.invalidateQueries({ queryKey: ['studentInfoReview']}).then(()=>{
                    console.log("We have invalidated the infoReview query, causing the app to re-fetch the data & (hopefully) an updated array of review dates. If all went well, the latest date in the array shold be today and the modal will no longer appear!");
                    setIsFormSubmitting(false);
                }, ()=>{
                    console.log("We failed to invalidate the infoReview query. The modal may still appear.");
                    setIsFormSubmitting(false);
                }); 
            }, 
            (invalidationError)=>{
                setError('Error invalidating queries');
                console.log('We could not invalidate one of our queries', invalidationError);
                setIsFormSubmitting(false);
            }
        );
      }
    });
  };
  
  const getBasicInfoObj = (values) => {
    console.log("generating basic info obj");
    const basicInfo = {
      date_of_birth: values.birthdate,
      gender: values.gender,
      military_status: combinedProfile.basic.military_flag,
      cit_country: 'USA',
      cit_status: combinedProfile.basic.tac_citizenship_cd,
      ethnicity: combinedProfile.basic.tac_ethnic_group_cd,
      hispanic: combinedProfile.basic.ethnic_group,
      country: 'USA',
    };
    
    let ssnData;

    // Only set ssn data if they have NOT opted out,
    // AND their ssn is NOT already in the system
    if (!values.optOut && !combinedProfile.basic.national_id_srh?.trim()) {
        // Regex for SSN format.
        const regex = /^(?!666|000|111|9\d{2})\d{3}?(?!00)\d{2}?(?!0{4})\d{4}$/;
        // we want to check the regex and end the function if
        // the regex does not match.
        if (!regex.test(values.ssn.replace(/\D/g, ''))) {
          //TODO: Implement this in Yup
          setError('Invalid SSN format.'); 
          return;
        }
        ssnData = {
          ssn: values.ssn.replace(/\D/g, ''),
        };
    }
    const basicData = {
      ...basicInfo,
      ...ssnData,
    };
    basicData.studentID = userData.profile.studentID;
    basicData.student_id = userData.profile.studentID;
    
    return basicData;
  };
  /* Call Optout endpoint */
  const updateOptOutStatus = async(values) => {
     console.log("calling optOut endpoint");
    if (values.optOut && !combinedProfile.basic.national_id_srh?.trim()) {
        const optOutData = {
          studentID: userData.profile.studentID,
        };

        return optOutMutate(optOutData, {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            console.log('SSN optout successful');
          },
        });
    } else {
        return Promise.resolve();
    }
  }
  
  
  /* UPDATE CONTACT INFO */
  const getContactInfoObj = (values) => {
      console.log("generating contact info obj");
    const contactData = {};
    contactData.studentID = userData.profile.studentID;
    contactData.primary_address = values.primaryAddress;
    contactData.primary_phone = values.primaryPhone;
    contactData.primary_email = values.primaryEmail;

    const homeData = {
      email: values.personalEmail,
      address1:  values.homeAddress,
      address2:  values.homeAddress2,
      city:  values.homeCity,
      state:  values.homeState,
      postal:  values.homeZip,
      country: 'USA',
      phone:  values.personalPhone.replace(/\D/g, ''),
      phone_type:  values.homePhoneType,
    };

    contactData.home_information = homeData;

    const busData = {
      email: values.businessEmail,
      address1: values.businessAddress,
      address2: values.businessAddress2,
      city: values.businessCity,
      state: values.businessState,
      postal: values.businessZip,
      country: 'USA',
      phone: values.businessPhone.replace(/\D/g, ''),
      phone_type: values.businessPhoneType,
    };

    contactData.business_information = busData;

    return contactData;
  };
  
  /* Update primary email address */
  const updatePrimaryEmailAddress = async(values) => {
      console.log("updating okta primary email");
//Update Okta email
        // TODO: Replace this with a call on the server side rather than here.
        const data = {
          uid: authState?.accessToken?.claims?.uid,
          newPrimaryEmail: values.primaryEmail === 'R' ? values.personalEmail : values.businessEmail,
        };
        
        return updatePrimaryEmail(data, {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            if (!data) {
              setError('Error updating primary email.');
            } 
          },
        });
  }
  
  /* UPDATE DEMOGRAPHIC INFO */
  const updateDemographicInfo = async(values) => {
    console.log("updating demographic info");
    const data = {
        studentID: userData.profile.studentID,
        birthdate: values.birthdate,
        gender: values.gender,
        military_status: values.military,
        cit_country: 'USA',
        cit_status: values.citizenship,
        ethnicity: values.ethnicity === 'DNWTD' ? 'DNWTD' : values.race,
        hispanic: values.ethnicity === 'DNWTD' ? 'N' : values.ethnicity,
        res_country: 'USA',
      };
    
    demographicMutate(data, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data) {
          if (data.code === 'E') {
            setError('Error saving data.');
          } else {
            combinedProfile.basic.military_flag = values.military;
            combinedProfile.basic.tac_citizenship_cd = values.citizenship;
            combinedProfile.basic.tac_ethnic_group_cd = values.race;
            combinedProfile.basic.ethnic_group = values.ethnicity;
            //TODO: Only call this at the VERY VERY end of the submit function.
//            setProfile(combinedProfile);
          }
        } else {
          setError('Error saving data.');
        }
      },
    });
  };
  /* UPDATE Company INFO */
  const getCompanyInfoObj = (values) => {
    console.log("generating company info obj");  
    // Derive the company ID and name from the two company fields
      values.company = values?.company2 ? values.company2 : values.company1;
      values.companyName = values?.userCompanyName !==""
        ? values.userCompanyName
        : values.companyName;
       
      const data = {
        studentID: userData.profile.studentID,
        student_id: userData.profile.studentID,
        company_affiliation: {
          company_id: values.company,
          company_name: values.companyName.substring(0,30),
          independent: [ocId._INDEPENDENT, ocId._OTHER].includes( values.company1)
              ? 'Y'
              : 'N',
          affiliation_id: values.agent_id,
          role: values.agent_role,
          agency_name: '',
          region: values.region,
          nt_id: values.ntid.substring(0,15),
          lsp_cd: '',
          market: '',
          territory: '',
          agent_license: values.crd,
          license_number: values.npn,
        },
      };
    return data;
  };
  
  //Preset Values to hide individual fields
  const basicPreset = {
      ssn:combinedProfile.basic.national_id_srh.trim().length>0,
      dob:combinedProfile.basic.birthdate !== "",
      gender:combinedProfile.basic.sex.trim().length>0,
  }
  const demographicPreset = {
      citizenship:combinedProfile.basic.tac_citizenship_cd !== "",
      race:combinedProfile.basic.tac_ethnic_group_cd !== "",
      military:combinedProfile.basic.military_flag !== "",
      ethnicity:combinedProfile.basic.ethnic_group !== "",
  }
   
  return (
    <div className="modal complete-modal active infoReview">
      {isFormSubmitting && <Timer/>}  
      <div className="modalContent scrollable">
        <div className="container--sm">
          <div className="title2">Please assist us in ensuring that your information is accurate.</div>
          <p className="p3 introText">
            Please review the information we have on file for you below and make any necessary updates. Click "Update" once you have completed your review. Thank you!
          </p>
          <Formik
            initialValues={{
              /* Misc fields */
                agreement: false,
              /* Basic Information fields */
                birthdate:dayjs(combinedProfile.basic.birthdate).format('YYYY-MM-DD'),
                gender:combinedProfile.basic.sex,
                ssn:combinedProfile.basic.national_id_srh,
                optOut:combinedProfile.flags.ssn_opt_out?.trim() === 'Y',
              /* Contact Info fields */
                personalEmail: combinedProfile.contact.email,
                homeAddress: combinedProfile.contact.address1,
                homeAddress2: combinedProfile.contact.address2,
                homeCity: combinedProfile.contact.city ,
                homeState: combinedProfile.contact.state,
                homeZip: combinedProfile.contact.postal,
                personalPhone: formatPhone(combinedProfile.contact.phone),
                homePhoneType:  combinedProfile.contact.residence_phone_type,
                businessEmail:  combinedProfile.contact.email_alternate,
                businessAddress:  combinedProfile.contact.address1_work,
                businessAddress2:  combinedProfile.contact.address2_work,
                businessCity:  combinedProfile.contact.city_work,
                businessState:  combinedProfile.contact.state_work ,
                businessZip:  combinedProfile.contact.postal_work,
                businessPhone:  formatPhone(combinedProfile.contact.phone_work),
                businessPhoneType:  combinedProfile.contact.business_phone_type,
                primaryEmail:combinedProfile.contact.email_type_primary,
                primaryPhone:combinedProfile.contact.phone_type_primary,
                primaryAddress:combinedProfile.contact.address_type_primary,
              /* Demographic Info fields */
                citizenship:combinedProfile.basic.tac_citizenship_cd,
                race: combinedProfile.basic.tac_ethnic_group_cd,
                military: combinedProfile.basic.military_flag,
                //FYI this logic may be broken as according to code DNWTD is *not* a valid option for ethnicity
                ethnicity: (combinedProfile.basic.ethnic_group === 'N' && 
                           combinedProfile.basic.tac_citizenship_cd === 'DNWTD')?
                            'DNWTD':
                            combinedProfile.basic.ethnic_group,
              /* Company Info fields */
                crd:combinedProfile.company.agent_license,
                npn: combinedProfile.company.license_number,
                company1:combinedProfile.company.company_id,
                company2:'',
                userCompanyName:combinedProfile.company.company_name,
                companyName:combinedProfile.company.company_name,
                agent_id:combinedProfile.company.agent_id,
                // When we get this value, it is in string/label form, i.e. "Agent". When we save data, we have to send it as the value, i.e. "32".
                // We need to convert it here so it can be used in the dropdown. 
                agent_role:([cc._ALLSTATE, cc._STATEFARM].includes(combinedProfile.company.company_id)
                    ? mapping.jobTitleReverse[combinedProfile.company.company_id][combinedProfile.company.agent_role]
                    : ''), 
                region:combinedProfile.company.region,
                ntid:combinedProfile.company.nt_id,
                independent:'N',
                affiliation_id:combinedProfile.company.agent_id,
            }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="annualInfoReviewForm">
            {error}
            <BasicInfoForm 
                values = {values}
                errors = {errors}
                touched = {touched}
                handleChange = {handleChange}
                handleBlur = {handleBlur}
                presetVals ={basicPreset}
            />
            <ContactInfoForm 
                values = {values}
                errors = {errors}
                touched = {touched}
                handleChange = {handleChange}
                handleBlur = {handleBlur}
            />
            <CompanyInfoForm 
                values = {values}
                errors = {errors}
                touched = {touched}
                handleChange = {handleChange}
                handleBlur = {handleBlur}/>
            <DemographicInfoForm 
                values = {values}
                errors = {errors}
                touched = {touched}
                handleChange = {handleChange}
                handleBlur = {handleBlur}
                presetVals ={demographicPreset}
            />
            <div className="column-layout">
             <div className="">
                <div className="field">
                  <ul className="checkboxes">
                    <li>
                      <input
                        type="checkbox"
                        id="agreement"
                        name="agreement"
                        className={
                          touched.agreement && errors.agreement ? 'error' : null
                        }
                        checked={values.agreement}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                      />
                      <label htmlFor="agreement">
                        <p>
                          Yes, I have ensured that my information is accurate by verifying and/or updating it.
                        </p>
                      </label>
                      {touched.agreement && errors.agreement && (
                        <span className="red-text">{errors.agreement}</span>
                      )}
                    </li>
                  </ul>
                  </div>
                </div>
            </div>
            
            <div className="flex w-full column-layout">
              <button
                type="submit"
                form="annualInfoReviewForm"
                className="btn col">
                Update
              </button>
            </div>
          </form>
        )}
      </Formik>
        </div>
      </div>
    </div>
  );
}
const zipRegExp = /^\d{5}$/;
const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    
const ValidationSchema = Yup.object().shape({
    agreement: Yup.bool().required('You must affirm that you have reviewed the above information'),
    birthdate:Yup.date()
                 .min('1900-01-01', "Date must be later than 1900")
                 .max(new Date(), "Date must be in the past")
                 .nullable()
                 .typeError('Please enter a valid date')
                 .required('Required Field'),
    gender:Yup.string().required('Please select a gender'),
    optOut: Yup.bool(),
    ssn: Yup.string().when(['optOut'], (optOut, schema) => {
      if (optOut && optOut[0] === false)
        return schema
          .required('Required Field')
          .matches(
            /^(?!666|000|111|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
            'Invalid SSN format.',
          );
    }),
    personalEmail: Yup.string()
      .email('Must be a valid email')
      .required('Please enter a valid email'),
    homeAddress: Yup.string().required('Required Field'),
    homeCity: Yup.string().required('Required Field'),
    homeState: Yup.string().required('Required Field'),
    homeZip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field'),
    personalPhone: Yup.string()
      .matches(phoneRegExp, 'Invalid phone format')
      .required('Required Field'),
    homePhoneType: Yup.string().required('Required Field'),
    businessEmail: Yup.string()
      .email('Must be a valid email')
      .required('Please enter a valid email'),
    businessAddress: Yup.string().required('Required Field'),
    businessCity: Yup.string().required('Required Field'),
    businessState: Yup.string().required('Required Field'),
    businessZip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field'),
    businessPhone: Yup.string()
      .matches(phoneRegExp, 'Invalid phone format')
      .required('Required Field'),
    businessPhoneType: Yup.string().required('Required Field'),
    company1: Yup.string().required('Please select a Company'),
    company2: Yup.string().when(['company1'], (company1, schema) => {
      if (
        [
          ocId._INDEPENDENT,
          ocId._OTHER,
          ocId._BROKER_NOT_LISTED,
          ocId._ATTORNEY,
          ocId._CPA,
        ].includes(company1)
      ) {
        return schema.required('Please select a Broker/Dealer');
      }
    }),
    userCompanyName: Yup.string().when(['company1'], (company1, schema) => {
      if (
        [
          ocId._INDEPENDENT,
          ocId._OTHER,
          ocId._NONPROFIT,
          ocId._BROKER_NOT_LISTED,
          ocId._ATTORNEY,
          ocId._CPA,
        ].includes(company1)
      ) {
        return schema.required('Please enter a Company Name');
      }
    }),
    citizenship:Yup.string().required('Required Field. Please make a selection.'),
    race:Yup.string().required('Required Field. Please make a selection.'),
    military:Yup.string().required('Required Field. Please make a selection.'),
    ethnicity:Yup.string().required('Required Field. Please make a selection.'),
    
});

export function validateAgainstOptions(value, options){
    let isValid = false;
    if(value !== ""){
        for(let i=0;i<options.length;i++){
            if(options[i].value === value){
                isValid = true;
                break;
            }
        }
    }
    return isValid;
}
function formatPhone(phone) {
  return phone ? phone.replace('/', '-') : '';
}
