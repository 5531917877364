import { useState } from 'react';
import InputField from '../../subcomponents/InputField';
import selectOptions from '../../../data/selectOptions.json';
import mapping from '../../../data/selectValuesMapping.json';
// Import constants defining the companies that have custom labels, fields, or field names.
import { CustomizedCompanies as cc } from '../../../data/Constants';
import parse from 'html-react-parser';
export function CompanyCustomFields({
  companyId,
  employeeID,
  setEmployeeID,
  combinedProfile,
  setRole,
  setRegion,
  setNtid,
}) {
  const jobTitleOptions = selectOptions.jobTitle;
  const [jobTitle, setJobTitle] = useRoleState(
    [cc._ALLSTATE, cc._STATEFARM].includes(companyId)
      ? mapping.jobTitleReverse[companyId][combinedProfile.company.agent_role]
      : '',
  );
  const customCompanyFieldRequired = [
    cc._STATEFARM,
    cc._EDWARDJONES,
    cc._JPM,
    cc._ALLSTATE,
    cc._EQUITABLE,
    cc._PRUDENTIAL,
    cc._THRIVENT,
    cc._WESTERNSOUTHERN,
    cc._KNIGHTSCOLUMBUS,
    cc._FARMERS,
  ].includes(companyId);

  const customCompanyFieldHidden =
    parseInt(cc._NORTHWESTERNMUTUAL) === parseInt(companyId);

  // Clear company-specific fields if company doesn't match
  //    useEffect(()=>{
  //        if(![cc._ALLSTATE].includes(companyId)){
  //            setRegion('');
  //            setNtid('');
  //        }
  //        if(![cc._ALLSTATE, cc._STATEFARM].includes(companyId)){
  //            setRole('');
  //        }
  //    }, [companyId, setRegion, setNtid, setRole]);

  function useRoleState(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
      if (e === undefined) {
        //If e is undefined, set valye and company name to null
        setValue();
        setRole();
      } else {
        setValue(e.target.value.trim());
        setRole(e.target.value.trim());
      }
    }
    return [value, handleChange];
  }
  const [employeeIDLabel, roleLabel] = getCustomizedCompanyFields({
    companyId,
  });
  return (
    <>
      {!customCompanyFieldHidden && (
        <div className="row">
          <div className="cell">
            <label htmlFor="employeeID-field">
              {employeeIDLabel}{' '}
              {customCompanyFieldRequired
                ? parse("<span className='req-star'>*</span>")
                : ''}
            </label>
          </div>
          <div className="cell">
            <InputField
              label={employeeIDLabel}
              id="employeeID-field"
              name="employeeID"
              type="text"
              value={employeeID}
              onChange={(e) => setEmployeeID(e.target.value.trim())}
              srOnly={true}
              maximumLength={companyId === cc._STATEFARM?6:60}
              required={customCompanyFieldRequired ? true : false}
            />
          </div>
        </div>
      )}

      {[cc._ALLSTATE, cc._STATEFARM].includes(companyId) && (
        <div className="row inline-field">
          <div className="cell">
            <label htmlFor="jobtitle-field">
              {roleLabel} <span className="req-star">*</span>
            </label>
          </div>
          <div className="cell">
            <select
              id="jobtitle-field"
              name="jobtitle"
              value={jobTitle}
              onChange={setJobTitle}
              required={true}>
              {jobTitleOptions[companyId].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {[cc._ALLSTATE].includes(companyId) && (
        <>
          <div className="row">
            <div className="cell">
              <label htmlFor="region-field">
                Region <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <InputField
                label="Region"
                id="region-field"
                name="region"
                type="text"
                value={combinedProfile.company.region}
                onChange={(e) => setRegion(e.target.value.trim())}
                maximumLength="11"
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">
              <label htmlFor="ntid-field">
                NTID <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <InputField
                label="NTID"
                id="ntid-field"
                name="ntid"
                type="text"
                value={combinedProfile.company.nt_id}
                onChange={(e) => setNtid(e.target.value.trim())}
                maximumLength="15"
                srOnly={true}
                required={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default CompanyCustomFields;

export function getCustomizedCompanyFields({ companyId }) {
  let employeeIDLabel = 'Agent/Employee ID';
  let roleLabel = 'Job Title';
  switch (companyId) {
    case cc._STATEFARM:
      employeeIDLabel = 'Alias';
      roleLabel = 'Role';
      break;
    case cc._EDWARDJONES:
      employeeIDLabel = 'EDJ P/J #';
      break;
    case cc._JPM:
      employeeIDLabel = 'SID';
      break;
    case cc._ALLSTATE:
      employeeIDLabel = 'Employee ID';
      roleLabel = 'Job Role';
      break;
    case cc._EQUITABLE:
    case cc._PRUDENTIAL:
    case cc._THRIVENT:
    case cc._WESTERNSOUTHERN:
    case cc._KNIGHTSCOLUMBUS:
    case cc._FARMERS:
      employeeIDLabel = 'Employee ID';
      break;
    default:
      break;
  }
  return [employeeIDLabel, roleLabel];
}
