import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { OktaSSOLink } from '../../_okta/config/OktaSSO';

import { useQuery } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import ReadStorage from '../storage/ReadStorage';

import classOptions from '../../data/classOptions.json';

import Accordion from '../subcomponents/Accordion';

export default function CurrentCourses({ courseHistory, setTab }) {
  const { load } = useTacfsService();
  // Pull in the user data from local storage.
  const tacfsProfile = ReadStorage('oktaProfile');
  let designations = [];
  const designationsList = classOptions.designations;

  for (let i = 0; i < courseHistory.length; i++) {
    if (
      courseHistory[i].academic_plan !== 'NOTAPPL' &&
      courseHistory[i].status !== 'BRIGHTSPACE' &&
      !designations.includes(courseHistory[i].academic_plan)
    ) {
      designations.push(courseHistory[i].academic_plan);
    }
  }
  // Get the list of LMS hash courses.
  const hashV1 = courseHistory.filter(
    (course) => course.lms_file_type === 'KOF',
  );
  // Get the list of LMS V4 hash courses.
  const hashV4 = courseHistory.filter((course) => course.lms_type === 'SUB');
  // Get the list of LMS V6 hash courses.
  const hashV6 = courseHistory.filter(
    (course) => course.lms_file_type === 'LFT',
  );
  // Load the V1 hash if there are courses that need it to log in.
  const { isLoading: lmsV1HashLoading, data: lmsV1Hash } = useQuery(
    [`lmsHashV1`],
    () => load('lmsHashV1'),
    { enabled: hashV1.length > 0 },
  );
  // Load the V4 hash if there are courses that need it to log in.
  const { isLoading: lmsV4HashLoading, data: lmsV4Hash } = useQuery(
    [`lmsHashV4`],
    () => load('lmsHashV4'),
    { enabled: hashV4.length > 0 },
  );
  // Load the V6 hash if there are courses that need it to log in.
  const { isLoading: lifterHashLoading, data: lifterHash } = useQuery(
    [`lifterHash`],
    () => load('lifterHash'),
    { enabled: hashV6.length > 0 },
  );
  const gradProgs = [
    'MSM',
    'MSMT',
    'MSFP',
    'MSFPR',
    'MSFPP',
    'FRP',
    'MSFS',
    'MSFSP',
    'MSFSR',
  ];

  const goToHistory = () => {
    setTab(2);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="current-courses container">
      <div className="page-header">
        <h2>Active Programs</h2>
        <p>
          Here’s a snapshot of your current courses with direct access to your
          learning experience and quick links to purchase a textbook, schedule
          an exam, and request CE.
        </p>
      </div>

      <div className="card-layout">
        {designations
          .sort((a, b) => (a > b ? 1 : -1))
          .map((designation) => {
            const nextCourse = calculateNextCourse(courseHistory, designation);

            return (
              <div
                key={designation}
                className="card drop-box padding--lg w-full">
                <div id={designation} className="designation-anchor"></div>

                <Accordion
                  title={
                    designationsList[designation.toLowerCase()] ? (
                      <span className="title3">
                        {parse(
                          designationsList[designation.toLowerCase()].shortName,
                        )}{' '}
                        -
                        {parse(
                          designationsList[designation.toLowerCase()].name,
                        )}
                      </span>
                    ) : (
                      <span className="title3">{designation}</span>
                    )
                  }
                  startOpen={true}
                  id={`${designation}_drawer`}>
                  {courseHistory.sort(sortCourses).map((course) => {
                    // Show "enroll" button for:
                    // Re-enroll courses,
                    // Retake/reschedule courses,
                    // the next required course (purchased or not),
                    // all untaken electives,
                    // all untaken courses for grad programs,
                    // program window extension,
                    // and satisfied courses
                    const reEnroll =
                      course.status?.startsWith('RE-ENROLL') ||
                      course.status?.startsWith('RE ENROLL') ||
                      course.status?.startsWith('ENROLL');
                    const rtRs =
                      course.status === 'RESCHEDULE' ||
                      course.status === 'RETAKE' ||
                      course.status === 'EXAM RESCHEDULE' ||
                      course.status === 'EXAM RETAKE';
                    const nextInSeq = course.course_sequence === nextCourse;
                    const availableElective =
                      (!course.status || course.status === 'PURCHASED') &&
                      course.course_type === 'ELECTIVE';
                    const availableGradCourse =
                      (!course.status || course.status === 'PURCHASED') &&
                      gradProgs.includes(designation);

                    const showEnroll =
                      course.enroll_perm !== 'N' &&
                      designationsList[designation.toLowerCase()] &&
                      designationsList[designation.toLowerCase()]?.sunset !==
                        true &&
                      (reEnroll ||
                        rtRs ||
                        nextInSeq ||
                        availableElective ||
                        availableGradCourse ||
                        course.status === 'PROGRAM WINDOW EXTENSION' ||
                        course.status === 'SATISFIED');

                    const completed =
                      course.status === 'COMPLETED' ||
                      course.status === 'SATISFIED';

                    const showGrade = completed && course.course_grade_off;

                    // if SATISFIED, still show info as they can take the course again
                    const showCourseInfo =
                      course.status && course.status !== 'COMPLETED';

                    const inProgress =
                      course.status === 'IN PROGRESS' ||
                      course.status === 'IN-PROGRESS' ||
                      course.status?.startsWith('CLASS STARTS ON');

                    const startDate = course.class_start
                      ? displayDate(course.class_start)
                      : '';
                    const endDate = course.class_end
                      ? displayDate(course.class_end)
                      : '';
                    const startTime = course.start_time
                      ? displayTime(course.start_time)
                      : '';
                    const endTime = course.end_time
                      ? displayTime(course.end_time)
                      : '';
                    const schedBy = course.exam_window_date
                      ? displayDate(course.exam_window_date)
                      : '';

                    return (
                      designation === course.academic_plan && (
                        <div
                          key={course.course_id}
                          className={
                            showCourseInfo || showGrade
                              ? 'section-wrapper padding--sm show-info'
                              : 'section-wrapper padding--sm no-show-info flex'
                          }>
                          <DisplayStatus course={course} />

                          <div className="course-name">
                            <div className="title4">{course.course}</div>
                            <p className="subtitle p2">{course.course_title}</p>
                          </div>

                          {(showCourseInfo || showGrade) && <Divider />}

                          <div className="course-content-wrapper flex">
                            {(showCourseInfo || showGrade) && (
                              <div className="course-data table">
                                {showCourseInfo && (
                                  <>
                                    <div className="row">
                                      <div className="cell">Date</div>
                                      <div className="cell">
                                        {startDate && endDate
                                          ? startDate + ' - ' + endDate
                                          : 'N/A'}
                                      </div>
                                    </div>
                                    {course.class_days?.includes('Y') && (
                                      <div className="row">
                                        <div className="cell">Days</div>
                                        <div className="cell">
                                          <DisplayDays course={course} />
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="cell">Time</div>
                                      <div className="cell">
                                        {startTime && endTime
                                          ? startTime + ' - ' + endTime
                                          : 'N/A'}
                                      </div>
                                    </div>
                                    {course.instruction_mode?.trim() && (
                                      <div className="row">
                                        <div className="cell">Format</div>
                                        <div className="cell">
                                          {parse(
                                            mapFormat(course.instruction_mode),
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="cell">
                                        Academic Credit Hours
                                      </div>
                                      <div className="cell">
                                        {displayCredits(course.course_credit)}
                                      </div>
                                    </div>
                                    {schedBy && (
                                      <div className="row">
                                        <div className="cell">
                                          Exam Deadline
                                        </div>
                                        <div className="cell">{schedBy}</div>
                                      </div>
                                    )}

                                    {course.proctor_name?.trim() && (
                                      <div className="row">
                                        <div className="cell">
                                          Proctor Requested
                                        </div>
                                        <div className="cell">
                                          {course.proctor_name.trim()}
                                          {course.proctor_email?.trim() &&
                                            ` / ${course.proctor_email.trim()}`}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}

                                {showGrade && (
                                  <div className="row">
                                    <div className="cell">Grade</div>
                                    <div className="cell">
                                      {course.course_grade_off}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="course-buttons">
                              {showEnroll &&
                                (course.status === 'PURCHASED' ? (
                                  // PURCHASED courses get $0 enrollment
                                  <OktaSSOLink
                                    classes="btn"
                                    enroll={`${course.tac_course_id?.trim()}`}
                                    program={`${course.academic_plan.toLowerCase()}`}>
                                    Enroll
                                  </OktaSSOLink>
                                ) : (
                                  // All other courses
                                  <OktaSSOLink
                                    classes="btn"
                                    program={`${course.academic_plan.toLowerCase()}`}
                                    course={`${course.tac_course_id?.trim()}`}>
                                    Enroll
                                  </OktaSSOLink>
                                ))}

                              {(inProgress || completed) &&
                                course.lms_file_type !== 'KOF' &&
                                course.lms_file_type !== 'SUB' &&
                                course.lms_file_type !== 'SIM' &&
                                course.lms_file_type !== 'LFT' &&
                                course.lms_url && (
                                  <Link
                                    to={{ pathname: course.lms_url }}
                                    target="_blank"
                                    className="btn">
                                    Access Course
                                  </Link>
                                )}
                              {(inProgress || completed) &&
                                course.lms_file_type === 'SIM' && (
                                  <a
                                    href="https://tac-wmcp.regis-co.com/?okta"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn">
                                    Access Course
                                  </a>
                                )}
                              {(inProgress || completed) &&
                                course.lms_file_type === 'KOF' &&
                                course.lms_url && (
                                  <form
                                    target="_blank"
                                    rel="noreferrer"
                                    action={course.lms_url}
                                    method="post"
                                    id={`knowledgeOfferingsLoginForm${course.course_id}`}
                                    acceptCharset="UTF-8">
                                    <div>
                                      {lmsV1HashLoading === false && (
                                        <>
                                          <input
                                            type="submit"
                                            value="Access Course"
                                            name="access_course"
                                            className="btn"
                                          />
                                          <input
                                            type="hidden"
                                            name="student_id"
                                            value={
                                              tacfsProfile.profile.studentID
                                            }
                                          />
                                          <input
                                            type="hidden"
                                            name="hash"
                                            value={lmsV1Hash.value}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </form>
                                )}
                              {(inProgress || completed) &&
                                course.lms_file_type === 'SUB' &&
                                course.lms_url && (
                                  <form
                                    target="_blank"
                                    action={course.lms_url}
                                    rel="noreferrer"
                                    method="post"
                                    id={`knowledgeOfferingsLoginForm${course.course_id}`}
                                    acceptCharset="UTF-8">
                                    <div>
                                      {lmsV4HashLoading === false && (
                                        <>
                                          <input
                                            type="submit"
                                            value="Access Course"
                                            name="access_course"
                                            className="btn"
                                          />
                                          <input
                                            type="hidden"
                                            name="student_id"
                                            value={
                                              tacfsProfile.profile.studentID
                                            }
                                          />
                                          <input
                                            type="hidden"
                                            name="hash"
                                            value={lmsV4Hash.value}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </form>
                                )}
                              {(inProgress || completed) &&
                                course.lms_file_type === 'LFT' &&
                                course.lms_url && (
                                  <form
                                    target="_blank"
                                    rel="noreferrer"
                                    action={course.lms_url}
                                    method="post"
                                    id={`liftersso${course.course_id}`}
                                    acceptCharset="UTF-8"
                                    cr-attached="true"
                                    cr-form-attach-retry="true">
                                    <div>
                                      {lifterHashLoading === false && (
                                        <>
                                          <input
                                            type="submit"
                                            value="Access Course"
                                            name="access_course"
                                            className="btn"
                                          />
                                          <input
                                            type="hidden"
                                            name="student_id"
                                            value={
                                              tacfsProfile.profile.studentID
                                            }
                                          />
                                          <input
                                            type="hidden"
                                            name="hash"
                                            value={lifterHash.value}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </form>
                                )}
                              {course.textbook_available === 'Y' &&
                                course.status !== 'COMPLETED' && (
                                  <OktaSSOLink
                                    classes="btn secondary"
                                    addons={`${course.product_list}`}>
                                    Purchase Textbook
                                  </OktaSSOLink>
                                )}

                              {inProgress &&
                                dayjs() <= dayjs(course.exam_window_date) &&
                                course.class_exam_type?.toUpperCase() ===
                                  'EX' && (
                                  <>
                                    {!course.exam_status?.trim() ||
                                    course.exam_status?.trim() ===
                                      'Canceled' ? (
                                      <Link
                                        to={{
                                          pathname: 'https://www.pearsonvue.com/us/en/tac.html',
                                        }}
                                        target="_blank"
                                        className="btn">
                                        Schedule Exam
                                      </Link>
                                    ) : (
                                      <button
                                        className="btn secondary disabled"
                                        disabled>
                                        Schedule Exam
                                      </button>
                                    )}
                                  </>
                                )}

                              {inProgress &&
                                dayjs() <= dayjs(course.exam_window_date) &&
                                course.proctor_flag === 'Y' && (
                                  <>
                                    {!course.proctor_form_status?.trim() ? (
                                      <Link
                                        to={`/proctor-registration/${course.brightspace_class_id}/add-proctor`}
                                        className="btn secondary"
                                        relative="path">
                                        Select a Proctor
                                      </Link>
                                    ) : (
                                      <>
                                        <Link
                                          to={`/proctor-registration/${course.brightspace_class_id}/add-proctor`}
                                          className="btn secondary"
                                          relative="path">
                                          <span>
                                            Proctor {course.proctor_status}
                                          </span>
                                          <br />
                                          <span>
                                            Select a different proctor?
                                          </span>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                )}

                              {/* Not doing this for launch */}
                              {/*inProgress &&
                              course.ce_available === 'Y' && (
                                <>
                                  {ceRequested ? (
                                    <a
                                      href="#"
                                      className="btn secondary disabled"
                                      disabled
                                    >
                                      CE Requested
                                    </a>
                                  ) : (
                                    <a href="#" className="btn secondary">
                                      Request CE
                                    </a>
                                  )}
                                </>
                              )*/}

                              {completed && (
                                <button
                                  className="btn secondary"
                                  onClick={goToHistory}>
                                  Course History
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </Accordion>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function DisplayDays({ course }) {
  const classDays = course.class_days.split(',');
  let days = [];

  if (classDays[0] === 'Y') days.push('Mon');
  if (classDays[1] === 'Y') days.push('Tues');
  if (classDays[2] === 'Y') days.push('Wed');
  if (classDays[3] === 'Y') days.push('Thurs');
  if (classDays[4] === 'Y') days.push('Fri');
  if (classDays[5] === 'Y') days.push('Sat');
  if (classDays[6] === 'Y') days.push('Sun');

  if (days.length === 0) {
    return <span>N/A</span>;
  } else {
    return <span>{days.join(', ')}</span>;
  }
}

function displayDate(date) {
  const display = dayjs(date).format('M/D/YYYY');
  if (display === 'Invalid Date' || display === '1/1/1900') return '';
  return display;
}

function displayTime(time) {
  // dayjs cannot format a time by itself
  const display = dayjs('1900-01-01 ' + time).format('h:mma');
  if (display === 'Invalid Date') return '';
  return display;
}

function DisplayStatus({ course }) {
  let status;

  if (course.status?.startsWith('CLASS STARTS ON')) status = 'IN PROGRESS';
  else if (
    course.status?.startsWith('RE-ENROLL') ||
    course.status?.startsWith('RE ENROLL') ||
    course.status?.startsWith('ENROLL')
  )
    status = 'RE ENROLL';
  else status = course.status;

  switch (status) {
    case 'COMPLETED':
    case 'SATISFIED':
      return <div className="lightgray status-tag">Completed</div>;
    case 'PURCHASED':
      return <div className="mist status-tag">Purchased</div>;
    case 'RE ENROLL':
      return <div className="red status-tag">Re-enroll</div>;
    case 'PROGRAM WINDOW EXTENSION':
      return <div className="red status-tag">Window Extension</div>;
    case 'IN PROGRESS':
    case 'IN-PROGRESS':
      return <div className="sky status-tag">Active</div>;
    case 'RESCHEDULE':
    case 'EXAM RESCHEDULE':
      return <div className="lilac status-tag">Reschedule</div>;
    case 'RETAKE':
    case 'EXAM RETAKE':
      return <div className="lilac status-tag">Retake</div>;
    default:
      if (course.course_type === 'ELECTIVE')
        return <div className="peach status-tag">Elective</div>;
      else
        return (
          <div className="red status-tag">
            {course.course_type?.charAt(0)?.toUpperCase() +
              course.course_type?.slice(1)?.toLowerCase()}
          </div>
        );
  }
}

function displayCredits(credits) {
  const words = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ];
  const creditsNum = parseInt(credits);

  if (isNaN(creditsNum)) return 'N/A';
  return `${words[creditsNum]} (${credits})`;
}

const calculateNextCourse = (courseHistory, designation) => {
  let lowestSeq = 0,
    nextCourse = '';

  for (let i = 0; i < courseHistory.length; i++) {
    if (courseHistory[i].academic_plan === designation) {
      // Can only enroll in next required or purchased course
      // Ignore electives as they shouldn't prevent from enrolling in next required course
      const req =
        !courseHistory[i].status?.trim() ||
        courseHistory[i].status?.trim() === 'PURCHASED';
      const notElective = courseHistory[i].course_type?.trim() !== 'ELECTIVE';
      const newLowest =
        !lowestSeq || parseInt(courseHistory[i].course_sequence) < lowestSeq;

      if (req && notElective && newLowest) {
        lowestSeq = parseInt(courseHistory[i].course_sequence);
        nextCourse = courseHistory[i].course_sequence;
      }
    }
  }

  return nextCourse;
};

const sortCourses = (a, b) => {
  const order = [
    'IN PROGRESS',
    'RESCHEDULE',
    'RETAKE',
    'RE ENROLL',
    'PROGRAM WINDOW EXTENSION',
    'PURCHASED',
    'REQUIRED',
    'SATISFIED',
    'COMPLETED',
  ];

  let statusA = a.status?.trim() ? a.status.trim() : 'REQUIRED';
  let statusB = b.status?.trim() ? b.status.trim() : 'REQUIRED';

  if (statusA.startsWith('CLASS STARTS ON') || statusA === 'IN-PROGRESS')
    statusA = 'IN PROGRESS';
  else if (
    statusA.startsWith('RE-ENROLL') ||
    statusA.startsWith('RE ENROLL') ||
    statusA.startsWith('ENROLL')
  )
    statusA = 'RE ENROLL';
  else if (statusA === 'EXAM RESCHEDULE') statusA = 'RESCHEDULE';
  else if (statusA === 'EXAM RETAKE') statusA = 'RETAKE';

  if (statusB.startsWith('CLASS STARTS ON') || statusB === 'IN-PROGRESS')
    statusB = 'IN PROGRESS';
  else if (
    statusB.startsWith('RE-ENROLL') ||
    statusB.startsWith('RE ENROLL') ||
    statusB.startsWith('ENROLL')
  )
    statusB = 'RE ENROLL';
  else if (statusB === 'EXAM RESCHEDULE') statusB = 'RESCHEDULE';
  else if (statusB === 'EXAM RETAKE') statusB = 'RETAKE';

  const diff = order.indexOf(statusA) - order.indexOf(statusB);

  if (diff !== 0) return diff;

  return parseInt(a.course_sequence) - parseInt(b.course_sequence);
};

const mapFormat = (instructionMode) => {
  switch (instructionMode.toLowerCase().trim()) {
    case 'cp':
      return 'CP';
    case 'es':
      return 'Exam Reschedule';
    case 'et':
      return 'Exam Retake';
    case 'gr':
      return 'GR';
    case 'gs':
      return 'Guided Study';
    case 'irp':
      return 'IRP';
    case 'lc':
      return 'Live Class';
    case 'os':
      return 'Online Self Study';
    case 'oe':
      return 'Personal Pathway&reg;';
    case 'pe':
      return 'Program Extension';
    case 're':
      return 'Re-enroll';
    case 'rs':
    case 'reschedule':
      return 'Reschedule';
    case 'rt':
    case 'retake':
      return 'Retake';
    case 'rip':
      return 'RFP';
    case 'self_study':
    case 'st':
      return 'Self Study';
    case 'sc':
      return 'Subscription';
    case 'wb':
    case 'webinar':
      return 'Webinar';
    default:
      return instructionMode.toUpperCase().trim();
  }
};
