import { useParams } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { AddProctorForm } from '../components/proctorRegistration/AddProctorForm';

export function AddProctor() {
  const { classId } = useParams();

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container">
        <div className="intro-content-wrapper">
          <AddProctorForm classId={classId} />
        </div>
      </div>
    </Layout>
  );
}
