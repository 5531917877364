import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from 'react-query';

import useProfileQuery from '../utils/tacfs/useProfileQuery';
import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { DesignationAppForm } from '../components/DesignationApplication/DesignationAppForm';
import Timer from '../components/subcomponents/Timer';

import classOptions from '../data/classOptions.json';

export function DesignationApplication() {
  const { authState } = useOktaAuth();
  const { designation } = useParams();
  const { load } = useTacfsService();
  const designations = classOptions.designations;

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const { data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );

  const { profileLoading, profileData } = useProfileQuery(userData);
  const [profile, setProfile] = useState(profileData);
  //  @TODO: This needs to be refactored as the
  //         the prfileData should be in the
  //         dependency array but cant add it
  //         bc the profileData gets updated in
  //         the useEffect hook.
  useEffect(() => {
    if (
      profileData.basic &&
      profileData.contact &&
      profileData.education &&
      profileData.company &&
      userData
    ) {
      setProfile(profileData);
    }
    // eslint-disable-next-line
  }, [profileLoading, userData]);

  const { isLoading: statusLoading, data: statusData } = useQuery(
    ['designationAppStatus'],
    () => load('designationAppStatus'),
  );

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (statusLoading === false && statusData) {
      // Make sure the designation application form is valid.
      // 1. Must match the designation passed in
      // 2. The status must be E or O
      var validStatus =
        Array.isArray(statusData) &&
        statusData.filter(function (status) {
          return (
            status.acad_plan === designation.toUpperCase() &&
            (status.item_statuts === 'E' || status.item_statuts === 'O')
          );
        })[0];
      if (validStatus) setValid(true);
    }
  }, [statusLoading, statusData, designation]);

  if (statusLoading)
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );

  if (valid === false)
    return (
      <Layout>
        <Breadcrumbs />
        <h1>Access Denied!</h1>
      </Layout>
    );

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container designation-app">
        {profile.basic &&
          profile.contact &&
          profile.education &&
          profile.company && (
            <>
              <h1 className="page-title">
                {designations[designation.toLowerCase()] ? (
                  <>
                    {parse(designations[designation.toLowerCase()].shortName)}
                  </>
                ) : (
                  <>{designation}</>
                )}
                Designation Application
              </h1>
              <DesignationAppForm
                designation={designation.toLowerCase()}
                profile={profile}
              />
            </>
          )}
      </div>
    </Layout>
  );
}
