import useTacfsService from '../../utils/tacfs/useTacfsService';
import { useQuery } from 'react-query';
import ReadStorage from '../../components/storage/ReadStorage';
import { useRef, useEffect, useState } from 'react';
import { ResourceItem } from '../resources/ResourceItem';
import { useMediaQuery } from 'react-responsive';
import { register } from 'swiper/element/bundle'; // https://swiperjs.com/element#usage-with-react
import { Link } from 'react-router-dom';
register();

export function StaticSavedResources() {
  const limit = 4;
  const oktaProfile = ReadStorage('oktaProfile');
  const resourceKeysBase = { oktaID: oktaProfile.id, offset: 0, limit: limit };
  const { load } = useTacfsService();
  const swiperElRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState('01');

  const { isLoading, data } = useQuery(['savedResourcesDashboard'], () =>
    load('savedResources', resourceKeysBase),
  );
  const applySlideChangeHandler = () => {
    swiperElRef?.current?.addEventListener('slidechange', (e) => {
      const [swiper] = e.detail;
      setIndex(swiper.activeIndex);
    });
  };
  const isMobileWidth = useMediaQuery(
    { query: '(max-width: 629px)' },
    undefined,
    applySlideChangeHandler,
  );
  const setIndex = (index) => {
    const paddedIndex = (index + 1).toString().padStart(2, '0');
    setSlideIndex(paddedIndex);
  };
  const navigate = (swiperRef, direction) => {
    const swiper = swiperRef.current.swiper;
    switch (direction) {
      case 'prev':
        swiper.slidePrev();
        break;
      case 'next':
        swiper.slideNext();
        break;
      default:
        swiper.slideTo(0);
        break;
    }
  };

  useEffect(() => {
    applySlideChangeHandler();
  }, []);

  return (
    <div className="card w-full saved-resource-module resources">
      <div className="title3">
        <Link to="/resources#saved-resources"> Saved Resources</Link>
      </div>
      {isLoading && 'Loading your Saved Resources...'}
      {!isLoading &&  !Array.isArray(data) && ('Resources are currently unavailable. Please check back later.')}
      {/*!isLoading &&
        ((data && data.length === 0) || !data) && ('No Saved Resources.')*/}

      {!isMobileWidth &&
        !isLoading &&
        data &&
        Array.isArray(data) &&
        data.length > 0 && (
          <div className="static resourceGrid">
            {data.map((resource, i) => (
              <ResourceItem
                key={resource.nid}
                resource={resource}
                saved={true}
              />
            ))}
          </div>
        )}
      {isMobileWidth &&
        !isLoading &&
        data &&
        Array.isArray(data) &&
        data.length > 0 && (
          <div className="swiper">
            <swiper-container
              ref={swiperElRef}
              slides-per-view="1"
              navigation="false"
              pagination="false"
            >
              {data.map((resource, i) => (
                <swiper-slide key={resource.nid}>
                  <ResourceItem resource={resource} saved={true} />{' '}
                </swiper-slide>
              ))}
            </swiper-container>
            <div className="swiper-btm-bar">
              <p>
                <span data-current>{slideIndex}</span>
                <span>/</span>
                <span data-total>
                  {data.length.toString().padStart(2, '0')}
                </span>
              </p>
              <div className="arrows">
                <div
                  className="prev"
                  data-swiper-prev
                  onClick={() => navigate(swiperElRef, 'prev')}
                >
                  <img
                    src="/icons/blue-arrow.svg"
                    alt="View previous saved resource"
                  />
                </div>
                <div
                  className="next"
                  data-swiper-next
                  onClick={() => navigate(swiperElRef, 'next')}
                >
                  <img
                    src="/icons/blue-arrow.svg"
                    alt="View next saved resource"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
