import { useEffect, useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import useTacfsService from '../utils/tacfs/useTacfsService';
import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import dayjs from 'dayjs';
import { AuthDetails } from '../components/login/AuthDetails';
import Timer from '../components/subcomponents/Timer';
import ReadStorage from '../components/storage/ReadStorage';
import { OktaSSOLink } from '../_okta/config/OktaSSO';

export function SubsLandingPage() {
 const ref = useRef();
 const [error, setError] = useState(null);
 const [hash, setHash] = useState(null);
 const [openLink, setOpenLink] = useState(null);
 const [membershipStatus/*, setMembershipStatus*/] = useState("Active");
 const [subscriptionStatus, setSubscriptionStatus] = useState();
 
 const tacfsProfile = ReadStorage('oktaProfile');
 const { load, save } = useTacfsService();
 const { isLoading, data } = useQuery(['studentFlags'], () =>
    load('studentFlags'),{enabled:false}
  );
  const { isLoading: khPlusHashLoading, mutate: khPlusHash } = useMutation(() =>
    save('khPlusHash'),
  );

  const onLifterClick = (e) => {
    e.preventDefault();
    if (khPlusHashLoading === false && hash === null) {
      khPlusHash([], {
        onError: (res) => setError('Sorry, something went wrong.'),
        onSuccess: (data) => {
          if (data && data.value) {
            // Save the report data.
            setHash(data.value);
          } else {
            setError(
              'We encountered an error retrieving the students hash value.',
            );
          }
        },
      });
    }
    setOpenLink(true);
  };

  useEffect(() => {
    if (khPlusHashLoading === false && hash && openLink) {
      ref.current.click();
      setOpenLink(false);
    }
  }, [khPlusHashLoading, hash, openLink]);
    
    useEffect(()=>{
      if(!isLoading && data){
        if(data.khp_subscription_id){
            setSubscriptionStatus(data.subscription_status);
        }
      }
    }, [isLoading, data]);
  
  return (
    <Layout>
      <Breadcrumbs />
      <div className="container dashboard">
        <div className="intro-content-wrapper">
        <AuthDetails page="subscriptions" />
          <p className="page-intro-text lg:w-2/3">
            As a subscriber to Knowledge Hub+, you have 24/7 access to a robust library of just-in-time learning delivered by over 35 leading industry experts. Benefit from new sessions added monthly, live quarterly events, and automated reporting to The College and the CFP-Board for approved continuing education credit.
          </p>
        </div>
        <h2>Subscription Details</h2>
         <div className="column-layout">
            <p className="page-intro-text">
            Below are the details regarding your subscription, including the initial purchase date, the term for your subscription, the annual charge, and your renewal date. To update your billing information or cancel your subscription, please click Manage Subscription. You will then be directed to complete a two-factor authentication process, which starts with providing your email address and entering the code emailed to you. 
            </p>
            <div className="card">
                {khPlusHashLoading === false && hash && (
                <form
                  style={{ display: 'none' }}
                  target="_blank"
                  action={process.env.REACT_APP_KNOWLEDGE_HUB_PLUS_URL}
                  rel="noreferrer"
                  method="post"
                  id={`knowledgeOfferingsPlusLoginForm`}
                  acceptCharset="UTF-8">
                  <div>
                    <>
                      <div className="see-all" style={{ display: 'flex' }}>
                        <div>
                          <input
                            type="submit"
                            ref={ref}
                            name="access_course"
                            value={`Access Knowledge Hub+ `}
                          />
                        </div>
                      </div>
                      <input
                        type="hidden"
                        name="student_id"
                        value={tacfsProfile.profile.studentID}
                      />
                      <input type="hidden" name="hash" value={hash} />
                    </>
                  </div>
                </form>
              )}
              {khPlusHashLoading && <Timer />}
              <button className="btn" onClick={onLifterClick} style={{float:'right'}}>
                Access Knowledge Hub+
              </button>
              <p className="red-text">{error}</p>
          </div> 
          </div>
          
        {!isLoading && data?.empl_id !== "undefined" && data && (
        <div className='card drop-box padding--sm  lg:w-2/3'>
            <div className="flex card-header">
              <h4>Knowledge Hub+ Subscription</h4>
              <a href={process.env.REACT_APP_ECOMM_ACCT_URL} target="_blank" rel="noreferrer" className="btn secondary large desktop">
                  Manage Subscription
              </a>
            </div>
            <br /><hr/><br/>
            {data && (<>
            <div className="table display-data">
              <div className="row">
                <div className="cell">Membership Status</div>
                <div className="cell">{membershipStatus}</div>
              </div>
              <div className="row">
                <div className="cell">Subscription Status</div>
                <div className="cell">
                    {subscriptionStatus}
                    {subscriptionStatus?.toUpperCase()==="ACTIVE"?"":" (Access expires on "+ dayjs(data.khp_subscription_date).add(1, "year").format('M/D/YYYY') +")"}
                </div>
              </div>
              <div className="row">
                <div className="cell">Next Billing Cycle</div>
                <div className="cell">
                    {subscriptionStatus?.toUpperCase() === 'ACTIVE' && (dayjs(data.khp_subscription_date).add(1, "year").format('M/D/YYYY'))}
                    {subscriptionStatus?.toUpperCase() === 'PAUSED' && (
                        <>Subscription is paused, click <a href={process.env.REACT_APP_ECOMM_ACCT_URL} target='_blank' rel='noreferrer' class='standard-link'>here</a> to resume.</>
                    )}
                    {subscriptionStatus?.toUpperCase() === 'SKIPPED' && (dayjs(data.khp_subscription_date).add(2, "year").format('M/D/YYYY'))}
                    {subscriptionStatus?.toUpperCase() === 'CANCELLED' && (<>
                        Subscription will not auto-renew, please <OktaSSOLink classes="standard-link">re-enroll</OktaSSOLink> to gain access.
                    </>)}
                </div>
              </div>
              <div className="row">
                <div className="cell">Price</div>
                <div className="cell">$179.00/year</div>
              </div>
              <div className="row">
                <div className="cell">Purchased</div>
                <div className="cell">{dayjs(data.khp_subscription_date).format('M/D/YYYY')}</div>
              </div>
             <div className="row">
                <div className="cell">Subscription Duration</div>
                <div className="cell">Annual Subscription</div>
              </div>
              <div className="row">
                <div className="cell">Subscription Email</div>
                <div className="cell">{data.khp_subscription_email}</div>
              </div>
            </div><br /></>)}
             <a href={process.env.REACT_APP_ECOMM_ACCT_URL} target="_blank" rel="noreferrer" className="btn secondary large mobile">
                  Manage Subscription
              </a>
         </div> 
         )}  
      </div>
    </Layout>
  );
}
