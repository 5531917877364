import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import { Formik, Field } from 'formik';
import FileUpload from '../subcomponents/FileUpload';
import Timer from '../subcomponents/Timer';
import * as Yup from 'yup';

export function CfpTransferOfCreditForm(props) {
  const [error, setError] = useState('');

  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();

  const { isLoading: studentLoading, data: student } = useQuery(
    ['studentInitial'],
    () => load('studentInitial'),
  );
  const { isLoading: tocLoading, mutate: transferCredit } = useMutation(
    (values) => save('transferOfCredit', values),
  );

  const formSubmit = async (values) => {
    // Submit transfer of credit
    transferCredit(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data.status === 'S') props.showSent();
        else setError('There was an error with the form submission.');
      },
    });
  };

  if (studentLoading || tocLoading) return <Timer />;

  // If no student is available that means the user does not have
  // access to this form.
  if (student === false && studentLoading === false)
    return <h2>Access Denied!</h2>;

  return (
    <>
      <h4 class="begin-app">Begin your application</h4>
      {error && <p className="red-text">{error}</p>}
      <Formik
        initialValues={{
          phone: '',
          email: '',
          whenCompleted: '',
          clu: [],
          chfc: [],
          certificationLetter: [],
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit} id="transferOfCredit">
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="firstname">
                  Student ID:<span>*</span>
                </label>
                <Field
                  type="text"
                  id="studentid"
                  name="studentid"
                  value={`Student ID: ${student.studentInfoInitial.empl_id}`}
                  disabled
                />
              </div>
            </div>
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="firstname">
                  First Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={student.studentInfoInitial.first_name}
                  disabled
                />
              </div>
              <div className="field col">
                <label htmlFor="firstname">
                  Last Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={student.studentInfoInitial.last_name}
                  disabled
                />
              </div>
            </div>
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="email">
                  Email Address<span>*</span>
                </label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className={touched.email && errors.email ? 'error' : null}
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email Address*"
                  onBlur={handleBlur}
                />
                {touched.email && errors.email && (
                  <span className="red-text">{errors.email}</span>
                )}
              </div>
              <div className="field col">
                <label htmlFor="phone">
                  Personal Phone Number<span>*</span>
                </label>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  className={touched.phone && errors.phone ? 'error' : null}
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="Personal Phone Nmber*"
                  onBlur={handleBlur}
                />
                {touched.phone && errors.phone && (
                  <span className="red-text">{errors.phone}</span>
                )}
              </div>
            </div>
            <div><strong>
              When Did You Complete The CFP<sup>®</sup> Exam?*
              </strong></div>
            <div className="field field-radio complete-exam">
              <label>
                <Field
                  type="radio"
                  name="whenCompleted"
                  value="L"
                  checked={values.whenCompleted === 'L'}
                  onChange={() => setFieldValue('whenCompleted', 'L')}
                />
                Less than seven years ago
              </label>
              <label>
                <Field
                  type="radio"
                  name="whenCompleted"
                  value="M"
                  checked={values.whenCompleted === 'M'}
                  onChange={() => setFieldValue('whenCompleted', 'M')}
                />
                More than seven years ago
              </label>
              {touched.whenCompleted && errors.whenCompleted && (
                <span className="red-text">{errors.whenCompleted}</span>
              )}
            </div>
            <div>
              CLU<sup>®</sup> Chartered Life Underwriter
            </div>

            <div>
              For students who completed all or some CFP<sup>®</sup>{' '}
              Certification Education Program coursework and wish to earn the
              CLU<sup>®</sup> designation, please indicate which
              required/elective courses you are transferring in:
            </div>
            <div className="form-layout clu-courses">
              <div className="col--third">
                <strong>Required:</strong>
                <div className="field field-checkbox">
                  <label>
                    <Field type="checkbox" name="clu" value="HS330" />
                    HS 330
                  </label>
                </div>
              </div>
              <div className="col--two-thirds">
                <strong>Electives:</strong>
                <div className="field field-checkbox field-electives">
                  <label>
                    <Field type="checkbox" name="clu" value="HS300" />
                    HS 300
                  </label>
                  <label>
                    <Field type="checkbox" name="clu" value="HS311" />
                    HS 311
                  </label>
                  <label>
                    <Field type="checkbox" name="clu" value="HS321" />
                    HS 321
                  </label>
                  <label>
                    <Field type="checkbox" name="clu" value="HS326" />
                    HS 326
                  </label>
                  <label>
                    <Field type="checkbox" name="clu" value="HS328" />
                    HS 328
                  </label>
                  {touched.clu && errors.clu && (
                    <span className="red-text">{errors.clu}</span>
                  )}
                </div>
              </div>
            </div>

            <div>
              ChFC<sup>®</sup> Chartered Financial Consultant
            </div>

            <div>
              For students who completed all or some CFP<sup>®</sup>{' '}
              Certification Education Program coursework and wish to earn the
              ChFC<sup>®</sup> designation, please indicate which
              required/elective courses you are transferring in:
            </div>
            <strong>Required:</strong>
            <div className="field field-checkbox">
              <div className="form-layout">
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS300" />
                  HS 300
                </label>
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS311" />
                  HS 311
                </label>
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS321" />
                  HS 321
                </label>
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS326" />
                  HS 326
                </label>
              </div>
              <div className="form-layout">
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS328" />
                  HS 328
                </label>
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS330" />
                  HS 330
                </label>
                <label className="col-quarter">
                  <Field type="checkbox" name="chfc" value="HS333" />
                  HS 333
                </label>
              </div>
              {touched.chfc && errors.chfc && (
                <span className="red-text">{errors.chfc}</span>
              )}
            </div>
            <div>
              Upload Your Official CFP<sup>®</sup> Certification Verification
              Letter
            </div>
            <div>
              <p>
                Please note that as a CFP<sup>®</sup> professional, you must
                provide with this application a verification letter of the award
                of your certification and its current good standing. This may be
                obtained by contacting:
              </p>
              <p>
                <strong>CFP Board of Standards</strong>
                <br />
                <strong>1425 K Street, NW Suite 500</strong>
                <br />
                <strong>Washington, DC 20005</strong>
              </p>
            </div>
            <FileUpload
              selector="certificationLetter"
              values={values.certificationLetter}
              setFieldValue={setFieldValue}
              setErrors={setErrors}
              errors={errors.certificationLetter}
              uploadCount={1}
            />
            <div className="flex w-full column-layout">
              <button type="submit" form="transferOfCredit" className="btn col cfp-transfer-of-credit">
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className="disclaimer">
        <p>
          Certified Financial Planner Board of Standards Inc. owns the marks CFP
          <sup>®</sup>, CERTIFIED FINANCIAL PLANNER™ and CFP (with flame logo)
          <sup>®</sup>, which it awards to individuals who successfully complete
          initial and ongoing certification requirements
        </p>
      </div>
    </>
  );
}

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const ValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, '*Invalid phone format')
    .required('*Phone number required'),
  email: Yup.string().email('Invalid email').required('*Required'),
  whenCompleted: Yup.string().required('The field is required.'),
  clu: Yup.array().min(1, 'Atleast One Required'),
  chfc: Yup.array().min(1, 'Atleast One Required'),
  certificationLetter: Yup.array().min(
    1,
    'Required - Please be sure to upload a CFP® Verification Letter.',
  ),
});
