import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from 'react-query';

import ReadStorage from '../components/storage/ReadStorage';

import useProfileQuery from '../utils/tacfs/useProfileQuery';
import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import ProfileTabs from '../components/profile/ProfileTabs';
import ProgressBar from '../components/subcomponents/ProgressBar';
import { AuthDetails } from '../components/login/AuthDetails';
import Timer from '../components/subcomponents/Timer';

export function MyProfile() {
  const { authState } = useOktaAuth();
  const { load } = useTacfsService();

  const [percentComplete, setPercentComplete] = useState(
    parseInt(ReadStorage('percentComplete')),
  );
  const progressTitle = `Profile Complete: ${percentComplete}%`;

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const { isLoading: userDataLoading, data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );

  const { profileLoading, profileData } = useProfileQuery(userData);
  const [profile, setProfile] = useState(profileData);
  //  @TODO: This needs to be refactored as the
  //         the prfileData should be in the
  //         dependency array but cant add it
  //         bc the profileData gets updated in
  //         the useEffect hook.
  useEffect(() => {
    if (
      profileData.basic &&
      profileData.contact &&
      profileData.education &&
      profileData.company &&
      profileData.flags &&
      userData
    ) {
      setProfile(profileData);
    }
  }, [profileLoading, userData]);

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container my-profile">
        <div className="column-layout intro-content-wrapper">
          <div className="col">
            <AuthDetails page="my profile" />
            <p className="page-intro-text">
              Review your current personal and professional information, select
              payment methods, and make sure your account remains secure. This
              dashboard view gives you access to it all in one place!
            </p>
          </div>
          <div className="col profile-complete">
            <ProgressBar
              percentComplete={`${percentComplete}%`}
              title={progressTitle}
            />
          </div>
        </div>
        <div>
          {(profileLoading || userDataLoading) && <Timer />}
          {userData &&
            profile.basic &&
            profile.contact &&
            profile.company &&
            profile.education &&
            profile.flags && (
              <ProfileTabs
                userData={userData}
                combinedProfile={profile}
                setProfile={setProfile}
                setPercentComplete={setPercentComplete}
              />
            )}
        </div>
      </div>
    </Layout>
  );
}
