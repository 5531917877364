import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';

import { getBadgeImage, openBase64NewTab } from '../../utils/helper';
import classOptions from '../../data/classOptions.json';
import DesignationAppUpdateForm from '../DesignationApplication/DesignationAppUpdateForm';
import alumniToolkitsData from '../../data/alumniTookKitsData.json';
import Modal from '../subcomponents/Modal';

export function DesignationStatus({ designation, completed, studentCE }) {
  const [enabled, setEnabled] = useState(false);
  const designations = classOptions.designations;
  const alumniToolkitsKeys = Object.keys(alumniToolkitsData);
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  const { isLoading, data: studentFlags } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );
  // Retrieves the award letter for the user.
  const { isLoading: awardLetterLoading, data: awardLetter } = useQuery(
    [`${designation.acad_plan}AwardLetter`],
    () => load('awardLetter', { designation: designation.acad_plan }),
    {
      enabled: enabled !== false,
    },
  );

  // Opens the SSO Accredible link in a new window.
  const ssoAccredible = () =>
    window.open(process.env.REACT_APP_ACCREDIBLE_SSO_URL, '_blank');

  useEffect(() => {
    if (
      awardLetterLoading === false &&
      enabled &&
      awardLetter &&
      awardLetter.pdf_data
    ) {
      openBase64NewTab(
        decodeURIComponent(awardLetter.pdf_data),
        `awardLetter_${designation.acad_plan}.pdf`,
      );
      // reset enabled.
      setEnabled(false);
    }
  }, [awardLetterLoading, awardLetter, enabled, designation]);

  // Load the pdf in a seperate window.
  const viewAwardLetter = () => setEnabled(true);

  const badgeImage = getBadgeImage(designation.acad_plan);
  return (
    <>
      <div className="col">
        {designation.acad_plan && badgeImage && (
          <img src={badgeImage} alt={`${designation.acad_plan} Badge`} />
        )}
      </div>

      <div className="col">
        <p className="title7">
          {designations[designation.acad_plan.toLowerCase()] !== undefined
            ? parse(designations[designation.acad_plan.toLowerCase()].name)
            : `Unknown Academic Plan: ${designation.acad_plan.toLowerCase()}`}
        </p>
        <div className="table">
          {designation.status_date && (
            <div className="row">
              <div className="cell">Application Date</div>
              <div className="cell">
                {dayjs(designation.status_date).format('MM/DD/YY')}
              </div>
            </div>
          )}
          {designation.degree_confer_date && (
            <div className="row">
              <div className="cell">Awarded Date</div>
              <div className="cell">
                {dayjs(designation.degree_confer_date).format('MM/DD/YY')}
              </div>
            </div>
          )}
          {!completed && designation.status_message && (
            <div className="row">
              <div className="cell">Status</div>
              <div className="cell">{designation.status_message}</div>
            </div>
          )}
          {/* {@TODO: This is not in the API yet} */}
          {/* {completed && (
            <div className="row">
              <div className="cell">Renewal Date</div>
              <div className="cell">{dayjs(designation.degree_confer_date).format("MM/DD/YY")}</div>
            </div>
          )} */}
        </div>
      </div>

      <div className="col buttons">
        {(designation.item_statuts === 'O' ||
          designation.item_statuts === 'E') && (
          <Link
            to={`/designation-application-form/${designation.acad_plan}`}
            className="btn">
            Complete Designation
          </Link>
        )}
        {designation.item_statuts ===
          'S' /*&& is an independent advisor. Check company IDs? */ && (
          <>
            <Modal
              title="Update Supervisor"
              linkClasses="btn"
              scrollable={true}>
              <div className="container">
                <DesignationAppUpdateForm
                  empl_id={studentFlags?.empl_id}
                  applid={designation.applid}
                  acad_plan={designation.acad_plan}
                />
              </div>
            </Modal>
          </>
        )}

        {/* Links to Accredible where user can share badge */}
        {completed &&
          studentFlags &&
          isLoading === false &&
          studentFlags.digital_badge_access === 'Y' && (
            <>
              {alumniToolkitsKeys.includes(
                designation.acad_plan.toLowerCase(),
              ) && (
                <Link
                  to={`/designations-certifications/toolkits/${designation.acad_plan}`}
                  className="btn large">
                  View {designation.acad_plan} Toolkit
                </Link>
              )}
              <button className="btn" onClick={ssoAccredible}>
                Share My Badge
              </button>
            </>
          )}
        {/* Gets the award letter form the endpoint */}
        {studentCE?.tac_dc_award_ltr?.toUpperCase() === 'Y' && (
          <button className="btn secondary large" onClick={viewAwardLetter}>
            {awardLetterLoading ? (
              <span>Loading...</span>
            ) : (
              <span>View Your Award Letter</span>
            )}
          </button>
        )}
      </div>
    </>
  );
}
