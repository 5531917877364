import { useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import { Formik, Field } from 'formik';
import FileUpload from '../subcomponents/FileUpload';
import Timer from '../subcomponents/Timer';
import * as Yup from 'yup';

import { DegreeModalForm } from './DegreeModalForm';
import { CourseModalForm } from './CourseModalForm';

export function TransferOfCreditForm(props) {
  const [error, setError] = useState('');

  const [degrees, setDegrees] = useState([]);
  const [openDegrees, setOpenDegrees] = useState(false);
  const [degreeIndex, setDegreeIndex] = useState(false);

  const [courses, setCourses] = useState([]);
  const [openCourses, setOpenCourses] = useState(false);
  const [courseIndex, setCourseIndex] = useState(false);

  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();

  const formikRef = useRef();

  const { isLoading: studentLoading, data: student } = useQuery(
    ['studentInitial'],
    () => load('studentInitial'),
  );
  const { isLoading: tocLoading, mutate: transferCredit } = useMutation(
    (values) => save('transferOfCredit', values),
  );

  const close = () => {
    setOpenDegrees(false);
    setDegreeIndex(false);
    setOpenCourses(false);
    setCourseIndex(false);
  };

  const editDegree = (index) => {
    setDegreeIndex(index);
    setOpenDegrees(true);
  };

  const editCourse = (index) => {
    setCourseIndex(index);
    setOpenCourses(true);
  };

  const removeDegree = (index) => {
    const newDegrees = degrees.filter((degree, arrIndex) => arrIndex !== index);
    setDegrees(newDegrees);
    formikRef.current.setFieldValue('degrees', newDegrees);
  };

  const removeCourse = (index) => {
    const newCourse = courses.filter((course, arrIndex) => arrIndex !== index);
    setCourses(newCourse);
    formikRef.current.setFieldValue('courses', newCourse);
  };

  const formSubmit = async (values) => {
    transferCredit(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data.status === 'S') props.showSent();
        else setError('There was an error with the form submission.');
      },
    });
  };

  if (studentLoading || tocLoading) return <Timer />;

  // If no student is available that means the user does not have
  // access to this form.
  if (student === false && studentLoading === false)
    return <h2>Access Denied!</h2>;

  return (
    <>
      <h4>Begin your application</h4>
      {error && <p className="red-text">{error}</p>}
      <Formik
        initialValues={{
          phone: '',
          email: '',
          degrees: [],
          courses: [],
          syllabiFiles: [],
          addFiles: [],
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        innerRef={formikRef}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit} id="transferOfCredit">
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="firstname">
                  Student ID:<span>*</span>
                </label>
                <Field
                  type="text"
                  id="studentid"
                  name="studentid"
                  value={`Student ID: ${student.studentInfoInitial.empl_id}`}
                  disabled
                />
              </div>
            </div>
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="firstname">
                  First Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={student.studentInfoInitial.first_name}
                  disabled
                />
              </div>
              <div className="field col">
                <label htmlFor="firstname">
                  Last Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={student.studentInfoInitial.last_name}
                  disabled
                />
              </div>
            </div>
            <div className="form-layout">
              <div className="field col">
                <label htmlFor="email">
                  Email Address<span>*</span>
                </label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className={touched.email && errors.email ? 'error' : null}
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email Address*"
                  onBlur={handleBlur}
                />
                {touched.email && errors.email && (
                  <span className="red-text">{errors.email}</span>
                )}
              </div>
              <div className="field col">
                <label htmlFor="phone">
                  Personal Phone Number<span>*</span>
                </label>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  className={touched.phone && errors.phone ? 'error' : null}
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="Personal Phone Nmber*"
                  onBlur={handleBlur}
                />
                {touched.phone && errors.phone && (
                  <span className="red-text">{errors.phone}</span>
                )}
              </div>
            </div>
            <div className="info-section">
              <p>
                PLEASE INCLUDE DETAILED INFORMATION OF THE COLLEGES OR
                UNIVERSITIES THAT YOU WOULD LIKE THE COLLEGE TO REVIEW FROM THE
                TRANSCRIPTS YOU'LL BE SUBMITTING:
              </p>
              <button
                type="button"
                className="btn col"
                onClick={() => setOpenDegrees(true)}>
                Add School Information
              </button>
            </div>
            {touched.degrees && errors.degrees && (
              <span className="red-text">{errors.degrees}</span>
            )}
            {values.degrees.map((degree, index) => (
              <div key={index} className="info-row">
                <div className="inline-block">
                  <p>
                    <strong>Name of School:</strong> {degree.name}
                    <br />
                    <strong>Years Attended:</strong> {degree.yearFrom} -{' '}
                    {degree.yearTo}
                    <br />
                    <strong>Degree or certificate awarded:</strong>{' '}
                    {degree.certificate}
                    <br />
                    <strong>Date certificate awarded:</strong>{' '}
                    {degree.awardMonth}/{degree.awardDay}/{degree.awardYear}
                  </p>
                </div>
                <div className="float-right">
                  <div>
                    <button type="button" onClick={() => editDegree(index)}>
                      Edit
                    </button>
                  </div>
                  <div>
                    <button type="button" onClick={() => removeDegree(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="info-section">
              <p>
                PLEASE INCLUDE INFORMATION OF THE COURSE YOU WANT TO TRANSFER
                AND WHAT HS COURSE YOU EXPECT TO RECEIVE CREDIT FOR:
              </p>
              <div>
                <button
                  type="button"
                  className="btn col"
                  onClick={() => setOpenCourses(true)}>
                  Add Course Information
                </button>
              </div>
            </div>

            {values.courses.map((course, index) => (
              <div key={index} className="info-row">
                <div className="inline-block">
                  <p>
                    <strong>Course title and number:</strong> {course.title}
                    <br />
                    <strong>Name of School:</strong> {course.school}
                    <br />
                    <strong>Date course completed:</strong>{' '}
                    {course.completedMonth}/{course.completedDay}/
                    {course.completedYear}
                    <br />
                    <strong>Grade received or pass/fail:</strong> {course.grade}
                    <br />
                    <strong>Credit hours:</strong> {course.hours}
                    <br />
                    <strong>
                      HS course you expect to receive credit for:
                    </strong>{' '}
                    {course.hsCourse}
                    <br />
                  </p>
                </div>
                <div className="float-right">
                  <div>
                    <button type="button" onClick={() => editCourse(index)}>
                      Edit
                    </button>
                  </div>
                  <div>
                    <button type="button" onClick={() => removeCourse(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {touched.courses && errors.courses && (
              <span className="red-text">{errors.courses}</span>
            )}
            <div className="info-section">
              <div>
                UPLOAD THE SYLLABI FOR THE SPECIFIC COURSE(S) YOU'RE LOOKING TO
                TRANSFER:*
              </div>
              <div>
                <p>
                  The syllabus should include a description of the course(s) and
                  topics covered, along with the title(s) of the textbook(s)
                  used. The description could be from a catalog and the topics
                  covered might be from a course syllabus.
                </p>
                <p>
                  Also, please be advised that in some cases the subject matter
                  in more than one college or university course may be needed to
                  match the Huebner School (HS) course.
                </p>
              </div>
              <FileUpload
                selector="syllabiFiles"
                values={values.syllabiFiles}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                errors={errors.syllabiFiles}
                uploadCount={3}
              />
            </div>

            <div className="info-section">
              <div>
                UPLOAD ANY ADDITIONAL DOCUMENTS, SUCH AS A CERTIFICATE
                (OPTIONAL):*
              </div>
              <div>
                <p>
                  If you are applying to receive Transfer of Credit from a
                  completed certification or designation, please submit the
                  confirmation letter or a scan of the certificate.
                </p>
              </div>
              <FileUpload
                selector="addFiles"
                values={values.addFiles}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                errors={errors.addFiles}
                uploadCount={5}
              />
            </div>

            <div className="flex w-full column-layout">
              <button type="submit" form="transferOfCredit" className="btn col">
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className="disclaimer">
        <p>
          Certified Financial Planner Board of Standards Inc. owns the marks CFP
          <sup>®</sup>, CERTIFIED FINANCIAL PLANNER™ and CFP (with flame logo)
          <sup>®</sup>, which it awards to individuals who successfully complete
          initial and ongoing certification requirements
        </p>
      </div>
      <DegreeModalForm
        index={degreeIndex}
        degrees={degrees}
        setDegrees={(degrees) => {
          setDegrees(degrees);
          formikRef.current.setFieldValue('degrees', degrees);
        }}
        open={openDegrees}
        close={close}
      />
      <CourseModalForm
        index={courseIndex}
        courses={courses}
        setCourses={(courses) => {
          setCourses(courses);
          formikRef.current.setFieldValue('courses', courses);
        }}
        open={openCourses}
        close={close}
      />
    </>
  );
}

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const ValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, '*Phone number is not valid')
    .required('*Phone number required'),
  email: Yup.string().email('Invalid email').required('*Required'),
  degrees: Yup.array().min(1, 'Atleast One Required'),
  courses: Yup.array().min(1, 'Atleast One Required'),
  syllabiFiles: Yup.array().min(1, 'Atleast One Required'),
});
