import { useState } from 'react';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { CfpTransferOfCreditForm } from '../components/transferOfCredit/CfpTransferOfCreditForm';

export function CfpTransferOfCredit() {
  const [sent, setSent] = useState(false);

  const showSent = () => setSent(true);

  // If the api call was successfull the show the thank you copy.
  if (sent) {
    return (
      <Layout>
        <Breadcrumbs />
        <div className="container">
          <div className="intro-content-wrapper">
            {/* TODO: Maybe pull this text from the database so nothing is hardcoded. */}
            <h1 className="thank-title">
              Thank you for submitting your application.
            </h1>
            <p className="thank-message">
              The Office of the Registrar will evaluate your request shortly.
              You will be contacted by email once your request has been
              processed. If there are any questions or concerns, please email{' '}
              <a href="mailto:registrar@theamericancollege.edu">
                Registrar@theamericancollege.edu
              </a>
              .
            </p>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container">
        <div className="intro-content-wrapper">
          <div>
            {/* TODO: Maybe pull this text from the database so nothing is hardcoded. */}
            <h3 className="divider-band">Application For Transfer Of Credit</h3>
            <h4>
              For Students Holding CFP<sup>®</sup> Certification
            </h4>

            <div>
              <p>
                The American College of Financial Services has a transfer of
                credit policy under which students who have been awarded the CFP
                <sup>®</sup> certification may receive credit for up to seven
                (7) of the following courses:
              </p>
              <table className="cfp-toc-courses">
                <tbody>
                  <tr>
                    <td>
                      <strong>Fin. Planning Process and Environment</strong>
                      &nbsp;(HS 300)
                    </td>
                    <td>
                      <strong>Planning for Retirement Needs</strong>&nbsp;(HS
                      326)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fundamentals of Insurance Planning</strong>
                      &nbsp;(HS 311)
                    </td>
                    <td>
                      <strong>Investments</strong>&nbsp;(HS 328)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Income Taxation</strong>&nbsp;(HS 321)
                    </td>
                    <td>
                      <strong>Fundamentals of Estate Planning</strong>&nbsp;(HS
                      330)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Personal Financial Planning: Case Analysis
                      </strong>
                      &nbsp;(HS 333)
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>

              <p><strong>
                YOU MUST BE AN APPROVED MATRICULATED STUDENT WITH THE COLLEGE
                BEFORE APPLYING FOR A TRANSFER OF CREDIT.
                </strong></p>

              <p>
                <span>NOTE:</span>&nbsp;Any unofficial transfer of credit
                evaluations conducted prior to matriculation are not considered
                official and may not be used towards program completion.
              </p>

              <p>
                If you are eligible and wish to apply for transfer of credit,
                please do the following:
              </p>

              <ol>
                <li>
                  <p>Complete the application below.</p>
                </li>
                <li>
                  <p>
                    Please note that as a CFP<sup>®</sup> professional, you must
                    provide with this application a verification letter of the
                    award of your certification and its current good standing.
                    This may be obtained by contacting:
                    <br />
                    <strong>
                      CFP Board of Standards
                      <br />
                      1425 K Street, NW Suite 500
                      <br />
                      Washington, DC 20005
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    Mail your official verification letter to (if using a
                    physical copy):
                    <br />
                    <strong>
                      The Office of the Registrar
                      <br />
                      ATTN: Xiaohua Su
                      <br />
                      The American College of Financial Services
                      <br />
                      630 Allendale Road, Suite 400
                      <br />
                      King of Prussia, PA 19406
                    </strong>
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <CfpTransferOfCreditForm showSent={showSent} />
        </div>
      </div>
    </Layout>
  );
}
