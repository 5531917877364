import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Divider from '@mui/material/Divider';
import { decode, encode } from 'html-entities';

import InputField from '../subcomponents/InputField';
import selectOptions from '../../data/selectOptions.json';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import parsePhoneNumber from 'libphonenumber-js';

export function SecurityQuestionForm({ userData }) {
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);

  const [securityQuestion, setSecurityQuestion] = useInput(
    decode(userData.credentials?.recovery_question?.question),
  );
  const [securityAnswer, setSecurityAnswer] = useInput(
    decode(userData.credentials?.recovery_question?.answer),
  );

  const [newSecurityQuestion, setNewSecurityQuestion] = useState(false);
  const [newSecurityAnswer, setNewSecurityAnswer] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [securityChangeError, setSecurityChangeError] = useState('');

  const { save } = useTacfsService();

  const { isLoading, mutate } = useMutation((values) =>
    save('changeSecurityQuestion', values),
  );

  const securityQuestionOptions = selectOptions.securityQuestion;

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    if (!newSecurityQuestion && !newSecurityAnswer) {
      setEdit(false);
      setSaving(false);
      setSecurityAnswer('');
    } else if (newSecurityAnswer) {
      changeSecurityQuestion();
    } else {
      setSecurityChangeError('You must specify a security answer.');
      setSaving(false);
    }
  };

  //needs the current password
  const changeSecurityQuestion = async () => {
    if (!isLoading) {
      const requestData = {
        password: { value: confirmPassword },
        recovery_question: {
          question: encode(securityQuestion),
          answer: encode(securityAnswer),
        },
      };

      try {
        mutate(requestData, {
          onError: (errorResponse) => {
            let errorMsg = '.';
            if (errorResponse) errorMsg = errorResponse;
            setSaving(false);
            setSecurityChangeError(`Error: ${errorMsg}`);
          },
          onSuccess: () => {
            setNewSecurityQuestion(false);
            setNewSecurityAnswer(false);
            setConfirmPassword('');
            setSecurityAnswer('');
            setSecurityChangeError('');
            setEdit(false);
            setSaving(false);
          },
        });
      } catch (error) {
        setSaving(false);
        setSecurityChangeError('Error updating security question.');
      }
    }
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Security Question</h4>
          {edit ? (
            saving ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit">
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}>
          <div className="row">
            <div className="cell">Security Question</div>
            <div className="cell">{securityQuestion}</div>
          </div>
          <div className="row">
            <div className="cell">Security Question Answer</div>
            <div className="cell">{'************'}</div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {securityChangeError && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">{securityChangeError}</div>
            </div>
          )}
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="gender-field">Security Question</label>
            </div>
            <div className="cell">
              <select
                id="securityQuestion-field"
                name="securityQuestion"
                value={securityQuestion}
                onChange={(e) => {
                  setSecurityQuestion(e);
                  setNewSecurityQuestion(true);
                }}>
                {securityQuestionOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell">Security Question Answer</div>
            <div className="cell">
              <InputField
                label="Security Question Answer"
                id="securityAnswer-field"
                name="securityAnswer"
                type="text"
                value={securityAnswer}
                onChange={(e) => {
                  setSecurityAnswer(e);
                  setNewSecurityAnswer(true);
                }}
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">Confirm Password</div>
            <div className="cell">
              <InputField
                label="Confirm Password"
                id="confirm-password-field"
                name="confirm-password"
                type="password"
                onChange={handleConfirmPassword}
                value={confirmPassword}
                srOnly={true}
              />
            </div>
          </div>
        </div>
        {edit ? (
          saving ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit">
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function PasswordForm() {
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { save } = useTacfsService();

  const { isLoading, mutate } = useMutation((values) =>
    save('changePasswordFlow', values),
  );

  const handleCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    if (newPassword && currentPassword) {
      changePassword();
    } else {
      setEdit(false);
      setSaving(false);
    }
  };

  const changePassword = async () => {
    if (!isLoading) {
      const requestData = {
        oldPassword: { value: currentPassword },
        newPassword: { value: newPassword },
        revokeSessions: false,
      };

      try {
        mutate(requestData, {
          onError: (errorResponse) => {
            let errorMsg = '.';
            if (errorResponse) errorMsg = errorResponse;
            setSaving(false);
            setPasswordError('Error:' + errorMsg);
          },
          onSuccess: () => {
            setCurrentPassword('');
            setNewPassword('');
            setPasswordError('');
            setEdit(false);
            setSaving(false);
          },
        });
      } catch (error) {
        setSaving(false);
        setPasswordError('Error updating password.');
      }
    }
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Update Password</h4>
          {edit ? (
            saving ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit">
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}>
          <div className="row">
            <div className="cell">Password</div>
            <div className="cell">************</div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {passwordError && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">{passwordError}</div>
            </div>
          )}
          <div className="row">
            <div className="cell">Old Password</div>
            <div className="cell">
              <InputField
                label="Old Password"
                id="old-password-field"
                name="old-password"
                type="password"
                onChange={handleCurrentPassword}
                value={currentPassword}
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">New Password</div>
            <div className="cell">
              <InputField
                label="New Password"
                id="new-password-field"
                name="new-password"
                type="password"
                onChange={handleNewPassword}
                value={newPassword}
                srOnly={true}
              />
            </div>
          </div>
          <div className="row password-req">
            <div className="cell"></div>
            <div className="cell">
              <p className="p4">
                Passwords must meet the following requirements:
              </p>
              <ul className="bullets p4">
                <li>Must be at least 8 characters</li>
                <li>Must contain a lowercase letter</li>
                <li>Must contain an uppercase letter</li>
                <li>Must contain a number</li>
                <li>Must not contain part of the username</li>
                <li>Must not be the same as the previous two passwords</li>
              </ul>
            </div>
          </div>
        </div>

        {edit ? (
          saving ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit">
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function BackupEmailForm({ userData }) {
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);

  const [backupEmail, setBackupEmail] = useInput(userData.profile.secondEmail);
  const [newBackupEmail, setNewBackupEmail] = useState(false);
  const [emailError, setEmailError] = useState('');

  const { save } = useTacfsService();

  const { isLoading, mutate } = useMutation((values) =>
    save('updateSecondaryEmail', values),
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    if (newBackupEmail) {
      changeBackupEmail();
    } else {
      setEdit(false);
      setSaving(false);
    }
  };

  const changeBackupEmail = async () => {
    if (!isLoading) {
      let requestData = { email: backupEmail };

      try {
        mutate(requestData, {
          onError: (errorResponse) => {
            let errorMsg = '.';
            if (errorResponse) errorMsg = errorResponse;
            setSaving(false);
            setEmailError(`Error: ${errorMsg}`);
          },
          onSuccess: () => {
            setEmailError('');
            setNewBackupEmail(false);
            setEdit(false);
            setSaving(false);
          },
        });
      } catch (error) {
        setSaving(false);
        setEmailError('Error updating backup email.');
      }
    }
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Backup Email</h4>
          {edit ? (
            saving ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit">
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}>
          <div className="row">
            <div className="cell">Backup Email</div>
            <div className="cell">{backupEmail || 'Not Provided'}</div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {emailError && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">{emailError}</div>
            </div>
          )}
          <div className="row">
            <div className="cell">Backup Email</div>
            <div className="cell">
              <InputField
                label="Backup Email"
                id="backupEmail-field"
                name="backupEmail"
                type="email"
                value={backupEmail}
                onChange={(e) => {
                  setBackupEmail(e);
                  setNewBackupEmail(true);
                }}
                srOnly={true}
              />
            </div>
          </div>
        </div>
        {edit ? (
          saving ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit">
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function SMSFactorForm({ userData }) {
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [verifyingOTP, setVerifyingOTP] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [smsError, setSmsError] = useState('');
  const [awaitingOTP, setAwaitingOTP] = useState(false);
  const [enrollmentResponse, setEnrollmentResponse] = useState({});
  const [, setOptSuccess] = useState(false);
  const [resendPossible, setResendPossible] = useState(false);
  const [smsFactor, setSmsFactor] = useState(false);

  const [sms, setSms] = useInput('');
  const [otp, setOtp] = useInput('');

  const { save, load } = useTacfsService();
  const queryClient = useQueryClient();

  const { isLoading: isUpdatingSms, mutate: updateSms } = useMutation(
    (values) => save('updateMobile', values),
  );
  const { mutate: verifyOTP } = useMutation((values) =>
    save('verifyOTP', values),
  );
  const { mutate: resendOTP } = useMutation((values) =>
    save('resendOTP', values),
  );
  const { isLoading: isFetchingFactors, data: factors } = useQuery(
    ['listFactors'],
    () => load('listFactors'),
  );

  useEffect(() => {
    if (!isFetchingFactors && factors) {
      if (Object.keys(factors).length > 0) {
        setSmsFactor(factors);
        if (factors.status === 'PENDING_ACTIVATION') {
          // Debug - To force the # form to appear while awaitng OTP verification, comment out the following two lines
          setAwaitingOTP(true);
          setResendPossible(true);
        }
        if (factors.status === 'ACTIVE') {
          console.log("We're in Active status.");
          setAwaitingOTP(false);
          setResendPossible(false);
        }
      }
    }
  }, [factors, isFetchingFactors, setSms]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    if (Object.keys(factors).length === 0) {
      // If student has no factors
      changeBackupSms();
    } else if (
      parsePhoneNumber(`+1${sms}`).formatInternational() !==
      parsePhoneNumber(factors.number).formatInternational()
    ) {
      // If student has factors and the submitted # and the saved # do not match
      changeBackupSms(true);
    } else {
      // Factor.number and the submitted SMS are the same.
      setEdit(false);
      setSaving(false);
    }
  };
  const submitOTP = (e) => {
    e.preventDefault();
    setVerifyingOTP(true);
    // Submit the OTP
    let payload = {};
    // We've just initiated the process for a new phone # enrollment
    if (Object.keys(enrollmentResponse).length > 0) {
      payload = {
        link: enrollmentResponse?._links.activate.href,
        otp: otp,
      };
    }
    // We're resuming the OTP process on a pending activation
    if (Object.keys(smsFactor).length > 0) {
      payload = {
        link: `/users/${userData.id}/factors/${smsFactor.id}/lifecycle/activate`,
        otp: otp,
      };
    }
    try {
      verifyOTP(payload, {
        onError: (errorResponse) => {
          let errorMsg = '.';
          if (errorResponse) errorMsg = errorResponse;
          setVerifyingOTP(false);
          setOtpError(`Error: ${errorMsg}`);
        },
        onSuccess: (responseObj) => {
          setOtpError(''); //Clear error obj
          queryClient.invalidateQueries({ queryKey: ['listFactors'] }); // Invalidate the list of factors and retrieve fresh data.
          setVerifyingOTP(false);
          setAwaitingOTP(false);
          // Clear these to make sure the form has been reset.
          setEdit(false);
          setOptSuccess(true);
        },
      });
    } catch (error) {
      setVerifyingOTP(false);
      setOtpError('Error verifying one-time password.');
    }
  };
  const initiateOTPResend = () => {
    setVerifyingOTP(true);
    setOtpError('');
    // Submit the OTP

    /* TODO:
     * If not already enrolled, we can pull the link and phone number from the
     * enrollmentResponse we get from initializing the process.
     * If we're pending activation, we want to be able to initiate a resend to
     * get the OTP to finish the process. But we do not have an enrollmentResponse.
     *
     * we do have the factorID (smsFactor.id) and we could derive the uid.
     *
     */
    let payload = {};
    // We've just initiated the process for a new phone # enrollment
    if (Object.keys(enrollmentResponse).length > 0) {
      payload = {
        link: enrollmentResponse?._links.resend.slice(-1)[0].href,
        phoneNumber: parsePhoneNumber(`+1${sms}`).formatInternational(),
      };
    }
    // We're resuming the OTP process on a pending activation
    if (Object.keys(smsFactor).length > 0) {
      payload = {
        link: `/users/${userData.id}/factors/${smsFactor.id}/resend`,
        phoneNumber: smsFactor.number,
      };
    }

    try {
      resendOTP(payload, {
        onError: (errorResponse) => {
          let errorMsg = '.';
          if (errorResponse) errorMsg = errorResponse;
          setVerifyingOTP(false);
          setOtpError(`Error: ${errorMsg}`);
        },
        onSuccess: (responseObj) => {
          setOtpError(''); //Clear error obj
        },
      });
    } catch (error) {
      console.log(error);
      setOtpError('Error resending one-time passcode.');
    }
  };

  const changeBackupSms = async (updatingSMS = false) => {
    if (!isUpdatingSms) {
      setResendPossible(false); //Reset whether or not we can trigger a resend.

      // Hard-coded to a US country code. We may have issues if we have non-us
      // phone numbers trying to do this.
      const parsedPhone = parsePhoneNumber(`+1${sms}`);
      //Format the phone number to the okta required E.164 format
      let requestData = {
        phoneNumber: parsedPhone.formatInternational(),
        isUpdate: updatingSMS,
      };
      if (updatingSMS) {
        requestData.fid = factors.id;
      }
      try {
        updateSms(requestData, {
          onError: (errorResponse) => {
            let errorMsg = '.';
            if (errorResponse) errorMsg = errorResponse;
            setSaving(false);
            setSmsError(`Error: ${errorMsg}`);
          },
          onSuccess: (responseObj) => {
            if (responseObj.status === 'ACTIVE') {
              //Enrollment factor has been made active (could be a previously verified #)
              setOptSuccess(true);
              setAwaitingOTP(false);
            } else {
              //TODO: What other statuses could we switch on?
              setAwaitingOTP(true);
            }
            // If we have a resend obj, we can allow the user to trigger a resend
            if (responseObj?._links?.resend) {
              setResendPossible(true);
            }
            // Invalidate the list of factors and retrieve fresh data.
            queryClient.invalidateQueries({ queryKey: ['listFactors'] });
            setSmsError('');
            setOtpError('');
            setEnrollmentResponse(responseObj);
            setEdit(false);
            setSaving(false);
          },
        });
      } catch (error) {
        console.log(error);
        setSaving(false);
        setSmsError('Error updating backup SMS.');
      }
    }
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }>
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Multi-factor Authentication</h4>
          {edit && saving && (
            <input
              type="submit"
              value="Saving..."
              className="btn desktop-save disabled"
              disabled
            />
          )}
          {edit && !saving && (
            <input type="submit" value="Save" className="btn desktop-save" />
          )}
          {!edit && !awaitingOTP && (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit">
              Edit
            </button>
          )}
        </div>
        <Divider />
        <h5>SMS</h5>
        <p>
          This mobile number can be used to reset your password as an
          alternative to your backup email address. This number should be an
          independent number and not associated with your current employer or
          any other entity in which you may lose access.
        </p>
        <div
          className={
            edit || awaitingOTP
              ? 'table display-data hidden'
              : 'table display-data'
          }>
          <div className="row">
            <div className="cell">Mobile Number</div>
            <div className="cell">
              {smsFactor?.status === 'ACTIVE' &&
                'Enrolled - ' +
                  parsePhoneNumber(smsFactor.number).format('NATIONAL')}
              {smsFactor?.status === 'PENDING_ACTIVATION' &&
                'Pending Activation - ' +
                  parsePhoneNumber(smsFactor.number).format('NATIONAL')}
              {!smsFactor?.status && 'Not Enrolled'}
            </div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {smsError && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">{smsError}</div>
            </div>
          )}

          <div className="row">
            <div className="cell">Mobile Number</div>
            <div className="cell">
              <InputField
                label="SMS"
                id="backupSms-field"
                name="sms"
                type="text"
                value={sms}
                onChange={(e) => {
                  setSms(e);
                }}
                srOnly={true}
              />
            </div>
          </div>
        </div>
        {edit ? (
          saving ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit">
            Edit
          </button>
        )}
      </form>
      <Divider />
      {awaitingOTP && (
        <form onSubmit={submitOTP}>
          <h5>Verify Your Device</h5>
          <p>
            Please check your mobile device for a one-time passcode that you
            must enter into the field below. This verifies that you have access
            to the device associated with the number you entered
            {smsFactor?.number ? `, ${parsePhoneNumber(smsFactor.number).format('NATIONAL')}` :''  }.
          </p>
          <div className="table info-form">
            {otpError && (
              <div className="save-error row">
                <div className="cell"></div>
                <div className="cell">{otpError}</div>
              </div>
            )}
            <div className="row">
              <div className="cell">
                <label htmlFor="otp-field">
                  One-time Passcode
                  <span className="req-star">*</span>
                </label>
              </div>
              <div className="cell">
                <InputField
                  label="OTP"
                  id="otp-field"
                  name="otp"
                  type="text"
                  value={otp}
                  onChange={setOtp}
                  srOnly={true}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="cell">
                <input
                  type="submit"
                  value={verifyingOTP ? 'Verifying...' : 'Verify Enrollment'}
                  className={
                    verifyingOTP
                      ? 'disabled btn desktop-save '
                      : 'btn desktop-save '
                  }
                  disabled={verifyingOTP ? true : false}
                />
                <input
                  type="submit"
                  value={verifyingOTP ? 'Verifying...' : 'Verify Enrollment'}
                  className={
                    verifyingOTP
                      ? 'disabled btn mobile-save '
                      : 'btn mobile-save '
                  }
                  disabled={verifyingOTP ? true : false}
                />
              </div>
              <div className="cell">
                {enrollmentResponse && resendPossible && (
                  <button
                    onClick={initiateOTPResend}
                    className="btn desktop-save secondary large">
                    Resend One-time Passcode
                  </button>
                )}
              </div>
            </div>
            {/* TODO: Add ability to cancel verification to enter new phone # */}
          </div>
        </form>
      )}
      {/*<div className="row">
            <div className="cell">
                Need to re-enter your mobile number?
            </div>    
            <div className="cell">
               <button onClick={() => reenterPhone()} className="btn desktop-save secondary large">Clear</button>
            </div>   
        </div>*/}
    </div>
  );
}

function useInput(initialValue) {
  if (!initialValue) initialValue = '';

  const [value, setValue] = useState(initialValue.trim());

  function handleChange(e) {
    if (typeof e === 'string') {
      setValue(e);
    } else {
      setValue(e.target.value.trim());
    }
  }

  return [value, handleChange];
}
