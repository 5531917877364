import { useState, useEffect } from 'react';
import Timer from '../../components/subcomponents/Timer';
import { useOktaAuth } from '@okta/okta-react';
import SetStorage from '../../components/storage/SetStorage';

export function SSONwmLoginCallback() {
  const { oktaAuth } = useOktaAuth();
  const [sid, setSid] = useState(false);

  useEffect(() => {
    if (sid === false) {
      oktaAuth.session
        .get()
        .then(function (session) {
          if (session.id) {
            // Set the settion token to the session
            SetStorage('session', session.id);
            // Set where we to be directed to after login.
            SetStorage('loginDestination', '/account/nwm-setup');
            setSid(session.id);
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  }, [oktaAuth, sid]);

  useEffect(() => {
    if (sid) {
      oktaAuth.token.getWithRedirect({
        sessionToken: sid,
        responseType: ['code', 'email', 'profile'],
        state: 'null',
      });
    }
  }, [oktaAuth, sid]);

  return <Timer />;
}
