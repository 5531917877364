import dayjs from 'dayjs';
//import customParseFormat from 'dayjs';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Divider from '@mui/material/Divider';
import parse from 'html-react-parser';
import { OktaSSOLink } from '../../_okta/config/OktaSSO';

import classOptions from '../../data/classOptions.json';

import { ProgressDots } from '../subcomponents/ProgressDots';
import { DownloadArrow } from '../subcomponents/Icon';

import shieldLogo from '../../assets/shield_logo.svg';

export function LearningJourneyModule({ designation, courseData }) {
  const webinarBaseUrl = `${process.env.REACT_APP_BRIGHTSPACE_LINK}?RelayState=https://theamericancollegetest.brightspace.com/d2l/home/`;

  const [classesCompleted, classesTotal, nextCourse] = calculateNextCourse(
    courseData,
    designation,
  );
  const designations = classOptions.designations;
  const hasWebinar = ['webinar', 'wb', 'oe', 're', 'ir'];

  const progressTitle = (
    <>
      <span className="num">{classesCompleted}</span> of{' '}
      <span className="num">{classesTotal}</span>
      <br />
      courses completed
    </>
  );

  return (
    <div className="card drop-box padding--sm w-full learning-module">
      <div className="title3">
        <Link to="/learning-journey">My Learning Journey</Link>
      </div>
      <Divider className="top-divider" />

      <div className="content-wrapper">
        {classesCompleted !== null &&
        classesTotal !== null &&
        designation.toLowerCase() !== 'ira' &&
        classesTotal > 0 ? (
          <ProgressDots
            totalCourses={classesTotal}
            completedCourses={classesCompleted}
            title={progressTitle}
          />
        ) : (
          <div className="progress-bar-wrapper flex-column items-center">
            <div className="no-enrollment-img shield-logo-box">
              <div className="no-img">
                <img
                  src={shieldLogo}
                  className="h-24 nm-logo"
                  alt="TACFS Shield Logo"
                />
              </div>
            </div>
          </div>
        )}

        <div className="courses-info">
          {designations[designation.toLowerCase()] ? (
            <p className="p2">
              {parse(designations[designation.toLowerCase()].shortName)} -
              {parse(designations[designation.toLowerCase()].name)}
            </p>
          ) : (
            <p className="p2">{designation}</p>
          )}
          <div className="small-headline gray">Current Courses</div>
          <div className="courses">
            <ul>
              {courseData.map((course) => {
                const inProgress =
                  course.status === 'IN PROGRESS' ||
                  course.status === 'IN-PROGRESS' ||
                  course.status?.startsWith('CLASS STARTS ON');

                if (course.lms_file_type === 'SIM') {
                  return (
                    course.academic_plan === designation &&
                    inProgress && (
                      <li key={course.course_id}>
                        <a
                          href="https://tac-wmcp.regis-co.com/?okta"
                          target="_blank"
                          rel="noreferrer"
                          className="underline-link">
                          {course.course}: {course.course_title}
                        </a>
                      </li>
                    )
                  );
                } else {
                  return (
                    course.academic_plan === designation &&
                    inProgress && (
                      <li key={course.course_id}>
                        {course.lms_url &&
                        course.lms_file_type !== 'KOF' &&
                        course.lms_file_type !== 'LFT' ? (
                          <Link
                            to={{ pathname: `${course.lms_url}` }}
                            target="_blank"
                            className="underline-link">
                            {course.course}: {course.course_title}
                          </Link>
                        ) : (
                          <span className="">
                            {course.course}: {course.course_title}
                          </span>
                        )}
                      </li>
                    )
                  );
                }
              })}
            </ul>
            {designation.toLowerCase() !== 'blkexc' && (
              <HashLink
                to={`/learning-journey#${designation}`}
                className="see-all">
                See all required coursework for {designation} <DownloadArrow />
              </HashLink>
            )}
          </div>
        </div>

        <div className="next-actions">
          <Divider
            orientation="vertical"
            flexItem={true}
            className="vertical-hr"
          />
          <Divider flexItem={true} className="horizontal-hr" />

          <div className="flex-column">
            <div className="small-headline gray">Next Actions</div>
            {/* Upcoming Webinars */}
            {courseData.map((course) => {
              const inProgress =
                course.status === 'IN PROGRESS' ||
                course.status === 'IN-PROGRESS' ||
                course.status?.startsWith('CLASS STARTS ON');

              return (
                course.academic_plan === designation &&
                hasWebinar.includes(
                  course.instruction_mode?.trim()?.toLowerCase(),
                ) &&
                inProgress &&
                course.brightspace_class_id &&
                course.class_days?.includes('Y') && (
                  <div className="webinar-link" key={course.course}>
                    <p className="p3" key={course.course}>
                      Upcoming {course.course} Webinar:{' '}
                      {calculateNextWebinar(course)}
                    </p>
                    <Link
                      to={{
                        pathname: `${webinarBaseUrl}${course.brightspace_class_id}`,
                      }}
                      target="_blank"
                      className="underline-link">
                      Join Here
                    </Link>
                  </div>
                )
              );
            })}

            <div className="action-buttons">
              {nextCourse &&
                designations[designation.toLowerCase()] &&
                designations[designation.toLowerCase()]?.sunset !== true && (
                  <OktaSSOLink
                    classes="btn"
                    program={`${nextCourse.academic_plan.toLowerCase()}`}
                    course={`${nextCourse.tac_course_id?.trim()}`}>
                    Enroll In Your Next Course
                  </OktaSSOLink>
                )}

              {courseData.map((course) => {
                const inProgress =
                  course.status === 'IN PROGRESS' ||
                  course.status === 'IN-PROGRESS' ||
                  course.status?.startsWith('CLASS STARTS ON');

                return (
                  course.academic_plan === designation &&
                  inProgress && (
                    <div key={course.course_id}>
                      {course.proctor_flag === 'Y' && (
                        <>
                          {!course.proctor_form_status?.trim() ? (
                            <Link
                              to={`/proctor-registration/${course.brightspace_class_id}/add-proctor`}
                              className="btn secondary"
                              relative="path">
                              Select a Proctor
                            </Link>
                          ) : (
                            <>
                              <Link
                                to={`/proctor-registration/${course.brightspace_class_id}/add-proctor`}
                                className="btn secondary"
                                relative="path">
                                <span>Proctor {course.proctor_status}</span>
                                <br />
                                <span>Select a different proctor?</span>
                              </Link>
                            </>
                          )}
                        </>
                      )}
                      {course.class_exam_type?.trim() === 'EX' &&
                        !course.exam_status?.trim() && (
                          <Link
                            to={{ pathname: 'https://www.pearsonvue.com/us/en/tac.html' }}
                            target="_blank"
                            className="btn secondary">
                            Schedule {course.course} Exam
                          </Link>
                        )}
                      {course.textbook_available === 'Y' && (
                        <OktaSSOLink
                          classes="btn secondary"
                          addons={`${course.product_list}`}>
                          Purchase {course.course} Textbook
                        </OktaSSOLink>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function LearningJourneyNoEnrollment() {
  return (
    <div className="card drop-box padding--sm w-full no-enrollment learning-module">
      <div className="title3">
        <Link to="/learning-journey">My Learning Journey</Link>
      </div>
      <Divider className="top-divider" />

      <div className="content-wrapper">
        <div className="no-enrollment-img">
          <img alt="" src="/images/no-enrollment.svg" />
        </div>

        <div>
          <p className="p2">No Current Enrollment!</p>

          <div className="small-headline gray">
            Learn more about the programs available to you
          </div>

          <ul className="p3">
            <li>
              {/* CFP program page */}
              <Link
                to={{
                  pathname: `${process.env.REACT_APP_MARKETING_URL}learn/professional-designations-certifications/cfp-certification-education-program`,
                }}
                target="_blank"
                className="underline-link">
                CFP&reg; Certification Education Program
              </Link>
            </li>
            <li>
              {/* RICP program page */}
              <Link
                to={{
                  pathname: `${process.env.REACT_APP_MARKETING_URL}learn/professional-designations-certifications/ricp-retirement-income-certified-professional`,
                }}
                target="_blank"
                className="underline-link ">
                RICP&reg; - Retirement Income Certified Professional&reg;
              </Link>
            </li>
            <li>
              {/* CAP program page */}
              <Link
                to={{
                  pathname: `${process.env.REACT_APP_MARKETING_URL}learn/professional-designations/cap-chartered-advisor-in-philanthropy`,
                }}
                target="_blank"
                className="underline-link ">
                CAP&reg; - Chartered Advisor in Philanthropy&reg;
              </Link>
            </li>
          </ul>

          <Link
            to={{
              pathname: `${process.env.REACT_APP_MARKETING_URL}learn-at-the-college`,
            }}
            target="_blank"
            className="see-all ">
            Learn at The College <DownloadArrow />
          </Link>
        </div>
      </div>
    </div>
  );
}

export function LearningJourneyJIT({ designation, courseData }) {
  return (
    <div className="card drop-box padding--sm w-full jit learning-module">
      <div className="title3">
        <Link to="/learning-journey">My Learning Journey</Link>
      </div>
      <Divider className="top-divider" />

      <div className="content-wrapper">
        <div className="on-demand-img">
          <img alt="" src="/images/on-demand.svg" />
        </div>

        <div>
          <div className="small-headline gray">
            Access your just-in-time knowledge
          </div>

          <ul className="p3">
            {courseData.map((course) => {
              return (
                course.academic_plan === designation && (
                  <li key={course.course_id}>
                    {course.lms_url ? (
                      <Link
                        to={{ pathname: `${course.lms_url}` }}
                        target="_blank"
                        className="underline-link">
                        {course.course}: {course.course_title}
                      </Link>
                    ) : (
                      <span className="underline-link">
                        {course.course}: {course.course_title}
                      </span>
                    )}
                  </li>
                )
              );
            })}
          </ul>

          <Link
            to={{
              pathname: `${process.env.REACT_APP_MARKETING_URL}learn/continuing-education/continuing-education`,
            }}
            target="_blank"
            className="see-all">
            See all of our continuing education <DownloadArrow />
          </Link>
        </div>
      </div>
    </div>
  );
}

const calculateNextWebinar = (course) => {
  const courseStartDate = dayjs(course.class_start);
  const daysOfWeek = course.class_days.split(',');

  let today = dayjs(); // The day we calculate from.
  let todayDayOfWeek = parseInt(today.format('d')); // The day of the week.

  // If the course start date is in the future
  if (courseStartDate >= today) {
    today = courseStartDate; // We want to calculate from the course's start date.
    todayDayOfWeek = parseInt(courseStartDate.format('d')); // Also update DoW.
  }

  // dayjs calculates Sunday as 0; PeopleSoft calculates Monday as 0
  todayDayOfWeek -= 1; // Subtract 1 day so Monday becomes 0...
  if (todayDayOfWeek === -1) {
    todayDayOfWeek = 6; // and make Sunday become 6
  }

  // Figure out the DoW when the next class occurs.
  let nextClassDayOfWeek;
  for (let i = todayDayOfWeek; i < 7; i = (i + 1) % 7) {
    if (daysOfWeek[i] === 'Y') {
      nextClassDayOfWeek = i;
      break;
    }
  }

  // Figure out the diff between the calculated "today" and when the next class occurs
  const diff = (((nextClassDayOfWeek - todayDayOfWeek) % 7) + 7) % 7;
  // Increment the date of the next class by the above diff. The variable is a string.
  let classDate = today.add(diff, 'day').format('ddd MM/DD/YY');
  // If the course has a start time, concatinate it to classDate.
  if (course.start_time) {
    // dayjs cannot format a time on its own. Must be part of a date string.
    const startTime = classDate + ' ' + course.start_time;
    const classTime = dayjs(startTime).format('h:mm a');
    classDate += ` at ${classTime} EST`;
  }
  return classDate;
};

const calculateNextCourse = (courseData, designation) => {
  let lowestSeq = 0,
    nextCourse = null,
    completed = 0,
    total = 0;

  for (let i = 0; i < courseData.length; i++) {
    if (courseData[i].academic_plan === designation) {
      /* WRP-818: total required & total completed are now provided to us, no need to calculate */
      total = courseData[i]?.courses_required
        ? courseData[i].courses_required
        : 0;
      completed = courseData[i]?.courses_completed
        ? courseData[i].courses_completed
        : 0;

      const inProgress =
        courseData[i].status === 'IN PROGRESS' ||
        courseData[i].status === 'IN-PROGRESS' ||
        courseData[i].status?.startsWith('CLASS STARTS ON');
      /*
       * Set nextCourse
       * courseData[i]:
       * Not In Progress &&
       * Not lowest sequence OR courseData[i]'s sequence is lower than currently calculated lowestSeq
       */
      if (courseData[i].status === 'COMPLETED') {
        // Do not include completed courses in the lowest sequence calculation
        continue;
      } else if (
        !inProgress &&
        (!lowestSeq || parseInt(courseData[i].course_sequence) < lowestSeq)
      ) {
        lowestSeq = parseInt(courseData[i].course_sequence);
        nextCourse = courseData[i];
      }
    }
  }
  return [completed, total, nextCourse];
};
