import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useOktaAuth } from '@okta/okta-react';
import { encode } from 'html-entities';
import dayjs from 'dayjs';

import { useQuery, useMutation } from 'react-query';
import useTacfsService from '../utils/tacfs/useTacfsService';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import Accordion from '../components/subcomponents/Accordion';
import {
  FormikCheckbox,
  FormikText,
  FormikSelect,
  FormikDatePicker,
} from '../components/subcomponents/InputField';

import { CEChart } from '../components/designationsCertifications/CEChart';
import Timer from '../components/subcomponents/Timer';

import selectOptions from '../data/selectOptions.json';

export function CEReporting() {
  const { load, save } = useTacfsService();
  const { authState } = useOktaAuth();

  const [attestationVisible, setAttestationVisible] = useState(false);
  const [attestationError, setAttestationError] = useState('');

  const [reportCEVisible, setReportCEVisible] = useState(false);
  const [reportCEError, setReportCEError] = useState('');
  
  const [beginDate, setBeginDate] = useState('');
  const [isAuth, setAuth] = useState(false);

  const categoryOptions = selectOptions.category;
  const providerOptions = selectOptions.provider;

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const { data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );

  const { isLoading: studentCELoading, data: studentCE } = useQuery(
    ['studentCE'],
    () => load('studentCE'),
    {
      enabled: isAuth,
    },
  );

  const { data: externalCE } = useQuery(
    ['externalCE'],
    () => load('externalCE', { beginDate: beginDate }),
    { enabled: beginDate.length > 0 },
  );

  const { isLoading: ceDetailLoading, data: ceDetail } = useQuery(
    ['ceDetail'],
    () => load('ceDetail', { beginDate: beginDate }),
    { enabled: beginDate.length > 0 },
  );

  const { isLoading: attestationLoading, mutate: attestationMutate } =
    useMutation((values) => save('ceAttestation', values));

  const { isLoading: exCELoading, mutate: externalCEMutate } = useMutation(
    (values) => save('externalCE', values),
  );

  const attestationSubmit = async (values) => {
    setAttestationError('');

    if (values.attestation) {
      const params = {
        studentID: userData.profile.studentID,
        initialEntry: '1',
      };

      attestationMutate(params, {
        onError: (res) => setAttestationError(res.toString()),
        onSuccess: (data) => {
          setAttestationVisible(false);
          window.location.reload();
        },
      });
    } else {
      setAttestationError('You must agree to the attestation.');
    }
  };

  const reportCESubmit = async (values) => {
    setReportCEError('');
    let params = { ...values };

    const providerVal =
      values.institution === 'O'
        ? encode(values.institution_other)
        : encode(values.institution);

    // initial_entry = 1 if first entry, 0 if existing
    if (externalCE && externalCE.length > 0) {
      const extCredits = externalCE[0]?.total_ce_credits_ext;
      params.initial_entry =
        extCredits && parseFloat(extCredits) > 0 ? '0' : '1';
    } else {
      params.initial_entry = '1';
    }

    params.period_begin_date = dayjs(studentCE.tac_ce_begin_dt).format(
      'YYYY-MM-DD',
    );
    params.year_display = studentCE.year_display;
    params.institution = providerVal;
    params.catalog_number = '';
    params.seq_number = '1';
    params.delete_flag = 'N';
    params.ce_crd_earned = Number.parseFloat(
      Number(params.ce_crd_earned).toFixed(2),
    ).toString();
    params.eth_crd_earned = Number.parseFloat(
      Number(params.eth_crd_earned).toFixed(2),
    ).toString();
    delete params.report_ce_check;
    delete params.institution_other;

    externalCEMutate(params, {
      onError: (res) => setReportCEError(res.toString()),
      onSuccess: (data) => {
        setReportCEVisible(false);
        window.location.reload();
      },
    });
  };
  const lastYear = dayjs().endOf('year').subtract(1, 'year');
  const dateEarnedMin = dayjs().subtract(3, 'year').format('MM-DD-YYYY');
  const dateEarnedMax = dayjs().add(10, 'year').format('MM-DD-YYYY');
  const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  const ReportValidationSchema = Yup.object().shape({
    institution: Yup.string().required('Provider is required.'),
    institution_other: Yup.string().when('institution', {
      is: (institution) => institution === 'O',
      then: () => Yup.string().required('Other provider is required.'),
    }),
    course_title: Yup.string().required('Course name is required.'),
    ce_crd_earned: Yup.number()
      .positive()
      .typeError('Credits earned must be a number.')
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after decimal.',
        (val) => {
          if (typeof val !== 'undefined') {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        },
      )
      .min(0, 'Credits earned must be at least 0.')
      .max(99.99, 'Credits earned must be at most 99.99.')
      .required('Credits earned is required.'),
    eth_crd_earned: Yup.number()
      .positive()
      .typeError('Ethics credits earned must be a number.')
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after decimal.',
        (val) => {
          if (typeof val !== 'undefined') {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        },
      )
      .min(0, 'Ethics credits earned must be at least 0.')
      .max(99.99, 'Ethics Credits earned must be at most 99.99.')
      .required('Ethics credits earned is required.'),
    category_number: Yup.string().required('Category is required.'),
    date_earned: Yup.date().when('category_number', {
      is: (category_number) => category_number === '7',
      then: () =>
        Yup.date()
          .max(lastYear, 'Carryover credits must be from a previous year.')
          .required('Date earned is required.'),
      otherwise: () =>
        Yup.date()
          .min(dateEarnedMin, 'Carryover credits must be from a previous year.')
          .max(dateEarnedMax, 'Carryover credits must be from a previous year.')
          .required('Date earned is required.'),
    }),
    report_ce_check: Yup.boolean().oneOf(
      [true],
      'You must agree to the attestation.',
    ),
  });

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setAuth(true);
    }
  }, [setAuth, authState]); 
  
  useEffect(() => {
    if (studentCE && !studentCELoading) {
      console.log("We have Student CE info");
      setBeginDate(dayjs(studentCE.begin_date).format('YYYY-MM-DD'));
    }
    if(studentCELoading){
        console.log("Loading CE");
    }
  }, [studentCELoading, studentCE]);
  
  
  if (studentCELoading || ceDetailLoading) {
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );
  }
  
  return (
    <Layout>
      <Breadcrumbs />
      <div className="ce-reporting container--full">
        <div className="intro-content-wrapper container">
          <h2 className="lg:w-2/3">Continuing Education (CE) Reporting</h2>
          <p className="lg:w-1/2">
            This dashboard view allows you to certify that you’ve earned CE
            hours and self report CE with other providers, and offers a table
            view of CE within a reporting cycle.
          </p>
          {studentCE && Object.keys(studentCE).length > 0 && studentCE.tac_ce_crd_earned && (
            <ul>
              <li>
                <span className="font-semibold">Current Credits:</span>{' '}
                {studentCE.tac_ce_crd_earned}
              </li>
              {studentCE.tac_ce_req_flag.toUpperCase() === 'Y' &&
                studentCE.tac_question_stat.toUpperCase() === 'C' &&
                studentCE.tac_ce_crd_outstd && (
                  <li>
                    <span className="font-semibold">Credits Needed:</span>{' '}
                    {studentCE.tac_ce_crd_outstd}
                  </li>
                )}
              {studentCE.tac_ce_req_flag.toUpperCase() === 'Y' &&
                studentCE.tac_question_stat.toUpperCase() !== 'C' && (
                  <li>
                    <span className="font-semibold">Credits Needed:</span>{' '}
                    Please complete questionnaire to determine required credits.
                  </li>
                )}
            </ul>
          )}
        </div>

        <div className="cta-sections container">
          {studentCE && Object.keys(studentCE).length > 0 && studentCE.tac_ce_attest.toUpperCase() === 'Y' && (
            <div className="flex cta-section">
              <div className="description">
                <h3>CE Attestation</h3>
                <p>
                  Select CE Attestation to certify that you have earned the
                  required CE hours during the current reporting period. This is
                  the preferred method for most designees as it is quick and
                  simple.
                </p>
              </div>
              <div className="modal-wrapper">
                <CEReportingModal
                  title="CE Attestation"
                  visible={attestationVisible}
                  setVisible={setAttestationVisible}>
                  <div className="container--sm attestation-modal">
                    <h2>CE Attestation</h2>
                    <p>
                      Please certify that you have earned the required CE hours
                      during the current reporting period.
                    </p>
                    <h3>3 Categories of Acceptable CE</h3>

                    <div className="category">
                      <div className="small-headline black">Category 1</div>
                      <p>
                        Programs pre-approved for CE credits by the CFP Board of
                        Standards, State Insurance Commission, State Bar or
                        State Board of Accountancy are accepted by the
                        Professional Recertification Program.
                      </p>
                    </div>

                    <div className="category">
                      <div className="small-headline black">Category 2</div>
                      <p className="mb-0">
                        Pass an examination in the recertification period for:
                      </p>
                      <ol>
                        <li>
                          A course in The American College Huebner School or
                          Graduate School program or another recognized
                          professional designation program (30 hours per
                          course); or
                        </li>
                        <li>A FINRA license (30 hours); or</li>
                        <li>The CFP® certification (30 hours); or</li>
                        <li>
                          A college or university course from which credit can
                          be earned toward a degree (15 hours for each semester
                          credit hour or its equivalent).
                        </li>
                      </ol>
                    </div>

                    <div className="category">
                      <div className="small-headline black">Category 3</div>
                      <p>
                        Live/Virtual: Attend an educational program or meeting
                        that has at least one (1) credit hour (50 minutes of
                        attendance equals one credit hour).
                      </p>
                    </div>

                    <Formik
                      initialValues={{ attestation: false }}
                      validationSchema={AttestationValidationSchema}
                      onSubmit={(values) => {
                        attestationSubmit(values);
                      }}>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit} id="attestationForm">
                          <ul className="checkboxes">
                            <li>
                              <FormikCheckbox
                                id="attestation"
                                name="attestation">
                                {studentCE.tac_client_facing.toUpperCase() === 'Y' && (
                                  <>
                                    I certify that I have earned at least 30
                                    hours of CE, including 1 hour of ethics CE,
                                    during the current reporting period ending{' '}
                                    {dayjs(studentCE.tac_ce_end_dt).format(
                                      'MM/DD/YYYY',
                                    )}
                                    . I understand that I may be requested to
                                    produce verification to substantiate any
                                    activity for which I claim credit. I further
                                    understand that I must retain documentation
                                    of CE credits claimed for 1 year after the
                                    current recertification period.
                                  </>
                                )}
                                {studentCE.tac_client_facing.toUpperCase() === 'N' && (
                                  <>
                                    I certify that I have earned at least 1 hour
                                    of ethics CE during the current reporting
                                    period ending{' '}
                                    {dayjs(studentCE.tac_ce_end_dt).format(
                                      'MM/DD/YYYY',
                                    )}
                                    . I understand that I may be requested to
                                    produce verification to substantiate any
                                    activity for which I claim credit. I further
                                    understand that I must retain documentation
                                    of CE credits claimed for 1 year after the
                                    current recertification period.
                                  </>
                                )}
                              </FormikCheckbox>
                            </li>
                          </ul>

                          <div className="modal-buttons">
                            <button
                              type="button"
                              className="btn secondary"
                              onClick={() => setAttestationVisible(false)}>
                              Cancel
                            </button>
                            {attestationLoading ? (
                              <button className="btn disabled" disabled>
                                Submitting
                              </button>
                            ) : (
                              <button
                                type="submit"
                                form="attestationForm"
                                className="btn">
                                Submit
                              </button>
                            )}
                          </div>
                          {attestationError && (
                            <div className="red-text error">
                              {attestationError}
                            </div>
                          )}
                        </form>
                      )}
                    </Formik>
                  </div>
                </CEReportingModal>
              </div>
            </div>
          )}

          {studentCE && Object.keys(studentCE).length > 0 && studentCE.tac_ce_req_flag.toUpperCase() === 'Y' && (
            <div className="flex cta-section">
              <div className="description">
                <h3>Continuing Education (CE) With Other Providers</h3>
                <p>
                  Select Report CE if you would like to list and track specific
                  CE programs you've completed with other providers. CE
                  self-reporting is an optional benefit to designee's that can
                  be used in place of the CE Attestation.
                </p>
              </div>
              <div className="modal-wrapper">
                <CEReportingModal
                  title="Report CE"
                  visible={reportCEVisible}
                  setVisible={setReportCEVisible}>
                  <div className="container--sm">
                    <h2>Detailed CE Reporting</h2>

                    <Formik
                      initialValues={{
                        date_earned: '',
                        institution: '',
                        institution_other: '',
                        course_title: '',
                        ce_crd_earned: 0,
                        eth_crd_earned: 0,
                        category_number: '',
                        report_ce_check: false,
                      }}
                      validationSchema={ReportValidationSchema}
                      onSubmit={(values) => {
                        reportCESubmit(values);
                      }}>
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit} id="reportCEForm">
                          <p>
                            Please note that the total of your reported CE may
                            exceed your requirements for the current reporting
                            period. If that is the case, excess CE will NOT be
                            carried over into the next period and cannot be
                            reported again.
                          </p>
                          <h3>Course Information</h3>

                          <div className="column-layout halves">
                            <div className="field col">
                              <FormikText
                                label="Course/Program Name"
                                type="text"
                                id="course_title"
                                name="course_title"
                                value={values.course_title}
                                onChange={handleChange}
                                formikRequired
                              />
                            </div>
                            <div className="field col">
                              <FormikSelect
                                label="Provider"
                                id="institution"
                                name="institution"
                                value={values.institution}
                                onChange={handleChange}
                                formikRequired>
                                {providerOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </FormikSelect>
                            </div>
                          </div>

                          {values.institution === 'O' && (
                            <div className="column-layout halves">
                              <div className="field col">
                                <FormikText
                                  label="Other Provider"
                                  type="text"
                                  id="institution_other"
                                  name="institution_other"
                                  value={values.institution_other}
                                  onChange={handleChange}
                                  formikRequired
                                />
                              </div>
                            </div>
                          )}

                          <div className="column-layout halves">
                            <div className="field col">
                              <FormikText
                                label="Credits"
                                type="text"
                                id="ce_crd_earned"
                                name="ce_crd_earned"
                                value={values.ce_crd_earned}
                                onChange={handleChange}
                                formikRequired
                              />
                            </div>
                            <div className="field col">
                              <FormikText
                                label="Ethic Credits"
                                type="text"
                                id="eth_crd_earned"
                                name="eth_crd_earned"
                                value={values.eth_crd_earned}
                                onChange={handleChange}
                                formikRequired
                              />
                            </div>
                          </div>

                          <div className="column-layout halves">
                            <div className="field col">
                              <FormikDatePicker
                                label="Date Earned"
                                id="date_earned"
                                name="date_earned"
                                value={values.date_earned}
                                onChange={handleChange}
                                formikRequired
                              />
                            </div>
                          </div>

                          <h3>3 Categories of Acceptable CE</h3>

                          <div className="category">
                            <div className="small-headline black">
                              Category 1
                            </div>
                            <p>
                              Programs pre-approved for CE credits by the CFP
                              Board of Standards, State Insurance Commission,
                              State Bar or State Board of Accountancy are
                              accepted by the Professional Recertification
                              Program.
                            </p>
                          </div>

                          <div className="category">
                            <div className="small-headline black">
                              Category 2
                            </div>
                            <p className="mb-0">
                              Pass an examination in the recertification period
                              for:
                            </p>
                            <ol>
                              <li>
                                A course in The American College Huebner School
                                or Graduate School program or another recognized
                                professional designation program (30 hours per
                                course); or
                              </li>
                              <li>A FINRA license (30 hours); or</li>
                              <li>The CFP® certification (30 hours); or</li>
                              <li>
                                A college or university course from which credit
                                can be earned toward a degree (15 hours for each
                                semester credit hour or its equivalent).
                              </li>
                            </ol>
                          </div>

                          <div className="category">
                            <div className="small-headline black">
                              Category 3
                            </div>
                            <p>
                              Live/Virtual: Attend an educational program or
                              meeting that has at least one (1) credit hour (50
                              minutes of attendance equals one credit hour).
                            </p>
                          </div>

                          <div className="field flex-column category-field">
                            <FormikSelect
                              label="Category"
                              id="category_number"
                              name="category_number"
                              value={values.category_number}
                              onChange={handleChange}
                              formikRequired>
                              {categoryOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </FormikSelect>
                          </div>

                          <ul className="checkboxes">
                            <li>
                              <FormikCheckbox
                                name="report_ce_check"
                                id="report_ce_check">
                                I attest to the above statements and certify
                                that all information is true.
                              </FormikCheckbox>
                            </li>
                          </ul>

                          <div className="modal-buttons">
                            <button
                              type="button"
                              className="btn secondary"
                              onClick={() => setReportCEVisible(false)}>
                              Cancel
                            </button>
                            {exCELoading ? (
                              <button className="btn disabled" disabled>
                                Submitting
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn"
                                form="reportCEForm">
                                Submit
                              </button>
                            )}
                          </div>
                          {reportCEError && (
                            <div className="red-text error">
                              {reportCEError}
                            </div>
                          )}
                        </form>
                      )}
                    </Formik>
                  </div>
                </CEReportingModal>
              </div>
            </div>
          )}
        </div>

        {studentCE && Object.keys(studentCE).length > 0 && ceDetail && (
          <CEChart ceCourses={ceDetail} studentCE={studentCE} />
        )}

        <div className="faqs">
          <div className="container">
            <h2>Continuing Education FAQs</h2>
            <Accordion
              title=<h4>
                Earning CE: What continuing education (CE) credits apply?
              </h4>
              id="cecredit_drawer">
              <div className="p3">
                <ol className="upper-alpha">
                  <li>
                    Programs pre-approved for CE credits by the CFP Board of
                    Standards, State Insurance Commission, State Bar or State
                    Board of Accountancy are accepted by the Professional
                    Recertification Program.
                  </li>
                  <li>
                    Pass an examination in the recertification period for:
                    <ol className="lower-alpha">
                      <li>
                        A course in The American College Huebner School or
                        Graduate School program or another recognized
                        professional designation program (30 hours per course);
                        or
                      </li>
                      <li>A FINRA license (30 hours); or </li>
                      <li>The CFP® certification (30 hours); or </li>
                      <li>
                        A college or university course from which credit can be
                        earned toward a degree (15 hours for each semester
                        credit hour or its equivalent).
                      </li>
                    </ol>
                  </li>
                  <li>
                    Live/Virtual: Attend an educational program or meeting that
                    has at least one (1) credit hour (50 minutes of attendance
                    equals one credit hour).
                  </li>
                </ol>
              </div>
            </Accordion>

            <Accordion
              title=<h4>Unacceptable activities for earning CE</h4>
              id="unacceptableactivities_drawer">
              <div className="p3">
                <ul className="bullets">
                  <li>
                    Courses for state insurance licensing examination or
                    examination (other than FINRA or CFP®) for a state or
                    federal license related to financial services
                  </li>
                  <li>
                    Personal development courses, such as motivation, public
                    speaking, or salesmanship
                  </li>
                  <li>Product presentations</li>
                  <li>Programs less than 50 minutes in length.</li>
                </ul>
              </div>
            </Accordion>

            <Accordion
              title=<h4>How are CE credit hours reported?</h4>
              id="cereporting_drawer">
              <div className="p3">
                CE credits earned through coursework at the college will be
                recorded automatically. CE credits taken outside of the college
                are self-reported.
              </div>
            </Accordion>
            <Accordion
              title=<h4>
                Can I apply CE credits that I earned to meet other CE program
                requirements?
              </h4>
              id="applycecredits_drawer">
              <div className="p3">
                Credits approved for state CE, CFP®, CPE, CLE, and securities
                professionals that are accrued in the professional
                recertification reporting cycle can be applied to professional
                recertification program requirements. Credits earned outside the
                reporting cycle do not apply.
              </div>
            </Accordion>
            <Accordion
              title=<h4>
                How will my CE requirements be affected if my client-facing
                status changes in the middle of a reporting cycle?
              </h4>
              id="clientfacingstatuschange_drawer">
              <div className="p3">
                Should the client-facing status of a designee change mid-cycle,
                their CE requirements are still based on their status at the
                beginning of the current two-year cycle and will change at the
                beginning of the next cycle. The annual recertification fee will
                change to reflect current client-facing status at the next
                annual payment due date.
              </div>
            </Accordion>
            <Accordion
              title=<h4>Professional Recertification Program Guidelines</h4>
              id="recertguidelines_drawer">
              <div className="p3">
                For more detailed information on requirements, review our{' '}
                <Link
                  to={{
                    pathname: `${process.env.REACT_APP_MARKETING_URL}sites/default/files/2023-09/recertification-program-guidelines-2024.pdf`,
                  }}
                  target="_blank">
                  Professional Recertification Program Guidelines
                </Link>
                .
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const CEReportingModal = ({
  title,
  children,
  linkClasses = 'btn modal-trigger',
  visible,
  setVisible,
}) => {
  useEffect(() => {
    if (visible) {
      document.querySelector('body').classList.remove('overflow-auto');
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
      document.querySelector('body').classList.add('overflow-auto');
    }
  }, [visible]);

  return (
    <>
      <button onClick={() => setVisible(true)} className={linkClasses}>
        {title}
      </button>
      <div className={visible ? 'modal active' : 'modal'}>
        <div className="modalContent scrollable">
          <div className="close" onClick={() => setVisible(false)}>
            <span role="img" aria-label="close modal">
              &times;
            </span>
          </div>

          {children}
        </div>
      </div>
    </>
  );
};

const AttestationValidationSchema = Yup.object().shape({
  attestation: Yup.boolean().oneOf(
    [true],
    'You must agree to the attestation.',
  ),
});
