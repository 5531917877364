import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import classOptions from '../../data/classOptions.json';
import { Download, SortArrow } from '../subcomponents/Icon';

import { openBase64NewTab } from '../../utils/helper';

import ReadStorage from '../storage/ReadStorage';

export function CourseHistory({ courseHistory, flags }) {
  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  // Pull in the user data from local storage.
  const tacfsProfile = ReadStorage('oktaProfile');
  const designations = classOptions.designations;
  const [transcriptAction, setTranscriptAction] = useState(false);
  // Retrieves the transcript for the user.
  const { isLoading, data: transcript } = useQuery(
    ['transcript'],
    () => load('transcript'),
    { enabled: transcriptAction !== false, retry: 0 },
  );

  const { isLoading: reportDataLoading, data: reportData } = useQuery(
    ['gradeReportList'],
    () => load('gradeReportList'),
  );

  const [gradeList, setGradeList] = useState(false);

  // Get the list of LMS V6 hash courses.
  const hashV6Enabled = courseHistory.filter(
    (course) => course.lms_file_type === 'LFT',
  );

  // Load the V6 hash if there are courses that need it to log in.
  const { isLoading: lifterHashLoading, data: lifterHash } = useQuery(
    [`lifterHash`],
    () => load('lifterHash'),
    { enabled: hashV6Enabled.length > 0 },
  );

  const huebner = flags.pred_gpa;
  const irwin = flags.grad_gpa;
  const cumulative = flags.special_gpa;

  //WRP-1261 Give access to courses taken/completed w/in last 6 months
  // Get max access date for all designations in course history
  const designationMaxAccess = courseHistory.reduce((accum, course) => {
    accum[course.academic_plan] = course.blackboard_exp_date;
    return accum;
  }, {});
  const khMaxAccess = courseHistory.reduce((accum, course) => {
    accum[course.academic_plan] = course.blackboard_exp_date;
    return accum;
  }, {});

  useEffect(() => {
    if (isLoading === false) {
      if (transcript && transcript.pdf_data) {
        if (transcriptAction === 'view') {
          openBase64NewTab(
            decodeURIComponent(transcript.pdf_data),
            `unofficial_transcript.pdf`,
          );
        } else if (transcriptAction === 'download') {
          // Setting various property values to
          // download the pdf file.
          const downloadLink = document.createElement('a');
          const fileName = `unofficial_transcript.pdf`;
          // Add the attributes to the a
          // link to download the file.
          downloadLink.href = `data:application/pdf;base64,${transcript.pdf_data}`;
          downloadLink.target = '_blank';
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
      // reset the action.
      setTranscriptAction(false);
    }
  }, [transcript, transcriptAction, isLoading]);

  useEffect(() => {
    if (
      reportDataLoading === false &&
      reportData?.gradeReportList &&
      gradeList === false
    ) {
      // Loop through the grade list to get the LMS URL and
      // lms type from the course history.
      reportData.gradeReportList.forEach(function (x, i) {
        const course = courseHistory.filter(
          (course) =>
            course.course_id === x.course_id && course.lms_url != null,
        );
        if (course.length > 0) {
          reportData.gradeReportList[i] = {
            ...x,
            lms_url: course[0].lms_url,
            lms_file_type: course[0].lms_file_type,
          };
        }
      });
      setGradeList(reportData.gradeReportList);
    }
  }, [reportDataLoading, reportData, gradeList, courseHistory]);

  // Displays the unofficial transcript to the user.
  const viewTranscript = () => setTranscriptAction('view');

  // Downloads a copy of the unoffical transcript.
  const downloadTranscript = () => setTranscriptAction('download');

  // Opens the SSO Parchment link in a new window.
  const ssoParchment = () =>
    window.open(process.env.REACT_APP_PARCHMENT_SSO_URL, '_blank');

  // Keep track of which column is being sorted by
  // Custom hook so we can alternate asc/desc if it's clicked twice
  const [sort, setSort] = useSort('designation');

  const sortCourses = (a, b) => {
    switch (sort[0]) {
      case 'designation':
        if (a.academy_plan === 'NOTAPPL') return 1;
        if (b.academy_plan === 'NOTAPPL') return -1;
        if (sort[1] === 'asc')
          return a.academy_plan?.localeCompare(b.academy_plan);
        else return b.academy_plan?.localeCompare(a.academy_plan);
      case 'course':
        if (sort[1] === 'asc') return a.course.localeCompare(b.course);
        else return b.course.localeCompare(a.course);
      case 'date':
        if (sort[1] === 'asc')
          return dayjs(a.exam_date).isBefore(b.exam_date) ? -1 : 1;
        else return dayjs(a.exam_date).isBefore(b.exam_date) ? 1 : -1;
      case 'grade':
        if (sort[1] === 'asc')
          return a.course_grade_off.localeCompare(b.course_grade_off);
        else return b.course_grade_off.localeCompare(a.course_grade_off);
      default:
        return 0;
    }
  };
  return (
    <div className="course-history container">
      <div className="page-header">
        <h2>Course History & Transcripts</h2>
        <p>
          See the courses you’ve completed and view and download transcripts for
          your records.
        </p>
      </div>
      <br />
      <div className="card-layout halves sm-gap">
        <div className="card drop-box padding--sm transcript">
          <div className="card-holder column-layout">
            <div className="card-header">
              <h4>Unofficial Transcripts</h4>
            </div>
            <div className="col--third">
              <div className="buttons">
                {isLoading ? (
                  <>
                    <button className="btn w-full disabled" disabled>
                      {transcriptAction === 'view'
                        ? 'Downloading...'
                        : 'View Transcripts'}
                    </button>
                    <button
                      className="btn secondary w-full download-btn disabled"
                      disabled>
                      {transcriptAction === 'download' ? (
                        'Downloading...'
                      ) : (
                        <>
                          <Download />
                          Download
                        </>
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn w-full view-transcript"
                      onClick={viewTranscript}>
                      View Transcripts
                    </button>
                    <button
                      className="btn secondary w-full download-btn"
                      onClick={downloadTranscript}>
                      <Download />
                      Download
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr className="card-hr" />
          <div>
            <p>
              View and download your unofficial transcripts for safe record
              keeping.
            </p>
          </div>
        </div>
        <div className="card drop-box padding--sm transcript">
          <div className="card-holder column-layout">
            <div className="card-header">
              <h4>Official Transcripts</h4>
            </div>
            <div className="col--third">
              <div className="buttons">
                <button
                  className="btn w-full view-transcript"
                  onClick={ssoParchment}>
                  View Transcripts
                </button>
              </div>
            </div>
          </div>
          <hr className="card-hr" />
          <div>
            <p>
              If you need an official transcript for your organization or
              personal record keeping, click the button above to complete the
              transaction.
            </p>
          </div>
        </div>
      </div>
      <div className="course-history-past">
        <div className="course-history-past-header column-layout">
          <div className="col col--third">
            <h3>Past Courses & Grades</h3>
            {(cumulative || huebner || irwin) && (
              <div className="gpas">
                {cumulative && (
                  <div className="gpa">
                    <span className="font-semibold">Cumulative GPA:</span>{' '}
                    {cumulative}
                  </div>
                )}
                {huebner && (
                  <div className="gpa">
                    <span className="font-semibold">Huebner School GPA:</span>{' '}
                    {huebner}
                  </div>
                )}
                {irwin && (
                  <div className="gpa">
                    <span className="font-semibold">
                      Irwin Graduate School GPA:
                    </span>{' '}
                    {irwin}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="course-history-table chart-table">
          <span>
            Students will retain course access for 6 months after finishing the
            course. If any of the courses below apply, please click the course
            title to view your materials.
          </span>
          <table className="table-auto">
            <thead>
              <tr>
                <th scope="col" id="designation">
                  <button
                    className={
                      sort[0] === 'designation'
                        ? 'thead-layout active-sort'
                        : 'thead-layout'
                    }
                    onClick={() => setSort('designation')}>
                    Designation
                    <span className={sort[1] === 'desc' ? 'desc' : ''}>
                      <SortArrow />
                    </span>
                  </button>
                </th>
                <th scope="col" id="courseName">
                  <button
                    className={
                      sort[0] === 'course'
                        ? 'thead-layout active-sort'
                        : 'thead-layout'
                    }
                    onClick={() => setSort('course')}>
                    Course ID & Course Name
                    <span className={sort[1] === 'desc' ? 'desc' : ''}>
                      <SortArrow />
                    </span>
                  </button>
                </th>
                <th scope="col" id="date">
                  <button
                    className={
                      sort[0] === 'date'
                        ? 'thead-layout active-sort'
                        : 'thead-layout'
                    }
                    onClick={() => setSort('date')}>
                    Date Completed
                    <span className={sort[1] === 'desc' ? 'desc' : ''}>
                      <SortArrow />
                    </span>
                  </button>
                </th>
                <th scope="col" id="grade">
                  <button
                    className={
                      sort[0] === 'grade'
                        ? 'thead-layout active-sort'
                        : 'thead-layout'
                    }
                    onClick={() => setSort('grade')}>
                    Grade
                    <span className={sort[1] === 'desc' ? 'desc' : ''}>
                      <SortArrow />
                    </span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {gradeList && (
                <>
                  {gradeList.sort(sortCourses).map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {x.academy_plan && x.academy_plan !== 'NOTAPPL' && (
                            <span>
                              {parse(
                                designations[x.academy_plan?.toLowerCase()]
                                  ?.shortName,
                              )}{' '}
                              (
                              {parse(
                                designations[x.academy_plan?.toLowerCase()]
                                  ?.name,
                              )}
                              )
                            </span>
                          )}
                        </td>
                        <td>
                          {x.course} - {x.course_title}
                          {x.lms_url && x.academy_plan && (
                            <>
                              <br />
                              {x.lms_file_type === 'LFT' &&
                              dayjs(khMaxAccess[x.academy_plan]) >= dayjs() ? (
                                <form
                                  target="_blank"
                                  rel="noreferrer"
                                  action={x.lms_url}
                                  method="post"
                                  id={`liftersso${x.course_id}`}
                                  acceptCharset="UTF-8"
                                  cr-attached="true"
                                  cr-form-attach-retry="true">
                                  <div>
                                    {lifterHashLoading === false && (
                                      <>
                                        <input
                                          type="submit"
                                          value="Access Course"
                                          name="access_course"
                                          className="underline-link"
                                        />
                                        <input
                                          type="hidden"
                                          name="student_id"
                                          value={tacfsProfile.profile.studentID}
                                        />
                                        <input
                                          type="hidden"
                                          name="hash"
                                          value={lifterHash.value}
                                        />
                                      </>
                                    )}
                                  </div>
                                </form>
                              ) : (
                                dayjs(designationMaxAccess[x.academy_plan]) >=
                                  dayjs() && (
                                  <a
                                    href={x.lms_url}
                                    className="underline-link"
                                    rel="noreferrer"
                                    target="_blank">
                                    View Course
                                  </a>
                                )
                              )}
                            </>
                          )}
                        </td>
                        <td>{dayjs(x.exam_date).format('M/D/YYYY')}</td>
                        <td>
                          <div>{x.course_grade_off}</div>
                          <ViewGradeReport
                            term={x.term}
                            classNumber={x.class_number}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function ViewGradeReport({ term, classNumber }) {
  const { load } = useTacfsService();
  const [download, setDownload] = useState(false);

  const { isLoading: gradeLoading, data: gradeReport } = useQuery(
    [`${term}_${classNumber}_gradeReport`],
    () => load('gradeReport', { term: term, classNumber: classNumber }),
    {
      enabled: download,
      retry: 0,
    },
  );

  useEffect(() => {
    if (gradeLoading === false) {
      if (gradeReport?.pdf_data && download) {
        openBase64NewTab(
          decodeURIComponent(gradeReport.pdf_data),
          `gradeReport_${term}_${classNumber}.pdf`,
        );
      }
      setDownload(false);
    }
  }, [gradeReport, download, gradeLoading, classNumber, term]);

  if (term?.replaceAll('0', '') && classNumber?.replaceAll('0', '')) {
    return gradeLoading ? (
      <button className="view-grade disabled" disabled>
        Downloading...
      </button>
    ) : (
      <button
        className="underline-link view-grade"
        onClick={() => setDownload(true)}>
        View Grade Report
      </button>
    );
  }

  return <></>;
}

// Assumes initialValue is the column to sort
function useSort(initialValue) {
  const [sort, setSort] = useState([initialValue, 'asc']);

  function changeSort(col) {
    let newSort = [col, ''];

    if (sort[0] === col) {
      newSort[1] = sort[1] === 'asc' ? 'desc' : 'asc';
    } else {
      newSort[1] = 'asc';
    }

    setSort(newSort);
  }

  return [sort, changeSort];
}
