import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { CourseHistory } from './CourseHistory';
import CurrentCourses from './CurrentCourses';
import { RecommendedCourses } from './RecommendedCourses';

export default function LearningJourneyTabs({ courseHistory, flags }) {
  const [value, setValue] = useState(courseHistory ? 0 : 1);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleChangeMobile = (e) => {
    setValue(parseInt(e.target.value));
  };

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <div>
      <div className="learning-tabs tabs-layout">
        <Tabs
          value={value}
          onChange={handleChange}
          className="learning-tabs-inner"
          aria-label="learning journey tabs"
          classes={{ indicator: 'tabs-indicator' }}
        >
          <Tab
            disableRipple
            label="Active Programs"
            disabled={!courseHistory}
            {...tabProps(0)}
          />
          <Tab disableRipple label="Recommended Courses" {...tabProps(1)} />
          <Tab
            disableRipple
            label="Course History & Transcripts"
            disabled={!courseHistory || !flags}
            {...tabProps(2)}
          />
        </Tabs>
      </div>

      <div className="learning-select tabs-layout-mobile field">
        <label htmlFor="learning-dropdown">Sort By</label>
        <select
          id="learning-dropdown"
          value={value}
          onChange={handleChangeMobile}
        >
          <option value={0}>Active Programs</option>
          <option value={1}>Recommended Courses</option>
          <option value={2}>Course History & Transcripts</option>
        </select>
      </div>

      {/* Current Courses */}
      <LearningTabPanel value={value} index={0}>
        <CurrentCourses courseHistory={courseHistory} setTab={setValue} />
      </LearningTabPanel>

      {/* Recommended Courses */}
      <LearningTabPanel value={value} index={1}>
        <RecommendedCourses flags={flags}/>
      </LearningTabPanel>

      {/* Course History & Transcripts */}
      <LearningTabPanel value={value} index={2}>
        <CourseHistory courseHistory={courseHistory} flags={flags} />
      </LearningTabPanel>
    </div>
  );
}

function LearningTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="learning-panel"
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
