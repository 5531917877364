import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SetStorage from '../storage/SetStorage';

/**
 * NotificationBar component
 *
 * @param {Object} notification - boolean value to determine if notification should be visible
 * @param {Object} notificationInfo - object containing notification message, link, and link message
 *
 * @returns {JSX.Element} - renders a notification bar with a message, link, and close button
 */
export function NotificationBar({ notification, notificationInfo }) {
  const { notificationMessage, notificationLink, notificationLinkMessage } =
    notificationInfo;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    notification ? setVisible(true) : setVisible(false);
  }, [notification]);

  return (
    <>
      {visible && (
        <div className="notification">
          <div
            className="close"
            onClick={() => {
              setVisible(false);
              SetStorage('showNotifBar', false);
            }}
          >
            &times;
          </div>
          <div className="container--lg notif-wrapper">
            <p className="eyebrow">Recertify Now</p>
            <p className="font-bold p2">{notificationMessage}</p>{' '}

            <Link className="notification-bar-link" to={notificationLink}>
              {notificationLinkMessage}
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
