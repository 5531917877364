import React, { useState, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { Modal } from '../subcomponents/Modal';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import OktaRequests from '../../_okta/utils/utils';
import SetStorage from '../storage/SetStorage';
import { useMutation } from 'react-query';

export const LoginForm = ({
  alt = false,
  loginDestination,
  setToken,
  encryptStorage,
}) => {
  const { oktaAuth } = useOktaAuth();
  const locateUserRef = useRef();
  const resetUserRef = useRef();
  const [sessionToken, setSessionToken] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [foundExisting, setFoundExisting] = useState(false);
  const [multipleFactorsFound, setMultipleFactorsFound] = useState(false);

  //recovery items
  const [resetError, setResetError] = useState('');
  const [isReseting, setIsReseting] = useState(false);
  const [submittedValues, setSubmittedValues] = useState(false);
  const [smsVerificationState, setSmsVerificationState] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [showRedirectButton, setShowRedirecButton] = useState(false);

  //existing account items
  const [unifyError, setUnifyError] = useState('');
  const [isUnifying, setIsUnifying] = useState(false);

  // Load the tacfs helper Service Worker.
  const { load, save } = useTacfsService();
  //Check to see if we need to automagically open the reset or unification modals.
  const queryParams = new URLSearchParams(window.location.search);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openUnifyModal, setOpenUnifyModal] = useState(false);
  const [displayOTPForm, setDisplayOTPForm] = useState(false);

  const op = queryParams.get('op');
  const destination = loginDestination ?? queryParams.get('destination');
  const navigate = useHistory();

  const { beginResetPasswordFlow, verifySMSRecoveryOTP } = OktaRequests();
  const { mutate: resetPwdByEmail } = useMutation((values) =>
    save('resetPwdByEmail', values),
  );
  const { mutate: beginSMSRecoveryFlow } = useMutation((values) =>
    beginResetPasswordFlow(values),
  );
  const { mutate: verifyOTP } = useMutation((values) =>
    verifySMSRecoveryOTP(values),
  );

  useEffect(() => {
    if (op) {
      switch (op) {
        case 'reset':
          setOpenResetModal(true);
          break;
        case 'unify':
          setOpenUnifyModal(true);
          break;
        default:
          // Default rule needed to
          // not throw warning.
          break;
      }
    }
  }, [op]);

  const handleSubmit = (event) => {
    event.preventDefault();

    oktaAuth
      .signInWithCredentials({ username, password })
      .then((response) => {
        if (response.status === 'LOCKED_OUT') {
          setLoginError('You are currently locked out!');
        } else if (response.status !== 'SUCCESS') {
          // if the designation param is set we want
          // to save that to the browser.
          SetStorage('mfa', response);
          // if the designation param is set we want to save that to the browser.
          if (destination) SetStorage('loginDestination', destination);
          // TODO:: add query parameters to the URL.
          navigate.push('/auth/mfa');
        } else {
          const sessionToken = response.sessionToken;
          // We want to set the encryption storage to session.
          if (encryptStorage) encryptStorage.setItem('pSecret', password);
          // Push up the session token to parent component.
          if (setToken) setToken(sessionToken);
          setSessionToken(sessionToken);
          SetStorage('session', sessionToken);
          // if the designation param is set we want to save that to the browser.
          if (destination) SetStorage('loginDestination', destination);
          oktaAuth.signInWithRedirect({ sessionToken });
        }
      })
      .catch((err) => {
        setLoginError(err.errorSummary);
      });
  };

  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const resetUserSubmit = async (values) => {
    setResetError('');
    setSubmittedValues(false);
    if (isReseting === false) {
      setIsReseting(true);
      try {
        // Look up the okta record.
        const data = await load('lookUpOktaRecord', values);
        // Set the existing data.
        if (data) {
          // We have an SMS factor.
          // We need to let the user select how they want to recover their password.
          if (data?.profile?.phone) {
            setMultipleFactorsFound(true);
          }
          setSubmittedValues(values);
          setFoundExisting(data);
        } else {
          setResetError('There was a problem');
        }
        setIsReseting(false);
      } catch (error) {
        // Set the error this way bc we want
        // to render the text as html.
        setResetError({ __html: error });
        setIsReseting(false);
      }
    }
  };

  const selectFactorSubmit = async (values) => {
    if (values.factorMethod.toLowerCase() === 'email') {
      let payload = submittedValues;
      payload.studentId = submittedValues.studentID;
      resetPwdByEmail(payload, {
        onError: (error) => {
          if (error) setResetError({ __html: error });
        },
        onSuccess: () => {
          setMultipleFactorsFound(false); //Hide selection screen.
        },
      });
    } else if (values.factorMethod.toLowerCase() === 'sms') {
      //need username and factor type
      const payload = {
        factorType: 'SMS',
        username: foundExisting.plainUser,
      };
      beginSMSRecoveryFlow(payload, {
        onError: (error) => {
          if (error) setResetError({ __html: error });
        },
        onSuccess: (data) => {
          setSmsVerificationState(data.stateToken);
          //store state token!
          setDisplayOTPForm(true);
        },
      });
    } else {
      console.log('Unknown factor');
      setResetError({ __html: 'We encountered an unknow error' });
    }
  };
  const verifyOtp = async (values) => {
    const payload = {
      passCode: values.otpField,
      stateToken: smsVerificationState,
    };
    verifyOTP(payload, {
      onError: (error) => {
        setOtpError(error.data.summary);
      },
      onSuccess: (data) => {
        setSmsVerificationState(data.stateToken);
        setShowRedirecButton(true);
        setDisplayOTPForm(false);
        multipleFactorsFound(false);
      },
    });
  };
  const locateUserSubmit = async (values) => {
    setUnifyError('');
    if (isUnifying === false) {
      try {
        setIsUnifying(true);
        // Check if existing student is in PS.
        // No need for react-query as this data doesnt need to be cached.
        const data = await load('verifyExistingStudent', values);
          // Set the existing data.
        if (data) setFoundExisting(data);
          // Just in case null data was return show a
          // generic error message. This is a edge case
        else setUnifyError('There was a problem');
        setIsUnifying(false);
        // Send the unifcation verification email.
      } catch (error) {
        setIsUnifying(false);
        // Set the error this way bc we want
        // to render the text as html.
        setUnifyError({ __html: error });
      }
    }
  };

  const onClose = (ref) => {
    // reset the existing user.
    setFoundExisting('');
    // we want to reset the form.
    ref.current.resetForm();
  };

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null;
  }

  return (
    <div className="card-layout drop-box login-box">
      {alt === false && (
        <div className="p4 w-full">
          If you are accessing My Learning Hub for the first time, please{' '}
          <Modal
            title="click here"
            linkClasses="underline-link"
            scrollable={true}
            showNow={openUnifyModal}
            onClose={() => onClose(locateUserRef)}>
            <section>
              <div className="container container--sm">
                {!foundExisting ? (
                  <>
                    <p className="title1 element-spacing">
                      Find Your Existing Account
                    </p>
                    <div className="column-layout">
                      <div className="col">
                        <p>
                          In order to locate your record and update your
                          username and password, we need your email address
                          registered to The American College of Financial
                          Services OR your Student ID Number. Once your record
                          is located, you will receive an email to verify your
                          identity.
                        </p>
                      </div>
                      <div className="col">
                        {unifyError && (
                          <p dangerouslySetInnerHTML={unifyError} />
                        )}
                        <Formik
                          initialValues={{
                            email: '',
                            studentID: '',
                          }}
                          validationSchema={UnifyValidationSchema}
                          enableReinitialize={true}
                          innerRef={locateUserRef}
                          onSubmit={(values) => {
                            locateUserSubmit(values);
                          }}>
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit} id="locateUser">
                              <div className="field">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  value={values.email}
                                  disabled={values.studentID}
                                  className={
                                    touched.email && errors.email
                                      ? 'error'
                                      : null
                                  }
                                  onChange={handleChange}
                                  placeholder="Personal or Business Email Address"
                                  onBlur={handleBlur}
                                />
                                {touched.email && errors.email && (
                                  <span className="red-text">
                                    {errors.email}
                                  </span>
                                )}
                              </div>
                              <div className="field">
                                <Field
                                  type="text"
                                  id="text"
                                  name="studentID"
                                  value={values.studentID}
                                  disabled={values.email}
                                  className={
                                    touched.studentID && errors.studentID
                                      ? 'error'
                                      : null
                                  }
                                  onChange={handleChange}
                                  placeholder="Student ID"
                                  onBlur={handleBlur}
                                />
                                {touched.studentID && errors.studentID && (
                                  <span className="red-text">
                                    {errors.studentID}
                                  </span>
                                )}
                                <p>
                                  Your Student ID number is seven digits and
                                  starts with the numeral “1.” Your Student ID
                                  can be found next to your name on most
                                  communications from The College.
                                </p>
                              </div>

                              <div className="flex">
                                <button
                                  type="submit"
                                  form="locateUser"
                                  className="btn">
                                  Continue
                                </button>
                              </div>
                              <p className="disclaimer pt-8">
                                If you are having issues, please contact the
                                American College of Financial Services at{' '}
                                <a href="tel:8882637265">888-263-7265</a>.
                              </p>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="title1 element-spacing">
                      Your Account Has Been Found!
                    </p>
                    <p>
                      An email has been sent to {foundExisting?.email} with
                      additional instructions. Please open the email and follow
                      the instructions to finalize your account setup.
                    </p>
                    <a href="/" className="btn">
                      Login
                    </a>
                  </>
                )}
              </div>
            </section>
          </Modal>{' '}
          to set up your account.
        </div>
      )}
      <div className="card">
        {alt ? (
          <>
            <h4>Returning Student</h4>
            <p>Already have an account? Log in here.</p>
          </>
        ) : (
          <h4>Please enter your username and password</h4>
        )}

        <form className="login-form group" id="loginForm">
          {loginError && (
            <div className="red-text">
              <p className="p3 w-full red-text">
                {loginError}. Please check your username or password.
              </p>
            </div>
          )}
          <div className="field">
            <label htmlFor="username">
              Email Address <span>*</span>
            </label>
            <input type="text" id="username" onChange={handleUsername} />
          </div>
          <div className="field">
            <label htmlFor="password">
              Password <span>*</span>
            </label>
            <input type="password" id="password" onChange={handlePassword} />
          </div>
        </form>
      </div>
      <div className="flex w-full column-layout">
        <button
          id="loginSubmitButton"
          type="submit"
          form="loginForm"
          className="btn col-third"
          onClick={handleSubmit}>
          Log In
        </button>
        <Modal
          title="Forgot User Name or Password?"
          linkClasses="underline-link forgot-link"
          scrollable={true}
          showNow={openResetModal}
          onClose={() => onClose(resetUserRef)}>
          <section>
            <div className="container container--sm">
              {!foundExisting ? (
                <>
                  <p className="title1 element-spacing">
                    Forgot User Name or Password?
                  </p>
                  <div className="column-layout">
                    <div className="col">
                      <p>
                        In order to locate your username and/or reset your
                        password please provide ONE of the following pieces of
                        information that you believe we have on file.
                      </p>
                    </div>
                    <div className="col">
                      {resetError && <p dangerouslySetInnerHTML={resetError} />}
                      <Formik
                        initialValues={{
                          username: '',
                          email: '',
                          studentID: '',
                        }}
                        validationSchema={ResetValidationSchema}
                        enableReinitialize={true}
                        innerRef={resetUserRef}
                        onSubmit={(values) => {
                          resetUserSubmit(values);
                        }}>
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit} id="resetUser">
                            <div className="field">
                              <Field
                                type="text"
                                id="text"
                                name="studentID"
                                value={values.studentID}
                                disabled={values.email || values.username}
                                className={
                                  touched.studentID && errors.studentID
                                    ? 'error'
                                    : null
                                }
                                onChange={handleChange}
                                placeholder="Student ID"
                                onBlur={handleBlur}
                              />
                              {touched.studentID && errors.studentID && (
                                <span className="red-text">
                                  {errors.studentID}
                                </span>
                              )}
                              <p>
                                Your Student ID number is seven digits and
                                starts with the numeral “1.” Your Student ID can
                                be found next to your name on most
                                communications from The College.
                              </p>
                            </div>
                            <div className="field">
                              <Field
                                type="text"
                                id="username"
                                name="username"
                                value={values.username}
                                disabled={values.email || values.studentID}
                                className={
                                  touched.username && errors.username
                                    ? 'error'
                                    : null
                                }
                                onChange={handleChange}
                                placeholder="Username or Email Address"
                                onBlur={handleBlur}
                              />
                              {touched.username && errors.username && (
                                <span className="red-text">
                                  {errors.username}
                                </span>
                              )}
                            </div>

                            <div className="flex">
                              <button
                                type="submit"
                                form="resetUser"
                                className="btn">
                                Continue
                              </button>
                            </div>
                            <p className="disclaimer pt-8">
                              If you are having issues, please contact the
                              American College of Financial Services at{' '}
                              <a href="tel:8882637265">888-263-7265</a>.
                            </p>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="title1 element-spacing">
                    We Found You in our System, {foundExisting?.firstName}
                  </p>
                  {!multipleFactorsFound && !showRedirectButton && (
                    <>
                      <p>
                        An email has been sent to {foundExisting?.email} with
                        additional instructions. Please open the email and
                        follow the instructions to finalize your account setup.
                      </p>
                      <a href="/" className="btn">
                        Login
                      </a>
                    </>
                  )}
                  {multipleFactorsFound &&
                    !displayOTPForm &&
                    !showRedirectButton && (
                      <>
                        <p>
                          Please select how you would like begin your password
                          recovery process.
                        </p>
                        {resetError && (
                          <p
                            className="red-text"
                            dangerouslySetInnerHTML={resetError}
                          />
                        )}
                        <Formik
                          initialValues={{
                            factorMethod: '',
                          }}
                          validationSchema={selectFactorValidationSchema}
                          enableReinitialize={true}
                          innerRef={resetUserRef}
                          onSubmit={(values) => {
                            selectFactorSubmit(values);
                          }}>
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit} id="selectFactor">
                              <div className="field">
                                <ul
                                  className="radios"
                                  role="group"
                                  aria-labelledby="factorMethod">
                                  <li>
                                    <Field
                                      type="radio"
                                      id="factorMethod1"
                                      name="factorMethod"
                                      value="email"
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="factorMethod1">
                                      Send an email to {foundExisting?.email}
                                    </label>
                                  </li>
                                  <li>
                                    <Field
                                      type="radio"
                                      id="factorMethod2"
                                      name="factorMethod"
                                      value="sms"
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="factorMethod2">
                                      Send a text to{' '}
                                      {foundExisting?.profile?.phone}
                                    </label>
                                  </li>
                                </ul>
                              </div>
                              {touched.factorMethod && errors.factorMethod && (
                                <span className="red-text">
                                  {errors.factorMethod}
                                </span>
                              )}
                              <div className="flex">
                                <button
                                  type="submit"
                                  form="selectFactor"
                                  className="btn">
                                  Continue
                                </button>
                              </div>
                              <p className="disclaimer pt-8">
                                If you are having issues, please contact the
                                American College of Financial Services at{' '}
                                <a href="tel:8882637265">888-263-7265</a>.
                              </p>
                            </form>
                          )}
                        </Formik>
                      </>
                    )}
                  {displayOTPForm && (
                    <>
                      <div className="column-layout">
                        <div className="col">
                          <h5>Verify Your Device</h5>
                          <p>
                            Please check your mobile device for a one-time
                            passcode that you must enter into the form.{' '}
                          </p>
                        </div>
                        <div className="col">
                          {otpError && (
                            <span className="red-text">{otpError}</span>
                          )}
                          <Formik
                            initialValues={{
                              otpField: '',
                            }}
                            enableReinitialize={true}
                            innerRef={resetUserRef}
                            onSubmit={(values) => {
                              verifyOtp(values);
                            }}>
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit} id="verifyOtp">
                                <div className="field">
                                  <label htmlFor="otpField">
                                    One-time Passcode
                                  </label>
                                  <Field
                                    type="text"
                                    id="otpField"
                                    name="otpField"
                                    value={values.otpField}
                                    onChange={handleChange}
                                    required={true}
                                  />
                                </div>

                                <div className="flex">
                                  <button
                                    type="submit"
                                    form="verifyOtp"
                                    className="btn">
                                    Continue
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                      <p className="disclaimer pt-8">
                        If you are having issues, please contact the American
                        College of Financial Services at{' '}
                        <a href="tel:8882637265">888-263-7265</a>.
                      </p>
                    </>
                  )}
                  {showRedirectButton && (
                    <>
                      <h5>Successful Verification</h5>
                      <p>
                        You have successfully verified your device. Please click
                        the button below to reset your password.
                      </p>
                      <a
                        className="btn primary"
                        href={`/account/password-reset?stoken=${smsVerificationState}`}>
                        Reset Password
                      </a>
                    </>
                  )}
                </>
              )}
            </div>
          </section>
        </Modal>
      </div>
    </div>
  );
};

export const UnifyValidationSchema = Yup.object().shape(
  {
    email: Yup.string()
      .email('Invalid email')
      .when('studentID', (studentID, schema) => {
        if (studentID && studentID[0] == null)
          return schema.required(
            'Please provide at least one piece of information',
          );
      }),
    studentID: Yup.string()
      .matches(
        /^1\d{6}$/,
        'Student ID must be 7-digit numeric and start with 1',
      )
      .when('email', (email, schema) => {
        if (email && email[0] == null)
          return schema.required(
            'Please provide at least one piece of information',
          );
      }),
  },
  [['email', 'studentID']],
);

export const ResetValidationSchema = Yup.object().shape(
  {
    username: Yup.string().when('email', (email, schema) => {
      if (email && email[0] == null)
        return schema.when('studentID', (studentID, schema) => {
          if (studentID && studentID[0] == null)
            return schema.required(
              'Please provide at least one piece of information',
            );
        });
    }),
    email: Yup.string()
      .email('Invalid email')
      .when('username', (username, schema) => {
        if (username && username[0] == null)
          return schema.when('studentID', (studentID, schema) => {
            if (studentID && studentID[0] == null)
              return schema.required(
                'Please provide at least one piece of information',
              );
          });
      }),
    studentID: Yup.string()
      .matches(
        /^1\d{6}$/,
        'Student ID must be 7-digit numeric and start with 1',
      )
      .when('username', (username, schema) => {
        if (username && username[0] == null)
          return schema.when('email', (email, schema) => {
            if (email && email[0] == null)
              return schema.required(
                'Please provide at least one piece of information',
              );
          });
      }),
  },
  [['username', 'email', 'studentID']],
);
export const selectFactorValidationSchema = Yup.object().shape({
  factorMethod: Yup.string().required('Please select an option'),
});
