import { useState } from 'react';
import { useMutation } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import Timer from '../subcomponents/Timer';

export function ProctorAcknowledgeForm(props) {
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);
  // Load the tacfs helper Service Worker.
  const { save } = useTacfsService();

  const { isLoading: acknowledgeLoading, mutate: acknowledge } = useMutation(
    (values) => save('proctorAcknowledgement', values),
  );

  const formSubmit = async (values) => {
    // we want to add the term and class
    // for the api call. We can just use the
    // option.
    values.studentID = props.student.student_id;
    values.term = props.term;
    values.classNumber = props.classNumber;
    values.registrationDate = props.registrationDate;
    values.registrationNum = props.registrationNum;
    // Call the endpoint and post the values.
    // We want to register the course
    acknowledge(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data.error === 'S') setSent(true);
        else setError('There was an error with the form submission.');
      },
    });
  };

  if (acknowledgeLoading) {
    return <Timer />;
  }

  // If the api call was successfull the show the thank you copy.
  if (sent) {
    return (
      <div className="container">
        <div className="intro-content-wrapper">
          {/* TODO: Maybe pull this text from the database so nothing is hardcoded. */}
          <h2>Thank you for your submission!</h2>
        </div>
      </div>
    );
  }

  return (
    <div>
      {error && <p className="red-text">{error}</p>}
      <div>
        I, {props.proctor.first_name} {props.proctor.last_name} understand that{' '}
        {`${props.student.first_name} ${props.student.last_name}`} ("Student")
        has selected me as Student's proctor for a course examination given by
        The American College that Student intends to take remotely. I agree as
        follows:
      </div>
      <ol>
        <li>
          I represent that I am a disinterested third party who is not a
          relative, immediate supervisor, or employee of Student, and have no
          financial or personal interest in the outcome of the examination. In
          addition, I am not the moderator of Student's course, nor a student in
          the same class.
        </li>
        <li>
          I am volunteering to act as Student's proctor for Student's benefit
          and because Student selected me for the role of proctor.
        </li>
        <li>
          I acknowledge that when acting as Student's proctor I am acting at all
          times as a volunteer and that no relationship of partnership, joint
          venture or employment with The American College is created by this
          Agreement. I shall not represent to anyone that I am an employee,
          partner, officer, owner or trustee of The American College or that I
          have authority to bind or represent The American College in any
          matter. As a volunteer, I understand and agree that I will receive no
          compensation from The American College for acting as proctor.
        </li>
        <li>
          I agree to fulfill the proctoring responsibilities to the best of my
          ability and to comply with the following exam proctoring guidelines:
          <ul>
            <li>
              I will not allow Student to use the course textbook, notes, or any
              other materials, or receive help from any source during the exam.
            </li>
            <li>
              Student must complete the exam within the time allotted for the
              exam by The American College.
            </li>
            <li>
              I will not allow Student to stop the exam session for any reason,
              even with the intent of returning to it later, unless agreed to in
              advance by The American College.
            </li>
            <li>
              I will not allow Student to be seated in close proximity to any
              other student enabling the potential exchange of information
              during the exam.
            </li>
            <li>
              I will remain in the testing area with Student for the full
              duration of the exam and will not provide any assistance while the
              exam is being taken.
            </li>
          </ul>
        </li>
        <li>
          I shall hold in confidence, and shall not disclose or use for any
          purpose other than proctoring the exam, any information about the exam
          or Student which I gained as a result of serving as proctor.
        </li>
        <li>
          I understand that I may terminate my participation as a proctor at any
          time without prior notice and The American College can do the same.
        </li>
      </ol>
      <Formik
        // Pull initial values from storage.
        initialValues={{
          acknowledgement: '',
          signature: '',
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="proctorAcknowledgeForm">
            <div className="field">
              <div role="group" aria-labelledby="proctorAcknowledgeFormGroup">
                <ul
                  className="radios"
                  role="group"
                  aria-labelledby="acknowledgement-radios">
                  <li>
                    <Field
                      type="radio"
                      name="acknowledgement"
                      id="acknowledgement-yes"
                      value="A"
                      checked={values.acknowledgement === 'A'}
                    />
                    <label htmlFor="acknowledgement-yes">
                      Understood and Agreed
                    </label>
                  </li>
                  <li>
                    <Field
                      type="radio"
                      name="acknowledgement"
                      value="D"
                      checked={values.acknowledgement === 'D'}
                      id="acknowledgement-no"
                    />
                    <label htmlFor="acknowledgement-no">Decline</label>
                  </li>
                </ul>
              </div>
              {touched.acknowledgement && errors.acknowledgement && (
                <span className="red-text">{errors.acknowledgement}</span>
              )}
            </div>

            <div className="field">
              <label htmlFor="signature">
                Signature<span>*</span>
              </label>
              <input
                type="text"
                id="signature"
                name="signature"
                className={
                  touched.signature && errors.signature ? 'error' : null
                }
                value={values.signature}
                onChange={handleChange}
                placeholder="Signature*"
                onBlur={handleBlur}
              />
              {touched.signature && errors.signature && (
                <span className="red-text">{errors.signature}</span>
              )}
            </div>
            <div className="flex w-full column-layout">
              <button
                type="submit"
                form="proctorAcknowledgeForm"
                className="btn col">
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

const ValidationSchema = Yup.object().shape({
  acknowledgement: Yup.string().required('Required Field*'),
  signature: Yup.string().required('Required Field*'),
});
