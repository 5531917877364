import { Link } from 'react-router-dom';

export function PromotionalCalloutModule() {
  return (
    <div className="promotional-callout">
      <div className="title2">Earn Valuable CE Credit</div>

      <p className="p3">
        Review state CE credit information by course, including state insurance
        CE. We do our best to keep this page current, but make sure to verify CE
        credits with your individual state.
      </p>

      <Link
        to={{
          pathname: `${process.env.REACT_APP_MARKETING_URL}learn/browse-all-programs`,
        }}
        target="_blank"
        className="btn tertiary"
      >
        Search CE By State
      </Link>
    </div>
  );
}
