import { useState } from 'react';

import { useMutation } from 'react-query';
import OktaRequests from '../../_okta/utils/utils';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

export default function PasswordReset({ account }) {
  const [oktaAccount, setOktaAccount] = useState(account);
  return (
    <div>
      {{
        RECOVERY: (
          <ValidateRecoveryAnswerForm
            account={oktaAccount}
            setAccount={setOktaAccount}
          />
        ),
        PASSWORD_RESET: (
          <PasswordResetForm
            account={oktaAccount}
            setAccount={setOktaAccount}
          />
        ),
        SUCCESS: (
          <>
            <h2>Success!</h2>
            <p>Account password successfully updated.</p>
            <a href="/" className="btn">
              Login
            </a>
          </>
        ),
      }[oktaAccount.status] || (
        <>
          <h2>There was an issue!</h2>
          <p>
            You cannot reset your password through the website at this time.
            Please contact the American College of Financial Services at{' '}
            <a href="tel:8882637265">888-263-7265</a>.
          </p>
        </>
      )}
    </div>
  );
}

function ValidateRecoveryAnswerForm({ account, setAccount }) {
  const [error, setError] = useState('');
  const { verifyRecoveryAnswer } = OktaRequests();

  const { isLoading: answerVerificationLoading, mutate: verifyAnswer } =
    useMutation((values) => verifyRecoveryAnswer(values));

  const formSubmit = async (values) => {
    if (!answerVerificationLoading) {
      try {
        // The state token is needed
        // for this process flow.
        const data = {
          stateToken: account.stateToken,
          answer: values.answer,
        };
        // validate the recovery answer inputted.
        verifyAnswer(data, {
          onError: (res) => setError(res.data.summary),
          onSuccess: (data) => {
            if (data) {
              setAccount(data);
            } else {
              setError('There was an issue verifying the token.');
            }
          },
        });
      } catch (error) {
        setError(error);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    answer: Yup.string().required('Field is required'),
  });

  return (
    <>
      <h2>Answer Forgotten Password Challenge</h2>
      {error && <p>{error}</p>}
      <Formik
        initialValues={{
          answer: '',
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="validateAnswer">
            <div>
              <p>
                Recovery Question:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      account._embedded?.user?.recovery_question?.question,
                  }}
                />
              </p>
              <div className="field">
                <Field
                  type="password"
                  id="answer"
                  name="answer"
                  value={values.answer}
                  className={touched.answer && errors.answer ? 'error' : null}
                  onChange={handleChange}
                  placeholder="Answer"
                  onBlur={handleBlur}
                />
                {touched.answer && errors.answer && (
                  <span className="red-text">{errors.answer}</span>
                )}
              </div>
            </div>

            <div className="flex">
              <button type="submit" form="validateAnswer" className="btn">
                Continue
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

function PasswordResetForm({ account, setAccount }) {
  const [error, setError] = useState('');
  const { resetPassword } = OktaRequests();
  const accountLogin = account._embedded.user.profile.login;

  const { isLoading: passwordResetting, mutate: reset } = useMutation(
    (values) => resetPassword(values),
  );

  const formSubmit = async (values) => {
    if (!passwordResetting) {
      try {
        // The state token is needed
        // for this process flow.
        const data = {
          stateToken: account.stateToken,
          pass: values.pass,
        };
        // Set the user password.
        reset(data, {
          onError: (res) => setError(res.data.summary),
          onSuccess: (data) => {
            if (data) {
              setAccount(data);
            } else {
              setError('There was an issue verifying the token.');
            }
          },
        });
      } catch (error) {
        setError(error);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    pass: Yup.string()
      .test(
        'does-not-contain-email',
        'You may not use parts of your username in your password',
        (value, context) => {
          if (!value) {
            return true;
          }
          const emailParts = accountLogin.toLowerCase().split('@');
          if (value.toLowerCase().includes(emailParts[0])) {
            return false;
          }
          if (value.toLowerCase().includes(emailParts[1])) {
            return false;
          }
          return true;
        },
      )
      .required('Field is required')
      .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
      .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
      .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
      .min(8, 'Must use 8 or more characters'),
    confirmPass: Yup.string()
      .oneOf([Yup.ref('pass'), null], "Passwords don't match!")
      .required('Field is required'),
  });

  return (
    <>
      <h2>Change your password</h2>
      {error && <p>{error}</p>}
      <Formik
        initialValues={{
          pass: '',
          confirmPass: '',
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          formSubmit(values);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="resetPassword">
            <div>
              <p>
                <span className="section-header">PASSWORD: </span>Your password
                must have at least eight (8) characters, a lowercase letter, an
                uppercase letter, a number, and contain no parts of your
                username.
              </p>
              <div className="field">
                <Field
                  type="password"
                  id="pass"
                  name="pass"
                  value={values.pass}
                  className={touched.pass && errors.pass ? 'error' : null}
                  onChange={handleChange}
                  placeholder="Password"
                  onBlur={handleBlur}
                />
                {touched.pass && errors.pass && (
                  <span className="red-text">{errors.pass}</span>
                )}
              </div>

              <div className="field">
                <Field
                  type="password"
                  id="confirmPass"
                  name="confirmPass"
                  value={values.confirmPass}
                  className={
                    touched.confirmPass && errors.confirmPass ? 'error' : null
                  }
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  onBlur={handleBlur}
                />
                {touched.confirmPass && errors.confirmPass && (
                  <span className="red-text">{errors.confirmPass}</span>
                )}
              </div>
            </div>

            <div className="flex">
              <button type="submit" form="resetPassword" className="btn">
                Change Password
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
