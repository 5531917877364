import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import routesList from '../../routes/routesList';

export function Breadcrumbs({ path, overrideTitle }) {
  const match = useRouteMatch();

  const filteredRoutes = routesList.filter(
    (route) => match.path.includes(route.path) && route.breadcrumb,
  );

  return (
    <div className="breadcrumbs container">
      {filteredRoutes.map((route, i) => {
        return (
          <span key={i}>
            {i < filteredRoutes.length - 1 ? (
              <>
                <Link to={route.path} className="underline-link">
                  {route.breadcrumb}
                </Link>
                &nbsp; / &nbsp;
              </>
            ) : (
              <span className="font-semibold">
                {/* Using this so we can use &reg; */}
                {overrideTitle ? (
                  <span dangerouslySetInnerHTML={{__html: overrideTitle}}/>
                ) : (
                  route.breadcrumb
                )}
              </span>
            )}
          </span>
        );
      })}
      {filteredRoutes.length === 0 && (
        <Link to="/dashboard" className="underline-link">
          Dashboard
        </Link>
      )}
    </div>
  );
}
