import { Fragment } from 'react';
import { ResourceItem } from './ResourceItem';

export function ResourceGrid({ resources, saved }) {
  return (
    <>
      {Array.isArray(resources?.pages) && (
        <div className="resourceGrid">
          {resources?.pages.map((group, i) => (
            <Fragment key={i}>
              {Array.isArray(group) &&
                group.map((resource) => {
                  return (
                    <ResourceItem
                      key={resource.nid}
                      resource={resource}
                      saved={saved}
                    />
                  );
                })}
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
}
