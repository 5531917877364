import { useState } from 'react';

import { useQuery } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ReadStorage from '../storage/ReadStorage';

import { WarningIcon } from '../subcomponents/Icon';
import ToolTip from '../subcomponents/ToolTip';

import {
  BasicInfoForm,
  DemographicInfoForm,
  ContactInfoForm,
} from './PersonalInfoForms';

import {
  CompanyInfoForm,
  CareerEducationInfoForm,
  AdvisorGuideInfoForm,
} from './ProfessionalInfoForms';

//import { PaymentMethodsForm } from './PaymentMethodsForm';
import {
  SecurityQuestionForm,
  PasswordForm,
  BackupEmailForm,
  SMSFactorForm,
} from './SecurityInfoForm';

export default function ProfileTabs({
  userData,
  combinedProfile,
  setProfile,
  setPercentComplete,
}) {
  const [value, setValue] = useState(0);
  const persComplete = ReadStorage('persComplete');
  const profComplete = ReadStorage('profComplete');

  const persTitle = persComplete ? (
    'Personal Information'
  ) : (
    <span className="info-missing">
      Personal Information
      <ToolTip icon=<WarningIcon /> useDiv={true}>
        <div className="text-left">
          <h5 className="t-midnight">
            <WarningIcon /> Missing Information
          </h5>
          <p>Please fill in missing fields to complete your profile.</p>
        </div>
      </ToolTip>
    </span>
  );
  const profTitle = profComplete ? (
    'Professional Information'
  ) : (
    <span className="info-missing">
      Professional Information
      <ToolTip icon=<WarningIcon /> useDiv={true}>
        <div className="text-left">
          <h5 className="t-midnight">
            <WarningIcon /> Missing Information
          </h5>
          <p>Please fill in missing fields to complete your profile.</p>
        </div>
      </ToolTip>
    </span>
  );

  // Load the tacfs helper Service Worker.
  const { load } = useTacfsService();
  const { isLoading, data } = useQuery(['studentFlags'], () =>
    load('studentFlags'),
  );

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleChangeMobile = (e) => {
    setValue(parseInt(e.target.value));
  };

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <div>
      <div className="profile-tabs tabs-layout">
        <Tabs
          value={value}
          onChange={handleChange}
          className="profile-tabs-inner"
          aria-label="my profile tabs"
          classes={{ indicator: 'tabs-indicator' }}>
          {/* If we add back payment methods, need to move up index */}
          <Tab disableRipple label={persTitle} {...tabProps(0)} />
          <Tab disableRipple label={profTitle} {...tabProps(1)} />
          {/*<Tab disableRipple label="Payment Methods" {...tabProps(2)} />*/}
          <Tab disableRipple label="Security Information" {...tabProps(2)} />
        </Tabs>
      </div>

      <div className="profile-select tabs-layout-mobile field">
        <label htmlFor="profile-dropdown">
          Sort By
          {(!persComplete || !profComplete) && (
            <ToolTip placement="top-end" icon=<WarningIcon />>
              <div>
                <h5 className="t-midnight">
                  <WarningIcon /> Missing Information
                </h5>
                <p>Please fill in missing fields to complete your profile.</p>
              </div>
            </ToolTip>
          )}
        </label>
        <select
          id="profile-dropdown"
          value={value}
          onChange={handleChangeMobile}>
          {/* If we add back payment methods, need to move up index */}
          <option value={0}>Personal Information</option>
          <option value={1}>Professional Information</option>
          {/*<option value={2}>Payment Methods</option>*/}
          <option value={2}>Security Information</option>
        </select>
      </div>

      {/* Personal Information */}
      <ProfileTabPanel value={value} index={0}>
        <BasicInfoForm
          userData={userData}
          combinedProfile={combinedProfile}
          setProfile={setProfile}
          setPercentComplete={setPercentComplete}
        />
        <DemographicInfoForm
          userData={userData}
          combinedProfile={combinedProfile}
          setProfile={setProfile}
          setPercentComplete={setPercentComplete}
        />
        <ContactInfoForm
          userData={userData}
          combinedProfile={combinedProfile}
          setProfile={setProfile}
          setPercentComplete={setPercentComplete}
        />
      </ProfileTabPanel>

      {/* Professional Information */}
      <ProfileTabPanel value={value} index={1}>
        <CompanyInfoForm
          userData={userData}
          combinedProfile={combinedProfile}
          setProfile={setProfile}
          setPercentComplete={setPercentComplete}
        />
        <CareerEducationInfoForm
          userData={userData}
          combinedProfile={combinedProfile}
          setProfile={setProfile}
          setPercentComplete={setPercentComplete}
        />
        {isLoading === false && data && data.yag_access === 'Y' && (
          <AdvisorGuideInfoForm combinedProfile={combinedProfile} />
        )}
      </ProfileTabPanel>

      {/* Payment Methods */}
      {/*<ProfileTabPanel value={value} index={2}>
        <PaymentMethodsForm />
      </ProfileTabPanel>*/}

      {/* If we add back payment methods, need to move up index */}
      {/* Security Information */}
      <ProfileTabPanel value={value} index={2}>
        {/* Only show the security question and password form for non-federation users */}
        {userData?.credentials?.provider?.type?.toLowerCase() !==
        'federation' ? (
          <>
            <SecurityQuestionForm userData={userData} />
            <PasswordForm userData={userData} />
            <SMSFactorForm userData={userData} />
          </>
        ) : (
          <div class="card drop-box padding--sm">
            <p>
              The option to update your login information is currently
              unavailable. According to your company's protocols, we kindly ask
              that all login and password management be conducted through your
              company's designated channels.
            </p>
          </div>
        )}
        <BackupEmailForm userData={userData} />
      </ProfileTabPanel>
    </div>
  );
}

function ProfileTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="profile-panel"
      {...other}>
      {value === index && (
        <div className="card-layout halves sm-gap">{children}</div>
      )}
    </div>
  );
}
