import { useState, useEffect } from "react";

export function AlumniToolkit({ data }) {
  const [classes, setClasses] = useState('btn');
  const linkOptions = {
    "external":{target: "_blank"},
    "same":{target: "_self"},
    "download":{download:"", target: "_blank"}, // Chrome won't download cross-origin files. This at least opens downloads in a new tab.
  };
  
  useEffect(() => {
    if (data.linkText === 'Press Release Template') {
      setClasses('btn wide');
    }
    else if (data.linkText === 'View and Share Video') {
      setClasses('btn wide');
    }
  }, [data]);

  return (
      <div className="card drop-box padding--sm transcript">
        <div className="card-holder column-layout">
          <div className={!data.link ? "card-header col--social" : "card-header col--fill"}>
            <h4>{data.title}</h4>
          </div>
          <div className={!data.link ? "col--social" : "col--thirds"}>
            <div className="buttons">   
            { !data.link ? (
              <div className="buttons-wrapper">
                <a href={data.links[1].link} className="btn wide adjust-width add-bottom-margin " {...linkOptions[data.links[1].open]}>
                    {data.links[1].linkText}
                </a>
                <a href={data.links[0].link} className="btn wide adjust-width" {...linkOptions[data.links[0].open]}>
                    {data.links[0].linkText}
                </a>
              </div>
            ) : (
              <a href={data.link} className={classes} {...linkOptions[data.open]}>
                { data.linkText }
              </a>
            )}                       
            </div>
          </div>
        </div>
        <hr className="card-hr" />
        <div className="card-content">
          {/* Noting here we do this so that the copy from the json file can be rendered with correct formatting. */}
          <p dangerouslySetInnerHTML={{__html: data.copy}}/>
        </div>
      </div>
  )
}