import { useState } from 'react';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { TransferOfCreditForm } from '../components/transferOfCredit/TransferOfCreditForm';

export function TransferOfCredit() {
  const [sent, setSent] = useState(false);

  const showSent = () => setSent(true);

  // If the api call was successfull the show the thank you copy.
  if (sent) {
    return (
      <Layout>
        <Breadcrumbs />
        <div className="container">
          <div className="intro-content-wrapper">
            {/* TODO: Maybe pull this text from the database so nothing is hardcoded. */}
            <h1 className="thank-title">
              Thank you for submitting your application.
            </h1>
            <p className="thank-message">
              The Office of the Registrar will evaluate your request shortly. You
              will be contacted by email once your request has been processed. If
              there are any questions or concerns, please email{' '}
              <a href="mailto:registrar@theamericancollege.edu">
                Registrar@theamericancollege.edu
              </a>
              .
            </p>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />
      <div className="container">
        <div className="intro-content-wrapper">
          <div className="col-xs-12">
            {/* TODO: Maybe pull this text from the database so nothing is hardcoded. */}
            <h3 className="divider-band">Application For Transfer Of Credit</h3>
            <h4>From Colleges And Universities</h4>
            <div>
              <p>
                The American College of Financial Services credit transfer
                policy for Huebner School courses permits students who completed
                comparable courses at an accredited college or university within
                seven (7) years prior to the date of this application to apply
                for transfer of credit into a designation program of The College
                (CLU<sup>®</sup>, ChFC<sup>®</sup>, RICP<sup>®</sup>) or into
                its CFP<sup>®</sup> Certification Education Program.
              </p>
              <p className="header-style"><strong>RULES AND PROCEDURES:</strong></p>
              <ul>
                <li>
                  <p>
                    You must be matriculated with The College in order to apply
                    for transfer of credit. If you have not matriculated, please
                    do so before submitting this application.
                    <br />
                    <strong className="header-style">NOTE</strong>:&nbsp;Any
                    unofficial transfer of credit evaluations conducted prior to
                    matriculation are not considered official and may not be
                    used towards program completion.
                  </p>
                </li>
                <li>
                  <p>
                    You must have completed the comparable course(s) within
                    seven (7) years prior to the date of this application.&nbsp;
                    <strong>
                      Any course completed more than seven (7) years prior to
                      the date of this application is ineligible for transfer of
                      credit.
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    You must provide the Registrar at College with&nbsp;
                    <strong>
                      a syllabus and sealed copy of your official transcripts
                    </strong>{' '}
                    verifying completion of the comparable course(s) you want to
                    transfer for credit. An electronic official transcript can
                    be emailed from a College/University to{' '}
                    <a href="mailto:Xiaohua.Su@TheAmericanCollege.edu">
                      Xiaohua.Su@TheAmericanCollege.edu
                    </a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Mail your official transcripts to:
                    <br />
                    <strong>
                      The Office of the Registrar&nbsp;
                      <br />
                      ATTN: Xiaohua Su&nbsp;
                      <br />
                      The American College of Financial Services
                      <br />
                      630 Allendale Road, Suite 400&nbsp;
                      <br />
                      King of Prussia, PA 19406
                    </strong>
                  </p>
                </li>
                <li>
                  <p>You must provide the information below.</p>
                </li>
              </ul>
            </div>
          </div>
          <TransferOfCreditForm showSent={showSent} />
        </div>
      </div>
    </Layout>
  );
}
