import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Timer from '../../components/subcomponents/Timer';
import { useOktaAuth } from '@okta/okta-react';

import SetStorage from '../../components/storage/SetStorage';

export function SSOAdminLoginCallback() {
  const { oktaAuth } = useOktaAuth();

  const queryParams = new URLSearchParams(window.location.search);
  const session = queryParams.get('session');
  const { uid } = useParams();

  useEffect(() => {
    // Set the encrypted masquerding uid to the session.
    SetStorage('masqueradingUser', uid);
    oktaAuth.token.getWithRedirect({
      sessionToken: session,
      responseType: ['code', 'email', 'profile'],
      state: 'null',
    });
  }, [oktaAuth, session, uid]);

  return <Timer />;
}
