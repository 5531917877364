export default function ProgressBar({ percentComplete, title }) {
  return (
    <div className="progress-bar-wrapper flex-column">
      <p className="progress-text p3">{title}</p>
      <div className="progress-bar-bg">
        <div className="progress-bar" style={{ width: percentComplete }}></div>
      </div>
    </div>
  );
}
