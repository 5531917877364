import { Link } from 'react-router-dom';

export function QuickLinksModule() {
  const linksList = [
    {
      title: 'Discover Our Strategic Priorities',
      href: `${process.env.REACT_APP_MARKETING_URL}about-the-college`,
      target: '_blank',
    },
    {
      title: 'Take the Advisor Advice Accelerator Quiz',
      href: `${process.env.REACT_APP_MARKETING_URL}learn/advice-accelerator-quiz`,
      target: '_blank',
    },
    {
      title: 'Explore All Learning Opportunities',
      href: `${process.env.REACT_APP_MARKETING_URL}learn-at-the-college`,
      target: '_blank',
    },
    {
      title: 'Engage with Centers of Excellence',
      href: `${process.env.REACT_APP_MARKETING_URL}centers-of-excellence`,
      target: '_blank',
    },
    {
      title: 'Transfer of Credit Application',
      href: `/transfer-of-credit-application`,
      target: '_self',
    },
    {
      title: 'CFP Transfer of Credit Application',
      href: `/cfp-transfer-of-credit-application`,
      target: '_self',
    },
  ];

  return (
    <div className="quick-links">
      <div className="title3">Quick Links</div>

      <ul>
        {linksList.map(({ title, href, target }) => {
          return (
            <li key={title}>
              <Link
                to={{ pathname: href }}
                className="underline-link"
                target={target}>
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
